/*
 *
 *   Is used for machine dashboard, if no machine is selected or no data is available.
 *
 */

import React from "react";
import { Container, Typography } from "@mui/material";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";

interface IProps {
  label?: string;
}

export default function NoMachineView({ label }: IProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const displayLabel = label ? label : t("dashboard.machine.noMachineSelected");
  return (
    <Container className={classes.container}>
      <Typography color="primary" variant="h3">
        {displayLabel}
      </Typography>
    </Container>
  );
}
