/*
 *
 *   Service for creating timestamps on time selection. Timestamps are necessary for querying data from backend.
 *
 */

import moment from "moment-timezone";
import { TTimeSelectionKey } from "../redux/reducers/timeSelectReducer";
import {
  EJobTimeSelection,
  TJobTimeSelectionKey,
} from "../shared/components/jobDateTimeSelection/components/jobTimePeriodSelection";

// eslint-disable-next-line complexity
export default function createUtcTimestamp(
  startedAfter: boolean,
  value: TTimeSelectionKey | TJobTimeSelectionKey,
  timezone: string,
): string {
  const now = `${moment.tz(timezone).toISOString().slice(0, -5)}Z`;

  // Today.
  if (value === "today") {
    if (startedAfter) {
      return `${moment
        .tz(timezone)
        .clone()
        .startOf("day")
        .toISOString()
        .slice(0, -5)}Z`;
    }
    return now;
  }

  // This Week.
  if (value === "this-week") {
    if (startedAfter) {
      return `${moment
        .tz(timezone)
        .clone()
        .startOf("isoWeek")
        .toISOString()
        .slice(0, -5)}Z`;
    }
    return now;
  }

  // This Month.
  if (value === "this-month") {
    if (startedAfter) {
      return `${moment
        .tz(timezone)
        .clone()
        .startOf("month")
        .toISOString()
        .slice(0, -5)}Z`;
    }
    return now;
  }

  // This Year.
  if (value === "this-year") {
    if (startedAfter) {
      return `${moment
        .tz(timezone)
        .clone()
        .startOf("year")
        .toISOString()
        .slice(0, -5)}Z`;
    }
    return now;
  }

  // Last Day.
  if (value === "last-day") {
    if (startedAfter) {
      return `${moment
        .tz(timezone)
        .subtract(1, "days")
        .startOf("day")
        .toISOString()
        .slice(0, -5)}Z`;
    }
    return `${moment.tz(timezone).startOf("days").toISOString().slice(0, -5)}Z`;
  }

  // Last Week.
  if (value === "last-week") {
    if (startedAfter) {
      return `${moment
        .tz(timezone)
        .subtract(1, "weeks")
        .startOf("isoWeek")
        .toISOString()
        .slice(0, -5)}Z`;
    }
    return `${moment
      .tz(timezone)
      .startOf("isoWeek")
      .toISOString()
      .slice(0, -5)}Z`;
  }

  // Last Month.
  if (value === "last-month") {
    if (startedAfter) {
      return `${moment
        .tz(timezone)
        .subtract(1, "months")
        .startOf("month")
        .toISOString()
        .slice(0, -5)}Z`;
    }
    return `${moment
      .tz(timezone)
      .startOf("month")
      .toISOString()
      .slice(0, -5)}Z`;
  }

  // Last Year.
  if (value === "last-year") {
    if (startedAfter) {
      return `${moment
        .tz(timezone)
        .subtract(1, "years")
        .startOf("year")
        .toISOString()
        .slice(0, -5)}Z`;
    }
    return `${moment
      .tz(timezone)
      .startOf("years")
      .toISOString()
      .slice(0, -5)}Z`;
  }

  if (value === EJobTimeSelection.NOW) {
    return now;
  }

  if (value === EJobTimeSelection.In10Min) {
    return `${moment
      .tz(timezone)
      .add(10, "minutes")
      .toISOString()
      .slice(0, -5)}Z`;
  }

  if (value === EJobTimeSelection.in30min) {
    return `${moment
      .tz(timezone)
      .add(30, "minutes")
      .toISOString()
      .slice(0, -5)}Z`;
  }

  if (value === EJobTimeSelection.in1hour) {
    return `${moment.tz(timezone).add(1, "hour").toISOString().slice(0, -5)}Z`;
  }

  if (value === EJobTimeSelection.in6hour) {
    return `${moment.tz(timezone).add(6, "hours").toISOString().slice(0, -5)}Z`;
  }

  if (value === EJobTimeSelection.in24hour) {
    return `${moment
      .tz(timezone)
      .add(24, "hours")
      .toISOString()
      .slice(0, -5)}Z`;
  }

  return "";
}
