import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  form: {
    "& .MuiFormControl-root": {
      marginTop: 25,
      minWidth: 400,
    },
    "& .MuiButton-root": {
      marginTop: 25,
    },
    paddingBottom: 30,
  },
}));
export default useStyles;
