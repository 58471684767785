/*
 *   Machinelist Reducer for storing a list of all machines.
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import IGetMachineLists from "../../api/machine/IGetMachineLists";
import IMachineLists from "../../api/machine/IMachineLists";
import { IMachine } from "../../api/machine/IMachine";

const initialState: IMachine[] = [];

export const machineListReducer = createSlice({
  name: "machineList",
  initialState,
  reducers: {
    setMachineList: (state, action: PayloadAction<IGetMachineLists>) => {
      // Create a copy of the current state
      const newState = [...state];

      action.payload.getMachineLists.map((machineLists: IMachineLists) => {
        const { id } = machineLists.machineType;

        // Check if a machine with the same id already exists in the state
        const existingMachineIndex = newState.findIndex(
          (machine) => machine.id === id,
        );

        // If the machine with the same id doesn't exist, push the new machine object
        if (existingMachineIndex === -1) {
          const { machineIdentificationType } = machineLists.machineType;
          const {
            componentName,
            deviceLabel,
            deviceLabelExtension,
            deviceClass,
            deviceSublabel,
            machineImageType,
            location,
            statusCode,
          } = machineIdentificationType;
          newState.push({
            id: machineLists.machineType.id,
            name: machineLists.machineListIdentificationType.machineListName,
            deviceClass,
            location,
            componentName,
            deviceLabel,
            deviceLabelExtension,
            deviceSublabel,
            image: machineImageType.image,
            statusCode,
          });
        }
      });
      newState.sort((a, b) => a.id.localeCompare(b.id));
      return newState;
    },
  },
});

export const selectMachineList = (state: RootState): IMachine[] =>
  state.machineList;

export const { setMachineList } = machineListReducer.actions;

export default machineListReducer.reducer;
