import { useEffect, useState } from "react";
import ICategory from "../../api/ICategory";
import { COMPONENTS } from "../../api/MOCKS";
import { EApiState } from "../../../../../../../api";

export function useQueryComponents(): { components; apiState } {
  const [apiState, setApiState] = useState(EApiState.LOADING);
  const [components, setComponents] = useState<ICategory[]>([]);
  useEffect(() => {
    setApiState(EApiState.LOADING);
    const t = setTimeout(() => {
      setComponents(COMPONENTS);
      setApiState(EApiState.OK);
    }, 600);
    return () => {
      clearTimeout(t);
    };
  }, []);
  return { apiState, components };
}
