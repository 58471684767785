import React, { useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import LoginProgress from "./components/LoginProgress";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useLanguage from "../../../helper/language/useLanguage";

interface IProps {
  children: React.ReactElement;
}
enum ELoginState {
  IN_PROGRESS = "IN_PROGRESS",
  LOGGED_IN = "LOGGED_IN",
  UNAUTHENTICATED = "UNAUTHENTICATED",
  UNDEFINED = "UNDEFINED",
  ERROR = "ERROR",
}

export default function LoginGuard({ children }: IProps): React.ReactElement {
  const { t } = useTranslation();
  const [loginState, setLoginState] = useState<ELoginState>(
    ELoginState.UNAUTHENTICATED,
  );
  const { isLoading: isLanguageInitializationLoading } = useLanguage();

  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    if (
      inProgress !== InteractionStatus.None ||
      // we need to wait for language initialization here
      // else the ui will switch from default lang to selected lang
      isLanguageInitializationLoading
    ) {
      setLoginState(ELoginState.IN_PROGRESS);
    } else if (isAuthenticated) {
      setLoginState(ELoginState.LOGGED_IN);
    } else if (inProgress === InteractionStatus.None && !isAuthenticated) {
      setLoginState(ELoginState.UNAUTHENTICATED);
    }
  }, [inProgress, isAuthenticated, isLanguageInitializationLoading]);

  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    instance
      .loginRedirect()
      .then(() => {
        /* no-op */
      })
      .catch(() => {
        setLoginState(ELoginState.ERROR);
      });
  }
  let app = <></>;
  switch (loginState) {
    case ELoginState.LOGGED_IN:
      app = <>{children}</>;
      break;
    case ELoginState.IN_PROGRESS:
      app = <LoginProgress />;
      break;
    case ELoginState.UNAUTHENTICATED:
      app = <Typography>{t("app.error.unauthenticated")}</Typography>;
      break;
    case ELoginState.ERROR:
      app = <Typography>{t("app.error.unauthenticatedErr")}</Typography>;
      break;
    default:
      app = <LoginProgress />;
  }

  return <>{app}</>;
}
