import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  container: {
    minHeight: 600,
  },
  itemContainer: {
    margin: 16,
    height: 200,
    cursor: "pointer",
  },
});

export default useStyles;
