import {
  ITimeSelectionState,
  ITimeSlot,
  selectTimeSelection,
  setTimeSelection,
  TTimeSelectionKey,
} from "../../../redux/reducers/timeSelectReducer";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import getTimeScaleByStartAndEnd from "../../../helper/time/scale/getTimeScaleByStartAndEnd";
import { store } from "../../../redux/store";
import { Layouts } from "react-grid-layout";
import TimeSelection from "../timeSelection";
import GeorgButton, { EButtonSize, EButtonType } from "../GeorgButton";
import createUtcTimestamp from "../../../services/createUtcTimestamp";
import {
  APPLY_BUTTON_LAYOUT_KEY,
  dropDownHeight,
  getLayoutInfoLg,
  getLayoutInfoMd,
  getLayoutInfoSm,
  getLayoutInfoXl,
  getSmallLayoutInfoLg,
  getSmallLayoutInfoMd,
  getSmallLayoutInfoSm,
  getSmallLayoutInfoXl,
  MAIN_TIME_PERIOD_LAYOUT_KEY,
  REFERENCE_TIME_PERIOD_LAYOUT_KEY,
  smallDropDownHeight,
  timeRangeHeight,
  TResizeConfig,
} from "./layoutConfig";
import GridPage from "../gridPage";
import useStyles from "./styles";
import getFiltersWithPendingChanges from "./helper/getFiltersWithPendingChanges";

export enum EFilterParameters {
  STARTED_AFTER = "startedAfter",
  ENDED_BEFORE = "endedBefore",
  SHIFT = "shift",
  PERIOD = "period",
}
interface IProps {
  timezone: string;
  isShowingReference?: boolean;
  onNeedsMoreHeight?: (offset: number) => void;
  parentBreakPoint?: string;
}

const getLayoutInfo = (size, isShowingReference, resizeConf) => {
  switch (size) {
    case "xl":
      return isShowingReference
        ? getLayoutInfoXl(resizeConf)
        : getSmallLayoutInfoXl(resizeConf);
    case "lg":
      return isShowingReference
        ? getLayoutInfoLg(resizeConf)
        : getSmallLayoutInfoLg(resizeConf);
    case "md":
      return isShowingReference
        ? getLayoutInfoMd(resizeConf)
        : getSmallLayoutInfoMd(resizeConf);
    default:
      return isShowingReference
        ? getLayoutInfoSm(resizeConf)
        : getSmallLayoutInfoSm(resizeConf);
  }
};

// update function to check and update the period
const updateTimePeriod = (filter: ITimeSlot, timezone: string) => {
  const updatedFilter: ITimeSlot = { ...filter };
  if (filter.selectedPeriod !== "individual") {
    updatedFilter.startedAfter = createUtcTimestamp(
      true,
      filter.selectedPeriod,
      timezone,
    );
    updatedFilter.endedBefore = createUtcTimestamp(
      false,
      filter.selectedPeriod,
      timezone,
    );
  }
  updatedFilter.scale = getTimeScaleByStartAndEnd(
    updatedFilter.startedAfter,
    updatedFilter.endedBefore,
  ).scale;
  return updatedFilter;
};

export default function TimeSelectionCurrentReference({
  timezone,
  isShowingReference = true,
  onNeedsMoreHeight,
  parentBreakPoint = "",
}: IProps): React.ReactElement {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const timeSelection: ITimeSelectionState = useSelector(selectTimeSelection);
  const { currentFilter, referenceFilter } = timeSelection;
  const [currentPeriodStartedAfter, setCurrentPeriodStart] = useState(
    currentFilter.startedAfter,
  );
  const [currentPeriodEndedBefore, setCurrentPeriodEnd] = useState(
    currentFilter.endedBefore,
  );

  const [resizeConf, setResizeconf] = useState<TResizeConfig>(
    {} as TResizeConfig,
  );
  const [currentLayout, setCurrentLayout] = useState<Layouts>({});
  const layoutConf = useMemo(
    () => getLayoutInfo(parentBreakPoint, isShowingReference, resizeConf),
    [resizeConf, parentBreakPoint, isShowingReference],
  );
  useEffect(() => {
    setCurrentLayout(layoutConf);
  }, [layoutConf]);

  const [currentShift, setCurrentShift] = useState<string>(currentFilter.shift);

  const [referencePeriodStartedAfter, setReferencePeriodStart] = useState(
    referenceFilter.startedAfter,
  );
  const [referencePeriodEndedBefore, setReferencePeriodEnd] = useState(
    referenceFilter.endedBefore,
  );
  const [referenceShift, setReferenceShift] = useState<string>(
    referenceFilter.shift,
  );
  const [currentSelectedPeriod, setCurrentSelectedPeriod] =
    useState<TTimeSelectionKey>(currentFilter.selectedPeriod);
  const [referenceSelectedPeriod, setReferenceSelectedPeriod] =
    useState<TTimeSelectionKey>(referenceFilter.selectedPeriod);
  const [currentError, setCurrentError] = useState(false);
  const [referenceError, setReferenceError] = useState(false);
  const [isSmallLayout, setIsSmallLayout] = useState<boolean>(false);

  useLayoutEffect(() => {
    setIsSmallLayout(
      parentBreakPoint === "xxs" ||
        parentBreakPoint === "xs" ||
        parentBreakPoint === "sm",
    );
  }, [parentBreakPoint, isShowingReference]);

  useEffect(() => {
    if (currentShift !== currentFilter.shift) {
      setCurrentShift(currentFilter.shift);
    }
    if (referenceShift !== referenceFilter.shift) {
      setReferenceShift(referenceFilter.shift);
    }
  }, [currentFilter, referenceFilter]);

  // Checks the consistency between period with (start and end times)
  useLayoutEffect(() => {
    const newCacheFilter = updateTimePeriod(currentFilter, timezone);
    const newReferenceFilter = updateTimePeriod(referenceFilter, timezone);
    setCurrentPeriodStart(newCacheFilter.startedAfter);
    setCurrentPeriodEnd(newCacheFilter.endedBefore);
    setReferencePeriodStart(newReferenceFilter.startedAfter);
    setReferencePeriodEnd(newReferenceFilter.endedBefore);
    store.dispatch(
      setTimeSelection({
        currentFilter: newCacheFilter,
        referenceFilter: newReferenceFilter,
      }),
    );
  }, []);

  const { currentChanges, referenceChanges } = getFiltersWithPendingChanges(
    currentFilter,
    referenceFilter,
    currentPeriodStartedAfter,
    currentPeriodEndedBefore,
    currentShift,
    referencePeriodStartedAfter,
    referencePeriodEndedBefore,
    referenceShift,
    currentSelectedPeriod,
    referenceSelectedPeriod,
  );
  const hasSettingsChanged =
    currentChanges.length > 0 || referenceChanges.length > 0;

  useLayoutEffect(() => {
    moreHeightNeeded(true);
  }, [isShowingReference, parentBreakPoint]);

  const getReferenceHeight = useCallback(
    (selectedKey: TTimeSelectionKey) =>
      selectedKey === "individual" && isShowingReference ? timeRangeHeight : 0,
    [isShowingReference],
  );

  const moreHeightNeeded = useCallback(
    (isCalculationNeeded: boolean, currentHeight = 0, referenceHeight = 0) => {
      const isXLOrLgBreakpoint = ["xl", "lg"].includes(parentBreakPoint);
      const isNotSmallBreakpoint = ["xl", "lg", "md"].includes(
        parentBreakPoint,
      );
      const baseReferenceHeight = isShowingReference
        ? isNotSmallBreakpoint
          ? dropDownHeight
          : dropDownHeight + smallDropDownHeight
        : 0;
      if (onNeedsMoreHeight) {
        if (isCalculationNeeded) {
          currentHeight =
            currentSelectedPeriod === "individual" ? timeRangeHeight : 0;
          referenceHeight = getReferenceHeight(referenceSelectedPeriod);
        }
        setResizeconf((prevResizeConfig) => ({
          ...prevResizeConfig,
          [MAIN_TIME_PERIOD_LAYOUT_KEY]: currentHeight,
          [REFERENCE_TIME_PERIOD_LAYOUT_KEY]:
            baseReferenceHeight +
            (isXLOrLgBreakpoint && isShowingReference
              ? Math.max(referenceHeight, currentHeight)
              : referenceHeight),
        }));
        const parentNewOffset = isXLOrLgBreakpoint
          ? Math.max(referenceHeight, currentHeight)
          : currentHeight + referenceHeight;
        onNeedsMoreHeight(parentNewOffset);
      }
    },
    [
      onNeedsMoreHeight,
      referenceSelectedPeriod,
      currentSelectedPeriod,
      isShowingReference,
      parentBreakPoint,
      isSmallLayout,
      currentLayout,
    ],
  );

  const handleErrorChange = useCallback((period: string, hasError: boolean) => {
    if (period === "current") {
      setCurrentError(hasError);
    } else if (period === "reference") {
      setReferenceError(hasError);
    }
  }, []);

  const isApplyButtonDisabled = useMemo(
    () => currentError || referenceError,
    [currentError, referenceError],
  );
  const isNotXL = ["xxs", "xs", "sm", "md", "lg"].includes(parentBreakPoint);
  const buttonLabel = (t("common.timeRange.apply") as string).toUpperCase();
  const alignItems = isNotXL ? "unset" : "flex-end";
  const buttonWidth = isNotXL ? "100%" : 180;

  return (
    <GridPage
      layouts={currentLayout}
      gridBackgroundColor="transparent"
      containerPadding={[0, 0]}
      itemMargin={[16, 0]}
    >
      <div key={MAIN_TIME_PERIOD_LAYOUT_KEY}>
        <TimeSelection
          initPeriod={currentFilter.selectedPeriod}
          initStartTime={currentFilter.startedAfter}
          initEndTime={currentFilter.endedBefore}
          initShift={currentShift}
          periodLabel={t("dataSelection.current-period")}
          onTimeChanged={(
            selectedKey: TTimeSelectionKey,
            newStart: string,
            newEnd: string,
          ) => {
            if (newStart !== "" && newEnd !== "") {
              setCurrentPeriodStart(newStart);
              setCurrentPeriodEnd(newEnd);
              setCurrentSelectedPeriod(selectedKey);
              moreHeightNeeded(
                false,
                selectedKey === "individual" ? timeRangeHeight : 0,
                getReferenceHeight(referenceSelectedPeriod),
              );
            }
          }}
          onShiftChanged={(shift: string) => {
            setCurrentShift(shift);
          }}
          period={"current"}
          timezone={timezone}
          isSmallLayout={isSmallLayout}
          onMoreHeightNeeded={() => moreHeightNeeded(true)}
          changedFilters={currentChanges}
          onErrorChange={handleErrorChange}
        />
      </div>
      <div key={REFERENCE_TIME_PERIOD_LAYOUT_KEY}>
        {isShowingReference ? (
          <TimeSelection
            initShift={referenceShift}
            initPeriod={referenceFilter.selectedPeriod}
            initStartTime={referenceFilter.startedAfter}
            initEndTime={referenceFilter.endedBefore}
            periodLabel={t("dataSelection.reference-period")}
            onTimeChanged={(
              selectedKey: TTimeSelectionKey,
              newStart: string,
              newEnd: string,
            ) => {
              if (newStart !== "" && newEnd !== "") {
                setReferencePeriodStart(newStart);
                setReferencePeriodEnd(newEnd);
                setReferenceSelectedPeriod(selectedKey);
                moreHeightNeeded(
                  false,
                  currentSelectedPeriod === "individual" ? timeRangeHeight : 0,
                  getReferenceHeight(selectedKey),
                );
              }
            }}
            onShiftChanged={(shift: string) => {
              setReferenceShift(shift);
            }}
            period={"reference"}
            timezone={timezone}
            isSmallLayout={isSmallLayout}
            onMoreHeightNeeded={() => moreHeightNeeded(true)}
            changedFilters={referenceChanges}
            onErrorChange={handleErrorChange}
          />
        ) : (
          <></>
        )}
      </div>
      <div
        key={APPLY_BUTTON_LAYOUT_KEY}
        className={classes.submitBtnWrapper}
        style={{ alignItems, width: buttonWidth }}
      >
        <GeorgButton
          disabled={isApplyButtonDisabled}
          endIcon={<ChevronRightIcon color="secondary" />}
          buttonSize={EButtonSize.MEDIUM}
          buttonType={hasSettingsChanged ? EButtonType.DARK : EButtonType.LIGHT}
          label={buttonLabel}
          onClick={() => {
            const updatedCurrentFilter = updateTimePeriod(
              {
                shift: currentShift,
                scale: "DAYS",
                startedAfter: currentPeriodStartedAfter,
                endedBefore: currentPeriodEndedBefore,
                selectedPeriod: currentSelectedPeriod,
              },
              timezone,
            );

            const updatedReferenceFilter = updateTimePeriod(
              {
                shift: referenceShift,
                scale: "DAYS",
                startedAfter: referencePeriodStartedAfter,
                endedBefore: referencePeriodEndedBefore,
                selectedPeriod: referenceSelectedPeriod,
              },
              timezone,
            );

            setCurrentPeriodStart(updatedCurrentFilter.startedAfter);
            setCurrentPeriodEnd(updatedCurrentFilter.endedBefore);
            setReferencePeriodStart(updatedReferenceFilter.startedAfter);
            setReferencePeriodEnd(updatedReferenceFilter.endedBefore);

            const newFilter = {
              currentFilter: updatedCurrentFilter,
              referenceFilter: updatedReferenceFilter,
            };

            store.dispatch(setTimeSelection(newFilter));
          }}
        />
      </div>
    </GridPage>
  );
}
