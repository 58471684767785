import { IMachine } from "../../../../api/machine/IMachine";

export enum EOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export default function sortByMachineKey(
  order: EOrder,
  machines: IMachine[],
  key: "location" | "deviceClass",
): IMachine[] {
  const machinesToSort = machines.slice();
  return machinesToSort.sort((a: IMachine, b: IMachine): number => {
    const aValue = a[key];
    const bValue = b[key];
    let compared;
    if (order === EOrder.ASC) {
      compared = aValue.localeCompare(bValue);
    } else {
      compared = bValue.localeCompare(aValue);
    }
    return compared;
  });
}
