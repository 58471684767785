import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  cardContent: {
    display: "flex",
    flexDirection: "column",
  },
  center: {
    alignItems: "center",
    justifyContent: "center",
  },
  left: {
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  right: {
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  root: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "normal",
  },
  transparentBg: {
    background: "transparent",
  },
});

export default useStyles;
