import { makeStyles } from "tss-react/mui";
import { rootTheme } from "../../../../shared/theme";

const useStyles = makeStyles()({
  container: {
    alignItems: "center",
    height: 400,
    justifyContent: "center",
    ...rootTheme.viewsContainer,
  },
});

export default useStyles;
