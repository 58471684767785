import { IMachine } from "../../../api/machine/IMachine";

export interface ISelectOption {
  value: string;
  label: string | React.ReactElement;
}

export interface ITabConfig {
  label: string;
  // sub path of tab. It is a postfix e.g. basepath/{machineId}/subpath
  path: string;
}

export interface IProps {
  // path towards the page
  basePath: string;
  tabConfig: ITabConfig[];
  children: React.ReactElement[] | React.ReactElement;
  isTimePeriodSelectionShown?: boolean;
  tabsWithRefererencePeriod?: string[];
  tabsWithoutTimePeriod?: string[];
}

export function getIsMachineMatchingFunction(
  preSelectedMachineId: string,
): (machine: IMachine) => boolean {
  return (machine: IMachine): boolean => {
    return machine.id === preSelectedMachineId;
  };
}

export function getPathToSwitch(
  basePath: string,
  newMachineId: string,
): string {
  const urlParts = window.location.href.split("/");
  const lastPart = urlParts[urlParts.length - 1];
  if (lastPart === "machine") {
    return `${basePath}/${newMachineId}`;
  } else {
    return `${basePath}/${newMachineId}/${lastPart}`;
  }
}

export function getIsShowingReference(
  path: string,
  selectedMachine: string,
  isTimePeriodSelectionShown: boolean,
  tabsWithRefererencePeriod: string[] | undefined,
): boolean {
  return selectedMachine &&
    selectedMachine !== "" &&
    isTimePeriodSelectionShown &&
    tabsWithRefererencePeriod &&
    tabsWithRefererencePeriod.some((value) => path.includes(value))
    ? true
    : false;
}

export function getIsTabWithoutTimePeriod(
  path: string,
  tabsWithoutTimePeriod: string[] | undefined,
): boolean {
  return tabsWithoutTimePeriod &&
    tabsWithoutTimePeriod.some((value) => path.includes(value))
    ? true
    : false;
}
