import React from "react";
// import { useTranslation } from "react-i18next";
import { useStyles } from "./styles";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line no-empty-pattern
function TextCard(): React.ReactElement {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <div className={classes.cardContainer}>
      <Box className={classes.textContainerWithBottomPadding}>
        <Typography
          color="primary"
          variant="body1"
          className={classes.boldText}
        >
          {t("service.contact.firstTextBold")}
        </Typography>
        <Typography color="primary" variant="body1" className={classes.text}>
          {t("service.contact.firstText")}
        </Typography>
      </Box>
      <Typography
        className={classes.boldText}
        color={"primary"}
        variant="body1"
      >
        {t("service.contact.serviceText")}
      </Typography>
      <Typography
        className={`${classes.italicText} ${classes.textContainerWithBottomPadding}`}
        color={"primary"}
        variant="body1"
      >
        {t("service.contact.serviceNumber")}
      </Typography>
      <Typography className={classes.boldText} color={"primary"}>
        {t("service.contact.service247TextBold")}
      </Typography>
      <Typography className={classes.textContainer} color={"primary"}>
        {t("service.contact.service247Text")}
      </Typography>
      <div style={{ flexGrow: 1 }} />
      <div>
        <Typography className={classes.smallTextContainer} color={"primary"}>
          {t("service.contact.lastText")}
        </Typography>
      </div>
    </div>
  );
}

export default React.memo(TextCard);
