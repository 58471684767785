import { ApolloError, useLazyQuery } from "@apollo/client";
import indicators from "./getIndicatorQuery";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setIndicator } from "../../redux/reducers/indicatorReducer";

type TResponse = [
  doQuery: () => void,
  resp: {
    error: ApolloError | undefined;
    isLoading: boolean;
  },
];

// This hook is used to fetch the indicator list and dispatch it to the redux store.
export default function useLazyQueryIndicatorListAndDispatchToReduxStore(): TResponse {
  const dispatch = useDispatch();
  const [doQuery, { data, loading, error: indicatorListQueryErr }] =
    useLazyQuery(indicators, {
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (
      !indicatorListQueryErr &&
      !loading &&
      data != null &&
      data.getAllIndicatorIdentificationList != null
    ) {
      dispatch(setIndicator(data?.getAllIndicatorIdentificationList));
    }
  }, [data]);
  return [doQuery, { isLoading: loading, error: indicatorListQueryErr }];
}
