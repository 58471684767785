import moment from "moment";
import { IJobCreateOrUpdateFrontend } from "../../../../../../api/jobs/interfaces/IJobSettings";

/*
attention!
If you change the values on the right hand side,
please change the translations within translation files as well!
 */
export enum EValidationResult {
  OK = "OK",
  NAME_EMPTY = "NAME_EMPTY",
  NAME_LENGTH_VIOLATED = "NAME_LENGTH_VIOLATED",
  DESCRIPTION_EMPTY = "DESCRIPTION_EMPTY",
  DESCRIPTION_LENGTH_VIOLATED = "DESCRIPTION_LENGTH_VIOLATED",
  RESPONSIBLE_EMPTY = "RESPONSIBLE_EMPTY",
  DUE_DATE_EMPTY = "DUE_DATE_EMPTY",
  DUE_DATE_VIOLATED = "DUE_DATE_VIOLATED",
  PRIORITY_VIOLATED = "PRIORITY_VIOLATED",
  PRIORITY_EMPTY = "PRIORITY_EMPTY",
}

export const MAX_NAME_LENGTH = 25;
export const MAX_DESCRIPTION_LENGTH = 150;

function isEmptyString(
  value: string,
  emptyResult: EValidationResult,
): EValidationResult {
  return value.length !== 0 ? EValidationResult.OK : emptyResult;
}

function validateName(name: string): EValidationResult {
  return name.length > MAX_NAME_LENGTH
    ? EValidationResult.NAME_LENGTH_VIOLATED
    : isEmptyString(name, EValidationResult.NAME_EMPTY);
}

function validateScheduledAt(
  scheduledAt: string | undefined,
): EValidationResult {
  return !scheduledAt ||
    new Date(scheduledAt).getTime() < moment().subtract(1, "day").valueOf()
    ? EValidationResult.DUE_DATE_VIOLATED
    : EValidationResult.OK;
}
function validateDescription(value: string): EValidationResult {
  return value.length > MAX_DESCRIPTION_LENGTH
    ? EValidationResult.DESCRIPTION_LENGTH_VIOLATED
    : isEmptyString(value, EValidationResult.DESCRIPTION_EMPTY);
}
function validatePriority(priority: number | undefined): EValidationResult {
  if (!priority) {
    return EValidationResult.PRIORITY_EMPTY;
  }
  return priority < 0 || priority > 1000
    ? EValidationResult.PRIORITY_VIOLATED
    : EValidationResult.OK;
}

export default function validation(
  job: IJobCreateOrUpdateFrontend,
): EValidationResult[] {
  const results: EValidationResult[] = [];

  results.push(validateName(job.name || ""));
  results.push(validateScheduledAt(job.scheduledAt));
  results.push(validatePriority(job.priority));
  results.push(validateDescription(job.description || ""));

  const errorList = results.filter((r) => r !== EValidationResult.OK);
  return errorList.length > 0 ? errorList : [EValidationResult.OK];
}
