import IAzureB2cResponseType from "../IAzureB2cResponseType";
import { useMutation } from "@apollo/client";
import ResetAzureB2cUserPasswordMutation from "./ResetAzureB2cUserPasswordMutation";
import { useCallback } from "react";

interface IResetUsersPasswordResponse {
  resetAzureB2cUserPassword: IAzureB2cResponseType;
}

export default function useResetUsersPassword(): {
  mutate: (userInput: string) => void;
  isLoading: boolean;
  hasError: boolean;
  response?: IResetUsersPasswordResponse | null;
} {
  const [mutateFunction, { data, loading, error }] =
    useMutation<IResetUsersPasswordResponse>(ResetAzureB2cUserPasswordMutation);
  const mutate = useCallback((userId: string): void => {
    mutateFunction({
      variables: { userId: userId },
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
    });
  }, []);
  return {
    mutate,
    isLoading: loading,
    hasError: error != null,
    response: data,
  };
}
