import { useCallback, useState } from "react";
import useLocalStorage from "../persistence/useLocalStorage";

interface ITooltipSettingsApi {
  isTooltipsEnabled: boolean;
  setTooltipEnabled: (enabled: boolean) => void;
}

const TOOLTIP_LS_KEY = "tooltips";

/*
Please use this not directly but with GeorgStorageProvider
 */
export default function useTooltipSettings(): ITooltipSettingsApi {
  const { load, set } = useLocalStorage();
  const isTooltipEnabledFromLS = load(TOOLTIP_LS_KEY) === "true";
  const [isTooltipsEnabled, setIsTooltipsEnabledInternal] = useState<boolean>(
    isTooltipEnabledFromLS,
  );
  const setTooltipEnabled = useCallback((enabled: boolean) => {
    setIsTooltipsEnabledInternal(enabled);
    set(TOOLTIP_LS_KEY, `${enabled}`);
  }, []);

  return { isTooltipsEnabled, setTooltipEnabled };
}
