import moment, { duration } from "moment";

export interface ISecondDisplayFormat {
  minutes: string;
  hours: string;
  days: number;
}

export default function secondsToDisplayFormat(
  seconds: string,
): ISecondDisplayFormat {
  const durationOfState = duration(parseInt(seconds as string, 10), "seconds");
  const days = durationOfState.asDays();
  const hours = moment.utc(durationOfState.asMilliseconds()).format("H");
  const minutes = moment.utc(durationOfState.asMilliseconds()).format("m");
  return {
    minutes,
    hours,
    days: Math.floor(days),
  };
}
