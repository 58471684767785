import gql from "graphql-tag";

export default gql`
  query {
    getMachineLists {
      machineListIdentificationType {
        machineListName
      }
      machineType {
        id
        machineIdentificationType {
          componentName
          deviceLabel
          deviceLabelExtension
          deviceClass
          deviceSublabel
          statusCode
          location
          machineImageType {
            image
          }
        }
        variableListIdentificationTypes {
          id
          variableListName
          function
          type
        }
      }
    }
  }
`;
