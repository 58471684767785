import moment from "moment";
import { TFunction } from "i18next";

const HOUR_FORMAT = "hh:mm";

export function withZeroPrefix(timeNumber: string): string {
  return timeNumber.padStart(2, "0");
}

export function calcTimeDiff(
  //Diff of 2 timestamps, no timezone needed
  startTime: Date,
  endTime: Date,
  type: "minutes" | "hour" | "day",
): number {
  return moment(endTime, [HOUR_FORMAT]).diff(
    moment(startTime, [HOUR_FORMAT]),
    type,
  );
}

function formatTime(
  days: number,
  hours: number,
  minutes: number,
  t?: TFunction,
): string {
  if (t == null) {
    return "Error";
  }
  if (hours < 0 || days < 0) {
    return t("settings.shifts.table.timeError");
  }

  if (days > 0) {
    return `${days} ${t("common.time.helper.days")} : ${hours} ${t(
      "common.time.helper.hours",
    )}`;
  }

  return `${hours} ${t("common.time.helper.hours")} : ${minutes} ${t(
    "common.time.helper.minutes",
  )}`;
}

export default function calculateTimeDiffHourMinute(
  startTime: Date,
  endTime: Date,
  t?: TFunction,
): string {
  const hourDiff = calcTimeDiff(startTime, endTime, "hour");
  const minDiff = calcTimeDiff(startTime, endTime, "minutes");
  const dayDiff = hourDiff > 24 ? calcTimeDiff(startTime, endTime, "day") : 0;

  return formatTime(dayDiff, hourDiff % 24, minDiff % 60, t);
}

export function calculateTimeStampDiffHourMinute(
  startMoment: moment.Moment,
  endMoment: moment.Moment,
  t?: TFunction,
  isOnlyHoursAndMinutes = false,
): string {
  const duration = moment.duration(endMoment.diff(startMoment));
  if (isOnlyHoursAndMinutes) {
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    return formatTime(0, hours, minutes, t);
  }
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();

  return formatTime(days, hours, minutes, t);
}
