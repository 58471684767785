// timePeriodOptions.ts
import { useTranslation } from "react-i18next";
import ITimeSelectionPeriodOption from "./ITimeSelectionPeriodOption";

export const getPeriodSelectOptions = (): ITimeSelectionPeriodOption[] => {
  const { t } = useTranslation();
  // The period list is sorted according to (Ticket: 1027)
  return [
    { value: "today", label: t("dataSelection.today") },
    { value: "last-day", label: t("dataSelection.last-day") },
    { value: "this-week", label: t("dataSelection.this-week") },
    { value: "last-week", label: t("dataSelection.last-week") },
    { value: "this-month", label: t("dataSelection.this-month") },
    { value: "last-month", label: t("dataSelection.last-month") },
    { value: "this-year", label: t("dataSelection.this-year") },
    { value: "last-year", label: t("dataSelection.last-year") },
    { value: "individual", label: t("dataSelection.individual") },
  ];
};
