import React from "react";
import YesNoDialog from "../../../../../../shared/components/yesnodialog";
import { useTranslation } from "react-i18next";

interface IProps {
  removeUser: () => void;
  onCancel: () => void;
  isModalVisible: boolean;
}
export default function RemoveUserModal({
  removeUser,
  onCancel,
  isModalVisible,
}: IProps): React.ReactElement {
  const { t } = useTranslation();
  return (
    <YesNoDialog
      isModalVisible={isModalVisible}
      onClose={onCancel}
      title={t("settings.userManagement.removeUserDialog.title")}
      question={t("settings.userManagement.removeUserDialog.description")}
      onCancel={onCancel}
      onSubmit={removeUser}
    />
  );
}
