/*
 *
 *   commonPieChart for shopfloor machine card.
 *
 */

import React, { useMemo } from "react";
import { Cell, PieChart, Pie, Label } from "recharts";
import { colors } from "../../../shared/theme";
import useStyles from "./styles";
import { IShopfloorAnomalyInfo } from "../../views/machineryView/components/machineKPIFooter/component/lastDayKpiPerformance";
import WithToggableTooltip from "../../withToggableTooltip";

//------------------------------------------------------------------------------------------------------------
// Interfaces & Types.
interface Props {
  value?: number;
  anomalyInfo?: IShopfloorAnomalyInfo;
}

//------------------------------------------------------------------------------------------------------------
// Component.
function ShopfloorPieChart({ value, anomalyInfo }: Props): React.ReactElement {
  const { classes } = useStyles();
  if (value == undefined) return <></>;
  const pieChartColors: string[] = [colors.blue_base, colors.chart_grey_base];
  const chart = useMemo(() => {
    const data = [{ value }, { value: 100 - value }];
    return (
      <PieChart height={64} width={100}>
        <Pie
          data={data}
          dataKey="value"
          startAngle={180}
          cy={50}
          cx={42}
          endAngle={0}
          innerRadius={35}
          outerRadius={45}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={pieChartColors[index]} />
          ))}
          <Label
            content={({ cx, cy }) => (
              <WithToggableTooltip
                tooltipInfo={anomalyInfo?.tooltipInfo}
                icon={anomalyInfo?.icon}
                placement="top"
                isContainerRequired={false}
                forceEnable={true}
              >
                <text
                  x={cx ?? 0 + 46}
                  y={cy ?? 0 + 51}
                  fill={pieChartColors[0]}
                  className="recharts-text"
                  textAnchor="middle"
                  dominantBaseline="middle"
                  fontSize={16}
                >
                  {`${value}%`}
                </text>
              </WithToggableTooltip>
            )}
            position={"center"}
          />
        </Pie>
      </PieChart>
    );
  }, [value]); // Dependency array includes only `value` since it's the only prop affecting the chart
  return <div className={classes.shopfloorPieChartWrapper}>{chart}</div>;
}
export default React.memo(ShopfloorPieChart);
