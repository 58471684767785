import React, { useState } from "react";
import GeorgModal from "../../../../../../shared/components/modal";
import { FormGroup } from "@mui/material";
import GeorgFormInput from "../../../../../../shared/components/formInput";
import useStyles from "./styles";
import GeorgButton from "../../../../../../shared/components/GeorgButton";
import { useTranslation } from "react-i18next";

interface IProps {
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (firstname: string, surName: string, email: string) => void;
}

export default function CreateUserModal({
  isVisible,
  onClose,
  onSubmit,
}: IProps): React.ReactElement {
  const [firstName, setFirstName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const { t } = useTranslation();

  const { classes } = useStyles();
  return (
    <GeorgModal
      isModalVisible={isVisible}
      onClose={onClose}
      title={t("settings.userManagement.createUserDialog.title")}
    >
      <FormGroup className={classes.form}>
        <GeorgFormInput
          value={firstName}
          onChange={setFirstName}
          id={"firstName"}
          label={t("settings.userManagement.createUserDialog.firstName")}
        />
        <GeorgFormInput
          value={surname}
          onChange={setSurname}
          id={"surname"}
          label={t("settings.userManagement.createUserDialog.surname")}
        />
        <GeorgFormInput
          value={email}
          onChange={setEmail}
          id={"email"}
          label={t("settings.userManagement.createUserDialog.email")}
        />

        <GeorgButton
          onClick={() => onSubmit(firstName, surname, email)}
          label={t("settings.userManagement.createUserDialog.submit")}
        />
      </FormGroup>
    </GeorgModal>
  );
}
