import React, { useMemo } from "react";
import StateTooltip from "../../../../stateTooltip";
import secondsToDisplayFormat from "../../../../../../helper/time/formatting/secondsToDisplayFormat";
import displayFormatToString from "../../../../../../helper/time/formatting/displayFormatToString";
import { useTranslation } from "react-i18next";
import getIndicatorTranslationByStateName from "../../../../../../helper/indicator/getIndicatorTranslationByStateName";
import { TIndicatorName } from "../../../../../../config/states/STATE_COLOR_MAP";

interface ITooltipPayload {
  name: string;
  value: string;
  color: string;
  payload: {
    name: string;
  };
}

export interface ITooltipProps {
  payload: ITooltipPayload[];
}

// Hint: We do not use useStyles hook within this component,
// because this component breaks hooks rules
interface IProps {
  tooltipData: ITooltipProps;
}
const ChartTooltip = ({ tooltipData }: IProps): React.ReactElement => {
  const { t } = useTranslation();
  if (tooltipData.payload == null) return <></>;
  let xAxisLabel = "";
  const rowConf = useMemo(
    () =>
      tooltipData.payload.map((item: ITooltipPayload) => {
        let label: TIndicatorName | string = item.name;
        const translatedName = getIndicatorTranslationByStateName(
          item.name as TIndicatorName,
          t,
        ).name;
        const displayName = translatedName !== "" ? translatedName : label;
        xAxisLabel = item.payload.name;
        // Convert first letter to upperCase().
        label = displayName.charAt(0).toUpperCase() + displayName.slice(1);
        const displayFormat = secondsToDisplayFormat(item.value as string);
        const labelTime = displayFormatToString(t, displayFormat);
        return {
          color: item.color,
          label: `${label}: ${labelTime}`,
        };
      }),
    [tooltipData.payload],
  );
  const reversedRowConf = [...rowConf].reverse();
  return <StateTooltip label={xAxisLabel} config={reversedRowConf} />;
};

export default ChartTooltip;
