import { makeStyles } from "tss-react/mui";
import { colors } from "../../../../../../../shared/theme";

const useStyles = makeStyles()(() => ({
  timeSelectionBox: {
    marginLeft: 15,
  },
  tableBox: {
    marginTop: 32,
    "& div.MuiDataGrid-root": {
      color: colors.blue_base,
      // color: theme.palette.text.color
      ".MuiDataGrid-editInputCell": {
        input: {
          color: colors.blue_base,
        },
      },
    },
    "& .MuiDataGrid-root .MuiDataGrid-editInputCell input": {
      color: colors.blue_base,
    },
    minHeight: "200px",
    height: "auto",
    width: "100%",
    borderCollapse: "collapse",
  },
}));

export default useStyles;
