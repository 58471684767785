import React, { useEffect, useState } from "react";
import { IHeader, ITabs } from "../../shared/components";
import useStyles from "./styles";
import QRView, { IQRCodeData } from "./views/QRViwer";
import { Typography } from "@mui/material";
import IosShareIcon from "@mui/icons-material/IosShare";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddToHomeScreenIcon from "@mui/icons-material/AddToHomeScreen";
import NotFound from "../notFound";
import IsKnownRoute from "../../services/routing/isKnownRoute";

type DeviceType = "Android" | "iOS" | "PC" | undefined;

export default function AppPage(): React.ReactElement {
  const knownPaths = ["/page"];
  if (!IsKnownRoute(knownPaths)) {
    return <NotFound />;
  }
  const { classes } = useStyles();
  const [deviceType, setDeviceType] = useState("PC");

  useEffect(() => {
    const ua = navigator.userAgent.toLowerCase();
    let type: DeviceType;
    if (/(iphone|ipad|ipod|ios)/.test(ua)) {
      type = "iOS";
    } else if (/(Android)/.test(navigator.userAgent)) {
      type = "Android";
    } else {
      type = "PC";
    }
    setDeviceType(type);
  }, []);

  const links: IQRCodeData[] = [{ link: "mygeorg.com", name: "" }];

  return (
    <div className={classes.Container}>
      <IHeader
        headline="Dashboard Apps"
        headlineColor="primary"
        headlineVariant="h3"
        tagline=""
        taglineColor="primary"
        taglineVariant="body1"
        styles={{ tagline: classes.title }}
      />
      {deviceType === "iOS" ? (
        <div className={classes.ios}>
          <Typography align="center" color="primary" variant="h2">
            Try Dashboard on iOS
          </Typography>
          <Typography align="center" color="primary" variant="body1">
            You are just two tabs away
          </Typography>
          <Typography align="center" color="primary" variant="body2">
            Tab the share icon at the bottom of your screen, scroll down the
            pop-up menu till you see 'add to home screen' and hit add.
          </Typography>
          <Typography align="center" color="primary" variant="body2">
            Dashboard app operates only with safari on iPhone 7 and above with
            iOS 14 or higher.
          </Typography>
          <Typography align="center" color="primary" variant="body2">
            Tab the <IosShareIcon /> icon button below, then 'Add to home
            screen'
          </Typography>
        </div>
      ) : deviceType === "Android" ? (
        <div className={classes.android}>
          <Typography align="center" color="primary" variant="h2">
            Try Dashboard on Android
          </Typography>
          <Typography align="center" color="primary" variant="body1">
            You are just two tabs away
          </Typography>
          <Typography align="center" color="primary" variant="body2">
            Tab the dots icon at the top-right of your screen, scroll down the
            pop-up menu till you see 'add to home screen' and hit add.
          </Typography>
          <Typography align="center" color="primary" variant="body2">
            Dashboard app operates only with Chrome on Android 13 or higher.
          </Typography>
          <Typography align="center" color="primary" variant="body2">
            Tab the <MoreVertIcon /> icon and then <AddToHomeScreenIcon /> icon
            button below, then 'Add to home screen'
          </Typography>
        </div>
      ) : (
        <ITabs
          disableContentPadding
          disableRipple
          tabs={[{ label: "Android & iOS Apps", path: knownPaths[0] }]}
          tabContent={[<QRView qrLinks={links} />]}
          tabVariant="fullWidth"
        />
      )}
    </div>
  );
}
