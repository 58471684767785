import gql from "graphql-tag";
// TODO: delete this after the backend is updated

export default gql`
  query {
    getMachineLists {
      machineListIdentificationType {
        machineListName
      }
      machineType {
        id
        machineIdentificationType {
          componentName
          deviceLabel
          deviceLabelExtension
          deviceClass
          deviceSublabel
          statusCode
          location
          machineImageType {
            image
          }
        }
        oeeShopfloor {
          indicatorIdentificationType {
            id
            indicatorName
          }
          value
        }
      }
    }
  }
`;
