import { TFunction } from "i18next";
import { EDisplayState } from "../../../../../../../shared/components/georgDataGrid/components/stateCell";
import { jobCriticalState } from "../../../../../../../api/jobs/interfaces/IJobSettings";

export function getRemainingTimeInMillis(dateTimeInMillis: number): number {
  const now = new Date().getTime();
  return dateTimeInMillis - now;
}

export function getTimeState(
  dateTimeInMillis: number,
  t?: TFunction,
): jobCriticalState {
  return getRemainingTime(getRemainingTimeInMillis(dateTimeInMillis), t);
}

// eslint-disable-next-line complexity
function getRemainingTime(
  remainingInMillis: number,
  t?: TFunction,
): jobCriticalState {
  const retValue: jobCriticalState = {
    timeRemaining: t == null ? "Error" : "-",
    criticalityState: EDisplayState.UNDEFINED,
    remainingInMillis: remainingInMillis.toString(),
  };

  const SECOND = 1000;
  const MINUTE = 60 * SECOND;
  const HOUR = 60 * MINUTE;
  const DAY = 24 * HOUR;
  const MONTH = 30 * DAY;

  let prefix = "";
  const isNegative = remainingInMillis < 0;
  if (isNegative) {
    prefix = "-";
    remainingInMillis = Math.abs(remainingInMillis);
  }
  const remainingSeconds = Math.ceil(remainingInMillis / SECOND);
  const remainingMinutes = Math.floor(remainingSeconds / 60);

  if (remainingInMillis < 10 * MINUTE) {
    const seconds = remainingSeconds % 60;
    retValue.criticalityState = EDisplayState.DANGER;
    retValue.timeRemaining =
      t == null
        ? "Error"
        : `${prefix}${
            remainingMinutes > 0
              ? `${remainingMinutes} ${t("common.time.helper.minutes")}`
              : ""
          } ${seconds} ${t("common.time.helper.seconds")}`;
    return retValue;
  }

  if (remainingInMillis < DAY) {
    const hours = Math.floor(remainingMinutes / 60);
    const minutes = remainingMinutes % 60;
    const remainingTime =
      t == null
        ? "Error"
        : `${prefix}${
            hours > 0 ? `${hours} ${t("common.time.helper.hours")}` : ""
          } ${minutes} ${t("common.time.helper.minutes")}`;

    retValue.criticalityState = isNegative
      ? EDisplayState.DANGER
      : EDisplayState.WARNING;
    retValue.timeRemaining = remainingTime;
    return retValue;
  }

  retValue.criticalityState = isNegative
    ? EDisplayState.DANGER
    : EDisplayState.OK;
  if (remainingInMillis < MONTH) {
    const remainingDays = Math.ceil(remainingInMillis / DAY);
    const weeks = Math.floor(remainingDays / 7);
    const days = remainingDays % 7;

    const remainingTime =
      t == null
        ? "Error"
        : `${prefix} ${
            weeks > 0 ? `${weeks} ${t("common.time.helper.weeks")}` : ""
          } ${days} ${t("common.time.helper.days")}`;
    retValue.timeRemaining = remainingTime;
    return retValue;
  }

  const remainingMonths = Math.ceil(remainingInMillis / MONTH);

  retValue.timeRemaining =
    t == null
      ? "Error"
      : `${prefix}${remainingMonths} ${t("common.time.helper.months")}`;
  return retValue;
}
