import { Theme } from "@mui/material/styles";
import { colors, rootTheme } from "../../../shared/theme";
import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()((theme: Theme) => ({
  boxFilter: {
    alignItems: "center",
    borderRightColor: colors.whiteB_16,
    borderRightStyle: "solid",
    borderRightWidth: 1,
    display: "flex",
    height: 50,
    width: "50%",
  },
  boxIcon: {
    borderLeftColor: colors.whiteB_16,
    borderLeftStyle: "solid",
    borderLeftWidth: 1,
    width: "5%",
    marginRight: 0,
  },
  boxSort: {
    alignItems: "center",
    display: "flex",
    height: 50,
    width: "45%",
  },
  button: {
    backgroundColor: colors.white_base,
    height: "100%",
    marginRight: 10,
  },
  container: {
    alignItems: "center",
    borderRadius: 0,
    display: "flex",
    height: rootTheme.elementHeight,
    marginBottom: theme.spacing(2),
  },
  label: {
    display: "inline-block",
    marginLeft: 15,
    marginRight: 15,
  },
  root: {
    maxWidth: 120,
    // width: "100%", // for fixed width
    padding: 10,
  },
}));

export default useStyles;
