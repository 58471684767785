import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  spinnerBox: {
    alignSelf: "center",
  },
  textBox: {
    display: "inline-block",
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    minHeight: 70,
  },
}));

export default useStyles;
