import { TIndicatorType } from "../types";

export default function indicatorIdToIndicatorType(
  allIndicators: TIndicatorType[],
  indicatorId: string,
): TIndicatorType {
  const type = allIndicators.find(
    (indicatorType: TIndicatorType) => indicatorType.id === indicatorId,
  );
  if (type == null) {
    throw new Error(
      `Requested indicator id ${indicatorId}. But it could not be found in ${JSON.stringify(
        allIndicators,
      )}`,
    );
  }
  return type;
}
