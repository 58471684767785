import { DocumentNode } from "graphql";
import getAggregatedVariable from "./aggregatedVariableQuery";
import gql from "graphql-tag";
import { logErrorToSentry } from "../../../useSentry";
import { IGetVariableType } from "../../../redux/reducers/variableIdentificationReducer";
import { CustomWarning } from "../../../helper/exception/warning";
interface ICreateAggregatedVariable {
  query: DocumentNode;
  parameters: {
    variables: {
      currentStart: string;
      currentEnd: string;
      referenceStart: string;
      referenceEnd: string;
      variableListIdentificationTypeId: string;
      type: string;
      function: string;
    };
  };
}

function emptyQuery(variableObject: IGetVariableType) {
  logErrorToSentry(new CustomWarning("Variable name is empty"), {
    level: "warning",
    tags: {
      query: "createAggregatedVariableQuery",
    },
    extra: {
      variable_id: variableObject.id,
      variable_function: variableObject.function,
      variable_type: variableObject.type,
    },
  });
  return {
    query: gql`
      query NoOp {
        __typename
      }
    `,
    parameters: {
      variables: {
        currentStart: "",
        currentEnd: "",
        referenceStart: "",
        referenceEnd: "",
        variableListIdentificationTypeId: "",
        type: "",
        function: "",
      },
    },
  };
}
export default function createAggregatedVariableQuery(
  variableId: string,
  currentStartDate: string,
  currentEndDate: string,
  referenceStartDate: string,
  referenceEndDate: string,
  type: string,
  process: string,
  variableName: string,
): ICreateAggregatedVariable {
  if (variableName === "") {
    const variableObject = {
      id: variableId,
      variableName,
      function: process,
      type,
    } as IGetVariableType;
    return emptyQuery(variableObject);
  }
  return {
    query: getAggregatedVariable(variableName),
    parameters: {
      variables: {
        currentStart: currentStartDate,
        currentEnd: currentEndDate,
        referenceStart: referenceStartDate,
        referenceEnd: referenceEndDate,
        variableListIdentificationTypeId: variableId,
        type: type,
        function: process,
      },
    },
  };
}
