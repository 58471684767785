import { makeStyles } from "tss-react/mui";
import { colors } from "../../theme";
// #666C71
const styles = makeStyles()({
  georgCheckboxUnchecked: {
    color: colors.grey_base,
  },
  georgCheckboxChecked: {
    color: colors.blueB32 + " !important",
  },
});

export default styles;
