import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import JobTimePeriodSelection, {
  EJobPriorityKey,
  JobPrioritySelectionValues,
  TJobPriorityKey,
} from "./components/jobPrioritySelection";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import getPriorityValue from "./transformPriorityValue";
interface IProps {
  initPriority: TJobPriorityKey;
  initPriorityValue?: number;
  onPriorityChanged: (
    selectedPriority: TJobPriorityKey,
    newPriority: number,
  ) => void;
  isPastAllowed?: boolean;
}

function isJobTPrioritySelectionKeyType(
  arg: TJobPriorityKey | unknown,
): arg is TJobPriorityKey {
  return JobPrioritySelectionValues.some((element) => element === arg);
}

export default function JobPrioritySelection({
  initPriority,
  initPriorityValue,
  onPriorityChanged,
}: IProps): React.ReactElement {
  const { t } = useTranslation();
  const [isIndividualSelected, setIsIndividualSelected] = useState<boolean>(
    () => {
      return initPriority === EJobPriorityKey.INDIVIDUAL;
    },
  );

  const [jobPriority, setJobPriority] = useState<number>(() => {
    return initPriorityValue != null
      ? initPriorityValue
      : getPriorityValue(EJobPriorityKey.URGENT);
  });
  const [selectedPriority, setSelectedPriority] =
    useState<TJobPriorityKey>(initPriority);
  useEffect(() => {
    onPriorityChanged(selectedPriority, jobPriority);
  }, [selectedPriority, jobPriority]);

  const { classes } = useStyles();
  return (
    <div className={classes.box}>
      <div className={classes.dropDown}>
        <JobTimePeriodSelection
          initPriority={initPriority}
          name={t("taskManager.jobManagement.form.timeSelection.created-at")}
          onPriorityChange={(priorityPeriod: TJobPriorityKey) => {
            let newJobPriority = 0;
            if (isJobTPrioritySelectionKeyType(priorityPeriod)) {
              if (priorityPeriod !== EJobPriorityKey.INDIVIDUAL) {
                newJobPriority = getPriorityValue(priorityPeriod);
              } else {
                newJobPriority = 334;
              }
            }
            setJobPriority(newJobPriority);
            setSelectedPriority(priorityPeriod);
          }}
          label={t("taskManager.jobManagement.form.priority")}
          onSetIndividualSelected={setIsIndividualSelected}
        />
      </div>
      {isIndividualSelected && (
        <div className={classes.dateTimeSelection}>
          <TextField
            error={jobPriority < 0 || jobPriority > 1000}
            id="priority"
            name="priority"
            label={t(`taskManager.jobManagement.form.priority`)}
            fullWidth
            type={"number"}
            variant="filled"
            slotProps={{
              htmlInput: {
                // min: 0,
                // max: 1000,
                step: 1,
              },
            }}
            value={jobPriority}
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
            ) => {
              const priority = Number.parseInt(event.target.value, 10);
              setJobPriority(priority);
            }}
          />
        </div>
      )}
    </div>
  );
}
