import { useTranslation } from "react-i18next";
import SideButton from "../sideButton";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";

interface IProps {
  isExpanded: boolean;
  onExpandClick: () => void;
  disabled: boolean;
}
export default function ExpandButton({
  isExpanded,
  onExpandClick,
  disabled,
}: IProps) {
  const { t } = useTranslation();
  const expandedText = t("taskManager.serviceOrders.shrinkButton");
  const shrinkedText = t("taskManager.serviceOrders.expandButton");

  return (
    <SideButton
      disabled={disabled}
      label={isExpanded ? expandedText : shrinkedText}
      onClick={onExpandClick}
      endIcon={
        isExpanded ? (
          <CloseFullscreenIcon color="secondary" />
        ) : (
          <OpenInFullIcon color="secondary" />
        )
      }
    />
  );
}
