import React, { useContext } from "react";
import GeorgDataGrid from "../../../../../../shared/components/georgDataGrid";
import getColumnConfig from "./getColumnConfig";
import { useTranslation } from "react-i18next";
import GeorgStorageContext from "../../../../../../context/GeorgStorageContext";
export interface ITableUserInfo {
  id: string;
  firstName: string;
  lastName: string;
  eMail: string;
  resetPassword: boolean;
  removeUser: boolean;
}
interface IProps {
  isResetPasswordButtonDeactivated: string;
  isRemoveUserButtonDeactivated: string;
  tableData: ITableUserInfo[];
  onOpenResetDialog: (id: string) => void;
  openRemoveUserDialog: (id: string) => void;
  isLoading: boolean;
}

export default function UserTable({
  tableData,
  onOpenResetDialog,
  openRemoveUserDialog,
  isResetPasswordButtonDeactivated,
  isRemoveUserButtonDeactivated,
  isLoading,
}: IProps): React.ReactElement {
  const { t } = useTranslation();
  const { isTooltipEnabled } = useContext(GeorgStorageContext);
  return (
    <GeorgDataGrid
      rows={tableData}
      columns={getColumnConfig(
        t,
        onOpenResetDialog,
        openRemoveUserDialog,
        isResetPasswordButtonDeactivated,
        isRemoveUserButtonDeactivated,
      )}
      isLoading={isLoading}
      isTooltipEnabled={isTooltipEnabled}
    />
  );
}
