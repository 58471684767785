import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles()((theme: Theme) => ({
  cardContainer: {
    borderRadius: 0,
    verticalAlign: "midday",
    transform: "perspective(1px) translateZ(0)",
    transitionDuration: "0.3s",
    transitionProperty: "transform",
    minWidth: 300,
    // maxWidth: 400,
  },
  contentKPIContainer: {
    cursor: "pointer",
    borderRadius: 0,
    height: "100%",
    maxHeight: 844,
    width: "100%",
  },
  contentMachineryContainer: {
    cursor: "pointer",
    borderRadius: 0,
    height: 350,
    width: "100%",
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
    gridGap: theme.spacing(3),
  },
  headerGroupElement: {
    display: "grid",
    gridColumn: "1 / -1",
  },
}));

export default useStyles;
