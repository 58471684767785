import { Layout, Layouts } from "react-grid-layout";
function enlargeByResizeConfig<TResizeConfig>(
  layouts: Layout[],
  resizeConfig: TResizeConfig,
): Layout[] {
  return layouts.map((layout: Layout) => {
    if (resizeConfig[layout.i]) {
      const offset = resizeConfig[layout.i];
      return { ...layout, h: layout.h + offset };
    } else {
      return layout;
    }
  });
}

export default function createLayout<TResizeConfig>(
  resizeConfig: TResizeConfig,
  xlLayoutSize: Layout[],
  lgLayoutSize: Layout[] = xlLayoutSize,
  mdLayoutSize: Layout[] = lgLayoutSize,
  smLayoutSize: Layout[] = mdLayoutSize,
  xsLayoutSize: Layout[] = smLayoutSize,
  xxsLayoutSize: Layout[] = xsLayoutSize,
): Layouts {
  return {
    xl: enlargeByResizeConfig<TResizeConfig>(xlLayoutSize, resizeConfig),
    lg: enlargeByResizeConfig<TResizeConfig>(lgLayoutSize, resizeConfig),
    md: enlargeByResizeConfig<TResizeConfig>(mdLayoutSize, resizeConfig),
    sm: enlargeByResizeConfig<TResizeConfig>(smLayoutSize, resizeConfig),
    xs: enlargeByResizeConfig<TResizeConfig>(xsLayoutSize, resizeConfig),
    xxs: enlargeByResizeConfig<TResizeConfig>(xxsLayoutSize, resizeConfig),
  };
}
