/*
 *
 *   Sidebar component for displaying the right drawer.
 *   On smartphone -> swipeable & temporary drawer.
 *   On desktop -> permanent drawer.
 *
 */

import React, { useCallback } from "react";
import { useEffect } from "react";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import {
  ClickAwayListener,
  Drawer,
  SwipeableDrawer,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// Custom Imports.
import { DrawerList } from "./components";
import useStyles from "./styles";
import { handleDrawer, selectDrawer } from "../../redux/reducers/drawerReducer";
import { useLocation } from "react-router-dom";

//------------------------------------------------------------------------------------------------------------
// Interfaces & Types.
interface Props {
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

//------------------------------------------------------------------------------------------------------------
// Component.

export default function SideBar({ onClick }: Props): React.ReactElement {
  const { classes } = useStyles();
  const open = useSelector(selectDrawer);
  const location = useLocation();
  const dispatch = useDispatch();
  const { breakpoints } = useTheme();
  const isWidthAboveMD = useMediaQuery(breakpoints.up("md"), { noSsr: true });

  useEffect(() => {
    dispatch(handleDrawer(isWidthAboveMD));
  }, [isWidthAboveMD]);

  useEffect(() => {
    if (!isWidthAboveMD && open) {
      dispatch(handleDrawer(false));
    }
  }, [location]);

  const closeDrawer = useCallback(
    (event) => {
      const target = event.target;

      // Check if the clicked element or any of its parents has the data-testid 'MenuIcon'
      const isMenuIconClicked = target.closest("[data-testid='MenuIcon']");
      if (isMenuIconClicked) {
        return;
      }
      if (!isWidthAboveMD && open) {
        dispatch(handleDrawer(false));
      }
    },
    [isWidthAboveMD, open],
  );

  return (
    <>
      {isWidthAboveMD ? (
        <Drawer
          variant="permanent"
          onClose={() => dispatch(handleDrawer(false))}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <DrawerList mobile={false} onClick={onClick} />
        </Drawer>
      ) : (
        <ClickAwayListener
          onClickAway={closeDrawer}
          mouseEvent="onMouseDown"
          // touchEvent="onTouchStart"
          touchEvent={false}
        >
          <SwipeableDrawer
            variant="persistent"
            anchor="left"
            classes={{ paper: classes.drawerOpenMobile }}
            onClose={() => dispatch(handleDrawer(false))}
            onOpen={() => dispatch(handleDrawer(true))}
            open={open} //open={isWidthBelowMD ? false : open}
            disableBackdropTransition={true}
          >
            <DrawerList mobile={true} onClick={onClick} />
          </SwipeableDrawer>
        </ClickAwayListener>
      )}
    </>
  );
}
