import { IServiceOrderSettingsCreate } from "../../api/IServiceOrderSettings";

/*
attention!
If you change the values on the right hand side,
please change the translations within translation files as well!
 */
export enum EValidationResult {
  OK = "OK",
  NAME_EMPTY = "NAME_EMPTY",
  NAME_LENGTH_VIOLATED = "NAME_LENGTH_VIOLATED",
  EMPTY_CATEGORY = "EMPTY_CATEGORY",
  EMPTY_VARIABLE = "EMPTY_VARIABLE",
  DESCRIPTION_EMPTY = "DESCRIPTION_EMPTY",
  DESCRIPTION_LENGTH_VIOLATED = "DESCRIPTION_LENGTH_VIOLATED",
  PREWARNING_VIOLATED = "PREWARNING_VIOLATED",
  RESPONSIBLE_EMPTY = "RESPONSIBLE_EMPTY",
  COMPONENT_EMPTY = "COMPONENT_EMPTY",
  COMPONENT_LENGTH_VIOLATED = "COMPONENT_LENGTH_VIOLATED",
  INTERVAL_VIOLATED = "INTERVAL_VIOLATED",
  CATEGORY_LENGTH_VIOLATED = "CATEGORY_LENGTH_VIOLATED",
}

export const MAX_NAME_LENGTH = 15;
export const MAX_CATEGORY_LENGTH = 15;

export const MAX_COMPONENT_LENGTH = 15;
export const MAX_DESCRIPTION_LENGTH = 150;

function isEmptyString(
  s: string,
  errorRes: EValidationResult,
): EValidationResult {
  return s.length !== 0 ? EValidationResult.OK : errorRes;
}

function validateName(name: string): EValidationResult {
  return name.length > MAX_NAME_LENGTH
    ? EValidationResult.NAME_LENGTH_VIOLATED
    : isEmptyString(name, EValidationResult.NAME_EMPTY);
}
function validateCategory(
  categoryId?: string,
  newCategoryName?: string,
): EValidationResult {
  if (categoryId) {
    return EValidationResult.OK;
  } else if (newCategoryName) {
    return newCategoryName.length > MAX_CATEGORY_LENGTH
      ? EValidationResult.CATEGORY_LENGTH_VIOLATED
      : isEmptyString(newCategoryName, EValidationResult.EMPTY_CATEGORY);
  } else {
    return EValidationResult.EMPTY_CATEGORY;
  }
}
function validateVariable(variableId: string): EValidationResult {
  return isEmptyString(variableId, EValidationResult.EMPTY_VARIABLE);
}
function validateDescription(description: string): EValidationResult {
  return description.length > MAX_DESCRIPTION_LENGTH
    ? EValidationResult.DESCRIPTION_LENGTH_VIOLATED
    : isEmptyString(description, EValidationResult.DESCRIPTION_EMPTY);
}
function validatePrewarning(prewarning: number): EValidationResult {
  return prewarning < 0 || prewarning > 100
    ? EValidationResult.PREWARNING_VIOLATED
    : EValidationResult.OK;
}
function validateResponsible(responsibleId: string): EValidationResult {
  return isEmptyString(responsibleId, EValidationResult.RESPONSIBLE_EMPTY);
}
function validateComponent(
  componentId?: string,
  newComponentName?: string,
): EValidationResult {
  if (componentId) {
    return EValidationResult.OK;
  } else if (newComponentName) {
    return newComponentName.length > MAX_COMPONENT_LENGTH
      ? EValidationResult.COMPONENT_LENGTH_VIOLATED
      : isEmptyString(newComponentName, EValidationResult.COMPONENT_EMPTY);
  } else {
    return EValidationResult.COMPONENT_EMPTY;
  }
}
function validateInterval(interval: number): EValidationResult {
  return interval <= 0
    ? EValidationResult.INTERVAL_VIOLATED
    : EValidationResult.OK;
}
export default function validation(
  serviceOrder: IServiceOrderSettingsCreate,
): EValidationResult {
  const results: EValidationResult[] = [];

  results.push(validateName(serviceOrder.name));
  results.push(validateVariable(serviceOrder.variableId));
  results.push(
    validateComponent(serviceOrder.componentId, serviceOrder.newComponentName),
  );
  results.push(
    validateCategory(serviceOrder.categoryId, serviceOrder.newCategoryName),
  );
  results.push(validateResponsible(serviceOrder.responsibleId));
  results.push(validateInterval(serviceOrder.interval));
  results.push(validatePrewarning(serviceOrder.preWarning));
  results.push(validateDescription(serviceOrder.description));

  const errorList = results.filter((r) => r !== EValidationResult.OK);
  return errorList.length > 0 ? errorList[0] : EValidationResult.OK;
}
