import React from "react";
import { IButton } from "../../../../shared/components";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";

export default function LoginButton(): React.ReactElement {
  const { instance } = useMsal();
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <IButton
      buttonColor="primary"
      icon={<AccountCircleIcon color="primary" />}
      label={t(`navigations.login`)}
      labelColor="textPrimary"
      labelVariant="body1"
      onClick={() => instance.loginRedirect()}
      styles={{
        button: classes.button,
        label: classes.buttonLoginLabel,
      }}
    />
  );
}
