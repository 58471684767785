import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  aligner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  aligneritem: {
    maxWidth: "50%",
  },
});

export default useStyles;
