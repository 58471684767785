import React, { useMemo } from "react";
import TabsWithMachineTimePeriodSelection from "../../shared/components/tabsWithMachineTimePeriodSelection";
import { useTranslation } from "react-i18next";
import ManualStateView from "./views/manualstateView";

export default function ManualStateWithMachineSelection(): React.ReactElement {
  const { t } = useTranslation();
  const tabConfig = useMemo(
    () => [
      {
        label: t("manualInput.title"),
        path: "/manualstate",
      },
    ],
    [],
  );
  return (
    <TabsWithMachineTimePeriodSelection
      basePath={"/manualstate/machine"}
      tabConfig={tabConfig}
    >
      <ManualStateView />
    </TabsWithMachineTimePeriodSelection>
  );
}
