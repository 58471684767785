import React from "react";
import GeorgModal from "../modal";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import GeorgButton from "../GeorgButton";

interface IProps {
  isModalVisible: boolean;
  title: string;
  description: string;
  onSubmit: () => void;
}

export default function OkDialog({
  isModalVisible,
  title,
  description,
  onSubmit,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <GeorgModal
      isModalVisible={isModalVisible}
      onClose={onSubmit}
      title={title}
    >
      <>
        <div className={classes.question}>{description}</div>
        <div className={classes.buttons}>
          <GeorgButton
            label={t("shared.components.okDialog.ok")}
            onClick={() => {
              onSubmit();
            }}
          />
        </div>
      </>
    </GeorgModal>
  );
}
