import React, { useMemo } from "react";
import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
// Custom Imports.
import { IHeader, ITabs } from "../../shared/components";
import useStyles from "./styles";
import UserProfileView from "./views/userProfileView";
import DashboardProfileView from "./views/dashboardProfileView";
import useDefaultPage from "../../services/routing/useDefaultPage";
import IsKnownRoute from "../../services/routing/isKnownRoute";
import NotFound from "../notFound";

export default function Profile(): React.ReactElement {
  const knownPaths = ["/dashboard", "/user"];
  if (!IsKnownRoute(knownPaths)) {
    return <NotFound />;
  }
  const { classes } = useStyles();
  useDefaultPage("/profile", "/profile/dashboard");
  const { i18n, t } = useTranslation();

  const tabs = useMemo(
    () => [
      { label: t("profile.dashboard.title"), path: knownPaths[0] },
      { label: t("profile.user.title"), path: knownPaths[1] },
    ],
    [i18n.language],
  );
  const tabContent = useMemo(
    () => [<DashboardProfileView />, <UserProfileView />],
    [],
  );
  return (
    <Container className={classes.container}>
      <IHeader
        headline={t("headers.profilePage.headline")}
        headlineColor="primary"
        headlineVariant="h3"
        tagline={t("headers.profilePage.tagline")}
        taglineColor="primary"
        taglineVariant="body1"
      />
      <ITabs
        disableContentPadding
        disableRipple
        tabs={tabs}
        tabContent={tabContent}
        tabVariant="fullWidth"
      />
    </Container>
  );
}
