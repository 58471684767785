import {
  ECreatorType,
  IJobCreateOrUpdate,
  IJobFromServer,
} from "../../../../../api/jobs/interfaces/IJobSettings";

export function transformJobForModal(
  job: IJobFromServer | null,
  machineTypeId: number,
): IJobCreateOrUpdate {
  if (job) {
    const { jobId, name, description, priority, scheduledAt, vid: vId } = job;
    return {
      jobId,
      name,
      description,
      priority,
      scheduledAt,
      machineId: machineTypeId,
      vid: vId,
    };
  } else {
    return {
      name: "",
      description: "",
      priority: 0,
      scheduledAt: "",
      machineId: machineTypeId,
      createdBy: ECreatorType.USER,
      vid: 0,
    };
  }
}
