import moment from "moment-timezone";
import formatDateToString from "./formatting/formatDateToString";
import { TFunction } from "i18next";

// this function is used to check if 2 dates are on the same day
export function areDatesOnSameDay(
  dateString1,
  dateString2,
  timezone = "UTC",
): boolean {
  return moment
    .tz(dateString1, timezone)
    .isSame(moment.tz(dateString2, timezone), "day");
}

// this function is used to convert 2 dates to a string that represents the date range
export function convertDatesToDateRange(
  dateString1: string,
  dateString2: string,
  timezone = "UTC",
  language = "en",
  t: TFunction,
): string {
  const areOnSameDay = areDatesOnSameDay(dateString1, dateString2, timezone);
  return areOnSameDay
    ? formatDateToString(
        new Date(dateString1 ?? 0),
        language,
        t,
        "date",
        "DAYS",
        timezone,
      )
    : `${formatDateToString(
        new Date(dateString1 ?? 0),
        language,
        t,
        "date",
        "DAYS",
        timezone,
      )} - ${formatDateToString(
        new Date(dateString2 ?? 0),
        language,
        t,
        "date",
        "DAYS",
        timezone,
      )}`;
}
