import gql from "graphql-tag";

// TODO: This query is not used anymore. It is replaced by getOeeForMachineLast24Query.ts. Delete this file when the API is synchronized.
export default gql`
  query getOeeForMachineLast24h($machineTypeId: Int) {
    getOeeForMachineLast24h(machineTypeId: $machineTypeId) {
      indicatorIdentificationType {
        id
        indicatorName
      }
      machineType {
        id
      }
      value
    }
  }
`;
