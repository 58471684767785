import { makeStyles } from "tss-react/mui";
import { colors } from "../../theme";

const useStyles = makeStyles()(() => ({
  tabContainer: {
    backgroundColor: colors.blue_base, //
    color: colors.white_base,
    marginTop: 20,
    maxWidth: 720,
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiButtonBase-root": {
      borderLeft: "1px solid",
      borderColor: colors.white_base,
      backgroundColor: colors.blue_base, //
      color: colors.white_base,
      WebkitAlignItems: "center",
    },
  },
  tab: {
    borderLeft: 2,
    borderLeftColor: colors.whiteB_16,
    borderLeftStyle: "solid",
    lineHeight: 1.3,

    // body_20_300_blue_base.
    textTransform: "none",
    color: colors.blue_base,
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 300,
    textAlign: "left",
    "&:first-of-type": {
      borderLeftColor: colors.white_base,
    },
  },
  tabRoot: {
    fontSize: "18px",
    fontWeight: 300,
    textTransform: "none",
    color: colors.whiteB_16,
    alignItems: "flex-start",
  },
  tabLabel: {
    alignItems: "flex-start",
  },
  tabUnderline: {
    width: 0,
    backgroundColor: colors.blue_base,
  },
  tabSelected: {
    backgroundColor: colors.white_base + "!important",
    color: colors.blue_base + "!important",
    borderColor: colors.blue_base,
    border: "1px solid",
    borderBottom: "0px",
  },
  tabDisabled: {
    backgroundColor: colors.whiteB_3 + "!important",
    color: colors.blue_base + "!important",
    borderColor: colors.whiteB_3,
    border: "1px solid",
    borderBottom: "0px",
  },
}));

export default useStyles;
