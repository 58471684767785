import { CSSProperties } from "react";
import colors from "./colors";

// Default styles.
interface IProps {
  elementHeight: number;
  mainContainer: {
    marginLeft: number;
    height: string;
    maxWidth: string | number;
    width: string;
    padding: number | string;
    [key: string]: string | number | Record<string, string | number>;
  };
  viewsContainer: {
    backgroundColor: string;
    display: string;
    maxWidth: string | number;
    padding: number;
    width: string | number;
  };
  tabContainer: {
    padding: number | string;
    [key: string]: string | number | Record<string, string | number>;
  };
  shadow: string;
  noOverflow: CSSProperties;
}

const styles: IProps = {
  elementHeight: 64,
  shadow: "0px 2px 4px rgba(100,100,100,0.5)",
  mainContainer: {
    marginLeft: 0,
    height: "100%",
    maxWidth: "none",
    width: "100%",
    padding: "0px 24px",
    "@media (max-width: 899px)": {
      padding: 0,
    },
  },
  viewsContainer: {
    backgroundColor: colors.white_base,
    display: "flex",
    maxWidth: "none",
    padding: 30,
    width: "100%",
  },
  tabContainer: {
    padding: 40,
    "@media (max-width: 900px)": {
      padding: 30,
    },
    "@media (max-width: 600px)": {
      padding: 16,
    },
  },
  noOverflow: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
};

export default styles;
