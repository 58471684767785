/*
 *
 *   Time selection for report-view, kpi-view and stacklightView-view.
 *
 */
import React, { useEffect, useState } from "react";
// Custom Imports.
import ITimeSelectionPeriodOption from "./ITimeSelectionPeriodOption";
import Dropdown from "../../../shared/components/dropDown";
import { TTimeSelectionKey } from "../../../redux/reducers/timeSelectReducer";
import { getPeriodSelectOptions } from "./getPeriodSelectOptions";
import useStyles from "./styles";

//------------------------------------------------------------------------------------------------------------
// Interfaces & Types.
interface IProps {
  initPeriod: TTimeSelectionKey;
  name?: string;
  onTimeChange?: (selected: TTimeSelectionKey) => void;
  value?: TTimeSelectionKey;
  label: string;
  onSetIndividualSelected?: (isSelected: boolean) => void;
  isChangePending?: boolean;
}

//------------------------------------------------------------------------------------------------------------
// Component.
export default function TimePeriodSelection({
  initPeriod,
  name,
  label,
  onTimeChange,
  onSetIndividualSelected,
  isChangePending = false,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  const [isIndividualSelected, setIsIndividual] = useState<boolean>(
    initPeriod === "individual",
  );
  const [value, setValue] = useState<TTimeSelectionKey>(initPeriod);
  const periodSelectOptions: ITimeSelectionPeriodOption[] =
    getPeriodSelectOptions();

  useEffect(() => {
    if (onSetIndividualSelected) {
      onSetIndividualSelected(isIndividualSelected);
    }
  }, [isIndividualSelected]);
  return (
    <Dropdown
      value={value}
      label={label}
      onChange={(selected: React.ChangeEvent<HTMLSelectElement>) => {
        const newValue = selected.target.value as TTimeSelectionKey;
        setValue(newValue);
        setIsIndividual(selected.target.value === "individual");
        if (onTimeChange) {
          onTimeChange(newValue);
        }
      }}
      name={name || ""}
      selectOptions={periodSelectOptions}
      styles={{
        inputContainer: classes.inputContainer,
        boxContainer: classes.boxContainer,
        inputRoot: `${
          isChangePending ? classes.pendingInputRoot : classes.inputRoot
        }`,
        textInput: classes.textInput,
        textContainer: classes.textContainer,
      }}
    />
  );
}
