import { IResponsible } from "../../../../settings/machine/views/serviceOrders/api/IServiceOrderSettings";
import {
  CATEGORIES,
  VARIABLES,
  COMPONENTS,
} from "../../../../settings/machine/views/serviceOrders/api/MOCKS";
import {
  IMachineWithServiceOrderSettings,
  IServiceOrderSettingsFromServer,
} from "./interfaces";
import { IMachine } from "../../../../../api/machine/IMachine";

const defaultResponsible: IResponsible = {
  id: "00175402-8b82-4791-8a1d-6a868c31284c",
  firstName: "Matthias",
  lastName: "Wennemer",
};

export function getMachinesForMachineId(
  machineIds: string[],
  allMachines: IMachine[],
): IMachineWithServiceOrderSettings[] {
  return machineIds.map((mId: string): IMachineWithServiceOrderSettings => {
    const name =
      allMachines.find((m) => m.id === mId)?.deviceLabelExtension ||
      "unknown machine";
    return {
      machine: {
        id: mId,
        name,
        serviceOrder: getServiceOrders(mId),
      },
    };
  });
}

function getServiceOrders(
  machineId: string,
): IServiceOrderSettingsFromServer[] {
  return SERVICE_ORDERS.map((so) => {
    return { ...so, id: `${machineId}-${so.id}` };
  });
}
export const SERVICE_ORDERS: IServiceOrderSettingsFromServer[] = [
  {
    id: "0",
    name: "Lubrication",
    category: CATEGORIES[0],
    component: COMPONENTS[0],
    description: "Lubricate all joints",
    preWarning: 0.7,
    responsible: defaultResponsible,
    variable: VARIABLES[2],
    interval: 1000,
    lastService: 7500,
    version: "0",
  },
  {
    id: "1",
    name: "Oil Change",
    category: CATEGORIES[1],
    component: COMPONENTS[1],
    description: "Oil change for the main gearbox",
    preWarning: 0.8,
    responsible: defaultResponsible,
    variable: VARIABLES[1],
    interval: 200,
    lastService: 1200,
    version: "0",
  },
  {
    id: "2",
    name: "Polishing",
    category: CATEGORIES[3],
    component: COMPONENTS[2],
    description: "Polishing all safety viewing windows",
    preWarning: 0.9,
    responsible: defaultResponsible,
    variable: VARIABLES[0],
    interval: 2100,
    lastService: 3000,
    version: "0",
  },
];
