import gql from "graphql-tag";

export default gql`
  mutation unabortJob($jobId: String!, $vid: Int!) {
    unabortJob(jobId: $jobId, vid: $vid) {
      vid
      abortMessage
      abortedAt
      createdBy
      createdById
      deletedAt
      description
      doneAt
      id
      jobId
      machineType {
        id
      }
      priority
      scheduledAt
    }
  }
`;
