import { DocumentNode } from "graphql";
import { ITimeSelectionState } from "../../../../redux/reducers/timeSelectReducer";
import TScale from "../../../../api/time/TScale";
import KPIPieChartDataQuery from "./KPIRefChartQuery";
interface ICreateKPIRefChartConfig {
  query: DocumentNode;
  parameters: {
    variables: {
      currentFilter_startedAfter: string;
      currentFilter_endedBefore: string;
      currentFilter_indicatorIdentificationTypeIds: string[];
      currentFilter_machineTypeId: string;
      currentFilter_scale: TScale;
      currentFilter_shift: string;
      currentFilter_timezone: string;
      referenceFilter_startedAfter: string;
      referenceFilter_endedBefore: string;
      referenceFilter_indicatorIdentificationTypeIds: string[];
      referenceFilter_machineTypeId: string;
      referenceFilter_scale: TScale;
      referenceFilter_shift: string;
      referenceFilter_timezone: string;
    };
  };
}
export default function createKPIRefChartQuery(
  indicatorId: string,
  selectedMachine: string,
  timeSelection: ITimeSelectionState,
  timezone: string,
): ICreateKPIRefChartConfig {
  const { currentFilter, referenceFilter } = timeSelection;
  return {
    query: KPIPieChartDataQuery,
    parameters: {
      variables: {
        currentFilter_startedAfter: currentFilter.startedAfter,
        currentFilter_endedBefore: currentFilter.endedBefore,
        currentFilter_indicatorIdentificationTypeIds: [indicatorId],
        currentFilter_machineTypeId: selectedMachine,
        currentFilter_scale: currentFilter.scale,
        currentFilter_shift: currentFilter.shift,
        currentFilter_timezone: timezone,
        referenceFilter_startedAfter: referenceFilter.startedAfter,
        referenceFilter_endedBefore: referenceFilter.endedBefore,
        referenceFilter_indicatorIdentificationTypeIds: [indicatorId],
        referenceFilter_machineTypeId: selectedMachine,
        referenceFilter_scale: referenceFilter.scale,
        referenceFilter_shift: referenceFilter.shift,
        referenceFilter_timezone: timezone,
      },
    },
  };
}
