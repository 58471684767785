import {
  GridCellParams,
  GridColDef,
  GridEditCellProps,
  GridRowId,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import React, { useContext, useMemo } from "react";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import IShiftConfigEntry from "../../../../../../../api/shifts/app/IShiftConfigEntry";
import updateDataField from "../../../../tableHelper/updateDataField";
import DateTimeSelection from "../../../../../../../shared/components/dateTimeSelection";
import { ClassNameMap } from "@mui/material/styles";
import { calculateTimeStampDiffHourMinute } from "../../../../../../../helper/time/calculateTimeDiffHourMinute";
import GeorgDataGrid from "../../../../../../../shared/components/georgDataGrid";
import { Checkbox } from "@mui/material";
import { TooltipCell } from "../../../../../../../shared/components/georgDataGrid/components/tooltipCell";
import moment from "moment-timezone";
import GeorgStorageContext from "../../../../../../../context/GeorgStorageContext";

const defaultProps: Omit<GridColDef, "field"> = {
  headerAlign: "center",
  align: "center",
  sortable: false,
  disableColumnMenu: true,
};

const systemDateToString = (date: Date): string => {
  return `${String(date.getHours()).padStart(2, "0")}:${String(
    date.getMinutes(),
  ).padStart(2, "0")}:00`;
};

const stringToSystemDate = (timeString: string): Date => {
  if (typeof timeString !== "string") {
    return new Date(); // or any default value you'd like to use
  }
  const [hour, minute] = timeString.split(":").map((str) => parseInt(str, 10));
  const date = new Date();
  date.setHours(hour, minute, 0);
  return date;
};

function convertToHHMM(timeString: string): string {
  const [hour, minute] = timeString.split(":");
  return `${hour}:${minute}`;
}

function tooltipTextCellRender(cellValue: string) {
  return (
    <TooltipCell tooltipText={cellValue}>
      <span>{cellValue}</span>
    </TooltipCell>
  );
}

const sameDayText = (t: TFunction) => t("settings.shifts.table.sameDay");

const previousDayText = (t: TFunction) =>
  t("settings.shifts.table.previousDay");

function isSameOrBeforeDay(
  startTime: string,
  endTime: string,
  timezone: string,
): boolean {
  const startMoment = moment.tz(startTime, "HH:mm:ss", timezone);
  const endMoment = moment.tz(endTime, "HH:mm:ss", timezone);

  return endMoment.isSameOrBefore(startMoment);
}

function getColumnConfig(
  styles: ClassNameMap,
  t: TFunction,
  language: string,
  timezone: string,
  onActiveChange: (s: GridRowId, current: boolean) => void,
  onSetStartTime: (rowId: GridRowId, startTime: Date) => void,
  onSetEndTime: (rowId: GridRowId, endTime: Date) => void,
): GridColDef[] {
  return [
    {
      ...defaultProps,
      sortable: false,
      field: "displayName",
      minWidth: 200,
      renderHeader: () => <h3>{t("settings.shifts.table.dayOfWeek")}</h3>,
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return tooltipTextCellRender(cellValue);
      },
    },
    {
      ...defaultProps,
      field: "startTime",
      editable: false,
      type: "string",
      minWidth: 150,
      renderHeader: () => <h3>{t("settings.shifts.table.startTime")}</h3>,
      renderCell: (params: GridCellParams) => {
        /*
         Workaround:
         23:00 -> 05:00 = 06:00
                   ^
                   must be set to previous day else negative time diff occurs
         */
        const { endTime } = params.row;
        const startTime = params.value as string;
        const dateToHHMM = convertToHHMM(startTime);
        const preText = isSameOrBeforeDay(startTime, endTime, timezone)
          ? previousDayText(t)
          : sameDayText(t);
        const tooltipText = preText + " " + dateToHHMM + " UTC";
        const hoursMin = stringToSystemDate(startTime);
        return params.row.isActive ? (
          <TooltipCell tooltipText={tooltipText}>
            <DateTimeSelection
              value={hoursMin}
              onTimeSelect={(d: Date) => onSetStartTime(params.id, d)}
              disableUnderline={true}
              mode={"time"}
              isContentEditable={false}
              locale={language}
            />
          </TooltipCell>
        ) : (
          <></>
        );
      },
    },
    {
      ...defaultProps,
      field: "endTime",
      editable: false,
      minWidth: 150,
      renderCell: (params: GridCellParams) => {
        const endDate = params.value as string;
        const dateToHHMM = convertToHHMM(endDate);
        const tooltipText = sameDayText(t) + " " + dateToHHMM + " UTC";
        const hoursMin = stringToSystemDate(endDate);
        return params.row.isActive ? (
          <TooltipCell tooltipText={tooltipText}>
            <DateTimeSelection
              value={hoursMin}
              onTimeSelect={(d: Date) => onSetEndTime(params.id, d)}
              disableUnderline={true}
              mode={"time"}
              isContentEditable={false}
              locale={language}
            />
          </TooltipCell>
        ) : (
          <></>
        );
      },
      renderHeader: () => <h3>{t("settings.shifts.table.endTime")}</h3>,
    },
    {
      ...defaultProps,
      field: "hoursTotal",
      editable: false,
      type: "number",
      minWidth: 170,
      renderHeader: () => <h3>{t("settings.shifts.table.hoursTotal")}</h3>,
      valueGetter: (_row, value) => {
        const { startTime, endTime, isActive } = value;
        /*
         Workaround:
         23:00 -> 05:00 = 06:00
                   ^
                   must be set to next day else negative time diff occurs
         */
        const startMoment = moment.tz(startTime, "HH:mm:ss", timezone);
        let endMoment = moment.tz(endTime, "HH:mm:ss", timezone);

        if (endMoment.isSameOrBefore(startMoment)) {
          endMoment = endMoment.add(1, "days");
        }

        return isActive
          ? calculateTimeStampDiffHourMinute(startMoment, endMoment, t, true)
          : null;
      },
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return tooltipTextCellRender(cellValue);
      },
    },
    {
      ...defaultProps,
      sortable: false,
      field: "isActive",
      minWidth: 25,
      renderHeader: () => <h3>{t("settings.shifts.table.active")}</h3>,
      renderCell: (params: GridCellParams) => {
        const isChecked = params.value as boolean;
        return (
          <Checkbox
            onChange={() => onActiveChange(params.id, isChecked)}
            checked={isChecked}
          />
        );
      },
    },
  ];
}

interface IProps {
  data: IShiftConfigEntry[];
  onTableUpdate: (newData: IShiftConfigEntry[]) => void;
  timezone: string;
  isLoading: boolean;
}

export default function ShiftTable({
  data,
  onTableUpdate,
  timezone,
  isLoading,
}: IProps): React.ReactElement {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();
  const { isTooltipEnabled } = useContext(GeorgStorageContext);
  const columns = useMemo(
    () =>
      getColumnConfig(
        classes,
        t,
        i18n.language,
        timezone,
        (rowId: GridRowId, current: boolean) => {
          const newData = updateDataField(data, rowId, "isActive", !current);
          onTableUpdate(newData as IShiftConfigEntry[]);
        },
        (rowId: GridRowId, d: Date) => {
          const newDate = systemDateToString(d);
          const newData = updateDataField(data, rowId, "startTime", newDate);
          onTableUpdate(newData as IShiftConfigEntry[]);
        },
        (rowId: GridRowId, d: Date) => {
          const newDate = systemDateToString(d);
          const newData = updateDataField(data, rowId, "endTime", newDate);
          onTableUpdate(newData as IShiftConfigEntry[]);
        },
      ),
    [t, data, i18n.language, classes, onTableUpdate],
  );
  return (
    <div>
      <div className={classes.tableBox}>
        <GeorgDataGrid
          onSelectionModelChange={(selectionModel: GridRowSelectionModel) => {
            const newData = data.map((entry: IShiftConfigEntry) => {
              return { ...entry, active: selectionModel.includes(entry.id) };
            });
            onTableUpdate(newData);
          }}
          processRowUpdate={(newRow: GridEditCellProps) => {
            const { id, field, props } = newRow;
            const newData = updateDataField(data, id, field, props.value);
            onTableUpdate(newData as IShiftConfigEntry[]);
            return newRow;
          }}
          columns={columns}
          disableSelectionOnClick={true}
          disableColumnSelector={true}
          disableColumnFilter={true}
          rows={data}
          isLoading={isLoading}
          isTooltipEnabled={isTooltipEnabled}
        />
      </div>
    </div>
  );
}
