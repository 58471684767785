import { makeStyles } from "tss-react/mui";
import { rootTheme } from "../../theme";

export default makeStyles()({
  base: {
    ...rootTheme.noOverflow,
    minWidth: 120,
  },
  smallButton: {
    ...rootTheme.noOverflow,
    width: 84,
    height: 36,
  },
  smallFont: {
    ...rootTheme.noOverflow,
    textTransform: "capitalize",
    fontSize: 16,
  },
  mediumButton: {
    ...rootTheme.noOverflow,
    minWidth: 120,
    height: 42,
  },
});
