import { useState } from "react";
import { IManualStateConfigEntryFromServer } from "../../../../../api/manualState/IManualStateConfigEntry";
import { useEffect } from "react";
import XML_MOCK from "../../../../home/views/newsView/api/rssmock";

type TQueryState = "LOADING" | "ERROR" | "OK" | "EMPTY";

export interface IManualStateQueryResponse {
  queryState: TQueryState;
  payload: IManualStateConfigEntryFromServer[];
}

const MOCK_DATA: IManualStateConfigEntryFromServer[] = [
  {
    id: "id-1",
    displayName: "Waiting For Crane",
    isMarkedAsDeleted: false,
    isVisible: true,
    version: "0",
    displayPrio: 0,
    isNowActive: false,
  },
  {
    id: "id-2",
    displayName: "Internal Meeting",
    isMarkedAsDeleted: false,
    isVisible: true,
    version: "0",
    displayPrio: 1,
    isNowActive: false,
  },
  {
    id: "id-3",
    displayName: "Waiting for Stacker",
    isMarkedAsDeleted: false,
    isVisible: true,
    version: "0",
    displayPrio: 4,
    isNowActive: false,
  },
  {
    id: "id-4",
    displayName: "Machine Cleaning",
    isMarkedAsDeleted: false,
    isVisible: true,
    version: "0",
    displayPrio: 3,
    isNowActive: false,
  },
  {
    id: "id-5",
    displayName: "No Material",
    isMarkedAsDeleted: false,
    isVisible: true,
    version: "0",
    displayPrio: 2,
    isNowActive: true,
  },
  {
    id: "id-6",
    displayName: "Shift Change",
    isMarkedAsDeleted: false,
    isVisible: true,
    version: "0",
    displayPrio: 5,
    isNowActive: false,
  },
];

export default function useQueryManualStates(): IManualStateQueryResponse {
  const [queryState, setQueryState] = useState<TQueryState>("OK");
  const [payload, setPayload] = useState<IManualStateConfigEntryFromServer[]>(
    [],
  );
  useEffect(() => {
    setQueryState("LOADING");
    const timeout = setTimeout(() => {
      setQueryState("OK");

      setPayload(MOCK_DATA);
    }, 800);
    return () => clearTimeout(timeout);
  }, [XML_MOCK]);

  return {
    queryState,
    payload,
  };
}
