import { ApolloError, useLazyQuery } from "@apollo/client";
// import moment from "moment-timezone";
import getAllMachinesQuery from "./getMachineListsQuery";
import getAllMachinesQueryVer1 from "./getMachineListsQueryVer1";
import { useEffect, useState } from "react";
import { setMachineList } from "../../redux/reducers/machineListReducer";
import { useDispatch } from "react-redux";
import { setVariableIdentification } from "../../redux/reducers/variableIdentificationReducer";
// import { SetAllDataPayload } from "../../redux/reducers/oeeMachineListReducer";

type TResponse = [
  doQuery: () => void,
  resp: {
    error: ApolloError | undefined;
    isLoading: boolean;
  },
];

// TODO: remove alternative query after backend is updated
export default function useLazyQueryMachineListAndDispatchToReduxStore(): TResponse {
  const dispatch = useDispatch();
  // const { timezone } = useContext(GeorgStorageContext);
  const [overallError, setOverallError] = useState<ApolloError | undefined>();

  const handleError = (
    error: ApolloError | undefined,
    isAlternative: boolean = false,
  ) => {
    if (!error) return;

    if (error.message.includes("Cannot query field") && !isAlternative) {
      doAlternativeQuery();
    } else {
      setOverallError(error);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSuccess = (data: any, isAlternative: boolean = false) => {
    if (!data || !data.getMachineLists) return;

    /*const payloadData: SetAllDataPayload = {
      value: data.getMachineLists,
      updateTime: moment.tz(timezone).valueOf(),
    };*/
    dispatch(setMachineList(data));
    // This list will be loaded in the Shopfloor component
    // dispatch(setAllOeeMachineList(payloadData));
    if (!isAlternative) {
      dispatch(setVariableIdentification(data.getMachineLists));
    }
  };

  const [doQuery, { data, loading, error: machineQueryErr }] = useLazyQuery(
    getAllMachinesQuery,
    {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
  );
  const [
    doAlternativeQuery,
    { data: altData, loading: altLoading, error: altError },
  ] = useLazyQuery(getAllMachinesQueryVer1, {
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  useEffect(() => {
    handleError(machineQueryErr);
    if (!machineQueryErr && data) {
      handleSuccess(data);
    }
  }, [data, machineQueryErr]);

  useEffect(() => {
    handleError(altError, true);
    if (altData) {
      handleSuccess(altData, true);
    }
  }, [altData, altError]);
  return [doQuery, { isLoading: loading || altLoading, error: overallError }];
}
