import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useMemo } from "react";

export interface IClaims {
  acr: string;
  at_has: string;
  aud: string;
  auth_time: number;
  email: string;
  exp: number;
  extension_companyName: string;
  extension_role: "USER" | string;
  extension_tenant: string;
  family_name: string;
  given_name: string;
  iat: number;
  iss: string;
  name: string;
  nbf: number;
  nonce: string;
  sub: string;
  ver: string;
  extension_chatRestoreId: string;
}

export default function useClaims(): IClaims | null {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  return useMemo(() => {
    let returnedClaims: IClaims | null = null;
    if (isAuthenticated) {
      const account = instance.getActiveAccount();
      if (account) {
        returnedClaims = account.idTokenClaims as unknown as IClaims;
      }
    }

    return returnedClaims;
  }, [isAuthenticated, instance.getActiveAccount]);
}
