/*
attention!
If you change the values on the right hand side,
please change the translations within translation files as well!
 */
export enum EValidationResult {
  OK = "OK",
  REASON_EMPTY = "REASON_EMPTY",
  REASON_LENGTH_VIOLATED = "REASON_LENGTH_VIOLATED",
}

export const MAX_REASON_LENGTH = 150;

function isEmptyString(
  s: string,
  errorRes: EValidationResult,
): EValidationResult {
  return s.length !== 0 ? EValidationResult.OK : errorRes;
}

function validateReason(reason: string | undefined): EValidationResult {
  return !reason
    ? EValidationResult.REASON_EMPTY
    : reason.length > MAX_REASON_LENGTH
      ? EValidationResult.REASON_LENGTH_VIOLATED
      : isEmptyString(reason, EValidationResult.REASON_EMPTY);
}

export default function validation(
  reason: string | undefined,
): EValidationResult {
  const results: EValidationResult[] = [];

  results.push(validateReason(reason));

  const errorList = results.filter((r) => r !== EValidationResult.OK);
  return errorList.length > 0 ? errorList[0] : EValidationResult.OK;
}
