import IShifts from "./IShifts";
import IUpsertWorkShiftEntry from "../app/IGetWorkShiftsResponse";
import IShiftConfigEntry from "./IShiftConfigEntry";
import moment from "moment-timezone";

function getPartialHourFormatString(t: number): string {
  const s = `${t}`;
  return s.length === 1 ? `0${s}` : s;
}

export function getHourFormatFromDate(d: Date): string {
  const hours = getPartialHourFormatString(d.getHours());
  const minutes = getPartialHourFormatString(d.getMinutes());
  const seconds = getPartialHourFormatString(d.getSeconds());
  return `${hours}:${minutes}:${seconds}`;
}

export function getTimeFormatFromDateTimeString(
  dateTimeString: string,
): string {
  return moment.parseZone(dateTimeString).format("HH:mm:ss");
}

export function getDayFormatFromDate(d: Date, locale = "en"): string {
  return moment(d).locale(locale).format("dddd");
}

// This function is used to convert the time from the app timezone to UTC.
// TODO: This function is not used in the codebase. It should be removed.
export const stringToUTCTimeString = (
  timeStr: string,
  timezone: string,
): string => {
  const timeInAppTimezone = moment.tz(timeStr, "HH:mm:ss", timezone);
  // Convert the time to UTC
  const utcTime = timeInAppTimezone.utc();
  return utcTime.format("HH:mm:ss");
};

// Any changes on this function should be tested on transformAppToApiFormat.test.ts.
export default function transformAppToApiFormat(
  shiftConf: IShifts,
  timezone?: string,
): IUpsertWorkShiftEntry[] {
  const keys = Object.keys(shiftConf);
  let flatList: IUpsertWorkShiftEntry[] = [];
  keys.forEach((k) => {
    flatList = [
      ...flatList,
      ...shiftConf[k].map((conf: IShiftConfigEntry): IUpsertWorkShiftEntry => {
        const entry: IUpsertWorkShiftEntry = {
          shiftName: k,
          shiftStart: timezone
            ? stringToUTCTimeString(conf.startTime, timezone)
            : conf.startTime,
          shiftEnd: timezone
            ? stringToUTCTimeString(conf.endTime, timezone)
            : conf.endTime,
          dayOfWeek: conf.dayOfWeek,
          isActive: conf.isActive,
          virtual: false,
          machineTypeId: conf.machineTypeId,
        };
        if (!conf.isNew) {
          entry.id = conf.id;
        }
        return entry;
      }),
    ];
  });

  return flatList;
}
