import React, { useLayoutEffect, useMemo } from "react";
// Custom Imports.
import { useTranslation } from "react-i18next";
import getStatusLabel, {
  MachineCardStatusTypeWithColor,
} from "../../../../../services/getStatusLabel";
import ServerDataStatusWrapper from "../../../../../shared/components/serverDataStatusWrapper";
import useCurrentIndicatorStateList, {
  IIndicatorStateSince,
  isPayloadValid,
} from "./api/useCurrentIndicatorStateList/useCurrentIndicatorStateList";
import { TIndicatorType } from "../../../../../helper";
import { EApiState } from "../../../../../api/state/EApiState";
import MachineStatusFooterContent from "./components/statusFooterContent";
import useStyles from "./styles";

interface IProps {
  machineId: string;
  allIndicators: TIndicatorType[];
  onStateChange?: (state: IIndicatorStateSince) => void;
  timezone: string;
}
function MachineStatusFooter({
  machineId,
  allIndicators,
  onStateChange,
  timezone,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { indicatorStateSince, apiState } = useCurrentIndicatorStateList(
    parseInt(machineId, 10),
    allIndicators,
    timezone,
  );

  useLayoutEffect(() => {
    if (onStateChange && isPayloadValid(apiState, indicatorStateSince)) {
      onStateChange(indicatorStateSince);
    }
  }, [indicatorStateSince, apiState]);

  // Get Status from service.
  const result: MachineCardStatusTypeWithColor | undefined = useMemo<
    MachineCardStatusTypeWithColor | undefined
  >(() => {
    if (isPayloadValid(apiState, indicatorStateSince)) {
      return getStatusLabel(t, indicatorStateSince.indicatorState);
    } else {
      return undefined;
    }
  }, [apiState, indicatorStateSince]);

  const isLoading = useMemo(() => apiState === EApiState.LOADING, [apiState]);

  return (
    <ServerDataStatusWrapper
      isEmpty={apiState === EApiState.EMPTY}
      hasError={apiState === EApiState.ERROR}
      wrapperStyle={classes.wrapperContainer}
    >
      <MachineStatusFooterContent
        result={result}
        isLoading={isLoading}
        indicatorStateSince={indicatorStateSince}
      />
    </ServerDataStatusWrapper>
  );
}

export default React.memo(MachineStatusFooter);
