import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  container: {
    display: "flex",
    justifyContent: "center",
  },
  clickable: {
    cursor: "pointer",
  },
  disabled: {
    opacity: 0.3,
    pointerEvents: "none",
  },
}));

export default useStyles;
