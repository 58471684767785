/*
 *   Downtimes Reducer which stores all downtime indicators from backend as key-value pair. Is necessary for example for querying kpiPieChartData.
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { createSelector } from "reselect";

export interface IDowntimeState {
  machineTypeId: string;
  indicatorIdentificationTypeIds: number[];
}

const initialState: IDowntimeState[] = [];

export const downtimeSlice = createSlice({
  name: "downtimes",
  initialState,
  reducers: {
    setDowntime: (state, action: PayloadAction<IDowntimeState[]>): void => {
      // Clear the state and push the new downtime indicators
      state.splice(0, state.length);
      state.push(
        ...action.payload.map(
          (machineIndicator: IDowntimeState) =>
            ({
              machineTypeId: machineIndicator.machineTypeId.toString(),
              indicatorIdentificationTypeIds:
                machineIndicator.indicatorIdentificationTypeIds,
            }) as IDowntimeState,
        ),
      );
    },
  },
});

export const getDowntimeList = (state: RootState) => state.downtimeList;

export const getSelectedMachineDowntimeList = (machineId: string) =>
  createSelector(
    [getDowntimeList],
    (downtimeList: IDowntimeState[]): string[] => {
      const foundDowntime = downtimeList.find(
        (downtime) => downtime.machineTypeId == machineId,
      );
      return (
        foundDowntime?.indicatorIdentificationTypeIds.map((id) =>
          id.toString(),
        ) ?? []
      );
    },
  );

export const { setDowntime } = downtimeSlice.actions;

export default downtimeSlice.reducer;
