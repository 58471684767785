import React, { ReactElement, useCallback, useMemo } from "react";
import { Tab, Tabs } from "@mui/material";
import {
  Route,
  Routes,
  useNavigate,
  useLocation,
  useResolvedPath,
} from "react-router-dom";
import useStyles from "./styles";

//------------------------------------------------------------------------------------------------------------
// Interfaces & Types.
type Orientation = "horizontal" | "vertical" | undefined;

interface ITabType {
  icon?: React.ReactElement;
  label?: string | React.ReactElement | null;
  path?: string;
}

type TabTextColor = "inherit" | "primary" | "secondary" | undefined;

type TabVariant = "scrollable" | "fullWidth" | "standard" | undefined;

type UnderlineColor = "primary" | "secondary";

interface IProps {
  alignCenter?: boolean;
  disableTabs?: boolean;
  disableContentPadding?: boolean;
  disableRipple?: boolean;
  orientation?: Orientation;
  swipeableContent?: boolean;
  tabs?: ITabType[];
  tabContent?: React.ReactElement[];
  tabTextColor?: TabTextColor;
  tabVariant?: TabVariant;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tabContainerProps?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tabProps?: any;
  underlineColor?: UnderlineColor;
  wrapTabLabel?: boolean;
  onTabChange?: (path: string) => void;
}

//------------------------------------------------------------------------------------------------------------
// Component.
// TODO Check if large interface is really needed
export default function DashboardTabs({
  alignCenter,
  disableTabs,
  disableRipple,
  orientation = "horizontal",
  tabs = [{ icon: <></>, label: "" }],
  tabContent = [],
  tabTextColor,
  tabVariant,
  tabContainerProps,
  tabProps,
  underlineColor,
  wrapTabLabel,
  onTabChange,
}: IProps): React.ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const { classes } = useStyles();
  const handleChange = useCallback(
    (event: React.ChangeEvent, newValue: string) => {
      navigate(newValue);
      if (onTabChange) {
        onTabChange(newValue);
      }
    },
    [navigate, location],
  );
  const useParentPath = () => {
    const paths = location.pathname.split("/");
    // Remove the last segment of the path
    paths.pop();
    const parentPath = paths.join("/") || "/";
    return parentPath;
  };
  const url = useResolvedPath(".").pathname;
  const parentPath = useParentPath();

  const tabsToRender = useMemo(() => {
    return tabs.length > 0
      ? tabs.map((item) => {
          return (
            <Tab
              classes={{
                selected: classes.tabSelected,
                disabled: classes.tabDisabled,
                root: classes.tabRoot,
                // button: classes.tab,
                wrapper: classes.tabLabel,
                wrapped: classes.tabLabel,
              }}
              disabled={disableTabs}
              disableRipple={disableRipple}
              icon={item.icon}
              key={item.path}
              label={item.label}
              {...tabProps}
              wrapped={wrapTabLabel}
              value={`${parentPath}${item.path}`}
            />
          );
        })
      : null;
  }, [
    tabs,
    location,
    disableTabs,
    disableRipple,
    tabProps,
    wrapTabLabel,
    parentPath,
  ]);

  const switches = useMemo(() => {
    // here refactor the code to use the tabContent array
    // const indexRoute = <Route key="index" path="*" element={tabContent[0]} />;
    const generatedRoutes = tabContent?.map(
      (value: ReactElement, index: number) => {
        const path = `${url}${tabs[index].path}`;
        return (
          <Route
            key={path}
            path={url.endsWith(tabs[index].path ?? "") ? "" : tabs[index].path}
            element={<>{value}</>}
          />
        );
      },
    );
    return [...generatedRoutes];
  }, [tabContent, tabs, location]);

  return (
    <>
      <Tabs
        className={classes.tabContainer}
        centered={alignCenter}
        disableripple={`${disableRipple}`}
        indicatorColor={underlineColor}
        onChange={handleChange}
        orientation={orientation}
        scrollButtons="auto"
        textColor={tabTextColor}
        value={location.pathname}
        variant={tabVariant}
        {...tabContainerProps}
      >
        {tabsToRender}
      </Tabs>
      <Routes>{switches}</Routes>
    </>
  );
}
