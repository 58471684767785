import createLayout from "../gridPage/helper/createLayout";

export const MAIN_TIME_PERIOD_LAYOUT_KEY = "MainTimePeriod";
export const REFERENCE_TIME_PERIOD_LAYOUT_KEY = "ReferenceTimePeriod";
export const APPLY_BUTTON_LAYOUT_KEY = "ApplyButton";
type TGridLayoutTabsKEY =
  | typeof MAIN_TIME_PERIOD_LAYOUT_KEY
  | typeof REFERENCE_TIME_PERIOD_LAYOUT_KEY
  | typeof APPLY_BUTTON_LAYOUT_KEY;

export const dropDownHeight = 2;
export const smallDropDownHeight = 1.5;
export const timeRangeHeight = 1.5;
export const buttonHeight = 1.5;

const MainPeriodDouble = {
  w: 6,
  h: dropDownHeight,
};
const MainPeriodSingle = {
  w: 12,
  h: dropDownHeight,
};
const MainPeriodSingle2 = {
  w: 12,
  h: dropDownHeight + smallDropDownHeight,
};
const noComponentDouble = {
  w: 6,
  h: 0,
  minH: 0,
};
const noComponent = {
  w: 12,
  h: 0,
  minH: 0,
};
const ApplyButtonDouble = {
  w: 6,
  h: buttonHeight,
};
const ApplyButtonSingle = {
  w: 12,
  h: buttonHeight,
};

const layoutXLarge = [
  { i: MAIN_TIME_PERIOD_LAYOUT_KEY, x: 0, y: 0, ...MainPeriodDouble },
  { i: REFERENCE_TIME_PERIOD_LAYOUT_KEY, x: 6, y: 0, ...noComponentDouble },
  { i: APPLY_BUTTON_LAYOUT_KEY, x: 6, y: 2, ...ApplyButtonDouble },
];

const SmalllayoutXLarge = [
  { i: MAIN_TIME_PERIOD_LAYOUT_KEY, x: 0, y: 0, ...MainPeriodSingle },
  { i: REFERENCE_TIME_PERIOD_LAYOUT_KEY, x: 6, y: 0, ...noComponentDouble },
  { i: APPLY_BUTTON_LAYOUT_KEY, x: 6, y: 2, ...ApplyButtonDouble },
];

const layoutLarge = [
  { i: MAIN_TIME_PERIOD_LAYOUT_KEY, x: 0, y: 0, ...MainPeriodDouble },
  { i: REFERENCE_TIME_PERIOD_LAYOUT_KEY, x: 6, y: 0, ...noComponentDouble },
  { i: APPLY_BUTTON_LAYOUT_KEY, x: 0, y: 2, ...ApplyButtonSingle },
];

const smallLayoutLarge = [
  { i: MAIN_TIME_PERIOD_LAYOUT_KEY, x: 0, y: 0, ...MainPeriodSingle },
  { i: REFERENCE_TIME_PERIOD_LAYOUT_KEY, x: 0, y: 2, ...noComponent },
  { i: APPLY_BUTTON_LAYOUT_KEY, x: 0, y: 4, ...ApplyButtonSingle },
];

const layoutSmall = [
  { i: MAIN_TIME_PERIOD_LAYOUT_KEY, x: 0, y: 0, ...MainPeriodSingle2 },
  { i: REFERENCE_TIME_PERIOD_LAYOUT_KEY, x: 0, y: 4, ...noComponent },
  { i: APPLY_BUTTON_LAYOUT_KEY, x: 0, y: 8, ...ApplyButtonSingle },
];

export type TResizeConfig = {
  [key in TGridLayoutTabsKEY]: number;
};

// export default KPI_VIEW_LAYOUTS;

export function getLayoutInfoXl(resizeConfig: TResizeConfig) {
  return createLayout<TResizeConfig>(resizeConfig, layoutXLarge);
}

export function getSmallLayoutInfoXl(resizeConfig: TResizeConfig) {
  return createLayout<TResizeConfig>(resizeConfig, SmalllayoutXLarge);
}

export function getLayoutInfoLg(resizeConfig: TResizeConfig) {
  return createLayout<TResizeConfig>(resizeConfig, layoutLarge);
}

export function getSmallLayoutInfoLg(resizeConfig: TResizeConfig) {
  return createLayout<TResizeConfig>(resizeConfig, smallLayoutLarge);
}

export function getLayoutInfoMd(resizeConfig: TResizeConfig) {
  return createLayout<TResizeConfig>(resizeConfig, smallLayoutLarge);
}

export function getSmallLayoutInfoMd(resizeConfig: TResizeConfig) {
  return createLayout<TResizeConfig>(resizeConfig, smallLayoutLarge);
}

export function getLayoutInfoSm(resizeConfig: TResizeConfig) {
  return createLayout<TResizeConfig>(resizeConfig, layoutSmall);
}

export function getSmallLayoutInfoSm(resizeConfig: TResizeConfig) {
  return createLayout<TResizeConfig>(resizeConfig, layoutSmall);
}
