import React from "react";
import { Container, Grid2 as Grid } from "@mui/material";
// Custom imports.
import useStyles from "./styles";
import NewsPreviewCard from "./components/newsPreviewCard";
import useNewsQuery from "./api/useNewsQuery";
import ServerDataStatusWrapper from "../../../../shared/components/serverDataStatusWrapper";
import NewsPreviewCardSkeleton from "./components/skeleton";

export default function NewsView(): React.ReactElement {
  const { classes } = useStyles();
  const { hasError, isLoading, payload } = useNewsQuery();

  return (
    <Container className={classes.container}>
      <ServerDataStatusWrapper
        hasError={hasError}
        isLoading={isLoading}
        loadingContent={
          <Grid container className={classes.outerGrid} spacing={3}>
            {Array.from({ length: 4 }, (_, index) => (
              <Grid size={{ xl: 3, lg: 4, md: 6, sm: 12, xs: 12 }} key={index}>
                <NewsPreviewCardSkeleton />
              </Grid>
            ))}
          </Grid>
        }
      >
        <Grid className={classes.outerGrid} container spacing={3}>
          {payload != null &&
            payload?.slice(0, 6).map(({ content, header, image, uri }) => {
              return (
                <Grid
                  size={{ xl: 3, lg: 4, md: 6, sm: 12, xs: 12 }}
                  key={header + image}
                >
                  <NewsPreviewCard
                    key={image + header}
                    header={header}
                    content={content}
                    image={image}
                    uri={uri}
                  />
                </Grid>
              );
            })}
        </Grid>
      </ServerDataStatusWrapper>
    </Container>
  );
}
