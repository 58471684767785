import React from "react";
import { Link } from "react-router-dom";
import { Button, ButtonGroup, IconButton, Typography } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { ButtonPropsColorOverrides } from "@mui/material/Button/Button";

//------------------------------------------------------------------------------------------------------------
// Interfaces & Types.

type ButtonSize = "large" | "medium" | "small" | undefined;

type ButtonVariant = "text" | "outlined" | "contained" | undefined;

type FlexDirection =
  | "row"
  | "-moz-initial"
  | "inherit"
  | "initial"
  | "revert"
  | "unset"
  | "column"
  | "column-reverse"
  | "row-reverse"
  | undefined;

type LabelVariant =
  | "button"
  | "caption"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "inherit"
  | "subtitle1"
  | "subtitle2"
  | "body1"
  | "body2"
  | "overline"
  | "srOnly"
  | undefined;

type StyleProps = {
  button?: string;
  label?: string;
  disabled?: string;
};
// TODO Check if all these props are really needed
export interface IProps {
  buttonColor?: OverridableStringUnion<
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning",
    ButtonPropsColorOverrides
  >;
  buttonGroupLabels?: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  buttonProps?: any; // todo replace any
  buttonSize?: ButtonSize;
  buttonVariant?: ButtonVariant;
  disabled?: boolean;
  disableRipple?: boolean;
  flexDirection?: FlexDirection;
  fullWidth?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon?: any;
  label?: string | null;
  labelColor?: OverridableStringUnion<
    | "inherit"
    | "primary"
    | "textPrimary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning",
    ButtonPropsColorOverrides
  >;
  labelVariant?: LabelVariant;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  labelProps?: any; // todo replace any
  name?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: any;
  route?: string;
  styles?: StyleProps;
  endIcon?: JSX.Element;
  isNotAction?: boolean;
}

//------------------------------------------------------------------------------------------------------------
// Component.
export default function IButton({
  buttonColor = "primary",
  buttonGroupLabels = [],
  buttonProps,
  buttonSize,
  buttonVariant,
  disabled = false,
  disableRipple,
  flexDirection = "row",
  fullWidth,
  icon: Icon,
  label = "",
  labelColor = "primary",
  labelVariant = "button",
  labelProps,
  name = "",
  onClick,
  route = "",
  styles = { button: "", label: "", disabled: "" },
  endIcon,
  isNotAction = false,
}: IProps): React.ReactElement {
  const renderChilds = () => {
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection,
        }}
      >
        {Icon || null}
        {label ? (
          <Typography
            className={styles.label}
            color={labelColor}
            variant={labelVariant}
            {...labelProps}
          >
            {label}
          </Typography>
        ) : null}
      </div>
    );
  };

  if (buttonGroupLabels && buttonGroupLabels.length) {
    return (
      <ButtonGroup
        className={styles.button}
        color={buttonColor}
        disabled={disabled}
        disableRipple={disableRipple}
        fullWidth={fullWidth}
        size={buttonSize}
        variant={buttonVariant}
        area-label={name}
        {...buttonProps}
      >
        {buttonGroupLabels.map((item, index) => {
          return (
            <Button key={index} aria-labelId={index}>
              <Typography
                key={index}
                className={styles.label}
                color={labelColor}
                variant={labelVariant}
                {...labelProps}
              >
                {item}
              </Typography>
            </Button>
          );
        })}
      </ButtonGroup>
    );
  }

  if (label === "" && Icon) {
    return (
      <IconButton
        className={styles.button}
        classes={{ disabled: styles.disabled }}
        color={buttonColor}
        disabled={disabled}
        disableRipple={disableRipple}
        name={name}
        aria-label={name}
        onClick={onClick}
        date-testid={name}
      >
        {Icon}
      </IconButton>
    );
  }

  if (route !== "") {
    return (
      <Button
        className={styles.button}
        classes={{ disabled: styles.disabled }}
        color={buttonColor}
        component={Link}
        disabled={disabled}
        disableRipple={disableRipple}
        fullWidth={fullWidth}
        size={buttonSize}
        to={route}
        variant={buttonVariant}
        {...buttonProps}
      >
        {renderChilds()}
      </Button>
    );
  }
  return (
    <Button
      className={styles.button}
      classes={{ disabled: styles.disabled }}
      color={buttonColor}
      disabled={disabled}
      disableRipple={disableRipple}
      fullWidth={fullWidth}
      name={name} // label
      size={buttonSize}
      onClick={onClick}
      variant={buttonVariant}
      endIcon={endIcon}
      aria-label={name}
      style={{ opacity: disabled ? 0.4 : 1 }}
      sx={{
        boxShadow: isNotAction ? "none" : undefined,
        "&:hover": {
          boxShadow: isNotAction ? "none" : undefined,
        },
      }}
    >
      {renderChilds()}
    </Button>
  );
}
