/*
 *
 *   Time selection for report-view, kpi-view and stacklightView-view.
 *
 */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// Custom Imports.
import FormCombobox from "../../../formCombobox";

interface IJobTimeSelectionPeriodOption {
  value: TJobTimeSelectionKey;
  label: string;
}

export enum EJobTimeSelection {
  NOW = "now",
  In10Min = "in-10-min",
  in30min = "in-30-min",
  in1hour = "in-1-hour",
  in6hour = "in-6-hours",
  in24hour = "in-24-hours",
  individual = "individual",
}
export type TJobTimeSelectionKey = `${EJobTimeSelection}`;
export const JobTimeSelectionValues = Object.values(
  EJobTimeSelection,
) as TJobTimeSelectionKey[];

//------------------------------------------------------------------------------------------------------------
// Interfaces & Types.
interface IProps {
  initPeriod: TJobTimeSelectionKey;
  name?: string | null;
  onTimeChange?: (selected: TJobTimeSelectionKey) => void;
  value?: TJobTimeSelectionKey;
  label: string;
  onSetIndividualSelected?: (isSelected: boolean) => void;
}

//------------------------------------------------------------------------------------------------------------
// Component.
export default function JobTimePeriodSelection({
  initPeriod,
  label,
  onTimeChange,
  onSetIndividualSelected,
}: IProps): React.ReactElement {
  const [isIndividualSelected, setIsIndividual] = useState<boolean>(
    initPeriod === "individual",
  );
  const { t } = useTranslation();
  const [value, setValue] = useState<TJobTimeSelectionKey | undefined>(
    initPeriod,
  );
  const periodSelectOptions: IJobTimeSelectionPeriodOption[] =
    JobTimeSelectionValues.map((val) => ({
      value: val,
      label: t(`taskManager.jobManagement.form.timeSelection.${val}`),
    }));

  useEffect(() => {
    if (onSetIndividualSelected) {
      onSetIndividualSelected(isIndividualSelected);
    }
  }, [isIndividualSelected]);
  return (
    <FormCombobox
      value={value || ""}
      hasError={!value}
      label={label}
      id={"dateTimeComponent"}
      onChange={(value) => {
        const newValue = value as TJobTimeSelectionKey;
        setValue(newValue);
        setIsIndividual(value === "individual");
        if (onTimeChange) {
          onTimeChange(newValue);
        }
      }}
      options={periodSelectOptions}
    />
  );
}
