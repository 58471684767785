import { makeStyles } from "tss-react/mui";
import { colors } from "../../../../../../../shared/theme";

const useStyles = makeStyles()(() => ({
  shiftHeaderBox: {
    fontFamily: "Titillium Web",
    width: "100%",
  },
  headerTitle: {
    "& h5": {
      marginTop: "-15px",
      color: colors.greyB8,
      fontWeight: "normal",
    },
  },
  selection: {
    width: 250,
  },
}));

export default useStyles;
