import { IKPILineChartData } from "./kpiEntryReducer";
import IKpiAvgResponse from "../IKpiAvgResponse";
import IKpiAvgDatalakeDataType from "../IkpiAvgDatalakeDataType";
import TKPIType from "../../../../../api/kpi/TKPIType";
import indicatorIdToIndicatorType from "../../../../../helper/indicator/indicatorIdToIndicatorType";
import { TIndicatorType } from "../../../../../helper";

export default function kpiAvgResponseToEmptyLineChart(
  data: IKpiAvgResponse,
  allIndicators: TIndicatorType[] | [],
): IKPILineChartData | null {
  const { getAvgOfKpiEntriesWithWorkShifts } = data;
  if (
    !getAvgOfKpiEntriesWithWorkShifts ||
    !getAvgOfKpiEntriesWithWorkShifts.kpiData
  ) {
    return null;
  }
  /*if (!allIndicators || allIndicators.length === 0) {
    return null;
  }*/
  const kpiLineChartDataMap: IKPILineChartData = {};
  getAvgOfKpiEntriesWithWorkShifts.kpiData.forEach(
    (kpiAvgDatalakeDataType: IKpiAvgDatalakeDataType) => {
      const { indicatorIdentificationTypeId } = kpiAvgDatalakeDataType;
      const indicator = indicatorIdToIndicatorType(
        allIndicators,
        `${indicatorIdentificationTypeId}`,
      );
      kpiLineChartDataMap[indicator.indicatorName] = {
        indicatorTypeId: parseInt(indicator.id, 10),
        indicator: indicator.indicatorName as TKPIType,
        lineChartDataEntries: [],
      };
    },
  );

  return Object.keys(kpiLineChartDataMap).length > 0
    ? kpiLineChartDataMap
    : null;
}
