import { useMemo } from "react";

interface ILocalStorageApi {
  set: (key: string, value: string) => void;
  load: (key: string) => string | null;
  deleteItem: (key: string) => void;
  getAllKeys: () => Map<string, string>;
}

const PREFIX_ID = "de.georg.persistence.";

function set(key: string, value: string): void {
  localStorage.setItem(`${PREFIX_ID}${key}`, value);
}

function load(key: string): string | null {
  return localStorage.getItem(`${PREFIX_ID}${key}`);
}

function deleteItem(key: string): void {
  localStorage.removeItem(`${PREFIX_ID}${key}`);
}

function getAllKeys(): Map<string, string> {
  return Object.keys(localStorage).reduce((acc, key: string) => {
    if (key.startsWith(PREFIX_ID)) {
      acc.set(key, localStorage.getItem(key) ?? "");
    }
    return acc;
  }, new Map<string, string>());
}

export default function useLocalStorage(): ILocalStorageApi {
  return useMemo(() => {
    return { set, load, deleteItem, getAllKeys };
  }, []);
}
