import { IChartPart } from "../../../../../../shared/components/charts/barChartHeatmap/components/heatmapRow/api/extractChartParts/extractChartParts";
import {
  DAY_AXIS_RESOLUTION_UNIT_NUMBER,
  getAxisResolutionUnitNumber,
} from "../dateRangeScaler/dateRangeScaler";

export interface IHeatmapRowPointData {
  indicatorValue: number;
  displayDurationParts: {
    days: number;
    hours: string;
  };
  displayPeriodParts: string;
  pointDate: string;
  ts: number;
}
export interface IHeatmapRowData {
  t: number;
  [prop: string]: number | IHeatmapRowPointData[];
}
function addMissingProps(
  heatMapRowData: IHeatmapRowData[],
  prioList: string[],
): IHeatmapRowData[] {
  return heatMapRowData.map((rowData: IHeatmapRowData) => {
    prioList.forEach((key: string) => {
      const keyStr = key;
      if (rowData[keyStr] == null) {
        rowData[keyStr] = 0;
      }
    });
    return rowData;
  });
}

// TODO: please delete [displayStringParts, displayDurationParts] if not needed anymore
export default function chartPartsToHeatMapRowData(
  parts: IChartPart[],
  prioList: string[],
  scale: string,
  resolution = DAY_AXIS_RESOLUTION_UNIT_NUMBER,
): IHeatmapRowData[] {
  const scaledResolution =
    getAxisResolutionUnitNumber(scale.toLowerCase()) || resolution;
  const heatMapRowData: IHeatmapRowData[] = [];
  for (let i = 0; i < scaledResolution; i++) {
    const data: IHeatmapRowPointData[] = [];
    const rowDataPointsEntry: IHeatmapRowData = { t: i };
    const displayDurationParts = {
      days: 0,
      hours: "",
    };
    parts.forEach(
      ({
        tsStart,
        tsEnd,
        id,
        displayStringParts,
        timeDuration,
        displayDate,
      }: IChartPart) => {
        if (i >= Math.floor(tsStart) && i <= Math.ceil(tsEnd)) {
          rowDataPointsEntry[id] = 1;
          data.push({
            indicatorValue: id,
            displayDurationParts: displayStringParts || displayDurationParts,
            displayPeriodParts: timeDuration || "",
            pointDate: displayDate || "",
            ts: tsEnd,
          });
        }
      },
    );
    heatMapRowData.push({
      ...rowDataPointsEntry,
      data,
    });
  }

  return addMissingProps(heatMapRowData, prioList).filter(
    // remove duplicate t entries
    (rowData: IHeatmapRowData, index: number, allRowData: IHeatmapRowData[]) =>
      index === allRowData.findIndex((t: IHeatmapRowData) => t.t === rowData.t),
  );
}
