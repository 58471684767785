import { makeStyles } from "tss-react/mui";
import { colors } from "../../theme";

const styles = makeStyles()({
  ToggleChecked: {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: colors.blue_base,
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: colors.blue_base,
    },
  },
});

export default styles;
