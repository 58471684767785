import { useCallback, useEffect, useMemo, useState } from "react";
import createAggregatedVariableQuery from "./createAggregatedVariableQuery";
import { useLazyQuery } from "@apollo/client";
import IAggregatedVariableDataResponse, {
  IAggregatedVariableDataFrontendEntry,
  IAggregatedVariableDataResponseEntry,
} from "./IAggregatedVariableDataResponse";
import { IGetVariableType } from "../../../redux/reducers/variableIdentificationReducer";
import { ITimeSelectionState } from "../../../redux/reducers/timeSelectReducer";

// convert all first word to lower case and the first character of the second word to upper case and concatenate them
function toCamelCase(word1: string, word2: string): string {
  return (
    word1.toLowerCase() +
    word2.charAt(0).toUpperCase() +
    word2.slice(1).toLowerCase()
  );
}

interface IAggregatedVariableResponse {
  aggregatedVariable: IAggregatedVariableDataFrontendEntry;
  isLoading: boolean;
  hasError: boolean;
  isEmpty: boolean;
}

function getFrontendData(
  data: IAggregatedVariableDataResponseEntry | undefined,
  propertyName: string,
): IAggregatedVariableDataFrontendEntry {
  // TODO: This should be fetched from the backend.
  const machineMaxPercentage = 160;

  const isProcent = data?.unit.displayName === "%";
  const currentValue =
    data?.current?.[propertyName] != null
      ? Math.trunc(data.current[propertyName] * data.unit.conversion_factor)
      : null;
  const referenceValue =
    data?.reference?.[propertyName] != null
      ? Math.trunc(data.reference[propertyName] * data.unit.conversion_factor)
      : null;
  return {
    current: {
      value: currentValue,
      isAnomaly:
        currentValue != null &&
        (currentValue < 0 ||
          (isProcent && currentValue > machineMaxPercentage)),
    },
    reference: {
      value: referenceValue,
      isAnomaly:
        referenceValue != null &&
        (referenceValue < 0 ||
          (isProcent && referenceValue > machineMaxPercentage)),
    },
    unit: data?.unit ?? {
      description: "-",
      displayName: "-",
      conversion_factor: 1,
    },
  };
}

export default function useAggregatedVaribaleQuery(
  variableObject: IGetVariableType,
  timeSelection: ITimeSelectionState,
): IAggregatedVariableResponse {
  const [errorMessage, setErrorMessage] = useState("");
  const [emptyQuery, setEmptyQuery] = useState(false);
  const propertyName = useMemo(
    () => toCamelCase(variableObject.function, variableObject.type),
    [variableObject.function, variableObject.type],
  );
  const { query, parameters } = createAggregatedVariableQuery(
    variableObject.id,
    timeSelection.currentFilter.startedAfter,
    timeSelection.currentFilter.endedBefore,
    timeSelection.referenceFilter.startedAfter,
    timeSelection.referenceFilter.endedBefore,
    variableObject.type,
    variableObject.function,
    propertyName,
  );
  const [doQuery, { data, loading: isLoading, error }] =
    useLazyQuery<IAggregatedVariableDataResponse>(query, parameters);

  const shouldDoQuery = useCallback(() => {
    if (
      (!timeSelection.currentFilter.startedAfter &&
        !timeSelection.currentFilter.endedBefore) ||
      (!timeSelection.referenceFilter.startedAfter &&
        !timeSelection.referenceFilter.endedBefore)
    ) {
      setErrorMessage(`Invalid input values.`);
      return false;
    } else if (
      !variableObject.id ||
      propertyName === "" ||
      variableObject.id === "0"
    ) {
      setErrorMessage("");
      setEmptyQuery(true);
      return false;
    }
    setErrorMessage("");
    return true;
  }, [timeSelection, variableObject.id, propertyName, setErrorMessage]);

  useEffect(() => {
    if (shouldDoQuery()) {
      doQuery();
    }
  }, [doQuery, shouldDoQuery, variableObject.id, timeSelection]);

  const aggregatedVariableDataFromServer =
    data?.aggregatedVariableReferenceChartData;
  const aggregatedVariableDataResponse = useMemo(
    () => getFrontendData(aggregatedVariableDataFromServer, propertyName),
    [
      aggregatedVariableDataFromServer?.current,
      aggregatedVariableDataFromServer?.reference,
      propertyName,
    ],
  );

  const isError = useMemo(() => {
    return (
      error &&
      error.message !== "Empty dataset returned." &&
      error.message !== "Error: Not enough data available"
    );
  }, [error]);
  const isEmpty = !isLoading && !aggregatedVariableDataFromServer && emptyQuery;

  return {
    aggregatedVariable: aggregatedVariableDataResponse,
    isLoading,
    hasError: isError || !!errorMessage,
    isEmpty,
  };
}
