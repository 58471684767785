import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
// Custom Imports.
import { Loader, MachineSelectBar } from "../../index";
import { setMachine } from "../../../redux/reducers/machineSelectReducer";
import { IMachine } from "../../../api/machine/IMachine";
// import useLazyQueryMachineListAndDispatchToReduxStore from "../../../api/machine/useLazyQueryMachineListAndDispatchToReduxStore";
// import useInterval from "../../../helper/time/interval/useInterval";
import sortByMachineKey, { EOrder } from "./sort/sortByMachineKey";
import GroupedMachineList from "./components/groupedMachineList";
import InfiniteScroll from "react-infinite-scroll-component";
import { selectMachineList } from "../../../redux/reducers/machineListReducer";
import sortByMachineStateAndDuration from "./sort/sortByMachineState";
import { initializeMachineStateMap } from "./machineStateMap";
import TMachineStateMap from "./machineStateMap/TMachineStateMap";
import { IIndicatorStateSince } from "./components/machineStatusFooter/api/useCurrentIndicatorStateList/useCurrentIndicatorStateList";
import useStyles from "./styles";
import GeorgStorageContext from "../../../context/GeorgStorageContext";
import { getEndIndex } from "./getMachinesEndIndex";

interface IValueLabel {
  value: string;
  label: string;
}

function toValueLabel(strings: string[]): IValueLabel[] {
  return strings.map((l: string) => {
    return {
      value: l,
      label: l,
    };
  });
}

const isMachine = (machine: IMachine, machineId: string) =>
  machine.id === machineId;
const hasLocation = (machine: IMachine, location: string) =>
  machine.location === location;
const hasMachineTech = (machine: IMachine, deviceClass: string) =>
  machine.deviceClass === deviceClass;

export enum EFilterType {
  NO_FILTER = "NO_FILTER",
  BY_MACHINE = "BY_MACHINE",
  BY_TECH = "BY_TECH",
  BY_LOCATION = "BY_LOCATION",
}

export interface IFilterSettings {
  by_location?: string;
  by_machine?: string;
  by_tech?: string;
}

export enum ESortBy {
  TECH = "TECH",
  LOCATION = "LOCATION",
  STATE = "STATE",
  NO_SORT = "NO_SORT",
}

export interface ISort {
  sortBy: ESortBy;
  order: EOrder;
}

interface IProps {
  kpi: boolean;
}

function MachineryView({ kpi }: IProps): React.ReactElement {
  const machines = useSelector(selectMachineList);
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [machineStateMap, setMachineStateMap] = useState<TMachineStateMap>(
    new Map(),
  );

  const {
    machineViewSort,
    setMachineViewSort,
    machineViewFilter,
    setMachineViewFilter,
  } = useContext(GeorgStorageContext);

  const [sort, setSort] = useState<ISort>(machineViewSort);
  useEffect(() => {
    setMachineViewSort(sort);
  }, [sort]);
  const canSetSortState = () => {
    return machineStateMap.size === machines.length && machines.length > 0;
  };
  const setSortState = ({ sortBy, order }: ISort) => {
    if (canSetSortState()) {
      setSort({ sortBy, order });
    }
  };

  useEffect(() => {
    if (machineStateMap.size === 0) {
      setMachineStateMap(initializeMachineStateMap(machines));
    }
  }, [machines]);

  // Sort by state after machineStateMap is changed.
  useEffect(() => {
    if (machineStateMap.size > 0 && sort.sortBy === ESortBy.NO_SORT) {
      setSortState(machineViewSort);
    }
  }, [machineStateMap.size]);

  const dispatch = useDispatch();

  const removeFilter: IFilterSettings = {
    by_location: "",
    by_machine: "",
    by_tech: "",
  };
  const initialFilter: IFilterSettings = machineViewFilter;
  const [filter, setFilter] = useState<IFilterSettings>(initialFilter);
  useEffect(() => {
    setMachineViewFilter(filter);
  }, [filter]);
  const navigate = useNavigate();
  // TODO: Delete if not needed
  // refetch machine list for last 24h kpi values every 10 minutes
  // const [doQuery] = useLazyQueryMachineListAndDispatchToReduxStore();
  /*useInterval(
    () => {
      if (kpi) {
        doQuery();
      }
    },
    1000 * 60 * 10,
  );*/

  const filterOptions = useMemo(() => {
    // Unique location and technology sets
    const uniqueLocations = Array.from(
      new Set(machines.map((machine) => machine.location)),
    );
    const uniqueTechnologies = Array.from(
      new Set(machines.map((machine) => machine.deviceClass)),
    );

    // Map to value-label structures
    const locationOptions = toValueLabel(uniqueLocations) as IValueLabel[];
    const technologyOptions = toValueLabel(uniqueTechnologies) as IValueLabel[];

    // Map machines to value-label structures with detailed labels
    const machineOptions = machines.map((machine) => ({
      label: `${machine.deviceLabel}${machine.deviceLabelExtension}${machine.deviceSublabel}\n(${machine.id})`,
      value: machine.id,
    }));

    // Add default select option
    const defaultOption = (labelText: string) => [
      { value: "", label: labelText },
    ];

    return {
      location: defaultOption(t("common.location")).concat(locationOptions),
      machine: defaultOption(t("common.machine")).concat(machineOptions),
      technology: defaultOption(t("common.technology")).concat(
        technologyOptions,
      ),
    };
  }, [machines]);

  const handleFilter = useCallback((type: EFilterType, value: string) => {
    setFilter((prevFilter) => {
      if (type !== EFilterType.NO_FILTER) {
        return {
          ...prevFilter,
          [type.toLowerCase()]: value,
        };
      }
      return removeFilter;
    });
  }, []);

  const handleClick = useCallback((machineId: string) => {
    dispatch(setMachine(machineId));
    navigate(`/dashboards/machine/${machineId}/oee`);
  }, []);

  const { breakpoints } = useTheme();
  const isWidthAboveLG = useMediaQuery(breakpoints.up("lg"), { noSsr: true });

  const displayedMachinesAfterFilter: IMachine[] = useMemo(() => {
    const { by_location, by_machine, by_tech } = filter;
    return machines.filter((machine: IMachine) => {
      const locationFilter = !by_location || hasLocation(machine, by_location);
      const machineFilter = !by_machine || isMachine(machine, by_machine);
      const techFilter = !by_tech || hasMachineTech(machine, by_tech);
      return locationFilter && machineFilter && techFilter;
    });
  }, [machines, filter]);

  const displayedMachinesAfterSortAndFilter = useMemo(() => {
    const { sortBy, order } = sort;
    switch (sortBy) {
      case ESortBy.LOCATION:
        return sortByMachineKey(
          order,
          displayedMachinesAfterFilter,
          "location",
        );
      case ESortBy.TECH:
        return sortByMachineKey(
          order,
          displayedMachinesAfterFilter,
          "deviceClass",
        );
      case ESortBy.STATE:
        if (canSetSortState()) {
          return sortByMachineStateAndDuration(
            order,
            displayedMachinesAfterFilter,
            machineStateMap,
          );
        } else {
          return displayedMachinesAfterFilter;
        }
      case ESortBy.NO_SORT:
      default:
        return displayedMachinesAfterFilter;
    }
  }, [displayedMachinesAfterFilter, sort, machineStateMap]);

  const [nextPage, setNextPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const memoizedHasMore = useMemo(() => hasMore, [hasMore]);

  const fetchMore = useCallback(() => {
    const isHasMore =
      displayedMachinesAfterSortAndFilter.length > loadedMachines.length;
    setHasMore(isHasMore);

    if (isHasMore) {
      setNextPage((prevPage) => prevPage + 1); // Use functional update to increment page number
    }
  }, [nextPage, displayedMachinesAfterSortAndFilter]);

  const endIndex = getEndIndex(nextPage, displayedMachinesAfterSortAndFilter);
  const loadedMachines = useMemo(() => {
    return displayedMachinesAfterSortAndFilter.slice(0, endIndex);
  }, [endIndex, displayedMachinesAfterSortAndFilter]);

  // Load more elements if the user does't scroll down within 2 seconds.
  const loadMore = useCallback(() => {
    const timeoutId = setTimeout(() => {
      if (!memoizedHasMore) {
        clearTimeout(timeoutId);
      } else {
        fetchMore();
      }
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, [fetchMore, memoizedHasMore]);

  useEffect(() => {
    const cleanup = loadMore();
    return () => cleanup();
  }, [loadMore]);

  // Reset hasMore when filter changes to load available machines
  useEffect(() => {
    setHasMore(true);
  }, [filter]);

  const onStateChange = useCallback(
    (state: IIndicatorStateSince, id: string) => {
      if (machines.length > 0) {
        setMachineStateMap((prevMachineStateMap) => {
          if (
            prevMachineStateMap.get(id)?.indicatorState !== state.indicatorState
          ) {
            const updatedMap = new Map(prevMachineStateMap);
            updatedMap.set(id, state);
            return updatedMap;
          }
          return prevMachineStateMap;
        });
      }
    },
    [machines],
  );

  return (
    <>
      {isWidthAboveLG && (
        <MachineSelectBar
          filterOptions={filterOptions}
          filterProps={filter}
          handleFilter={handleFilter}
          onSort={(sortBy: ESortBy, order: EOrder) => {
            setSort({ sortBy, order });
          }}
          sortProps={sort}
        />
      )}
      <InfiniteScroll
        dataLength={loadedMachines.length}
        next={fetchMore}
        loader={<Loader />}
        scrollableTarget="root"
        hasMore={memoizedHasMore}
      >
        <div className={classes.gridContainer}>
          <GroupedMachineList
            sort={sort}
            machines={loadedMachines}
            handleClick={handleClick}
            kpi={kpi}
            onStateChange={onStateChange}
            machineStateMap={machineStateMap}
          />
        </div>
      </InfiniteScroll>
    </>
  );
}

export default React.memo(MachineryView);
