import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  icon: {
    width: "100%",
    height: "auto",
  },
});

export default useStyles;
