import { makeStyles } from "tss-react/mui";

const ARROW_HEIGHT = "18px";
const useStyles = makeStyles()({
  upArrow: {
    cursor: "pointer",
    height: ARROW_HEIGHT,
    marginBottom: "4px",
  },
  downArrow: {
    cursor: "pointer",
    height: ARROW_HEIGHT,
  },
});

export default useStyles;
