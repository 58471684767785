import React from "react";
import WithToggleableTooltip from "../../../../../components/withToggableTooltip";
import useStyles from "./styles";

interface IProps {
  children: React.ReactElement;
  tooltipText: string;
  description?: string;
  footer?: string;
  fillContent?: boolean;
  isFullWidthContainer?: boolean;
  hasBulletPoints?: boolean;
  isFirstHasBulletPoint?: boolean;
  isHidden?: boolean;
}

export function TooltipCell({
  children,
  tooltipText,
  description = "",
  footer = "",
  fillContent = false,
  isFullWidthContainer = true,
  hasBulletPoints = false,
  isFirstHasBulletPoint = false,
  isHidden = false,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  return (
    <WithToggleableTooltip
      tooltipInfo={{
        label: tooltipText,
        description,
        footer,
      }}
      smallTooltip={description.length === 0}
      noOverflow={true}
      centerContent={true}
      placement="bottom-end"
      isFullWidthContainer={isFullWidthContainer}
      hasBulletPoints={hasBulletPoints}
      isFirstHasBulletPoint={isFirstHasBulletPoint}
      isHidden={isHidden}
    >
      <div
        className={`${fillContent ? classes.fillContent : ""} ${
          classes.tooltipCell
        }`}
      >
        {children}
      </div>
    </WithToggleableTooltip>
  );
}
