import React, { useEffect, useState } from "react";
import UserTable, { ITableUserInfo } from "./components/userTable";
import useStyles from "./styles";
import useQueryUsers from "./hooks/useQueryUser/useQueryUsers";
import { useTranslation } from "react-i18next";
import ResetPasswordModal from "./components/resetPasswordModal";
import RemoveUserModal from "./components/removeUserModal";
import CreateUserModal from "./components/createUserModal";
import useCreateUser from "./hooks/useCreateUser/useCreateUser";
import GeorgTextBase, {
  EStyleType,
} from "../../../../shared/components/text/base";
import useResetUsersPassword from "./hooks/useResetUsersPassword/useResetUsersPassword";
import useDeleteUser from "./hooks/deleteUser/useDeleteUser";
import GeorgButton from "../../../../shared/components/GeorgButton";
import IAzureB2cResponseType from "./hooks/IAzureB2cResponseType";
import { TFunction } from "i18next";
import { EApiState } from "../../../../api";

enum EModalState {
  CLOSED = "CLOSED",
  RESET_PASSWORD = "RESET_PASSWORD",
  REMOVE_USER = "REMOVE_USER",
  CREATE_USER = "CREATE_USER",
}

enum ENotificationRequestState {
  NOTHING_IN_PROGRESS = "NOTHING_IN_PROGRESS",
  CREATE_USER_IN_PROGRESS = "CREATE_USER_IN_PROGRESS",
  RESET_PASSWORD_IN_PROGRESS = "RESET_PASSWORD_IN_PROGRESS",
  DELETE_USER_IN_PROGRESS = "DELETE_USER_IN_PROGRESS",
}

function isUserCreationPayloadValid(
  firstname: string,
  surName: string,
  email: string,
): boolean {
  return firstname !== "" && surName !== "" && email !== "";
}

function getCreateUserNotification(
  hasCreateUserError: boolean,
  t: TFunction,
  responseCreateUser: { createAzureB2cUser: IAzureB2cResponseType },
) {
  let notification = "";
  if (hasCreateUserError) {
    notification = t("settings.userManagement.messages.errors.createUser");
  }
  if (responseCreateUser?.createAzureB2cUser.statusCode === 201) {
    notification = t("settings.userManagement.messages.success.createUser");
  }
  return notification;
}

function getUserDeletionNotification(
  hasDeleteUserError: boolean,
  t: TFunction,
  responseDeleteUser: { deleteAzureB2cUser: IAzureB2cResponseType },
) {
  let notification = "";
  if (hasDeleteUserError) {
    notification = t("settings.userManagement.messages.errors.deleteUser");
  }
  if (responseDeleteUser?.deleteAzureB2cUser.statusCode === 204) {
    notification = t("settings.userManagement.messages.success.deleteUser");
  }
  return notification;
}

function getResetPasswordNotification(
  hasResetPasswordError: boolean,
  t: TFunction,
  responseResetPassword: {
    resetAzureB2cUserPassword: IAzureB2cResponseType;
  },
) {
  let notification = "";
  if (hasResetPasswordError) {
    notification = t("settings.userManagement.messages.errors.resetPassword");
  }
  if (responseResetPassword?.resetAzureB2cUserPassword.statusCode === 201) {
    notification = t("settings.userManagement.messages.success.resetPassword");
  }
  return notification;
}

export default function UserManagement(): React.ReactElement {
  const [notificationRequestState, setNotificationRequestState] =
    useState<ENotificationRequestState>(
      ENotificationRequestState.NOTHING_IN_PROGRESS,
    );
  const [modalState, setModalState] = useState<EModalState>(EModalState.CLOSED);
  const [userId, setUserId] = useState<string>("");
  const [msgToUser, setMsgToUser] = useState<string>("");
  const [displayedUserData, setDisplayedUserData] = useState<ITableUserInfo[]>(
    [],
  );
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLoading: isUserResetPasswordLoading,
    mutate: mutateUserResetPassword,
    response: responseResetPassword,
    hasError: hasResetPasswordError,
  } = useResetUsersPassword();

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLoading: isDeleteUserLoading,
    mutate: mutateDeleteUser,
    response: responseDeleteUser,
    hasError: hasDeleteUserError,
  } = useDeleteUser();

  const {
    isLoading: isCreateUserLoading,
    mutate: mutateCreateUser,
    responseCreateUser,
    hasError: hasCreateUserError,
  } = useCreateUser();
  const { state, data } = useQueryUsers();
  const { t } = useTranslation();
  const { classes } = useStyles();
  let content: JSX.Element;

  function setNotification(msg: string) {
    setMsgToUser(msg);
    setNotificationRequestState(ENotificationRequestState.NOTHING_IN_PROGRESS);
  }

  /*
      UI Notification System:
      1) callback sets ENotificationRequestState
      2) Hook updates data format
      3) effect listens to hook updates,
         if ENotificationRequestState was configured,
         UI gets updated accordingly
   */
  useEffect(() => {
    if (
      notificationRequestState ===
        ENotificationRequestState.CREATE_USER_IN_PROGRESS &&
      responseCreateUser
    ) {
      setNotification(
        getCreateUserNotification(hasCreateUserError, t, responseCreateUser),
      );
    } else if (
      notificationRequestState ===
        ENotificationRequestState.DELETE_USER_IN_PROGRESS &&
      responseDeleteUser
    ) {
      setNotification(
        getUserDeletionNotification(hasDeleteUserError, t, responseDeleteUser),
      );
    } else if (
      notificationRequestState ===
        ENotificationRequestState.RESET_PASSWORD_IN_PROGRESS &&
      responseResetPassword
    ) {
      setNotification(
        getResetPasswordNotification(
          hasResetPasswordError,
          t,
          responseResetPassword,
        ),
      );
    } else {
      setNotification("");
    }
  }, [
    responseCreateUser,
    hasCreateUserError,
    hasDeleteUserError,
    responseDeleteUser,
    hasResetPasswordError,
    responseResetPassword,
  ]);

  useEffect(() => {
    setDisplayedUserData(data);
  }, [data]);

  switch (state) {
    case EApiState.LOADING:
    case EApiState.OK:
      content = (
        <>
          <CreateUserModal
            isVisible={modalState === EModalState.CREATE_USER}
            onClose={() => {
              setModalState(EModalState.CLOSED);
            }}
            onSubmit={(firstname: string, surName: string, email: string) => {
              if (isUserCreationPayloadValid(firstname, surName, email)) {
                setNotificationRequestState(
                  ENotificationRequestState.CREATE_USER_IN_PROGRESS,
                );
                mutateCreateUser({
                  emailAddress: email,
                  givenName: firstname,
                  surname: surName,
                });
                setDisplayedUserData(
                  displayedUserData.concat([
                    {
                      id: userId,
                      firstName: firstname,
                      lastName: surName,
                      eMail: email,
                      removeUser: false,
                      resetPassword: false,
                    },
                  ]),
                );
                setModalState(EModalState.CLOSED);
              }
            }}
          />
          <ResetPasswordModal
            resetPassword={() => {
              setNotificationRequestState(
                ENotificationRequestState.RESET_PASSWORD_IN_PROGRESS,
              );
              mutateUserResetPassword(userId);
            }}
            onCancel={() => {
              setModalState(EModalState.CLOSED);
              setUserId("");
            }}
            isModalVisible={modalState === EModalState.RESET_PASSWORD}
          />
          <RemoveUserModal
            removeUser={() => {
              setNotificationRequestState(
                ENotificationRequestState.DELETE_USER_IN_PROGRESS,
              );
              mutateDeleteUser(userId);
              setDisplayedUserData(
                displayedUserData.filter((d) => d.id !== userId),
              );
            }}
            onCancel={() => {
              setModalState(EModalState.CLOSED);
              setUserId("");
            }}
            isModalVisible={modalState === EModalState.REMOVE_USER}
          />
          <div className={classes.topRow}>
            <div className={classes.inviteUserBtnWrapper}>
              <GeorgButton
                disabled={isCreateUserLoading || state === EApiState.LOADING}
                label={t("settings.userManagement.addNewUser")}
                onClick={() => setModalState(EModalState.CREATE_USER)}
              />
            </div>
            <div className={classes.errorWarningWrapper}>
              <GeorgTextBase
                className={classes.errorWarning}
                type={EStyleType.DARK}
                text={msgToUser}
              />
            </div>
          </div>
          <UserTable
            isResetPasswordButtonDeactivated={userId}
            isRemoveUserButtonDeactivated={userId}
            onOpenResetDialog={(id: string) => {
              setUserId(id);
              setModalState(EModalState.RESET_PASSWORD);
            }}
            openRemoveUserDialog={(id: string) => {
              setUserId(id);
              setModalState(EModalState.REMOVE_USER);
            }}
            tableData={displayedUserData}
            isLoading={state === EApiState.LOADING}
          />
        </>
      );
      break;
    case EApiState.ERROR:
    default:
      content = <>{t("settings.userManagement.error")}</>;
  }
  return <div className={classes.userManagementContainer}>{content}</div>;
}
