import IAzureB2cResponseType from "../IAzureB2cResponseType";
import { useMutation } from "@apollo/client";
import DeleteAzureB2cUserMutation from "./DeleteAzureB2cUserMutation";
import { useCallback } from "react";
import GetAzureB2cUsersQuery from "../useQueryUser/GetAzureB2cUsersQuery";

interface IDeleteUsersPasswordResponse {
  deleteAzureB2cUser: IAzureB2cResponseType;
}

export default function useDeleteUser(): {
  mutate: (userInput: string) => void;
  isLoading: boolean;
  hasError: boolean;
  response?: IDeleteUsersPasswordResponse | null;
} {
  const [mutateFunction, { data, loading, error }] =
    useMutation<IDeleteUsersPasswordResponse>(DeleteAzureB2cUserMutation);

  const mutate = useCallback((userId: string): void => {
    mutateFunction({
      variables: { userId: userId },
      refetchQueries: [{ query: GetAzureB2cUsersQuery }],
    }).catch(
      // eslint-disable-next-line no-console
      console.error,
    );
  }, []);
  return {
    mutate,
    isLoading: loading,
    hasError: error != null,
    response: data,
  };
}
