/*
 *
 *   Topbar component.
 *
 */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AppBar,
  Grid2 as Grid,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// Icons.
import MenuIcon from "@mui/icons-material/Menu";
// Custom Imports.
import { TimeBar } from "../../components";
import { IButton, ILogo } from "../../shared/components";
import logo from "../../assets/logos/GEO_2018_Logo_RGB_neg.svg";
import { handleDrawer, selectDrawer } from "../../redux/reducers/drawerReducer";
import useStyles from "./styles";
import { useIsAuthenticated } from "@azure/msal-react";
import LoginButton from "./components/LoginButton";
import LoggedInButton from "./components/LoggedInButton";
import { getTimezone } from "../../helper/time/timezone";

//------------------------------------------------------------------------------------------------------------
// Component.
export default function TopBar(): React.ReactElement {
  const isAuthenticated = useIsAuthenticated();
  const open = useSelector(selectDrawer);
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { breakpoints } = useTheme();
  const isWidthAboveMD = useMediaQuery(breakpoints.up("md"), { noSsr: true });
  const timezone = getTimezone();

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} color="primary" position="fixed">
        <Toolbar>
          {/* Display MenuIcon button for screens below "md" */}
          {!isWidthAboveMD && (
            <IButton
              icon={<MenuIcon color="primary" />}
              onClick={() => {
                dispatch(handleDrawer(!open));
              }}
              name="MenuIcon"
            />
          )}
          <Grid container className={classes.gridContainer}>
            <ILogo logo={logo} />
            {/* Display TimeBar for screens "md" and above */}
            {isWidthAboveMD && (
              <TimeBar
                styles={{
                  dateTimeContainer: classes.dateTimeContainer,
                  timeContainer: classes.timeContainer,
                  timeIcon: classes.timeIcon,
                  timeLabel: classes.timeLabel,
                }}
                timezone={timezone}
              />
            )}
          </Grid>
          {/* Display authentication buttons based on auth state */}
          {isAuthenticated ? <LoggedInButton /> : <LoginButton />}
        </Toolbar>
      </AppBar>
    </div>
  );
}
