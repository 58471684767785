import { EDisplayState } from "../../../shared/components/georgDataGrid/components/stateCell";

export enum ECreatorType {
  MACHINE = "MACHINE",
  USER = "USER",
}

export interface IUser {
  id: string;
  name: string;
}

interface ICommonJob {
  name: string; // max length 15
  description: string; // max length 150
  priority: number;
  scheduledAt: string;
  createdBy?: ECreatorType;
  vid?: number;
  responsibleId?: string;
}

export interface IJobFromServer extends ICommonJob {
  abortMessage?: string;
  abortedAt?: string;
  createdById?: string;
  createdByName?: string; // z.B. Vorname Nachname oder Maschinenname
  deletedAt?: string;
  doneAt?: string;
  id: number;
  jobId: string;
  machineType: {
    id: string;
    name?: string;
  };
}

export interface jobCriticalState {
  timeRemaining: string;
  criticalityState: EDisplayState;
  remainingInMillis: string;
}

export interface IJobTableFormat extends IJobFromServer {
  priorityState: EDisplayState;
  jobCriticalState?: jobCriticalState;
}

export interface IJobCreateOrUpdate extends ICommonJob {
  jobId?: string;
  machineId: number;
}

export interface IJobData {
  jobId?: string;
  vid?: number;
}

export interface IJobAbort extends IJobData {
  abortState: boolean;
  abortedAt: string;
  abortMessage?: string;
}

export function isJobUpdate(s: IJobCreateOrUpdate) {
  return s.jobId != null;
}

export interface IJobCreateOrUpdateFrontend {
  jobId?: string;
  machineId?: number;
  name?: string;
  description?: string;
  priority?: number;
  scheduledAt?: string;
  createdBy?: ECreatorType;
  vid?: number;
  responsibleId?: string;
}
