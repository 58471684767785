/*
 *   Reducer to set the token.
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

type State = {
  token: string;
};
type Props = string;

const initialState: State = {
  token: "",
};

export const tokenReducer = createSlice({
  name: "token",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<Props>): void => {
      state.token = action.payload;
    },
    resetToken: (state): void => {
      state.token = "";
    },
  },
});

export const token = (state: RootState): string => state.token.token;

export const { setToken, resetToken } = tokenReducer.actions;

export default tokenReducer.reducer;
