import createLayout from "../gridPage/helper/createLayout";

export const MACHINE_SELECTION_LAYOUT_KEY: TGridLayoutTabsKEY =
  "MachineSelection";
export const TIME_SELECTION_LAYOUT_KEY: TGridLayoutTabsKEY = "TimeSelection";

type TGridLayoutTabsKEY = "TimeSelection" | "MachineSelection";

const dropDownHeight = 1.8;
const smallDropDownHeight = 1.5;
const buttonHeight = 1.2;

const machineSelectionDouble = {
  w: 6,
  h: dropDownHeight,
};
const machineSelectionSingle = {
  w: 12,
  h: dropDownHeight,
};
const timeSelectionDouble = {
  w: 6,
  h: dropDownHeight + buttonHeight,
};
const timeSelectionSingle = {
  w: 12,
  h: dropDownHeight + buttonHeight,
};
const timeSelectionSingle2 = {
  w: 12,
  h: dropDownHeight * 2 + buttonHeight,
};
const timeSelectionSingle4 = {
  w: 12,
  h: dropDownHeight * 2 + smallDropDownHeight * 2 + buttonHeight,
};

// small
const sMachineSelectionDouble = {
  w: 8,
  h: dropDownHeight,
};
const sMachineSelectionSingle = {
  w: 12,
  h: dropDownHeight,
};
const sTimeSelectionDouble = {
  w: 4,
  h: dropDownHeight + buttonHeight,
};
const sTimeSelectionSingle = {
  w: 12,
  h: dropDownHeight + buttonHeight,
};
const sTimeSelectionSingle2 = {
  w: 12,
  h: dropDownHeight + smallDropDownHeight + buttonHeight,
};

// single component
const sCMachineSelectionDouble = {
  w: 8,
  h: dropDownHeight,
};
const sCMachineSelectionSingle = {
  w: 12,
  h: dropDownHeight,
};

const layoutXLarge = [
  { i: MACHINE_SELECTION_LAYOUT_KEY, x: 0, y: 0, ...machineSelectionDouble },
  { i: TIME_SELECTION_LAYOUT_KEY, x: 6, y: 0, ...timeSelectionDouble },
];
const layoutLarge = [
  { i: MACHINE_SELECTION_LAYOUT_KEY, x: 0, y: 0, ...machineSelectionSingle },
  { i: TIME_SELECTION_LAYOUT_KEY, x: 0, y: 2, ...timeSelectionSingle },
];
const layoutMedium = [
  { i: MACHINE_SELECTION_LAYOUT_KEY, x: 0, y: 0, ...machineSelectionSingle },
  { i: TIME_SELECTION_LAYOUT_KEY, x: 0, y: 2, ...timeSelectionSingle2 },
];

const layoutSmall = [
  { i: MACHINE_SELECTION_LAYOUT_KEY, x: 0, y: 0, ...machineSelectionSingle },
  { i: TIME_SELECTION_LAYOUT_KEY, x: 0, y: 2, ...timeSelectionSingle4 },
];

// small
const smallLayoutXLarge = [
  { i: MACHINE_SELECTION_LAYOUT_KEY, x: 0, y: 0, ...sMachineSelectionDouble },
  { i: TIME_SELECTION_LAYOUT_KEY, x: 8, y: 0, ...sTimeSelectionDouble },
];

const smallLayoutLarge = [
  { i: MACHINE_SELECTION_LAYOUT_KEY, x: 0, y: 0, ...sMachineSelectionSingle },
  { i: TIME_SELECTION_LAYOUT_KEY, x: 0, y: 2, ...sTimeSelectionSingle },
];
const smallLayoutSmall = [
  { i: MACHINE_SELECTION_LAYOUT_KEY, x: 0, y: 0, ...sMachineSelectionSingle },
  { i: TIME_SELECTION_LAYOUT_KEY, x: 0, y: 2, ...sTimeSelectionSingle2 },
];

// single component
const singleComponentLayoutXLarge = [
  { i: MACHINE_SELECTION_LAYOUT_KEY, x: 0, y: 0, ...sCMachineSelectionDouble },
];
const singleComponentLayoutMedium = [
  { i: MACHINE_SELECTION_LAYOUT_KEY, x: 0, y: 0, ...sCMachineSelectionSingle },
];

export type TResizeConfig = {
  [key in TGridLayoutTabsKEY]: number;
};

// export default KPI_VIEW_LAYOUTS;

export function getLayoutInfo(resizeConfig: TResizeConfig) {
  return createLayout<TResizeConfig>(
    resizeConfig,
    layoutXLarge,
    layoutLarge,
    layoutMedium,
    layoutSmall,
  );
}

export function getSmallLayoutInfo(resizeConfig: TResizeConfig) {
  return createLayout<TResizeConfig>(
    resizeConfig,
    smallLayoutXLarge,
    smallLayoutLarge,
    smallLayoutLarge,
    smallLayoutSmall,
  );
}

export function getSingleComponentLayoutInfo(resizeConfig: TResizeConfig) {
  return createLayout<TResizeConfig>(
    resizeConfig,
    singleComponentLayoutXLarge,
    singleComponentLayoutMedium,
  );
}
