import React, { useEffect, useMemo, useState } from "react";
import SaveCancelBtnPair from "../../../../../shared/components/saveCancelBtnPair";
import useStyles from "./styles";
import ManualStateCRUDTable from "./components/manualStateCRUDTable";
import { useTranslation } from "react-i18next";
import IManualStateConfigEntry, {
  IManualStateConfigEntryFromServer,
} from "../../../../../api/manualState/IManualStateConfigEntry";
import uiToServerFormat from "./helper/uiToServerFormat";
import TManualStateConfigEntry from "../../../../../api/manualState/IManualStateConfigEntry";
import GeorgButton from "../../../../../shared/components/GeorgButton";
import IModifyMutation from "./api/IModifyMutation";
import YesNoDialog from "../../../../../shared/components/yesnodialog";
import { moveStateDown, moveStateUp } from "./helper/moveState";
import { EApiState } from "../../../../../api";
import { selectMachine } from "../../../../../redux/reducers/machineSelectReducer";
import { useSelector } from "react-redux";
import { useQueryManualStates } from "./hooks/useQueryManualStates";

function hasNameChanged(
  newStates: IModifyMutation,
  manualStatesFromServer: IManualStateConfigEntryFromServer[],
): boolean {
  return newStates.update.some(
    (newState: IManualStateConfigEntryFromServer) => {
      return manualStatesFromServer.some(
        (stateFromServer: IManualStateConfigEntryFromServer) => {
          return (
            stateFromServer.id === newState.id &&
            stateFromServer.displayName !== newState.displayName
          );
        },
      );
    },
  );
}

export default function ConfigureManualStates(): React.ReactElement {
  const selectedMachine = useSelector(selectMachine);
  const { apiState, manualStatesFromServer, refreshData } =
    useQueryManualStates(selectedMachine);
  const [manualStatesFromUI, setManualStatesFromUI] = useState<
    IManualStateConfigEntry[]
  >([]);
  const [
    isNameChangeWarningDialogDisplayed,
    setNameChangeWarningDialogDisplayed,
  ] = useState<boolean>(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState<string | null>(null);
  const { t } = useTranslation();
  const { classes } = useStyles();
  const serverFormat: IModifyMutation = useMemo(() => {
    return uiToServerFormat(manualStatesFromServer, manualStatesFromUI);
  }, [manualStatesFromServer, manualStatesFromUI]);
  const isWarningNeeded = hasNameChanged(serverFormat, manualStatesFromServer);
  useEffect(() => {
    if (apiState === EApiState.OK) {
      setManualStatesFromUI(manualStatesFromServer);
    }
  }, [apiState, manualStatesFromServer]);

  const sortedStatsInUI = useMemo(() => {
    return [
      ...manualStatesFromUI.sort((d1, d2) => {
        return d1.displayPrio - d2.displayPrio;
      }),
    ];
  }, [manualStatesFromUI]);
  return (
    <div className={classes.box}>
      {apiState === EApiState.ERROR && (
        <>{t("settings.manualStates.loadingError")}</>
      )}
      {(apiState === EApiState.OK || apiState === EApiState.LOADING) && (
        <>
          <YesNoDialog
            isModalVisible={isNameChangeWarningDialogDisplayed}
            onClose={() => setNameChangeWarningDialogDisplayed(false)}
            title={t("settings.manualStates.changeNameWarning.title")}
            question={t("settings.manualStates.changeNameWarning.text")}
            onCancel={() => setNameChangeWarningDialogDisplayed(false)}
            onSubmit={() => {
              alert(JSON.stringify(serverFormat));
            }}
          />

          <YesNoDialog
            isModalVisible={idToBeDeleted !== null}
            onClose={() => setIdToBeDeleted(null)}
            title={t("settings.manualStates.deletionWarning.title")}
            question={t("settings.manualStates.deletionWarning.text")}
            onCancel={() => setIdToBeDeleted(null)}
            onSubmit={() => {
              setManualStatesFromUI(
                manualStatesFromUI.filter(
                  (stateEntry: TManualStateConfigEntry) =>
                    stateEntry.id !== idToBeDeleted,
                ),
              );
            }}
          />

          <div className={classes.titleWrapper}>
            <GeorgButton
              label={t("settings.manualStates.addBtn")}
              onClick={() => {
                const index = manualStatesFromUI.length;
                setManualStatesFromUI([
                  ...manualStatesFromUI,
                  {
                    id: `client-created-entry-${index}`,
                    isNowActive: false,
                    displayName: "State",
                    isMarkedAsDeleted: true,
                    isLocallyCreated: true,
                    isVisible: true,
                    displayPrio: index,
                    version: `client-created-entry-${index}`,
                  },
                ]);
              }}
            />
          </div>
          <ManualStateCRUDTable
            data={sortedStatsInUI}
            onTableUpdate={(newManualStates: TManualStateConfigEntry[]) => {
              setManualStatesFromUI(newManualStates);
            }}
            onRemove={(id: string) => {
              setIdToBeDeleted(id);
            }}
            onIsVisibleChange={(id: string, isVisible: boolean): void => {
              setManualStatesFromUI(
                manualStatesFromUI.map((s: TManualStateConfigEntry) => {
                  if (s.id === id) {
                    return { ...s, isVisible: !isVisible };
                  } else {
                    return s;
                  }
                }),
              );
            }}
            onMoveManualInputDown={(id: string) => {
              setManualStatesFromUI(moveStateDown(manualStatesFromUI, id));
            }}
            onMoveManualInputUp={(id: string) => {
              setManualStatesFromUI(moveStateUp(manualStatesFromUI, id));
            }}
            isLoading={apiState === EApiState.LOADING}
          />
          <SaveCancelBtnPair
            onSaveClicked={() => {
              if (isWarningNeeded) {
                setNameChangeWarningDialogDisplayed(true);
              } else {
                alert(JSON.stringify(serverFormat));
              }
              refreshData();
            }}
            onCancelClicked={() => {
              refreshData();
            }}
            disabled={apiState === EApiState.LOADING}
            isCancelAlwaysEnabled={false}
          />
        </>
      )}
    </div>
  );
}
