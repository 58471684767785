import React, { useMemo } from "react";
import { IList } from "../../../../../../shared/components";
import useStyles from "./styles";

interface IProps {
  children: React.ReactElement;
  icon: React.ReactElement;
  label: string;
  isOpen: boolean;
  isActive: boolean;
}

export default function MultiEntries({
  children,
  icon,
  label,
  isOpen,
  isActive,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  const listItemActiveStyles = {
    listItem: classes.listItemActive,
    hiddenIcon: classes.hiddenIconActive,
    label: classes.label,
  };
  const listItemStyles = {
    listItem: classes.listItem,
    hiddenIcon: classes.hiddenIcon,
    label: classes.label,
  };
  const iconMinWidth = useMemo(() => {
    return isOpen ? 56 : 30;
  }, [isOpen]);
  return (
    <div>
      <IList
        icon={icon}
        label={label}
        labelVariant="body1"
        styles={isActive ? listItemActiveStyles : listItemStyles}
        subItems={children}
        iconToLabelSpace={iconMinWidth}
        isCollapsed={!isOpen}
        isOpen={isActive}
      />
    </div>
  );
}
