/*
 *
 *   KpiFooter which is rendered if the shopfloordata is available.
 *
 */

import React from "react";
import { Grid2 as Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
// Custom Imports.
import { IList } from "../../../../../shared/components";
import useStyles from "./styles";
import LastDayStacklight from "./component/lastDayStacklight";
import LastDayKpiPerformance from "./component/lastDayKpiPerformance";
import { IOEEShopfloorEntry } from "../../../../../api/machine/IOEEShopfloor";

//------------------------------------------------------------------------------------------------------------
// Interfaces & Types.
interface Props {
  machineId: string;
  timezone: string;
  kpiProductivity?: IOEEShopfloorEntry;
  kpiAvailability?: IOEEShopfloorEntry;
  kpiPerformance?: IOEEShopfloorEntry;
  kpiQuality?: IOEEShopfloorEntry;
}

//------------------------------------------------------------------------------------------------------------
// Component.
function MachineKPIFooter({ machineId, timezone }: Props): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container className={classes.container}>
      <Grid size={12}>
        <IList
          label={t("headers.machineView_KPI.headline")}
          labelColor="primary"
          labelVariant="h2"
          sublabel={t("headers.machineView_KPI.tagline")}
          sublabelColor="textPrimary"
          sublabelVariant="body1"
          styles={{
            listItem: classes.listItem,
          }}
        />
        <Grid container>
          <LastDayKpiPerformance machineId={machineId} timezone={timezone} />
        </Grid>
        <IList
          label={t("headers.machineView_Stacklight.headline")}
          labelColor="primary"
          labelVariant="h2"
          sublabel={t("headers.machineView_Stacklight.tagline")}
          sublabelColor="textPrimary"
          sublabelVariant="body1"
          styles={{
            // listItem: classes.stacklightContainer,
            listItem: classes.listItem,
          }}
        />
        <Grid container>
          <LastDayStacklight machineId={machineId} timezone={timezone} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default React.memo(MachineKPIFooter);
