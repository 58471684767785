import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { DateTime } from "luxon";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { Grid2 as Grid } from "@mui/material";
import SaveCancelBtnPair from "../../../../../../shared/components/saveCancelBtnPair";
import GeorgStorageContext from "../../../../../../context/GeorgStorageContext";
import GeorgModal from "../../../../../../shared/components/modal";
import DateTimeSelection from "../../../../../../shared/components/dateTimeSelection";
import { IServiceOrderDone } from "../../api";
import { EDisplayState } from "../../../../../../shared/components/georgDataGrid/components/stateCell";

interface IProps {
  isModalVisible: boolean;
  onClose: () => void;
  title: string;
  question: string;
  onCancel: () => void;
  onSubmit: (data) => void;
  serviceOrderToUpdate?: IServiceOrderDone;
}

const emptyServiceOrder: IServiceOrderDone = {
  id: "",
  serviceTimestamp: "",
  version: "",
  description: "",
  machineId: "",
  interval: 0,
  lastService: 0,
  name: "",
  responsibleId: "",
  urgencyState: EDisplayState.OK,
};

export default function DoneDialog({
  isModalVisible,
  onClose,
  title,
  question,
  onCancel,
  onSubmit,
  serviceOrderToUpdate = emptyServiceOrder,
}: IProps): React.ReactElement {
  useEffect(() => {
    if (!isModalVisible) {
      return;
    }
    setMinInterval(serviceOrderToUpdate.lastService);
    setDialogData({
      ...serviceOrderToUpdate,
      serviceTimestamp: DateTime.now()
        .setZone(timezone, {
          keepLocalTime: true,
        })
        .toFormat("yyyy-MM-dd'T'HH:mm"),
    });
    setCurrentInterval(
      serviceOrderToUpdate.interval + serviceOrderToUpdate.lastService,
    );
  }, [isModalVisible]);

  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const { timezone } = useContext(GeorgStorageContext);
  const locale = i18n.language;
  const [isChecked, setIsChecked] = useState(false);
  const [minInterval, setMinInterval] = useState(0);
  const [currentInterval, setCurrentInterval] = useState(0);
  const [dialogData, setDialogData] =
    useState<IServiceOrderDone>(emptyServiceOrder);
  const valueDateTime = useMemo(() => {
    if (!dialogData) {
      return new Date();
    }
    return new Date(DateTime.fromISO(dialogData.serviceTimestamp).toJSDate());
  }, [dialogData.serviceTimestamp, isModalVisible]);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  // Update dialogData whenever currentInterval changes
  useEffect(() => {
    setDialogData((prevData) => ({
      ...prevData,
      lastService: currentInterval,
    }));
  }, [currentInterval]);

  const handleSave = useCallback(() => {
    // Ensure all the necessary values are updated before submission
    const updatedData = {
      ...dialogData,
      lastService: currentInterval, // Make sure lastService is updated
    };

    // Pass the updated data to onSubmit and close the dialog
    onSubmit(updatedData);
    resetDialogData();
    onClose();
  }, [dialogData, currentInterval, onSubmit, onClose]);

  const resetDialogData = useCallback(() => {
    setDialogData(emptyServiceOrder);
    setIsChecked(false);
  }, []);

  return (
    <GeorgModal isModalVisible={isModalVisible} onClose={onClose} title={title}>
      <div className={classes.container}>
        <Grid container spacing={4}>
          <Grid size={12}>{question}</Grid>
          <Grid size={12}>
            <FormControlLabel
              key={"checkbox_enable"}
              control={
                <Checkbox
                  id={"checkbox_enable"}
                  onChange={handleCheckboxChange}
                />
              }
              label={t("taskManager.serviceOrders.doneDialog.serviceTime")}
              value={"custom date"}
              checked={isChecked}
            />
          </Grid>
          <Grid size={{ md: 6, xs: 12 }} className={classes.dateTimeGrid}>
            <Box className={classes.dateTimeBox}>
              <DateTimeSelection
                mode={"datetime"}
                onTimeSelect={(newStartDate: Date) => {
                  setDialogData({
                    ...dialogData,
                    serviceTimestamp: DateTime.fromJSDate(newStartDate)
                      .setZone(timezone, {
                        keepLocalTime: true,
                      })
                      .toFormat("yyyy-MM-dd'T'HH:mm"),
                  });
                }}
                label={t("taskManager.serviceOrders.doneDialog.serviceTime")}
                value={valueDateTime}
                locale={locale}
                onError={(hasError) => {
                  console.log("hasError", hasError);
                  //setHasStartDateErr(hasError);
                }}
                styles={{
                  container: classes.dateTimeInput,
                  rootContainer: classes.dateTimeInputRoot,
                }}
                disabled={!isChecked}
              />
            </Box>
          </Grid>
          <Grid size={{ md: 6, xs: 12 }}>
            <TextField
              error={currentInterval < minInterval}
              type={"number"}
              disabled={!isChecked}
              id="interval"
              name="interval"
              label={t("taskManager.serviceOrders.doneDialog.value")}
              fullWidth
              slotProps={{
                htmlInput: {
                  // min: minInterval,
                  step: 1,
                },
              }}
              variant="filled"
              value={currentInterval}
              onChange={(
                event: React.ChangeEvent<
                  HTMLTextAreaElement | HTMLInputElement
                >,
              ) => {
                const interval = Number.parseInt(event.target.value, 10);
                setCurrentInterval(interval);
                setDialogData({
                  ...dialogData,
                  lastService: interval,
                });
              }}
            />
          </Grid>
          <Grid size={12}>
            <span>
              {t("taskManager.serviceOrders.doneDialog.serviceOrderParameter")}
            </span>
          </Grid>
          <Grid size={12}>
            <SaveCancelBtnPair
              // disabled={validationResult !== EValidationResult.OK}
              onCancelClicked={() => {
                onCancel();
                resetDialogData();
                onClose();
              }}
              onSaveClicked={handleSave}
            />
          </Grid>
        </Grid>
      </div>
    </GeorgModal>
  );
}
