import { colors } from "../../shared/theme";
export type TIndicatorName =
  | "indicator_controlmode_automatic"
  | "indicator_controlmode_mdamdi"
  | "indicator_controlmode_jogmanual"
  | "indicator_controlmode_jogincrement"
  | "indicator_controlmode_teachin"
  | "indicator_controlmode_remote"
  | "indicator_controlmode_repos"
  | "indicator_controlmode_reference"
  | "indicator_controlmode_other"
  | "indicator_operationmode_manual"
  | "indicator_operationmode_automatic"
  | "indicator_operationmode_setup"
  | "indicator_operationmode_semiautomatic"
  | "indicator_operationmode_service"
  | "indicator_operationmode_other"
  | "indicator_process_toolload"
  | "indicator_process_toolunload"
  | "indicator_process_toolchange"
  | "indicator_process_rawmaterialload"
  | "indicator_process_rawmaterialunload"
  | "indicator_process_rawmaterialchange"
  | "indicator_process_productunload"
  | "indicator_process_insert"
  | "indicator_process_cropping"
  | "indicator_process_breakin"
  | "indicator_process_sampling"
  | "indicator_process_measuring"
  | "indicator_state_orgdowntime"
  | "indicator_state_techdowntime"
  | "indicator_state_executing"
  | "indicator_state_waiting"
  | "indicator_state_idle"
  | "indicator_state_idle_long"
  | "indicator_state_notexecuting"
  | "indicator_state_warning"
  | "indicator_state_alarm"
  | "indicator_state_outofservice"
  | "indicator_state_notavailable"
  | "indicator_state_undefined"
  | "indicator_occupied_time"
  | "indicator_unoccupied_time"
  | "indicator_unscheduled_time"
  | "indicator_scheduled_time"
  | "indicator_setup_time"
  | "indicator_test_time"
  | "kpi_org_failurerate"
  | "kpi_tech_failurerate"
  | "kpi_maintenance_ratio"
  | "kpi_availability"
  | "kpi_productivity"
  | "kpi_tech_availability"
  | "kpi_org_availability"
  | "kpi_utilisation_ratio"
  | "kpi_setup_ratio"
  | "kpi_test_ratio"
  | "kpi_performance_ratio"
  | "kpi_quality_ratio"
  | "kpi_oee"
  | "indicator_orgdowntime_lackofjobs"
  | "indicator_orgdowntime_operatior_missing"
  | "indicator_orgdowntime_settingpersonal_missing"
  | "indicator_orgdowntime_tool_missingordefective"
  | "indicator_orgdowntime_program_notreadyormissingorfaulty"
  | "indicator_orgdowntime_equipment_missingordefective"
  | "indicator_orgdowntime_instructions_missing"
  | "indicator_orgdowntime_rawmaterial_missingordefective"
  | "indicator_orgdowntime_machine_warmup"
  | "indicator_orgdowntime_transport_waiting"
  | "indicator_orgdowntime_spareparts_waiting"
  | "indicator_orgdowntime_others"
  | "indicator_edgedevice_offline";
//  | "kpi_performance"
//  | "kpi_quantity";

const STATE_COLOR_MAP: { [key in TIndicatorName]: string } = {
  indicator_controlmode_automatic: colors.chart_grey_base, // 0
  indicator_controlmode_mdamdi: colors.chart_grey_base, // 1
  indicator_controlmode_jogmanual: colors.chart_grey_base, // 2
  indicator_controlmode_jogincrement: colors.chart_grey_base, // 3
  indicator_controlmode_teachin: colors.chart_grey_base, // 4
  indicator_controlmode_remote: colors.chart_grey_base, // 5
  indicator_controlmode_repos: colors.chart_grey_base, // 6
  indicator_controlmode_reference: colors.chart_grey_base, // 7
  indicator_controlmode_other: colors.chart_grey_base, // 8
  indicator_operationmode_manual: colors.chart_grey_base, // 9
  indicator_operationmode_automatic: colors.chart_grey_base, // 10
  indicator_operationmode_setup: colors.chart_grey_base, // 11
  indicator_operationmode_semiautomatic: colors.chart_grey_base, // 12
  indicator_operationmode_service: colors.chart_grey_base, // 13
  indicator_operationmode_other: colors.chart_grey_base, // 14
  indicator_process_toolload: colors.chart_grey_base, // 15
  indicator_process_toolunload: colors.chart_grey_base, // 16
  indicator_process_toolchange: colors.chart_grey_base, // 17
  indicator_process_rawmaterialload: colors.chart_grey_base, // 18
  indicator_process_rawmaterialunload: colors.chart_grey_base, // 19
  indicator_process_rawmaterialchange: colors.chart_grey_base, // 20
  indicator_process_productunload: colors.chart_grey_base, // 21
  indicator_process_insert: colors.chart_grey_base, // 22
  indicator_process_cropping: colors.chart_grey_base, // 23
  indicator_process_breakin: colors.chart_grey_base, // 24
  indicator_process_sampling: colors.chart_grey_base, // 25
  indicator_process_measuring: colors.chart_grey_base, // 26
  indicator_state_orgdowntime: colors.chart_yellow_base, // 27
  indicator_state_techdowntime: colors.chart_red_base, // 28
  indicator_state_executing: colors.chart_green_base, // 29
  indicator_state_waiting: colors.blueB64, // 30
  indicator_state_idle: colors.chart_yellow_base, // 31
  indicator_state_idle_long: colors.chart_red_base, // 31
  indicator_state_notexecuting: colors.chart_blue_base, // 32
  indicator_state_warning: colors.chart_yellow_base, // 33
  indicator_state_alarm: colors.chart_red_base, // 34
  indicator_state_outofservice: colors.chart_red_base, // 35
  indicator_state_notavailable: colors.chart_grey_base, // 36
  // attention! if we use more background white, we should introduce
  // another property for each color: font color,
  // because we cannot use a white font color on a white background!
  // 1 existing workaround in machineStatusFooter/index.tsx
  indicator_state_undefined: colors.whiteB_3, //58
  indicator_occupied_time: colors.chart_grey_base, // 37
  indicator_unoccupied_time: colors.chart_grey_base, // 3
  indicator_unscheduled_time: colors.chart_grey_base, // 39
  indicator_scheduled_time: colors.chart_grey_base, // 40
  indicator_setup_time: colors.chart_grey_base, // 41
  indicator_test_time: colors.chart_grey_base, // 42
  kpi_org_failurerate: colors.chart_grey_base, // 43
  kpi_tech_failurerate: colors.chart_grey_base, // 44
  kpi_maintenance_ratio: colors.chart_grey_base, // 45
  kpi_availability: colors.chart_grey_base, // 46
  kpi_productivity: colors.chart_grey_base, // 47
  kpi_tech_availability: colors.chart_grey_base, // 47
  kpi_org_availability: colors.chart_grey_base, // 46
  kpi_utilisation_ratio: colors.chart_grey_base, // 48
  kpi_setup_ratio: colors.chart_grey_base, // 49
  kpi_test_ratio: colors.chart_grey_base, // 50
  kpi_performance_ratio: colors.chart_grey_base, // 51
  kpi_quality_ratio: colors.chart_grey_base, // 52
  kpi_oee: colors.chart_grey_base, // 53
  // TODO: add colors for the following states
  indicator_orgdowntime_lackofjobs: colors.chart_green_base, // 54
  indicator_orgdowntime_operatior_missing: colors.chart_red_base, // 55
  indicator_orgdowntime_settingpersonal_missing: colors.chart_red_dark, // 56
  indicator_orgdowntime_tool_missingordefective: colors.chart_yellow_dark, // 57
  indicator_orgdowntime_program_notreadyormissingorfaulty:
    colors.chart_yellow_base, // 58
  indicator_orgdowntime_equipment_missingordefective: colors.chart_yellow_ligth, // 59
  indicator_orgdowntime_instructions_missing: colors.chart_orange_dark, // 60
  indicator_orgdowntime_rawmaterial_missingordefective:
    colors.chart_yellow_dark, // 61
  indicator_orgdowntime_machine_warmup: colors.chart_green_dark, // 62
  indicator_orgdowntime_transport_waiting: colors.chart_blue_base, // 63
  indicator_orgdowntime_spareparts_waiting: colors.chart_blue_dark, // 64
  indicator_orgdowntime_others: colors.chart_grey_base, // 65
  indicator_edgedevice_offline: colors.chart_grey_base,
  // kpi_performance: colors.chart_grey_base,
  // kpi_quantity: colors.chart_grey_base,
};

export default STATE_COLOR_MAP;
