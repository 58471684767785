import { makeStyles } from "tss-react/mui";
// import colorToRgba from "../../../../../../helper/adjustColor";
// import { colors } from "../../../../../../shared/theme";

const useStyles = makeStyles()({
  textContainer: {
    width: 120,
    height: 52,
    padding: 10,
    display: "flex",
    alignItems: "center",
    cursor: "default",
  },
  timezoneWarningContainer: {
    display: "flex",
    alignItems: "center",
    height: 24,
    paddingBottom: 8,
  },
  warningIcon: {
    paddingRight: 8,
  },
});

export default useStyles;
