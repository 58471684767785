/*
 *   Drawer Reducer for opening and closing sidebar component comprehensive.
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface DrawerState {
  open: boolean;
}

const initialState: DrawerState = {
  open: true,
};

export const drawerStateSlice = createSlice({
  name: "drawerState",
  initialState,
  reducers: {
    handleDrawer: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    },
  },
});

export const selectDrawer = (state: RootState): boolean =>
  state.drawerState.open;

export const { handleDrawer } = drawerStateSlice.actions;

export default drawerStateSlice.reducer;
