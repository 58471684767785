import React from "react";
import { Box, Container, Typography } from "@mui/material";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { IHeader, ITabs } from "../../../shared/components";
import ServicePartsView from "./views/servicePartsView";
import NotFound from "../../notFound";
import IsKnownRoute from "../../../services/routing/isKnownRoute";

export default function ServiceParts(): React.ReactElement {
  const knownPaths = ["/parts"];
  if (!IsKnownRoute(knownPaths)) {
    return <NotFound />;
  }
  const { t } = useTranslation();
  const { classes } = useStyles();
  const titleText = (
    <Box
      style={{ display: "flex", alignItems: "end" }}
      className={classes.textContainer}
    >
      <Typography color="primary" variant="h4">
        {t("service.serviceParts.boldTitle")}
      </Typography>
      <Typography color="primary" variant="h4">
        {t("service.serviceParts.title")}
      </Typography>
    </Box>
  );

  return (
    <Container className={classes.container}>
      <IHeader
        headline={t("headers.serviceParts.headline")}
        headlineColor="primary"
        headlineVariant="h3"
        tagline={t("headers.serviceParts.tagline")}
        taglineColor="primary"
        taglineVariant="body1"
      />
      <ITabs
        disableContentPadding
        disableRipple
        tabs={[{ label: titleText, path: knownPaths[0] }]}
        tabContent={[<ServicePartsView />]}
        tabVariant="fullWidth"
      />
    </Container>
  );
}
