import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import useStyles from "./styles";
import { ISeriesLabelConfig } from "../../index";
interface ITooltipPayload {
  name: string;
  color: string;
  value: string;
  payload: {
    xAxisLabel: string;
  };
}
export interface ITooltipProps {
  payload: ITooltipPayload[];
}

interface IProps {
  tooltipData: ITooltipProps;
  seriesLabel: ISeriesLabelConfig;
}

export default function LineChartTooltip({
  tooltipData,
  seriesLabel,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  return (
    <Paper className={classes.tooltipContainer}>
      {tooltipData.payload &&
        tooltipData.payload.map((item: ITooltipPayload, index: number) => {
          let label = seriesLabel[item.name];
          if (label == null) {
            // eslint-disable-next-line no-console
            console.error(
              `Attention, the line chart config is malformed! Needed Key ${item.name} within seriesLabels ${seriesLabel}`,
            );
            label = "Unknown";
          }
          return (
            <div
              key={index}
              style={{
                alignItems: "center",
                display: "flex",
                flexWrap: "nowrap",
              }}
            >
              <Box
                className={classes.legendColorBox}
                style={{
                  backgroundColor: item.color,
                }}
              />
              {item.payload.xAxisLabel && (
                <Typography color="primary">
                  {`${label}: ${item.payload.xAxisLabel} ${parseFloat(
                    item.value as string,
                  ).toFixed(2)}%`}
                </Typography>
              )}
            </div>
          );
        })}
    </Paper>
  );
}
