import { makeStyles } from "tss-react/mui";
import { colors } from "../../../shared/theme";

const useStyles = makeStyles()(() => ({
  shopfloorPieChartWrapper: {
    "& .recharts-text": {
      fontFamily: "Titillium Web",
      fontColor: colors.blue_base,
    },
  },
  halfCircleSkeleton: {
    width: 90,
    height: 45,
    borderRadius: "45px 45px 0px 0px",
    overflow: "hidden",
  },
}));
export default useStyles;
