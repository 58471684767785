import React from "react";
import { MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { GridFilterInputValueProps } from "@mui/x-data-grid";

interface listItem {
  label: string;
  value: string;
}

interface IProps {
  filterProps: GridFilterInputValueProps;
  filterList: listItem[];
}

export default function CustomFilterComponent({
  filterProps,
  filterList,
}: IProps): React.ReactElement {
  const { item, applyValue } = filterProps;
  const handleChange = (event: SelectChangeEvent) => {
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <>
      <Typography variant="caption" style={{ height: 16, lineHeight: 1.1 }}>
        Value
      </Typography>
      <Select
        value={item.value || ""}
        onChange={handleChange}
        displayEmpty
        style={{ width: "100%" }}
      >
        {filterList.map((filterValue: listItem) => (
          <MenuItem key={filterValue.value} value={filterValue.value}>
            {filterValue.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
