import gql from "graphql-tag";

// Add timezone: string to the query variables when the backend is ready
export default gql`
  query getWorkShifts($machineTypeId: Int) {
    getWorkShifts(machineTypeId: $machineTypeId) {
      dayOfWeek
      id
      shiftEnd
      shiftName
      shiftStart
      machineTypeId
      virtual
    }
  }
`;
