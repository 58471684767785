import { makeStyles } from "tss-react/mui";
import { colors } from "../../theme";

const useStyles = makeStyles()(() => ({
  box: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  dropDown: {
    flexGrow: 1,
  },
  dateTimeSelection: {
    display: "block",
    flexBasis: "100%",
    marginTop: 12,
    height: 56,
  },
  errorBox: {
    color: colors.red_base,
    fontFamily: "Titillium Web",
  },
}));
export default useStyles;
