import gql from "graphql-tag";

export default gql`
  query getCurrentIndicatorStateList(
    $machineTypeId: Int!
    $indicatorIdentificationTypeIds: [Int]!
    $timezone: String!
  ) {
    getCurrentIndicatorStateList(
      boolMode: TRUE
      machineTypeId: $machineTypeId
      timezone: $timezone
      whitelist: $indicatorIdentificationTypeIds
    ) {
      id
      indicatorIdentificationTypeId
      machineTypeId
      status
      timestampEnd
      timestampStart
      timezone
    }
  }
`;
