import { makeStyles } from "tss-react/mui";
import { colors, rootTheme } from "../../shared/theme";

const useStyles = makeStyles()({
  container: {
    ...rootTheme.mainContainer,
  },
  loginContainer: {
    alignItems: "center",
    height: 400,
    justifyContent: "center",
    ...rootTheme.viewsContainer,
  },
  tab: {
    borderLeft: 2,
    borderLeftColor: colors.whiteB_16,
    borderLeftStyle: "solid",
    lineHeight: 1.3,

    // body_20_300_blue_base.
    textTransform: "none",
    color: colors.blue_base,
    fontSize: 20,
    fontWeight: 300,

    textAlign: "left",
    "&:first-of-type": {
      borderLeftColor: colors.white_base,
    },
  },
  tabContainer: {
    backgroundColor: colors.white_base,
    marginTop: 20,
    maxWidth: 720,
  },
  tabLabel: {
    alignItems: "flex-start",
  },
  tabUnderline: {
    width: 0,
    backgroundColor: colors.blue_base,
    transition: "none",
  },
  tabSelected: {
    backgroundColor: colors.blue_base,
    borderLeftColor: colors.blue_base,
    color: colors.white_base,
    "&:first-of-type": {
      borderLeftColor: colors.blue_base,
    },
  },
});

export default useStyles;
