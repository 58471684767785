/*
 *
 *   Time selection for report-view, kpi-view and stacklightView-view.
 *
 */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// Custom Imports.
import FormCombobox from "../../../formCombobox";

interface IJobPrioritySelectionOption {
  value: TJobPriorityKey;
  label: string;
}

export enum EJobPriorityKey {
  URGENT = "urgent",
  HIGH = "high",
  MIDDLE = "middle",
  LOW = "low",
  INDIVIDUAL = "individual",
}
export type TJobPriorityKey = `${EJobPriorityKey}`;
export const JobPrioritySelectionValues = Object.values(
  EJobPriorityKey,
) as TJobPriorityKey[];
//------------------------------------------------------------------------------------------------------------
// Interfaces & Types.
interface IProps {
  initPriority: TJobPriorityKey;
  name?: string | null;
  onPriorityChange?: (selected: TJobPriorityKey) => void;
  value?: TJobPriorityKey;
  label: string;
  onSetIndividualSelected?: (isSelected: boolean) => void;
}

//------------------------------------------------------------------------------------------------------------
// Component.
export default function JobPrioritySelection({
  initPriority,
  label,
  onPriorityChange,
  onSetIndividualSelected,
}: IProps): React.ReactElement {
  const [isIndividualSelected, setIsIndividual] = useState<boolean>(
    initPriority === EJobPriorityKey.INDIVIDUAL,
  );
  const { t } = useTranslation();
  const [value, setValue] = useState<TJobPriorityKey>(initPriority);
  const prioritySelectOptions: IJobPrioritySelectionOption[] =
    JobPrioritySelectionValues.map((val) => ({
      value: val,
      label: t(`taskManager.jobManagement.form.prioritySelection.${val}`),
    }));
  useEffect(() => {
    if (onSetIndividualSelected) {
      onSetIndividualSelected(isIndividualSelected);
    }
  }, [isIndividualSelected]);
  return (
    <FormCombobox
      value={value}
      hasError={!value}
      label={label}
      id={"priorityComponent"}
      onChange={(value) => {
        const newValue = value as TJobPriorityKey;
        setValue(newValue);
        setIsIndividual(value === EJobPriorityKey.INDIVIDUAL);
        if (onPriorityChange) {
          onPriorityChange(newValue);
        }
      }}
      options={prioritySelectOptions}
    />
  );
}
