import { useEffect, useState } from "react";
import {
  ITimeSlot,
  TTimeSelectionKey,
} from "../../../../redux/reducers/timeSelectReducer";
import { EFilterParameters } from "..";

export default function getFiltersWithPendingChanges(
  // previous filter config
  currentFilter: ITimeSlot,
  referenceFilter: ITimeSlot,
  // local (possibly changed) filter config
  currentPeriodStartedAfter: string,
  currentPeriodEndedBefore: string,
  currentShift: string,
  referencePeriodStartedAfter: string,
  referencePeriodEndedBefore: string,
  referenceShift: string,
  currentSelectedPeriod: TTimeSelectionKey,
  referenceSelectedPeriod: TTimeSelectionKey,
): { currentChanges: string[]; referenceChanges: string[] } {
  const [currentChanges, setCurrentChanges] = useState<string[]>([]);
  const [referenceChanges, setReferenceChanges] = useState<string[]>([]);

  useEffect(() => {
    const currentChangesList: string[] = [];
    const referenceChangesList: string[] = [];

    if (currentFilter.startedAfter !== currentPeriodStartedAfter)
      currentChangesList.push(EFilterParameters.STARTED_AFTER);
    if (currentFilter.endedBefore !== currentPeriodEndedBefore)
      currentChangesList.push(EFilterParameters.ENDED_BEFORE);
    if (currentFilter.shift !== currentShift)
      currentChangesList.push(EFilterParameters.SHIFT);
    if (currentFilter.selectedPeriod !== currentSelectedPeriod)
      currentChangesList.push(EFilterParameters.PERIOD);

    if (referenceFilter.startedAfter !== referencePeriodStartedAfter)
      referenceChangesList.push(EFilterParameters.STARTED_AFTER);
    if (referenceFilter.endedBefore !== referencePeriodEndedBefore)
      referenceChangesList.push(EFilterParameters.ENDED_BEFORE);
    if (referenceFilter.shift !== referenceShift)
      referenceChangesList.push(EFilterParameters.SHIFT);
    if (referenceFilter.selectedPeriod !== referenceSelectedPeriod)
      referenceChangesList.push(EFilterParameters.PERIOD);

    setCurrentChanges(currentChangesList);
    setReferenceChanges(referenceChangesList);
  }, [
    currentFilter,
    referenceFilter,
    currentPeriodStartedAfter,
    currentPeriodEndedBefore,
    currentShift,
    referencePeriodStartedAfter,
    referencePeriodEndedBefore,
    referenceShift,
    currentSelectedPeriod,
    referenceSelectedPeriod,
  ]);

  return {
    currentChanges,
    referenceChanges,
  };
}
