import React, { useMemo } from "react";
import { Grid2 as Grid, Typography } from "@mui/material";
import { getDateString, getTimeString } from "../../../../services/getDateTime";
import useInterval from "../../../../helper/time/interval/useInterval";

type StyleObject = {
  timeIcon: string;
  timeLabel: string;
};

interface IProps {
  icon: React.ReactElement;
  timezone: string;
  isDate?: boolean;
  styles: StyleObject;
}

function getDateOrTime(timezone: string, isDate: boolean): string {
  return isDate ? getDateString(timezone) : getTimeString(timezone);
}

function TimeInfo({
  icon,
  timezone,
  isDate = false,
  styles,
}: IProps): React.ReactElement {
  const separator = isDate ? "-" : ":";
  const [dateInfo, setDateInfo] = React.useState<string>(
    getDateOrTime(timezone, isDate),
  );

  useInterval(() => {
    setDateInfo(getDateOrTime(timezone, isDate));
  }, 1000);

  const renderTimeInfo: React.ReactElement = useMemo(() => {
    return (
      <Grid container>
        <icon.type {...icon.props} className={styles.timeIcon} />
        <div className={styles.timeLabel}>
          {dateInfo.length < 3 ? (
            <Typography variant="body2">{`?${separator}?${separator}?`}</Typography>
          ) : (
            <>
              <Typography variant="body2">{dateInfo}</Typography>
            </>
          )}
        </div>
      </Grid>
    );
  }, [dateInfo, icon, separator, styles.timeIcon, styles.timeLabel]);

  return renderTimeInfo;
}
export default React.memo(TimeInfo);
