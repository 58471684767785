import { useMediaQuery, useTheme } from "@mui/system";

export function useIsSmallView(isSidebarOpen: boolean): boolean {
  const { breakpoints } = useTheme();

  const isWidthBetweenMDXL = useMediaQuery(breakpoints.between("md", "xl"), {
    noSsr: true,
  });
  const isWidthBelowLG = useMediaQuery(breakpoints.down("lg"), { noSsr: true });

  return (isWidthBetweenMDXL && isSidebarOpen) || isWidthBelowLG;
}
