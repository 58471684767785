import { makeStyles } from "tss-react/mui";
const baseStyle = {
  marginLeft: 50,
  marginRight: 50,
};
const smallResolutionQuery = "@media (max-width: 479px)";
const useStyles = makeStyles()(() => ({
  footer: {
    height: 50,
    display: "flex",
    justifyContent: "center",
  },
  leftLink: {
    ...baseStyle,
    fontFamily: "Titillium Web,Roboto",
    [smallResolutionQuery]: {
      marginLeft: 50,
      marginRight: 20,
    },
  },
  middleLink: {
    ...baseStyle,
    fontFamily: "Titillium Web,Roboto",
    [smallResolutionQuery]: {
      marginLeft: 20,
      marginRight: 20,
    },
  },
  rightLink: {
    ...baseStyle,
    fontFamily: "Titillium Web,Roboto",
    [smallResolutionQuery]: {
      marginLeft: 20,
      marginRight: 50,
    },
  },
}));
export default useStyles;
