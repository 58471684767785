import { Theme } from "@mui/material/styles";
import { colors } from "../../shared/theme";
/*
  border left:
  bei nicht selektiert, border transparent
 */
const drawerWidth = 280;
const topBarHeight = 63;
const overflowY = "auto";
const overflowX = "hidden";
import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()((theme: Theme) => ({
  drawer: {
    // height: `calc(100vh - ${topBarHeight}px)`,
    flexShrink: 0,
    top: topBarHeight,
    whiteSpace: "nowrap",
    width: drawerWidth,
    overflowY,
    overflowX,
    display: "flex",
    scrollbarColor: [colors.grey_base, colors.grey_base],
    "& ::-webkit-scrollbar": {
      width: 3,
      height: 3,
      scrollbarWidth: "thin",
      scrollbarGutter: "unset",
    },
    "& :hover::-webkit-scrollbar": {
      width: 5,
      scrollbarWidth: "none",
    },
    "& ::-webkit-scrollbar-thumb": {
      background: colors.grey_base,
      borderRadius: 5,
    },
  },
  drawerOpen: {
    backgroundColor: colors.blue_base,
    color: colors.blue_base,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: drawerWidth,
    overflowY,
    overflowX,
  },
  drawerOpenMobile: {
    backgroundColor: colors.blue_base,
    color: colors.blue_base,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: drawerWidth,
    overflowY,
    overflowX,
  },
  drawerClose: {
    backgroundColor: colors.blue_base,
    width: theme.spacing(7.5),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX,
    overflowY,
  },
}));

export default useStyles;
