/*
 *
 *   Dashboard profile view for setting up the dashboard. Implemented in profile page.
 *   Inspiration: https://dribbble.com/shots/14115417-Settings-page-for-Bizy
 *
 */

import React, { useCallback, useMemo, useState } from "react";
// Custom imports.
// import useStyles from "./styles";
import LanguageSelection from "./components/LanguageSelection";
import TimezoneSelection from "./components/TimezoneSelection";
import TooltipEnableSelection from "./components/TooltipEnableSelection";
import PreviewEnableSelection from "./components/PreviewEnableSelection";
import getLayoutInfo, {
  BROWSER_TIMEZONE_LAYOUT_KEY,
  LANGUAGE_SELECT_LAYOUT_KEY,
  PREVIEW_SELECT_LAYOUT_KEY,
  TIMEZONE_SELECT_LAYOUT_KEY,
  TOOLTIP_SELECT_LAYOUT_KEY,
  TResizeConfig,
} from "./layoutConfig";
import GridPage from "../../../../shared/components/gridPage";
import BrowserTimezone from "./components/BrowserTimezone";

export default function DashboardProfileView(): React.ReactElement {
  const [resizeConf, setResizeconf] = useState<TResizeConfig>(
    {} as TResizeConfig,
  );
  const layoutConf = useMemo(() => getLayoutInfo(resizeConf), [resizeConf]);
  const onLayoutChange = useCallback((isMoreHeightNeeded: boolean) => {
    const baseValue = 1.76;
    const increasedValue = baseValue + 0.5;
    setResizeconf((prev) => ({
      ...prev,
      [BROWSER_TIMEZONE_LAYOUT_KEY]: isMoreHeightNeeded
        ? increasedValue
        : baseValue,
    }));
  }, []);

  return (
    <GridPage layouts={layoutConf}>
      <div key={LANGUAGE_SELECT_LAYOUT_KEY}>
        <LanguageSelection />
      </div>
      <div key={TIMEZONE_SELECT_LAYOUT_KEY}>
        <TimezoneSelection />
      </div>
      <div key={TOOLTIP_SELECT_LAYOUT_KEY}>
        <TooltipEnableSelection />
      </div>
      <div key={PREVIEW_SELECT_LAYOUT_KEY}>
        <PreviewEnableSelection />
      </div>
      <div key={BROWSER_TIMEZONE_LAYOUT_KEY}>
        <BrowserTimezone onNeedsMoreHeight={onLayoutChange} />
      </div>
    </GridPage>
  );
}
