import IGetWorkShiftsResponse, {
  IWorkShiftEntry,
} from "./IGetWorkShiftsResponse";
import { TFunction } from "i18next";
import IShiftConfigEntry from "../app/IShiftConfigEntry";
import IShifts from "../app/IShifts";

interface IGroupableShiftConfigEntry extends IShiftConfigEntry {
  shiftName: string;
}
// https://stackoverflow.com/questions/24998624/day-name-from-date-in-js
export function getDayName(index: number, t?: TFunction) {
  const DAY_MAP = {
    "1": t ? t("common.time.fullDay.Monday") : "Monday",
    "2": t ? t("common.time.fullDay.Tuesday") : "Tuesday",
    "3": t ? t("common.time.fullDay.Wednesday") : "Wednesday",
    "4": t ? t("common.time.fullDay.Thursday") : "Thursday",
    "5": t ? t("common.time.fullDay.Friday") : "Friday",
    "6": t ? t("common.time.fullDay.Saturday") : "Saturday",
    "7": t ? t("common.time.fullDay.Sunday") : "Sunday",
  };
  return DAY_MAP[index];
}

export default function transformApiToAppFormat(
  resp: IGetWorkShiftsResponse,
  // make t optional to make this function pure and testable
  t?: TFunction,
): IShifts {
  if (resp.getWorkShifts.some((shift) => shift == null)) {
    throw new Error("Invalid Shifts Response.");
  }
  const configEntries = resp.getWorkShifts
    .filter((shift): shift is IWorkShiftEntry => shift != null) // Filter out null values
    .map(
      ({
        id,
        dayOfWeek,
        shiftEnd,
        shiftName,
        shiftStart,
        machineTypeId,
        virtual,
      }: IWorkShiftEntry): IGroupableShiftConfigEntry => {
        return {
          id,
          shiftName,
          startTime: shiftStart,
          endTime: shiftEnd,
          displayName: getDayName(dayOfWeek, t),
          dayOfWeek: dayOfWeek,
          isActive: !virtual,
          machineTypeId: machineTypeId,
        };
      },
    )
    .sort((a: IShiftConfigEntry, b: IShiftConfigEntry) => {
      return a.dayOfWeek < b.dayOfWeek ? -1 : 1;
    });
  return configEntries.reduce(
    (
      acc,
      {
        id,
        displayName,
        startTime,
        endTime,
        shiftName,
        dayOfWeek,
        isActive,
        machineTypeId,
      }: IGroupableShiftConfigEntry,
    ) => {
      // Group initialization
      if (!acc[shiftName]) {
        acc[shiftName] = [];
      }

      // Grouping
      acc[shiftName].push({
        id,
        displayName,
        startTime,
        endTime,
        dayOfWeek,
        isActive,
        machineTypeId,
      });

      return acc;
    },
    {},
  );
}
