import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  outerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    width: "100%",
    height: "100%",
  },
  skeletonText: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));
export default useStyles;
