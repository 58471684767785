import DocumentTypeSelection from "../../../../components/service/common/DocumentTypeSelection";
import DocumentTypeResult from "../../../../components/service/documentTypesView/DocumentTypeResult";
import useStyles from "../../../../components/service/common/styles";
import React from "react";

interface IProps {
  keywordSearch?: boolean;
}

export default function DocumentTypeView(props: IProps): React.ReactElement {
  const cssClasses = useStyles();

  return (
    <div className={cssClasses.classes.docsearch_container}>
      <DocumentTypeSelection />
      <DocumentTypeResult
        keywordSearch={props.keywordSearch}
        // searchCriteria={{ and: ["doctypeFilter"] }}
      />
    </div>
  );
}
