import { makeStyles } from "tss-react/mui";
import { rootTheme } from "../../theme";

const useStyles = makeStyles()({
  container: {
    width: "100%",
    minWidth: "180px",
    flexWrap: "wrap",
  },
  inputContainer: {
    borderRadius: 0,
    display: "flex",
    justifyContent: "center",
    width: "95%",
    "&.MuiMenuItem-root": { color: "black" },
  },
  root: {
    display: "flex",
    justifyContent: "center",
    width: "100%", // for fixed width
  },
  rootHeight: {
    height: rootTheme.elementHeight,
  },
  selectIcon: {
    marginRight: 10,
  },
  textInputSelect: {
    paddingLeft: 10,
  },
});

export default useStyles;
