import React from "react";
import { Typography } from "@mui/material";

export enum EStyleType {
  LIGHT = "LIGHT",
  GREY = "GREY",
  DARK = "DARK",
}

interface IProps {
  type?: EStyleType;
  text: string;
  className?: string;
}

const colorConf = {
  [EStyleType.LIGHT]: "secondary",
  [EStyleType.GREY]: "textPrimary",
  [EStyleType.DARK]: "primary",
};

export default function GeorgTextBase({
  type = EStyleType.LIGHT,
  text,
  className,
}: IProps): React.ReactElement {
  const color = colorConf[type];
  return (
    <Typography
      key={"header2"}
      className={className}
      color={color}
      variant="button"
    >
      {text}
    </Typography>
  );
}
