import gql from "graphql-tag";

export default gql`
  mutation deleteAzureB2cUser($userId: String!) {
    deleteAzureB2cUser(userId: $userId) {
      message
      statusCode
    }
  }
`;
