import React, { useLayoutEffect, useMemo } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
// Custom imports.
import getDateFnsLocaleByString from "./helper/time/formatting/getDateFnsLocaleByString";
import MainContainer from "./MainContainer";
import { Container, Loader } from "./components";
import TimezoneToast from "./settings/timezoneToast";
import VersionToast from "./settings/versionToast";
import { useDataFetchAndUpdate } from "./hooks/useDataFetchAndUpdate";
import { useFlag } from "@unleash/proxy-client-react";
import useSentry from "./useSentry";

function App(): React.ReactElement {
  const { i18n, t } = useTranslation();
  const isSentryEnabled = useFlag("sentry");
  const { toggleSentry } = useSentry();

  const errorMessages = {
    machineQueryErr: t("app.error.machineData"),
    indicatorQueryErr: t("app.error.indicatorList"),
    downtimeQueryErr: t("app.error.downtimeList"),
    variableListQueryErr: t("app.error.variableList"),
  };
  // Localization
  const localeFns = useMemo(
    () => getDateFnsLocaleByString(i18n.language),
    [i18n.language],
  );

  const [useQueryAndDispatch, { isLoading, isError, errorMessage }] =
    useDataFetchAndUpdate(errorMessages);
  useLayoutEffect(() => {
    // Data fetching
    useQueryAndDispatch();
  }, []);

  useLayoutEffect(() => {
    if (isSentryEnabled) {
      toggleSentry(true);
    } else {
      toggleSentry(false);
    }
  }, [isSentryEnabled]);

  return (
    <LocalizationProvider
      adapterLocale={localeFns}
      dateAdapter={AdapterDateFns}
    >
      <TimezoneToast />
      <VersionToast />
      {isLoading ? (
        <Container isLoading={true}>
          <Loader />
        </Container>
      ) : isError ? (
        <Container>
          <>
            {Object.values(errorMessage).map((error, index) => (
              <p key={index}>{error}</p>
            ))}
          </>
        </Container>
      ) : (
        <MainContainer adapterLocale={i18n.language} />
      )}
    </LocalizationProvider>
  );
}

export default App;
