import { TIndicatorType } from "../types";
import { TIndicatorName } from "../../config/states/STATE_COLOR_MAP";

export default function indicatorNamesToIndicatorIds(
  indicatorNames: TIndicatorName[],
  allIndicators: TIndicatorType[],
): string[] {
  return indicatorNamesToIndicatorTypes(indicatorNames, allIndicators).map(
    (indicator) => indicator.id,
  );
}

export function indicatorNamesToIndicatorTypes(
  indicatorNames: TIndicatorName[],
  allIndicators: TIndicatorType[],
): TIndicatorType[] {
  return indicatorNames.map((nameToFind) => {
    const found = allIndicators.find(
      (indicator) => indicator.indicatorName === nameToFind,
    );
    if (!found) {
      throw new Error(
        `Indicator ${nameToFind} could not be found in indicator list from server`,
      );
    } else {
      return found;
    }
  });
}
