import { TextField } from "@mui/material";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  searchByKeyword,
  submitSearchQuery,
  selectCheckboxes,
  selectKeywords,
  selectFinalSearchTerm,
} from "../../../redux/reducers/serviceReducer";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect } from "react";
import { IButton } from "../../../shared/components";

interface IProps {
  keywordSearch?: boolean;
}

export default function DocumentSearchField(props: IProps): React.ReactElement {
  const dispatch = useDispatch();
  const cssClasses = useStyles();
  const keywords = useSelector(selectKeywords);
  const selectedDoctypes = useSelector(selectCheckboxes);
  const { t } = useTranslation();
  const finalSearchTerm = useSelector(selectFinalSearchTerm);

  useEffect(() => {
    dispatch(searchByKeyword(finalSearchTerm));
  }, []);

  function onSearchButtonClick() {
    dispatch(submitSearchQuery(keywords));
  }

  if (props.keywordSearch) {
    return selectedDoctypes.length > -1 ? (
      <a style={{ display: "flex" }}>
        <TextField
          className={cssClasses.classes.textfield_doc_search}
          variant={"outlined"}
          label={t("service.documentsearch.input_label")}
          value={keywords}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              const searchFieldValue = (e.target as HTMLInputElement).value;
              if (searchFieldValue.length === 0) {
                return;
              }
              onSearchButtonClick();
            }
          }}
          onChange={(value) => dispatch(searchByKeyword(value.target.value))}
        />
        <div
          style={{ cursor: keywords.length === 0 ? "not-allowed" : "pointer" }}
          className={cssClasses.classes.search_container}
        >
          <IButton
            onClick={onSearchButtonClick}
            icon={<SearchIcon color="primary" />}
            styles={{
              button: cssClasses.classes.search_icon,
            }}
            disabled={keywords.length === 0}
          />
        </div>
      </a>
    ) : (
      <></>
    );
  } else {
    return <></>;
  }
}
