/* eslint-disable no-console */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface ServiceState {
  machine: string;
  searchTerm: string;
  finalSearchTerm: string;
  bufferSearchTerm: string;
  documentType: string[];
  availableDocumentTypes: string[];
  resultsHeight: number;
  checkboxStates: CheckBoxState[];
  checkboxes: string[];
  checkboxStatesNew: string[];
  supportPersonell: SupportPerson[];
  showingDocumentation: boolean;
}

export interface SupportPerson {
  [x: string]: string;
  name: string;
  department: string;
  phone_number: string;
  email: string;
  image: string;
}

export enum CheckBoxEventType {
  DISELECT_ALL = 0,
  SELECT_NORMAL = 1,
  SELECT_ALL = 2,
}

export interface CheckBoxEvent {
  index: string;
  eventType: CheckBoxEventType;
}

export interface CheckBoxState {
  index: number;
  documentType: string;
  selected: boolean;
}

const initialState: ServiceState = {
  machine: "",
  searchTerm: "",
  finalSearchTerm: "",
  bufferSearchTerm: "",
  documentType: [],
  availableDocumentTypes: [],
  resultsHeight: 0,
  checkboxes: [],
  checkboxStatesNew: [],
  checkboxStates: [],
  supportPersonell: [],
  showingDocumentation: false,
};

export function checkboxStatesToStringArray(states: CheckBoxState[]): string[] {
  const result: string[] = [];
  states.forEach((state) => {
    if (state.selected) result.push(state.documentType);
  });
  if (result.length === 0) {
    return ["no_checkbox_state_selected"];
  }
  return result;
}

export const serviceStateSlice = createSlice({
  name: "documentsearchState",
  initialState,
  reducers: {
    filterByMachine: (state, action: PayloadAction<string>) => {
      state.machine = action.payload;
    },
    searchByKeyword: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    submitSearchQuery: (state, action: PayloadAction<string>) => {
      state.finalSearchTerm = action.payload;
    },
    filterByDoctypes: (state, action: PayloadAction<CheckBoxEvent>) => {
      //const index = action.payload.index;
      state.checkboxStatesNew.push(action.payload.index);
      switch (action.payload.eventType) {
        case CheckBoxEventType.SELECT_NORMAL: {
          const matchIndex = state.checkboxStates.findIndex(
            (x) => x.documentType === action.payload.index,
          );
          const isSelected = state.checkboxStates[matchIndex].selected;
          if (isSelected) {
            state.checkboxStates[matchIndex].selected = false;
            return;
          }
          state.checkboxStates[matchIndex].selected = true;
          break;
        }

        case CheckBoxEventType.SELECT_ALL: {
          state.checkboxStates.forEach((state) => {
            state.selected = true;
          });
          break;
        }

        case CheckBoxEventType.DISELECT_ALL: {
          state.checkboxStates.forEach((state) => {
            state.selected = false;
          });
          break;
        }

        default: {
          break;
        }
      }
    },
    filterByDocumentType(state, action: PayloadAction<string>) {
      const selectedAllDocumentsCheckbox =
        state.checkboxes.every((checkbox) => checkbox !== "all") &&
        action.payload === "all";
      const alreadySelectedCheckbox =
        !selectedAllDocumentsCheckbox &&
        state.checkboxes.some(
          (checkbox) => checkbox === action.payload && action.payload !== "all",
        );
      const alreadySelectedAllDocumentsCheckbox =
        state.checkboxes.includes("all") && action.payload === "all";
      if (selectedAllDocumentsCheckbox) {
        state.checkboxes = state.availableDocumentTypes;
        return;
      } else if (alreadySelectedCheckbox) {
        state.checkboxes = state.checkboxes.filter(
          (checkbox) => checkbox !== action.payload,
        );
        state.checkboxes = state.checkboxes.filter(
          (checkbox) => checkbox !== "all",
        );
        return;
      } else if (alreadySelectedAllDocumentsCheckbox) {
        state.checkboxes = [];
        return;
      } else {
        state.checkboxes = state.checkboxes.concat([action.payload]);
      }
    },
    populateDoctypeList: (state, action: PayloadAction<string[]>) => {
      console.log("Hello from populateDoctypeList");
      console.log(action.payload);
      let currCheckbox = 0;
      const updatedState: CheckBoxState[] = [];
      const updatedStateNew: string[] = [];
      action.payload.forEach((doctype) => {
        updatedState.push({
          index: currCheckbox,
          documentType: doctype,
          selected: false,
        });
        updatedStateNew.push(doctype);
        currCheckbox++;
      });
      state.searchTerm = "";
      state.bufferSearchTerm = "AAAAAA";
      state.checkboxStates = updatedState;
      state.checkboxes = [];
      state.availableDocumentTypes = updatedStateNew;
    },
    setServiceMachine: (state, action: PayloadAction<string>) => {
      state.machine = action.payload;
    },
    setResultsHeight: (state, action: PayloadAction<number>) => {
      state.resultsHeight = action.payload;
    },
    setSupportStaff: (state, action: PayloadAction<SupportPerson[]>) => {
      state.supportPersonell = action.payload;
    },
    showDocumentation: (state, action: PayloadAction<boolean>) => {
      state.showingDocumentation = action.payload;
    },
  },
});

export const selectServiceState = (state: RootState): ServiceState =>
  state.Service;
export const selectedMachine = (state: RootState): string =>
  state.Service.machine;
export const selectKeywords = (state: RootState): string =>
  state.Service.searchTerm;
export const selectBufferKeywords = (state: RootState): string =>
  state.Service.bufferSearchTerm;
export const selectFinalSearchTerm = (state: RootState): string =>
  state.Service.finalSearchTerm;
export const selectedDoctype = (state: RootState): string[] =>
  state.Service.documentType;
export const selectDoctypeList = (state: RootState): string[] =>
  state.Service.availableDocumentTypes;
export const selectCheckboxes = (state: RootState): string[] =>
  state.Service.checkboxes;
export const selectCheckboxStates = (state: RootState): CheckBoxState[] =>
  state.Service.checkboxStates;
export const selectSupportStaff = (state: RootState): SupportPerson[] =>
  state.Service.supportPersonell;
export const selectDocumentation = (state: RootState): boolean =>
  state.Service.showingDocumentation;
export const {
  filterByMachine,
  filterByDocumentType,
  filterByDoctypes,
  searchByKeyword,
  submitSearchQuery,
  populateDoctypeList,
  showDocumentation,
  setResultsHeight,
  setSupportStaff,
  setServiceMachine,
} = serviceStateSlice.actions;

export default serviceStateSlice.reducer;
