import React, { useMemo, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ICommitInfo } from "..";
import useClaims from "../../../auth/hooks/useClaims";
import { domainUrlMapping } from "../../../components/Apollo";
import { TFunction } from "i18next";
import generatedGitInfo from "../../../generatedGitInfo.json";
import useStyles from "./styles";

interface IProps {
  isFetchStarted: boolean;
  t: TFunction;
}

const bulletText = "\u2022";
const subBulletText = "\u2043";

function VersionUpdateList({ isFetchStarted, t }: IProps): React.ReactElement {
  const { classes } = useStyles();

  const currentAppVersion = generatedGitInfo.appVersion || "";
  const claims = useClaims();
  const [commitsUrl, setCommitsUrl] = useState("");

  const [commits, setCommits] = useState<ICommitInfo[]>([]);
  const [featureCommits, setFeatureCommits] = useState<ICommitInfo[]>([]);
  const [bugfixCount, setBugfixCount] = useState<number>(0);
  const [securityUpdateCount, setSecurityUpdateCount] = useState<number>(0);
  const [performanceUpdateCount, setPerformanceUpdateCount] =
    useState<number>(0);
  const [newVersion, setNewVersion] = useState("");

  useEffect(() => {
    if (claims != null) {
      const BACKEND_URL = claims.extension_tenant;
      const domain = domainUrlMapping[BACKEND_URL] || domainUrlMapping.default;
      setCommitsUrl(`https://${domain}/generatedChangelog.json`);
    }
  }, [claims]);

  useEffect(() => {
    const fetchCommits = async () => {
      try {
        const response = await fetch(commitsUrl, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        const meta = await response.json();
        setCommits(meta);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error fetching commit-list:", error);
      }
    };

    if (isFetchStarted && commitsUrl.length > 0) {
      fetchCommits();
    } else {
      setCommits([]);
    }
  }, [isFetchStarted, commitsUrl]);

  const filteredCommits = useMemo(() => {
    const targetIndex = commits.findIndex(
      (commit) => commit.appVersion === currentAppVersion,
    );

    // TODO: we need to implement "..."" when the commitHash is not found in the list
    if (targetIndex !== -1) {
      // If current version is found, get all updates from that version onwards
      return commits.slice(0, targetIndex + 1);
    } else {
      // If current version is not found, filter commits with version numbers greater than the current version
      const returnedList = commits.filter((commit) => {
        if (commit.appVersion) {
          return (
            commit.appVersion.localeCompare(currentAppVersion, undefined, {
              numeric: true,
            }) > 0
          );
        }
        return false;
      });
      if (returnedList.length === 0) {
        // If there are no updates available, return the last 10 commits
        returnedList.push(...commits.slice(0, 10));
      }
      return returnedList;
    }
  }, [commits, currentAppVersion]);

  useEffect(() => {
    const localFeatureCommits: ICommitInfo[] = [];
    let localBugfixCount = 0;
    let localSecurityUpdateCount = 0;
    let localPerformanceUpdateCount = 0;

    setNewVersion(filteredCommits[0]?.appVersion || "");

    filteredCommits.forEach((commit) => {
      const message = commit.gitCommitMessage.toLowerCase();
      if (message.includes("fix:")) {
        localBugfixCount++;
      } else if (message.includes("feat:")) {
        const newMessage = commit.gitCommitMessage.replace(/feat:/i, "").trim();
        localFeatureCommits.push({
          ...commit,
          gitCommitMessage:
            newMessage.charAt(0).toUpperCase() + newMessage.slice(1),
        });
      } else if (message.includes("chore(deps):")) {
        localSecurityUpdateCount++;
      } else if (message.includes("perf:")) {
        localPerformanceUpdateCount++;
      }
    });

    setFeatureCommits(localFeatureCommits);
    setBugfixCount(localBugfixCount);
    setSecurityUpdateCount(localSecurityUpdateCount);
    setPerformanceUpdateCount(localPerformanceUpdateCount);
  }, [filteredCommits]);

  return (
    <>
      <Accordion slotProps={{ transition: { unmountOnExit: true } }}>
        <AccordionSummary
          expandIcon={
            filteredCommits.length > 0 ? <KeyboardArrowDownIcon /> : null
          }
          aria-controls="panel1-content"
          id="panel1-header"
          style={{
            cursor: filteredCommits.length > 0 ? "pointer" : "default",
          }}
        >
          <Typography
            className={classes.textContainer}
            color={"secondary"}
            variant="body1"
          >
            {t("version.updateAvailable")}
          </Typography>
        </AccordionSummary>
        {filteredCommits.length > 0 && (
          <AccordionDetails>
            <div className={classes.accordionDetailsContainer}>
              <Typography color={"secondary"}>
                {`${t("version.updateSummaryTitle")} ${newVersion}:`}
              </Typography>
              <div style={{ paddingLeft: 10 }}>
                {featureCommits.length > 0 && (
                  <>
                    <Typography color={"secondary"}>
                      {`${bulletText} ${t("version.newFeatures")}`}
                    </Typography>
                    <div className={classes.featureCommitsContainer}>
                      {featureCommits.map((commit) => (
                        <div
                          key={commit.gitCommitHash}
                          className={classes.featureCommitContainer}
                        >
                          <Typography
                            color={"secondary"}
                            style={{ paddingRight: 5 }}
                          >
                            {subBulletText}
                          </Typography>
                          <Typography color={"secondary"}>
                            {commit.gitCommitMessage}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  </>
                )}
                {bugfixCount > 0 && (
                  <>
                    <Typography color={"secondary"}>
                      {`${bulletText} ${t("version.bugFixes")}`}
                    </Typography>
                  </>
                )}
                {securityUpdateCount > 0 && (
                  <>
                    <Typography color={"secondary"}>
                      {`${bulletText} ${t("version.securityUpdate")}`}
                    </Typography>
                  </>
                )}
                {performanceUpdateCount > 0 && (
                  <>
                    <Typography color={"secondary"}>
                      {`${bulletText} ${t("version.performanceImprovements")}`}
                    </Typography>
                  </>
                )}
              </div>
            </div>
          </AccordionDetails>
        )}
      </Accordion>
    </>
  );
}
export default React.memo(VersionUpdateList);
