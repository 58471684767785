import { TFunction } from "i18next";
import { convertDatesToDateRange } from "../../../../../../../../helper/time/areDatesOnSameDay";
import { IHeatmapRowLayer, IHeatMapRowLayerPart } from "../IHeatMapRow";

export interface IChartPart {
  duration?: number;
  displayStringParts: {
    days: number;
    hours: string;
  };
  timeDuration?: string;
  id: number;
  tsStart: number;
  tsEnd: number;
  displayDate?: string;
}

export default function extractChartParts(
  layers: IHeatmapRowLayer[],
  t: TFunction,
  language: string,
  timezone: string,
): IChartPart[] {
  return layers.flatMap((layer: IHeatmapRowLayer) => {
    return layer.parts.flatMap((part: IHeatMapRowLayerPart) => {
      return {
        ...part,
        displayDate: convertDatesToDateRange(
          part.dateStart || "",
          part.dateEnd || "",
          timezone,
          language,
          t,
        ),
        id: layer.indicatorIdentificationTypeId,
      };
    });
  });
}
