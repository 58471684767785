import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  container: {
    minHeight: 120,
  },
  withPadding: {
    paddingTop: 48,
  },
  placeHolderRow: {
    minHeight: 57,
  },
  question: {
    marginTop: 50,
  },
  lastItem: {
    marginBottom: 32,
  },
}));

export default useStyles;
