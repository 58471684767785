import { makeStyles } from "tss-react/mui";
import { colors } from "../../../shared/theme";
import colorToRgba from "../../../helper/adjustColor";

const styles = makeStyles()(() => ({
  textContainer: {
    "&.MuiTypography-root": {
      fontWeight: "normal",
    },
  },
  accordionDetailsContainer: {
    display: "flex",
    flexDirection: "column",
    borderTop: `1px solid ${colorToRgba(colors.white_base, 0.5)}`,
    paddingTop: 16,
    marginTop: 16,
  },
  featureCommitsContainer: {
    paddingInlineStart: 20,
    marginBlockStart: 5,
    marginBlockEnd: 5,
  },
  featureCommitContainer: {
    color: "white",
    display: "flex",
    flexDirection: "row",
  },
}));

export default styles;
