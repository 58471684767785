import createLayout from "../gridPage/helper/createLayout";

export const TIME_PERIOD_LAYOUT_KEY: TGridLayoutTabsKEY = "TimePeriod";
export const DATE_TIME_RANGE_LAYOUT_KEY: TGridLayoutTabsKEY = "DateTimeRange";
export const SHIFT_LAYOUT_KEY: TGridLayoutTabsKEY = "Shift";
type TGridLayoutTabsKEY = "TimePeriod" | "DateTimeRange" | "Shift";

const dropDownHeight = 2;
const componentDouble = {
  w: 6,
  h: dropDownHeight,
};
const componentSingle = {
  w: 12,
  h: dropDownHeight,
};
const noComponent = {
  w: 12,
  h: 0,
  minH: 0,
};

const nonSmallLayout = [
  { i: TIME_PERIOD_LAYOUT_KEY, x: 0, y: 0, ...componentDouble },
  { i: SHIFT_LAYOUT_KEY, x: 6, y: 0, ...componentDouble },
  { i: DATE_TIME_RANGE_LAYOUT_KEY, x: 0, y: 2, ...noComponent },
];

const smallLayout = [
  { i: TIME_PERIOD_LAYOUT_KEY, x: 0, y: 0, ...componentSingle },
  { i: DATE_TIME_RANGE_LAYOUT_KEY, x: 0, y: 2, ...noComponent },
  { i: SHIFT_LAYOUT_KEY, x: 0, y: 4, ...componentSingle },
];

export type TResizeConfig = {
  [key in TGridLayoutTabsKEY]: number;
};

export function getLayoutInfo(resizeConfig: TResizeConfig) {
  return createLayout<TResizeConfig>(resizeConfig, nonSmallLayout);
}

export function getLayoutInfoSmall(resizeConfig: TResizeConfig) {
  return createLayout<TResizeConfig>(resizeConfig, smallLayout);
}
