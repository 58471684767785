import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IOption {
  value: string;
  label: string;
}

interface IProps {
  id: string;
  label?: string | null;
  options: IOption[];
  value: string;
  onChange: (value: string) => void;
  freeSolo?: boolean;
  hasError?: boolean;
}

function isIOption(v: string | IOption | null): v is IOption {
  return (
    v !== null &&
    typeof (v as IOption) === "object" &&
    "label" in (v as IOption) &&
    "value" in (v as IOption)
  );
}

export default function FormCombobox({
  id,
  label,
  options,
  value,
  onChange,
  freeSolo = false,
  hasError = false,
}: IProps): React.ReactElement {
  const { t } = useTranslation();
  return (
    <Autocomplete
      aria-errormessage={"abc"}
      freeSolo={freeSolo}
      onInputChange={(event: React.SyntheticEvent, value: string) => {
        if (freeSolo) {
          onChange(value);
        }
      }}
      fullWidth
      disablePortal
      id={id}
      options={options}
      noOptionsText={t("shared.components.formCombobox.noOptions")}
      loadingText={t("shared.components.formCombobox.loading")}
      value={options.find((o) => o.value === value) || null}
      onChange={(
        event: React.SyntheticEvent,
        value: string | IOption | null,
      ) => {
        if (value != null) {
          if (isIOption(value)) {
            onChange(value.value);
          } else {
            onChange(value);
          }
        } else {
          onChange("");
        }
      }}
      /*
        default behavior uses label as key, so wee override it manually with value
       */
      renderOption={(props, option: IOption) => {
        return (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        );
      }}
      renderInput={(params) => {
        return <TextField error={hasError} {...params} label={label} />;
      }}
    />
  );
}
