import { useEffect, useState } from "react";
import ICategory from "../../api/ICategory";
import { CATEGORIES } from "../../api/MOCKS";
import { EApiState } from "../../../../../../../api";

export function useQueryCategories(): { categories; apiState } {
  const [apiState, setApiState] = useState(EApiState.LOADING);
  const [categories, setCategories] = useState<ICategory[]>([]);
  useEffect(() => {
    setApiState(EApiState.LOADING);
    const t = setTimeout(() => {
      setCategories(CATEGORIES);
      setApiState(EApiState.OK);
    }, 600);
    return () => {
      clearTimeout(t);
    };
  }, []);
  return { apiState, categories };
}
