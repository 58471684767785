import gql from "graphql-tag";

export default gql`
  query getKpiRefChart(
    $currentFilter_machineTypeId: ID!
    $currentFilter_indicatorIdentificationTypeIds: [ID]!
    $currentFilter_startedAfter: DateTime!
    $currentFilter_endedBefore: DateTime!
    $currentFilter_scale: AggregateScale!
    $currentFilter_shift: String!
    $currentFilter_timezone: String!
    $referenceFilter_machineTypeId: ID!
    $referenceFilter_indicatorIdentificationTypeIds: [ID]!
    $referenceFilter_startedAfter: DateTime!
    $referenceFilter_endedBefore: DateTime!
    $referenceFilter_scale: AggregateScale!
    $referenceFilter_shift: String!
    $referenceFilter_timezone: String!
  ) {
    getKpiRefChart(
      currentFilter: {
        machineTypeId: $currentFilter_machineTypeId
        indicatorIdentificationTypeIds: $currentFilter_indicatorIdentificationTypeIds
        startedAfter: $currentFilter_startedAfter
        endedBefore: $currentFilter_endedBefore
        scale: $currentFilter_scale
        shift: $currentFilter_shift
        timezone: $currentFilter_timezone
      }
      referenceFilter: {
        machineTypeId: $referenceFilter_machineTypeId
        indicatorIdentificationTypeIds: $referenceFilter_indicatorIdentificationTypeIds
        startedAfter: $referenceFilter_startedAfter
        endedBefore: $referenceFilter_endedBefore
        scale: $referenceFilter_scale
        shift: $referenceFilter_shift
        timezone: $referenceFilter_timezone
      }
    ) {
      currentAverageValue
      indicatorIdentificationType {
        indicatorName
      }
      delta
      referenceAverageValue
    }
  }
`;
