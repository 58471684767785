import React, { useContext, useLayoutEffect, useMemo } from "react";
import { FormLabel, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ErrorIcon from "@mui/icons-material/Error";
import useStyles from "./styles";
import GeorgStorageContext from "../../../../../../context/GeorgStorageContext";

interface ITimezoneSelectionProps {
  onNeedsMoreHeight: (offsetForHeight: boolean) => void;
}

export default function TimezoneSelection({
  onNeedsMoreHeight,
}: ITimezoneSelectionProps): React.ReactElement {
  const { t } = useTranslation();
  const { timezone } = useContext(GeorgStorageContext);
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { classes } = useStyles();

  const isTimezoneDifferent = browserTimezone !== timezone;
  useLayoutEffect(() => {
    onNeedsMoreHeight(isTimezoneDifferent);
  }, [isTimezoneDifferent]);
  const timezoneMismatchComponent = useMemo(() => {
    return (
      <div className={classes.timezoneWarningContainer}>
        <ErrorIcon className={classes.warningIcon} color="warning" />
        <Typography variant="body2" color="primary">
          {`${t("timezone.title")}!`}
        </Typography>
      </div>
    );
  }, [isTimezoneDifferent]);

  return (
    <>
      <div style={{ height: 24 }}>
        <FormLabel id="demo-controlled-radio-buttons-group">
          {t("profile.dashboard.browserTimezone")}
        </FormLabel>
      </div>
      <div className={classes.textContainer}>
        <Typography color="primary">{browserTimezone}</Typography>
      </div>
      {isTimezoneDifferent && timezoneMismatchComponent}
    </>
  );
}
