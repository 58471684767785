import ICategory from "./ICategory";
import IComponent from "./IComponent";
import IVariable from "./IVariable";

export interface IResponsible {
  id: string;
  firstName: string;
  lastName: string;
}

interface ICommonServiceOrderSettings {
  name: string;
  description: string;
  preWarning: number;
  isActive: boolean;
  interval: number;
}

export interface IServiceOrderSettingsFromServer
  extends ICommonServiceOrderSettings {
  id: string;
  component: IComponent;
  category: ICategory;
  variable: IVariable;
  responsible: IResponsible;
}

export interface IServiceOrderSettingsCreate
  extends ICommonServiceOrderSettings {
  variableId: string;
  responsibleId: string;
  categoryId?: string;
  newCategoryName?: string;
  componentId?: string;
  newComponentName?: string;
}

export interface IServiceOrderSettingsUpdate
  extends IServiceOrderSettingsCreate {
  id: string;
}

export type TServiceorderSettingsCreateOrUpdate =
  | IServiceOrderSettingsUpdate
  | IServiceOrderSettingsCreate;

export function isServiceOrderSettingsUpdate(
  s: TServiceorderSettingsCreateOrUpdate,
): s is IServiceOrderSettingsUpdate {
  return (s as IServiceOrderSettingsUpdate).id != null;
}
