import React, { useLayoutEffect, useMemo } from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";
import TabWithMachineSelection from "../../../shared/components/tabsWithMachineTimePeriodSelection";
import ContactsView from "../views/contactsView";
import { setSupportStaff } from "../../../redux/reducers/serviceReducer";
import mock_staff from "../../../components/service/contactsView/api/contacts.json";
import { useDispatch } from "react-redux";
export default function Service(): React.ReactElement {
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    dispatch(setSupportStaff(mock_staff["contacts"]));
  }, []);
  const { t } = useTranslation();
  const tabConfig = useMemo(() => {
    return [{ label: t("service.contact.title"), path: "/contact" }];
  }, []);
  return (
    <div>
      <TabWithMachineSelection
        basePath={"/service/contact/machine"}
        tabConfig={tabConfig}
      >
        <ContactsView />
      </TabWithMachineSelection>
    </div>
  );
}
