import { makeStyles } from "tss-react/mui";
import { colors } from "../../../../shared/theme";

const useStyles = makeStyles()(() => ({
  button: {
    borderLeftWidth: 0.5,
    borderLeftStyle: "solid",
    borderLeftColor: colors.grey_base,
    textAlgin: "center",
    width: 160,
  },
  buttonLoginLabel: {
    marginLeft: 10,
  },
}));

export default useStyles;
