import { useEffect, useLayoutEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import generatedGitInfo from "./generatedGitInfo.json";
import useClaims from "./auth/hooks/useClaims";

// Function to initialize Sentry
export default function UseSentry() {
  const [isEnabled, setIsEnabled] = useState(false);
  const claims = useClaims();

  useLayoutEffect(() => {
    Sentry.init({
      dsn: "https://d17d1f9386685b39bce5361563d6efd5@o4507582100799488.ingest.de.sentry.io/4507604477345873",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
      ],
      environment: process.env.NODE_ENV,
      release: generatedGitInfo.gitCommitHash,
      sampleRate: process.env.NODE_ENV === "production" ? 0.2 : 0.4,
      tracesSampleRate: 0.0,
      tracePropagationTargets: [/^https:\/\/(dev\.|stage\.|)mygeorg\.com/],
      beforeBreadcrumb() {
        // Don't send the breadcrumb to Sentry
        return null;
      },
      beforeSend(event) {
        if (
          event.tags?.enabled &&
          (event.level === "fatal" ||
            event.level === "error" ||
            event.exception ||
            event.level === "warning")
        ) {
          return event;
        }
        return null;
      },
    });
    Sentry.setTag("environment", process.env.NODE_ENV || "development");
    Sentry.setTag("version", generatedGitInfo.appVersion);
    Sentry.setTag("release", generatedGitInfo.gitCommitHash);
    Sentry.setTag("container", "frontend");
    Sentry.setTag("namespace", claims?.extension_tenant ?? "-");
    Sentry.setTag("enabled", isEnabled);
  }, []);

  useEffect(() => {
    Sentry.setTag("enabled", isEnabled);
  }, [isEnabled]);

  const toggleSentry = (enabled: boolean) => {
    setIsEnabled(enabled);
  };

  return { toggleSentry };
}

// You can export other functions to interact with Sentry if needed
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function logErrorToSentry(exception: Error, hint?: any) {
  Sentry.captureException(exception, hint);
}
