import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()(() => ({
  tooltipCell: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "inherit",
  },
  fillContent: {
    height: "100%",
    width: "100%",
  },
}));

export default useStyles;
