import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
/*
  Redirects to a specific subpage.
  E.g. Given a set of pages: /home /home/news /home/report
  You can use this hook to automatically redirect to a subpage (relativePath)
  if arriving on a specific page (onPathname) e.g. /home/news
 */
export default function useDefaultPage(
  onPathname: string,
  relativePath: string,
): void {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === onPathname) {
      navigate(relativePath, { replace: true });
    }
  }, [onPathname, relativePath]);
}
