import GeorgButton from "../../../../shared/components/GeorgButton";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import JobsAdminTable from "./components/jobAdminTable";
import {
  IJobAbort,
  IJobCreateOrUpdate,
  IJobData,
  IJobFromServer,
  IJobTableFormat,
  isJobUpdate,
} from "../../../../api/jobs/interfaces/IJobSettings";
import { useSelector } from "react-redux";
import { selectMachine } from "../../../../redux/reducers/machineSelectReducer";
import { selectMachineList } from "../../../../redux/reducers/machineListReducer";
import YesNoDialog from "../../../../shared/components/yesnodialog";
import JobCRUDForm from "./components/jobCrudForm";
import {
  IChangeIsDoneCallback,
  IJobQueryVariables,
  IRemoveJobMutation,
  IUpsertJobCallback,
  useAbortJobMutation,
  useChangeActiveMutation,
  useRemoveJobMutation,
  useUpsertJobMutation,
} from "./hooks";
import { transformJobForModal } from "./transformation";
import GeorgTextBase, {
  EStyleType,
} from "../../../../shared/components/text/base";
import { EApiState } from "../../../../api";
import { calculatePriorityState } from "./priority/calculatePriorityState";
import { Toggle } from "../../../../shared/components";
import JobAbortForm from "./components/jobAbortForm";
import { createUtcTimestamp } from "../../../../services";
import { getTimeState } from "./components/jobAdminTable/helper/getRemainingTime";
import useQueryJobs from "./hooks/queries/useQueryMachinesWithJobs";
import { GridColumnVisibilityModel, GridSortModel } from "@mui/x-data-grid";
import OkDialog from "../../../../shared/components/okDialog";
import { useMediaQuery, useTheme } from "@mui/material";
import { getTimezone } from "../../../../helper/time/timezone";
import useQueryUsers from "../../../settings/user/userManagement/hooks/useQueryUser/useQueryUsers";

enum EViewMode {
  TABLE = "TABLE",
  REMOVE_DIALOG = "REMOVE_DIALOG",
  ABORT_DIALOG = "ABORT_DIALOG",
  DONE_DIALOG = "DONE_DIALOG",
  EDIT_CREATE_MODAL = "EDIT_CREATE_MODAL",
  OLDVID_DIALOG = "OLDVID_DIALOG",
  MOBILE_PORTRAIT_DIALOG = "MOBILE_PORTRAIT_DIALOG",
}

function findById(orders: IJobFromServer[], id: string): IJobFromServer | null {
  return orders.find((o) => o.jobId === id) || null;
}

function getWidthClassByTableSize(isAllMachinesSelected: boolean, classes) {
  return isAllMachinesSelected ? classes.boxExpanded : classes.boxCompact;
}

const initializeTargetJob = (): IJobData => {
  return {
    jobId: undefined,
    vid: undefined,
  };
};

// eslint-disable-next-line complexity
export default function JobManagement(): React.ReactElement {
  const selectedMachineId = useSelector(selectMachine);
  const machines = useSelector(selectMachineList);

  const timezone = getTimezone();
  const [jobsForTable, setJobsForTable] = useState<IJobTableFormat[]>([]);
  const [viewMode, setViewMode] = useState<EViewMode>(EViewMode.TABLE);
  const [targetJob, setTargetJob] = useState<IJobData>(initializeTargetJob());
  const [jobDoneState, setJobDoneState] = useState<boolean>(false);
  const [jobAbortState, setJobAbortState] = useState<boolean>(false);
  const [jobToEdit, setJobToEdit] = useState<IJobCreateOrUpdate>(
    transformJobForModal(null, parseInt(selectedMachineId, 10)),
  );
  const [isAllMachinesSelected, setIsAllMachinesSelected] = useState(false);
  const [isLastDeletedJobsSelected, setIsLastDeletedJobsSelected] =
    useState(false);
  const [isLastAbortedJobsSelected, setIsLastAbortedJobsSelected] =
    useState(false);
  const [isLastDoneJobsSelected, setIsLastDoneJobsSelected] = useState(false);
  const [queryVariables, setQueryVariables] = useState<IJobQueryVariables>({
    deleted: false,
    aborted: false,
    done: false,
  });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "jobCriticalState", sort: "asc" },
  ]); // state for sorting column and direction
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({}); // state for column visibility
  const { classes } = useStyles();
  const { t } = useTranslation();

  // Resolution and orientation check
  const { breakpoints } = useTheme();
  const isWidthBelowMD = useMediaQuery(breakpoints.down("md"), { noSsr: true });
  const isPortrait = useMediaQuery("(orientation: portrait)", { noSsr: true });

  const IsMobileAndLandscape = useMemo(
    () => isWidthBelowMD && !isPortrait,
    [isWidthBelowMD, isPortrait],
  );

  useEffect(() => {
    setQueryVariables({
      deleted: isLastDeletedJobsSelected,
      aborted: isLastAbortedJobsSelected,
      done: isLastDoneJobsSelected,
      machineTypeId: isAllMachinesSelected
        ? undefined
        : parseInt(selectedMachineId, 10),
    });
  }, [
    selectedMachineId,
    isAllMachinesSelected,
    isLastDeletedJobsSelected,
    isLastAbortedJobsSelected,
    isLastDoneJobsSelected,
  ]);

  const { state: queryUsersState, data: usersData } = useQueryUsers(false);
  const { apiState, Jobs, refreshData } = useQueryJobs(queryVariables);
  const {
    changeIsActiveMutation,
    changeActivateError,
    apiState: changeIsActiveState,
  } = useChangeActiveMutation(jobDoneState);
  const {
    upsertJobMutation,
    upsertError,
    apiState: upsertJobMutationState,
  } = useUpsertJobMutation(!jobToEdit?.jobId);
  const {
    abortJobMutation,
    abortError,
    apiState: abortServiceState,
  } = useAbortJobMutation(jobAbortState);
  const {
    removeJobMutation: removeJobMutation,
    deleteError,
    apiState: removeServiceState,
  } = useRemoveJobMutation();

  const allMutationStates = [
    upsertJobMutationState,
    changeIsActiveState,
    removeServiceState,
    abortServiceState,
  ];

  const hasMutationError = allMutationStates.some((s) => s === EApiState.ERROR);
  const isMutationLoading = allMutationStates.some(
    (s) => s === EApiState.LOADING,
  );

  // This boolean determines if a Mutation sends an old vid to the backend
  const hasVIdMutationError = () => {
    const errorMessages = [
      upsertError,
      changeActivateError,
      deleteError,
      abortError,
    ];
    return errorMessages.some(
      (errorMessage) =>
        errorMessage && errorMessage.includes("Error: vid failure"),
    );
  };

  useEffect(() => {
    if (hasVIdMutationError()) {
      setViewMode(EViewMode.OLDVID_DIALOG);
    }
  }, [upsertError, changeActivateError, deleteError, abortError]);

  const queryStates = [apiState, queryUsersState];
  const isLoading = queryStates.some((s) => s === EApiState.LOADING);
  const hasError = queryStates.some((s) => s === EApiState.ERROR);
  const isOK = queryStates.every((s) => s === EApiState.OK);

  const preData: IJobTableFormat[] = useMemo(() => {
    if (isOK) {
      const result = Jobs.map((job) => {
        const name =
          machines.find((m) => m.id === job.machineType.id)
            ?.deviceLabelExtension || "unknown machine";
        const jobObject: IJobTableFormat = {
          ...job,
          machineType: { id: job.machineType.id, name: name },
          priorityState: calculatePriorityState(job.priority),
        };
        if (
          !jobObject.doneAt &&
          !jobObject.abortedAt &&
          !jobObject.deletedAt &&
          jobObject.scheduledAt
        ) {
          jobObject.jobCriticalState = getTimeState(
            new Date(jobObject.scheduledAt).getTime(),
            t,
          );
        }
        return jobObject;
      });
      return result;
    } else {
      return [];
    }
  }, [isOK, Jobs, machines]);

  useEffect(() => {
    setJobsForTable(preData);
    const interval = setInterval(() => {
      setJobsForTable(() => {
        const updatedJobs = preData.map((job) => {
          if (
            !job.doneAt &&
            !job.abortedAt &&
            !job.deletedAt &&
            job.scheduledAt
          ) {
            return {
              ...job,
              jobCriticalState: getTimeState(
                new Date(job.scheduledAt).getTime(),
                t,
              ),
            };
          }
          return job;
        });
        return updatedJobs;
      });
    }, 5000);
    return () => clearInterval(interval);
  }, [preData]);

  const descriptionForDeletionDialog = useMemo(() => {
    if (targetJob.jobId != null) {
      const found = findById(jobsForTable, targetJob.jobId);
      if (found != null) {
        return found.name;
      }
    }
  }, [targetJob, Jobs]);

  const onChangeDoneCallback = useCallback(
    (jobData: IJobData) => {
      if (!jobData.jobId || !jobData.vid) {
        // eslint-disable-next-line no-console
        console.error(
          `Illegal State: Tried to remove job without id or without state`,
        );
        return;
      }
      const changeActiveJobParams: IChangeIsDoneCallback = {
        fetchVariables: queryVariables,
        jobId: jobData.jobId,
        vid: jobData.vid,
      };
      changeIsActiveMutation(changeActiveJobParams);
      setTargetJob(initializeTargetJob());
    },
    [changeIsActiveMutation, jobDoneState, targetJob],
  );

  const classByTableSize = getWidthClassByTableSize(
    isAllMachinesSelected,
    classes,
  );

  const onEditCallback = useCallback(
    (jobId: string) => {
      if (IsMobileAndLandscape) {
        setViewMode(EViewMode.MOBILE_PORTRAIT_DIALOG);
        return;
      }
      const found = findById(jobsForTable, jobId);
      setJobToEdit(
        transformJobForModal(found, parseInt(selectedMachineId, 10)),
      );
      setViewMode(EViewMode.EDIT_CREATE_MODAL);
    },
    [findById, jobsForTable, transformJobForModal, IsMobileAndLandscape],
  );

  const onUpsertJobCallback = useCallback(
    (upsertJob: IJobCreateOrUpdate) => {
      const upsertJobParams: IUpsertJobCallback = {
        fetchVariables: queryVariables,
        jobInput: upsertJob,
      };
      if (
        !upsertJob.machineId ||
        upsertJob.machineId < 0 ||
        (isJobUpdate(upsertJob) && (!upsertJob.jobId || !upsertJob.vid)) ||
        (!isJobUpdate(upsertJob) && !upsertJob.createdBy)
      ) {
        // eslint-disable-next-line no-console
        console.error(`Illegal State: Tried to add or update a job without id`);
        return;
      }
      upsertJobMutation(upsertJobParams);
    },
    [queryVariables, isJobUpdate, jobToEdit],
  );

  const onAbortCallback = useCallback(
    (
      jobData: IJobData,
      abortMessage: string | undefined,
      abortState: boolean,
    ) => {
      if (!jobData.jobId || (abortState && !abortMessage) || !jobData.vid) {
        // eslint-disable-next-line no-console
        console.error(
          `Illegal State: Tried to abort job without id or without abort Message`,
        );
        return;
      }
      const abortJobObj: IJobAbort = {
        jobId: jobData.jobId,
        abortState: abortState,
        abortMessage: abortState ? abortMessage : undefined,
        abortedAt: createUtcTimestamp(true, "now", timezone),
        vid: jobData.vid,
      };
      abortJobMutation(abortJobObj, queryVariables);
      setTargetJob(initializeTargetJob());
    },
    [abortJobMutation, targetJob],
  );

  const onDeleteCallback = useCallback(
    (jobData: IJobData) => {
      if (!jobData.jobId || !jobData.vid) {
        // eslint-disable-next-line no-console
        console.error(`Illegal State: Tried to remove job without id`);
        return;
      }
      const removeJobParams: IRemoveJobMutation = {
        fetchVariables: queryVariables,
        jobDelete: jobData,
      };
      removeJobMutation(removeJobParams);
      setTargetJob(initializeTargetJob());
    },
    [removeJobMutation, targetJob],
  );

  const handleSortChange = useCallback(
    (newSortModel: GridSortModel) => {
      setSortModel(newSortModel);
    },
    [sortModel],
  );

  const handleColumnVisibilityChange = useCallback(
    (newColumnVisibilityModel: GridColumnVisibilityModel) => {
      setColumnVisibilityModel(newColumnVisibilityModel);
    },
    [columnVisibilityModel],
  );

  useEffect(() => {
    setColumnVisibilityModel({
      ...columnVisibilityModel,
      machineName: isAllMachinesSelected,
    });
  }, [isAllMachinesSelected]);

  const pointerEvents = isLoading ? "none" : "auto";
  const opacity = isLoading ? 0.3 : 1;

  return (
    <div className={`${classes.box} ${classByTableSize} `}>
      {hasError && (
        <GeorgTextBase
          type={EStyleType.DARK}
          text={t("taskManager.jobManagement.loadingError")}
        />
      )}
      {hasMutationError && (
        <GeorgTextBase
          type={EStyleType.DARK}
          text={t("taskManager.jobManagement.mutationError")}
        />
      )}
      {viewMode === EViewMode.OLDVID_DIALOG && (
        <OkDialog
          isModalVisible={viewMode === EViewMode.OLDVID_DIALOG}
          title={t("taskManager.jobManagement.oldVIdDialog.title")}
          description={t("taskManager.jobManagement.oldVIdDialog.description")}
          onSubmit={() => {
            refreshData();
            setViewMode(EViewMode.TABLE);
          }}
        />
      )}
      {viewMode === EViewMode.MOBILE_PORTRAIT_DIALOG && (
        <OkDialog
          isModalVisible={viewMode === EViewMode.MOBILE_PORTRAIT_DIALOG}
          title={t("taskManager.jobManagement.MobilePortraitDialog.title")}
          description={t(
            "taskManager.jobManagement.MobilePortraitDialog.description",
          )}
          onSubmit={() => {
            setViewMode(EViewMode.TABLE);
          }}
        />
      )}
      {(isOK || isLoading) && (
        <>
          <div>
            <div className={classes.titleWrapper}>
              <GeorgButton
                label={t("taskManager.jobManagement.addBtn")}
                onClick={() => {
                  if (IsMobileAndLandscape) {
                    setViewMode(EViewMode.MOBILE_PORTRAIT_DIALOG);
                    return;
                  }
                  setJobToEdit(
                    transformJobForModal(null, parseInt(selectedMachineId, 10)),
                  );
                  setViewMode(EViewMode.EDIT_CREATE_MODAL);
                }}
                disabled={isLoading}
              />
            </div>
            <div
              style={{
                display: "flex",
                pointerEvents,
                opacity,
              }}
            >
              <div className={classes.titleWrapper}>
                <Toggle
                  isChecked={isAllMachinesSelected}
                  onChange={() => {
                    setIsAllMachinesSelected(!isAllMachinesSelected);
                  }}
                />
                <GeorgTextBase
                  type={EStyleType.DARK}
                  text={t("taskManager.jobManagement.allMachinesButton")}
                />
              </div>
              <div
                className={classes.titleWrapper}
                style={{ marginLeft: "50px" }}
              >
                <Toggle
                  isChecked={isLastDeletedJobsSelected}
                  onChange={() => {
                    setIsLastDeletedJobsSelected(!isLastDeletedJobsSelected);
                  }}
                />
                <GeorgTextBase
                  type={EStyleType.DARK}
                  text={t("taskManager.jobManagement.lastDeletedJobs")}
                />
              </div>
              <div
                className={classes.titleWrapper}
                style={{ marginLeft: "50px" }}
              >
                <Toggle
                  isChecked={isLastAbortedJobsSelected}
                  onChange={() => {
                    setIsLastAbortedJobsSelected(!isLastAbortedJobsSelected);
                  }}
                />
                <GeorgTextBase
                  type={EStyleType.DARK}
                  text={t("taskManager.jobManagement.lastAbortedJobs")}
                />
              </div>
              <div
                className={classes.titleWrapper}
                style={{ marginLeft: "50px" }}
              >
                <Toggle
                  isChecked={isLastDoneJobsSelected}
                  onChange={() => {
                    setIsLastDoneJobsSelected(!isLastDoneJobsSelected);
                  }}
                />
                <GeorgTextBase
                  type={EStyleType.DARK}
                  text={t("taskManager.jobManagement.lastDoneJobs")}
                />
              </div>
            </div>
            <JobsAdminTable
              isMutationLoading={isMutationLoading}
              jobs={jobsForTable}
              onRemoveClick={(jobId: string) => {
                const found = findById(jobsForTable, jobId);
                if (!found || !found.vid) {
                  // eslint-disable-next-line no-console
                  console.error(
                    `Illegal State: Tried to remove job without id or without stete`,
                  );
                  return;
                }
                setTargetJob({ jobId: jobId, vid: found.vid });
                setViewMode(EViewMode.REMOVE_DIALOG);
              }}
              onChangeDone={(jobId: string, newIsDone: boolean) => {
                const found = findById(jobsForTable, jobId);
                if (!found || !found.vid) {
                  // eslint-disable-next-line no-console
                  console.error(
                    `Illegal State: Tried to change job done state without id or without stete`,
                  );
                  return;
                }
                setTargetJob({ jobId: jobId, vid: found.vid });
                setJobDoneState(newIsDone);
                setViewMode(EViewMode.DONE_DIALOG);
              }}
              onEdit={onEditCallback}
              onAbort={(jobId: string, newIsAbort: boolean) => {
                const found = findById(jobsForTable, jobId);
                if (found && found.vid) {
                  setTargetJob({ jobId: jobId, vid: found.vid });
                  setJobAbortState(newIsAbort);
                  setViewMode(EViewMode.ABORT_DIALOG);
                }
              }}
              onSortChange={handleSortChange}
              sortModel={sortModel}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={handleColumnVisibilityChange}
              isLoading={isLoading}
            />
          </div>
          {viewMode === EViewMode.REMOVE_DIALOG && (
            <YesNoDialog
              isModalVisible={viewMode === EViewMode.REMOVE_DIALOG}
              onClose={() => {
                setViewMode(EViewMode.TABLE);
                setTargetJob(initializeTargetJob());
              }}
              title={t("taskManager.jobManagement.removeDialog.title")}
              question={`${t(
                "taskManager.jobManagement.removeDialog.question",
              )} 
                '${descriptionForDeletionDialog || "unknown"}'?`}
              onCancel={() => {
                setViewMode(EViewMode.TABLE);
                setTargetJob(initializeTargetJob());
              }}
              onSubmit={() => {
                onDeleteCallback(targetJob);
                setTargetJob(initializeTargetJob());
              }}
            />
          )}
          {viewMode === EViewMode.ABORT_DIALOG && (
            <JobAbortForm
              isModalVisible={viewMode === EViewMode.ABORT_DIALOG}
              title={t(
                `taskManager.jobManagement.abortDialog.title.${
                  jobAbortState ? "abort" : "undoAbort"
                }`,
              )}
              question={`${
                jobAbortState
                  ? t("taskManager.jobManagement.abortDialog.question.abort")
                  : t(
                      "taskManager.jobManagement.abortDialog.question.undoAbort",
                    )
              } '${descriptionForDeletionDialog || "unknown"}'?`}
              toBeAborted={jobAbortState}
              onClose={() => {
                setViewMode(EViewMode.TABLE);
                setTargetJob(initializeTargetJob());
              }}
              onCancel={() => {
                setViewMode(EViewMode.TABLE);
                setTargetJob(initializeTargetJob());
              }}
              onSubmit={(reason) => {
                onAbortCallback(targetJob, reason, jobAbortState);
                setViewMode(EViewMode.TABLE);
                setTargetJob(initializeTargetJob());
              }}
            />
          )}
          {viewMode === EViewMode.DONE_DIALOG && (
            <YesNoDialog
              isModalVisible={viewMode === EViewMode.DONE_DIALOG}
              onClose={() => {
                setViewMode(EViewMode.TABLE);
                setTargetJob(initializeTargetJob());
              }}
              title={t("taskManager.jobManagement.doneDialog.title")}
              question={`${
                jobDoneState
                  ? t("taskManager.jobManagement.doneDialog.doneQuestion")
                  : t("taskManager.jobManagement.doneDialog.undoneQuestion")
              } '${descriptionForDeletionDialog || "unknown"}'?`}
              onCancel={() => {
                setViewMode(EViewMode.TABLE);
                setTargetJob(initializeTargetJob());
              }}
              onSubmit={() => {
                setViewMode(EViewMode.TABLE);
                setTargetJob(initializeTargetJob());
                onChangeDoneCallback(targetJob);
              }}
            />
          )}
          {viewMode === EViewMode.EDIT_CREATE_MODAL && (
            <JobCRUDForm
              isModalVisible
              onClose={() => {
                setViewMode(EViewMode.TABLE);
                setJobToEdit(
                  transformJobForModal(null, parseInt(selectedMachineId, 10)),
                );
              }}
              jobToEdit={jobToEdit}
              onSubmit={(crudJob) => {
                onUpsertJobCallback(crudJob);
                setViewMode(EViewMode.TABLE);
                setJobToEdit(
                  transformJobForModal(null, parseInt(selectedMachineId, 10)),
                );
              }}
              users={usersData}
            />
          )}
        </>
      )}
    </div>
  );
}
