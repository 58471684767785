import React, { useEffect, useMemo, useState } from "react";
import GeorgStorageContext from "./GeorgStorageContext";
import useTooltipSettings from "../helper/tooltip/useTooltipSettings";
import usePreviewSettings from "../helper/preview/usePreviewSettings";
import useLanguage from "../helper/language/useLanguage";
import useTimezone from "../helper/time/useTimezone";
import useScreenModeSettings from "../helper/screenMode/useScreenModeSettings";
import useMachineViewSettings from "../helper/machineView/useMachineViewSettings";
import { IFilterSettings, ISort } from "../components/views/machineryView";
import useCurrentReferenceTimeSettings from "../helper/currentReferenceTime/useCurrentReferenceTimeSettings";
import {
  selectTimeSelection,
  setTimeSelection,
} from "../redux/reducers/timeSelectReducer";
import { useSelector } from "react-redux";
import { store } from "../redux/store";

interface IProps {
  children: React.ReactElement;
}

export default function GeorgStorageProvider({
  children,
}: IProps): React.ReactElement {
  const {
    language: languageLS,
    setLanguage: setLanguageLS,
    isLoading: isLanguageLoading,
  } = useLanguage();
  const {
    timezone: timezoneLS,
    setTimezone: setTimezoneLS,
    ignoreTimezone: ignoreTimezoneLS,
    setIgnoreTimezone: setIgnoreTimezoneLS,
    isLoading: isTimezoneLoading,
  } = useTimezone();
  const {
    isTooltipsEnabled: isTooltipsEnabledLS,
    setTooltipEnabled: setTooltipEnabledLS,
  } = useTooltipSettings();
  const {
    isPreviewEnabled: isPreviewEnabledLS,
    setPreviewEnabled: setPreviewEnabledLS,
  } = usePreviewSettings();
  const {
    isFullscreenEnabled: isFullscreenEnabledLS,
    setFullscreenEnabled: setFullscreenEnabledLS,
    isDesktopEnabled: isDesktopEnabledLS,
    setDesktopEnabled: setDesktopEnabledLS,
  } = useScreenModeSettings();
  const {
    machineViewSort: machineViewSortLS,
    setMachineViewSort: setMachineViewSortLS,
    machineViewFilter: machineViewFilterLS,
    setMachineViewFilter: setMachineViewFilterLS,
  } = useMachineViewSettings();
  const {
    currentTimeFilter: currentTimeFilterLS,
    setCurrentTimeFilter: setCurrentTimeFilterLS,
    referenceTimeFilter: referenceTimeFilterLS,
    setReferenceTimeFilter: setReferenceTimeFilterLS,
  } = useCurrentReferenceTimeSettings();

  const [language, setLanguage] = useState(languageLS);
  const [timezone, setTimezone] = useState(timezoneLS);

  useEffect(() => {
    setLanguage(languageLS);
  }, [languageLS]);

  useEffect(() => {
    setTimezone(timezoneLS);
  }, [timezoneLS]);

  // current and reference time filter are now:
  // 1. loaded from local storage to redux-store by app start.
  // 2. saved to local storage every time it changes from redux-store.
  // thus no need to include set/get in context anymore.

  // load from local storage to redux-store by app start.
  useEffect(() => {
    store.dispatch(
      setTimeSelection({
        currentFilter: currentTimeFilterLS,
        referenceFilter: referenceTimeFilterLS,
      }),
    );
  }, []);

  // save to local storage every time it changes from redux-store
  const TimeFilterFromRedux = useSelector(selectTimeSelection);
  useEffect(() => {
    setCurrentTimeFilterLS(TimeFilterFromRedux.currentFilter);
    setReferenceTimeFilterLS(TimeFilterFromRedux.referenceFilter);
  }, [TimeFilterFromRedux]);

  const context = useMemo(() => {
    return {
      language,
      setLanguage: (newLang: string) => {
        setLanguage(newLang);
        setLanguageLS(newLang);
      },
      timezone,
      setTimezone: (newZone: string) => {
        setTimezone(newZone);
        setTimezoneLS(newZone);
      },
      ignoreTimezone: ignoreTimezoneLS,
      setIgnoreTimezone: (newValue: boolean) => {
        setIgnoreTimezoneLS(newValue);
      },
      setTooltipEnabled: (newIsTooltipsEnabled: boolean) => {
        setTooltipEnabledLS(newIsTooltipsEnabled);
      },
      setPreviewEnabled: (newIsPreviewEnabled: boolean) => {
        setPreviewEnabledLS(newIsPreviewEnabled);
      },
      setFullscreenEnabled: (newIsFullscreenEnabled: boolean) => {
        setFullscreenEnabledLS(newIsFullscreenEnabled);
      },
      setDesktopEnabled: (newIsDesktopEnabled: boolean) => {
        setDesktopEnabledLS(newIsDesktopEnabled);
      },
      setMachineViewSort: (newMachineViewSort: ISort) => {
        setMachineViewSortLS(newMachineViewSort);
      },
      setMachineViewFilter: (newMachineViewFilter: IFilterSettings) => {
        setMachineViewFilterLS(newMachineViewFilter);
      },
      isLanguageLoading,
      isTimezoneLoading,
      isTooltipEnabled: isTooltipsEnabledLS,
      isPreviewEnabled: isPreviewEnabledLS,
      isFullscreenEnabled: isFullscreenEnabledLS,
      isDesktopEnabled: isDesktopEnabledLS,
      machineViewSort: machineViewSortLS,
      machineViewFilter: machineViewFilterLS,
    };
  }, [
    language,
    isLanguageLoading,
    timezone,
    isTimezoneLoading,
    isTooltipsEnabledLS,
    isPreviewEnabledLS,
    isFullscreenEnabledLS,
    isDesktopEnabledLS,
    machineViewFilterLS,
    machineViewSortLS,
  ]);

  return (
    <GeorgStorageContext.Provider value={context}>
      {children}
    </GeorgStorageContext.Provider>
  );
}
