/*
 *   Service for displaying the real time inside the timeBar component.
 */
interface IDateTime {
  date: string[];
  time: string[];
}

// TODO: Deprecated function. Remove in future.
export default function getDateTime(timezone: string): IDateTime {
  const padZero = (value: number | string): string =>
    value.toString().padStart(2, "0");

  const localTime = new Date().toLocaleString("en-US", {
    timeZone: timezone,
    hour12: false,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  // Extract date and time components
  const [datePart, timePart] = localTime.split(", ");

  // Format date
  const [month, day, year] = datePart.split("/");
  const date = [padZero(year), padZero(month), padZero(day)];

  // Format time
  const time = timePart.split(":").map((part) => padZero(Number(part)));

  return { date, time };
}

export function getDateString(timezone: string): string {
  const padZero = (value: number | string): string =>
    value.toString().padStart(2, "0");

  const localDate = new Date().toLocaleString("en-US", {
    timeZone: timezone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  // Format date
  const [month, day, year] = localDate.split("/");

  return `${padZero(year)}-${padZero(month)}-${padZero(day)}`;
}

export function getTimeString(timezone: string): string {
  const localTime = new Date().toLocaleString("en-US", {
    timeZone: timezone,
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return localTime;
}
