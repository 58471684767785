import { useLazyQuery } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import useInterval from "../../../../../../../helper/time/interval/useInterval";
import { TIndicatorName } from "../../../../../../../config/states/STATE_COLOR_MAP";
import IGetCurrentIndicatorStateListQuery from "./IGetCurrentIndicatorStateListQuery";
import indicatorIdToIndicatorType from "../../../../../../../helper/indicator/indicatorIdToIndicatorType";
import { TIndicatorType } from "../../../../../../../helper";
import indicatorNamesToIndicatorIds from "../../../../../../../helper/indicator/indicatorNamesToIndicatorIds";
import { EApiState } from "../../../../../../../api/state/EApiState";
import moment from "moment";

export function getIndicatorIds(
  allIndicators: TIndicatorType[],
  indicatorNames: TIndicatorName[],
): number[] {
  if (allIndicators.length > 0) {
    const IndicatorIds = indicatorNamesToIndicatorIds(
      indicatorNames,
      allIndicators,
    );
    return IndicatorIds.map((value: string) => parseInt(value, 10));
  }
  return [];
}

interface IRowCurrentIndicatorState {
  indicatorIdentificationTypeId: number;
  status: boolean;
  timestampStart: string;
}

interface ICurrentIndicatorState extends IRowCurrentIndicatorState {
  indicatorIdentificationTypeName: TIndicatorName;
}

interface ICurrentIndicatorStateListResponse {
  getCurrentIndicatorStateList: IRowCurrentIndicatorState[];
}

interface ICurrentIndicatorStateListResult {
  apiState: EApiState;
  indicatorStateSince: IIndicatorStateSince;
}

function compareTimestamps(
  a: ICurrentIndicatorState,
  b: ICurrentIndicatorState,
) {
  if (a.timestampStart > b.timestampStart) {
    return -1;
  }
  if (a.timestampStart < b.timestampStart) {
    return 1;
  }
  // a must be equal to b
  return 0;
}
export interface IIndicatorStateSince {
  indicatorState: TIndicatorName;
  stateStartedSince?: Date;
}
function extractMostImportantCurrentState(
  indicatorStates: ICurrentIndicatorState[] = [],
  defaultState: IIndicatorStateSince = {
    indicatorState: "indicator_state_undefined",
  },
): IIndicatorStateSince {
  const sortedList = indicatorStates.sort((a, b) => compareTimestamps(a, b));
  const foundIndicatorState: ICurrentIndicatorState | undefined =
    sortedList?.at(0);
  const secondIndicatorState = sortedList?.at(1);

  if (foundIndicatorState) {
    let indicatorState: TIndicatorName =
      foundIndicatorState.indicatorIdentificationTypeName as TIndicatorName;

    //Idle_Long
    //Change indicator_state_idle 2 indicator_state_idle_long if stateStartedsince greater 30 min.
    const duration = moment.duration(
      moment().diff(foundIndicatorState.timestampStart),
    );
    const stateStartedSinceinSeconds = duration.asSeconds();
    if (
      indicatorState === ("indicator_state_idle" as TIndicatorName) &&
      stateStartedSinceinSeconds > 1800
    ) {
      indicatorState = "indicator_state_idle_long";
    }
    //Idle_Long
    if (
      indicatorState === ("indicator_state_available" as TIndicatorName) &&
      foundIndicatorState.status === false
    ) {
      return {
        indicatorState: "indicator_state_undefined" as TIndicatorName,
        stateStartedSince: new Date(foundIndicatorState.timestampStart),
      };
    } else if (
      indicatorState === ("indicator_state_available" as TIndicatorName) &&
      foundIndicatorState.status === true
    ) {
      if (secondIndicatorState) {
        return {
          indicatorState: secondIndicatorState.indicatorIdentificationTypeName,
          stateStartedSince: new Date(secondIndicatorState.timestampStart),
        };
      }
    }
    //workaround
    return {
      indicatorState: indicatorState,
      stateStartedSince: new Date(foundIndicatorState.timestampStart),
    };
  } else {
    return defaultState;
  }
}

export function isPayloadValid(
  apiState: EApiState,
  payload: IIndicatorStateSince | undefined,
): payload is IIndicatorStateSince {
  return apiState === EApiState.OK && payload != null;
}

export default function useCurrentIndicatorStateList(
  machineTypeId: number,
  allIndicators: TIndicatorType[],
  // TODO: Remove comment when Backend is ready
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  timezone: string,
): ICurrentIndicatorStateListResult {
  const [apiState, setApiState] = useState<EApiState>(EApiState.EMPTY);
  const indicatorIdentificationTypeIds = getIndicatorIds(allIndicators, [
    "indicator_state_undefined",
    "indicator_state_executing",
    "indicator_state_idle",
    "indicator_state_notavailable",
  ]);
  const [doQuery, { data, loading: isLoading, error }] =
    useLazyQuery<ICurrentIndicatorStateListResponse>(
      IGetCurrentIndicatorStateListQuery,
      {
        fetchPolicy: "no-cache",
        variables: {
          machineTypeId,
          indicatorIdentificationTypeIds,
          // TODO: change to timezone when Backend is ready
          timezone: "Europe/Berlin",
        },
      },
    );
  useEffect(() => {
    doQuery();
  }, []);
  useInterval(() => {
    doQuery();
  }, 20 * 1000);

  const payload: IIndicatorStateSince = useMemo<IIndicatorStateSince>(() => {
    const indicatorStateList: ICurrentIndicatorState[] | undefined =
      data?.getCurrentIndicatorStateList.map((rowItem) => {
        const indicatorIdentificationType = indicatorIdToIndicatorType(
          allIndicators,
          rowItem.indicatorIdentificationTypeId.toString(),
        );
        if (indicatorIdentificationType == null) {
          throw new Error(
            `Requested type of indicator id ${
              rowItem.indicatorIdentificationTypeId
            }. But it could not be found in ${JSON.stringify(allIndicators)}`,
          );
        }
        return {
          ...rowItem,
          indicatorIdentificationTypeName:
            indicatorIdentificationType.indicatorName,
        } as ICurrentIndicatorState;
      }) as ICurrentIndicatorState[] | undefined;

    return extractMostImportantCurrentState(indicatorStateList);
  }, [data, isLoading, error]);
  const hasError = error != null;
  useEffect(() => {
    if (
      data != null &&
      data.getCurrentIndicatorStateList != null &&
      !isLoading &&
      !hasError
    ) {
      setApiState(EApiState.OK);
    } else if (hasError) {
      setApiState(EApiState.ERROR);
    } else if (isLoading) {
      setApiState(EApiState.LOADING);
    }
  }, [data, isLoading, hasError]);

  return {
    apiState: apiState,
    indicatorStateSince: payload,
  };
}
