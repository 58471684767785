import React from "react";
import { Typography } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { Variant } from "@mui/material/styles/createTypography";
import { TypographyPropsVariantOverrides } from "@mui/material/Typography/Typography";

type Align = "left" | "right" | "inherit" | "center" | "justify" | undefined;

type Colors =
  | "inherit"
  | "initial"
  | "primary"
  | "secondary"
  | "textPrimary"
  | "textSecondary"
  | "error"
  | undefined;

type StyleObject = {
  headline?: string;
  tagline?: string;
};

interface IProps {
  align?: Align;
  headline?: string | null;
  tagline?: string | null;
  headlineColor?: Colors;
  headlineVariant?: OverridableStringUnion<
    Variant | "inherit",
    TypographyPropsVariantOverrides
  >;
  taglineColor?: Colors;
  taglineVariant?: OverridableStringUnion<
    Variant | "inherit",
    TypographyPropsVariantOverrides
  >;
  styles?: StyleObject;
}

//------------------------------------------------------------------------------------------------------------
// Component.
export default function IHeader({
  align,
  headline,
  headlineColor,
  headlineVariant,
  tagline,
  taglineVariant,
  styles = { headline: "", tagline: "" },
}: IProps): React.ReactElement {
  return (
    <>
      <Typography
        align={align}
        color={headlineColor}
        className={styles.headline}
        variant={headlineVariant}
      >
        {headline}
      </Typography>
      <Typography
        align={align}
        color={"textPrimary"}
        className={styles.tagline}
        variant={taglineVariant}
      >
        {tagline}
      </Typography>
    </>
  );
}
