/*
 *   React router component which handels the navigation.
 */

import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  Home,
  Machine,
  Machinery,
  NotFound,
  MachineSettings,
  Documentation,
  ServiceParts,
  Contact,
  Shopfloor,
  Profile,
} from "../../pages";
import UserSettings from "../../pages/settings/user";
import VersionModal from "../../pages/version";
import ManualStateWithMachineSelection from "../../pages/manualstate";
import AppPage from "../../pages/app";
import TaskManagerWithMachineSelection from "../../pages/taskmanager";

// Refactor this to a separate file
export const NotFoundRoute = (
  <Route key="not-found" path="*" element={<NotFound />} />
);

export default function Router(): React.ReactElement {
  return (
    <Routes>
      <Route path="/version" element={<VersionModal />} />
      <Route path="/dashboards/shopfloor" element={<Shopfloor />} />
      <Route path="/dashboards/machine/*" element={<Machine />} />
      <Route path="/dashboards/machine/:machineId?/*" element={<Machine />} />
      <Route path="/machinery" element={<Machinery />} />
      <Route path="/profile/*" element={<Profile />} />
      <Route path="/settings/user" element={<UserSettings />} />
      <Route
        path="/manualstate/machine/:machineId?/*"
        element={<ManualStateWithMachineSelection />}
      />
      <Route
        path="/manualstate/machine/*"
        element={<ManualStateWithMachineSelection />}
      />
      <Route
        path="/taskmanager/machine/:machineId?/*"
        element={<TaskManagerWithMachineSelection />}
      />
      <Route
        path="/taskmanager"
        element={<TaskManagerWithMachineSelection />}
      />
      <Route
        path="/service/documentation/machine/*"
        element={<Documentation />}
      />
      <Route
        path="/service/documentation/machine/*"
        element={<Documentation />}
      />
      <Route
        path="/service/documentation/machine/:machineId?/*"
        element={<Documentation />}
      />
      <Route path="/service/*" element={<ServiceParts />} />
      <Route path="/service/contact/machine/*" element={<Contact />} />
      <Route
        path="/service/contact/machine/:machineId?/*"
        element={<Contact />}
      />
      <Route path="/settings/machine/*" element={<MachineSettings />} />
      <Route
        path="/settings/machine/:machineId?/*"
        element={<MachineSettings />}
      />
      <Route path="/*" element={<Home />} />
      <Route path="/app/*" element={<AppPage />} />
      <Route path="/" element={<Navigate to="home" />} />
      {NotFoundRoute}
    </Routes>
  );
}
