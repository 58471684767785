/*
 *   Reducer for storing the currentFilter and referenceFilter for querying data on machine dashboard.
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const SW_INIT = "SW_INIT";
export const SW_UPDATE = "SW_UPDATE";

export interface IServiceWorkerTokenState {
  type: typeof SW_INIT | typeof SW_UPDATE;
  payload: ServiceWorkerRegistration | null;
}

const initialState: IServiceWorkerTokenState = {
  type: SW_INIT,
  payload: null,
};

export const serviceWorkerTokenSlice = createSlice({
  name: "serviveWorkerToken",
  initialState,
  reducers: {
    setServiceWorkerToken: (
      state,
      action: PayloadAction<IServiceWorkerTokenState>,
    ) => {
      switch (action.payload.type) {
        case SW_INIT:
          state.type = SW_INIT;
          state.payload = null;
          break;
        case SW_UPDATE:
          state.type = SW_UPDATE;
          state.payload = action.payload.payload;
          break;
        default:
          break;
      }
    },
  },
});

export const serviceWorkerUpdated = (state: RootState): boolean =>
  state.serviceWorkerToken.type === SW_UPDATE;
export const serviceWorkerRegistrationState = (
  state: RootState,
): ServiceWorkerRegistration | null => state.serviceWorkerToken.payload;

export const { setServiceWorkerToken } = serviceWorkerTokenSlice.actions;

export default serviceWorkerTokenSlice.reducer;
