import { makeStyles } from "tss-react/mui";
import colors from "../../../../theme/colors";
const useStyles = makeStyles()(() => ({
  warning: { backgroundColor: colors.cell_state_yellow },
  danger: { backgroundColor: colors.cell_state_red },
  ok: { backgroundColor: colors.cell_state_green },
  undefined: { backgroundColor: colors.cell_state_white },
  stateCell: {
    width: "100%",
    height: "100%",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  pulse: {
    WebkitAnimation: "pulse 3s linear infinite",
  },
}));

export default useStyles;
