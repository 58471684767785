import React from "react";
import { ShiftSelection } from "../../../../../../../components";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import useShiftOptions from "../../../../../../../api/shifts/useShiftOptions";

interface IProps {
  onShiftChange: (select: string) => void;
  machineId: number;
  isLoading: boolean;
  selectedShift?: string;
  timezone: string;
}

export default function ShiftHeader({
  onShiftChange,
  machineId,
  isLoading,
  selectedShift = "day_24h",
  timezone,
}: IProps): React.ReactElement {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { shiftOptions } = useShiftOptions(machineId, timezone);
  return (
    <div className={classes.shiftHeaderBox}>
      <div className={classes.headerTitle}>
        <h3>{t("settings.profile.tabTitles.shifts")}</h3>
        <h5>{`${t("dataSelection.early")}, ${t("dataSelection.midday")}, ${t(
          "dataSelection.late",
        )} ${t("settings.shifts.attentionLabel")}`}</h5>
      </div>
      {!isLoading && (
        <div className={classes.selection}>
          <ShiftSelection
            label={""}
            initShift={selectedShift}
            onShiftChange={onShiftChange}
            name={"ShiftSElection"}
            shiftOptions={shiftOptions}
          />
        </div>
      )}
    </div>
  );
}
