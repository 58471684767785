import { IStateListEntry } from "../../../../../charts/machineStateHeatmapCard/api/IStateListEntry";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import IStateList from "../../../../../charts/machineStateHeatmapCard/api/IStateList";
import useInterval from "../../../../../../helper/time/interval/useInterval";
import {
  getEndIsoDate,
  getStartIsoDate,
} from "../component/lastDayStacklight/helper/startEndTimeHelper";
import IStateListQuery from "../../../../../charts/machineStateHeatmapCard/api/IStateListQuery";
import { useDispatch, useSelector } from "react-redux";
import {
  ISetStackLightPayload,
  selectStackLightList,
  setStackLightList,
} from "../../../../../../redux/reducers/stackLightListReducer";
import { IStackLightEntry } from "../../../../../../api/machine/IStackLightEntry";
import { EApiState } from "../../../../../../api/state/EApiState";
import moment from "moment-timezone";

function getElementData(
  stackLightList: IStackLightEntry[],
  machineId: string,
): IStackLightEntry | undefined {
  return stackLightList.find((element) => element.id === machineId);
}

// Define the maximum age of data in milliseconds
const MAX_DATA_AGE_MS = 10 * 60 * 1000; // 10 minute

const START = getStartIsoDate();
const END = getEndIsoDate();
/*
  This Hook fetches the state list for the last 24h on a
 */
export default function useStateList24hIntervalQuery(
  indicatorIds: string[],
  machineTypeId: string,
  timezone: string,
): {
  stateListResponse: IStateListEntry[] | undefined;
  apiState: EApiState;
} {
  const [stateListResponse, setStateListResponse] = useState<
    IStateListEntry[] | undefined
  >([]);
  const [apiState, setApiState] = useState<EApiState>(EApiState.EMPTY);
  const dispatch = useDispatch();
  const stackLightList = useSelector(selectStackLightList);

  const [doQuery, { data: dataFromServer, error, loading: isLoading }] =
    useLazyQuery<IStateList>(IStateListQuery, {
      variables: {
        startedAfter: START,
        endedBefore: END,
        indicatorIdentificationTypeIds: indicatorIds,
        machineTypeId,
        shift: "day_24h", // load last 24h (= "")
        timezone,
      },
    });
  // fetch the data set initially
  useEffect((): void => {
    const stackLightElement = getElementData(stackLightList, machineTypeId);
    if (
      stackLightElement &&
      moment.tz(timezone).valueOf() - stackLightElement.lastUpdated <
        MAX_DATA_AGE_MS
    ) {
      setStateListResponse(stackLightElement.stateListEntryList);
    } else {
      doQuery();
    }
  }, []);
  // then start polling
  useInterval(() => {
    doQuery({
      variables: {
        startedAfter: getStartIsoDate(),
        endedBefore: getEndIsoDate(),
        indicatorIdentificationTypeIds: indicatorIds,
        machineTypeId,
        shift: "day_24h", // load last 24h (= "")
        timezone,
      },
    });
    // poll 10 minutes
  }, MAX_DATA_AGE_MS);

  const hasError = error != undefined;
  useEffect(() => {
    if (dataFromServer !== undefined && !isLoading && !hasError) {
      const payloadData: ISetStackLightPayload = {
        value: dataFromServer,
        machineId: machineTypeId,
        updateTime: moment.tz(timezone).valueOf(),
      };
      dispatch(setStackLightList(payloadData));
      setStateListResponse(dataFromServer?.getStateList);
      setApiState(EApiState.OK);
    } else if (hasError) {
      setApiState(EApiState.ERROR);
    } else if (isLoading) {
      setApiState(EApiState.LOADING);
    }
  }, [dataFromServer, isLoading, hasError]);

  return {
    stateListResponse,
    apiState,
  };
}
