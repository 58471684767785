export default {
  // GEORG yellow.
  yellowB_40: "#787612",
  yellowB_32: "#8c8a15",
  yellowB_24: "#a19e18",
  yellowB_16: "#b5b21b",
  yellowB_8: "#c9c71e",
  yellow_base: "#dedc22",
  yellowB8: "#f2ef24",

  // GEORG blue.
  blueB_8: "#020F17",
  blue_base: "#041c2c",
  blueB8: "#062940",
  blueB16: "#083654",
  blueB24: "#094269",
  blueB32: "#0b4f7d",
  blueB40: "#0d5c91",
  blueB48: "#0f69a6",
  blueB56: "#1176ba",
  blueB64: "#1383CF",

  // GEORG grey.
  greyB_24: "#2e3133",
  greyB_16: "#404447",
  greyB_8: "#53585c",
  grey_base: "#666c71",
  greyB8: "#777f85",
  greyB16: "#8a9299",
  greyB24: "#9ca6ad",
  greyB32: "#aeb9c2",
  greyB40: "#c1cdd6",
  greyB48: "#d3e0eb",
  greyB56: "#e6f4ff",

  // GEORG white.
  whiteB_16: "#d6d6d6",
  whiteB_12: "#e0e0e0",
  whiteB_8: "#ebebeb",
  whiteB_3: "#f3f3f3",
  white_base: "#ffffff",

  // Signal color (GEORG red & green).
  redB_16: "#c73a3a",
  red_base: "#ef4646",
  redB6: "#ef4b4b",

  greenB_16: "#0ff762",
  green_base: "#27fb6b",
  greenB6: "#35fd70",

  // Chart color.
  chart_blue_base: "#87c3f6", //blue_base
  chart_blue_dark: "#3d9cf0", //blueB_16

  chart_grey_base: "#aeb9c2",
  chart_grey_dark: "7f909f", //greyB_16

  chart_green_base: "#55e5bb", //green_base
  chart_green_dark: "#20cb9a", //greenB_16

  chart_red_base: "#f88e7d", //red_base
  chart_red_dark: "#f4492f", //redB_16

  chart_yellow_ligth: "#f4dd87", //yellow_ligth
  chart_yellow_base: "#f4dd87", //yellow_base
  chart_yellow_dark: "#edc63b", //yellowB_16

  chart_orange_base: "#ff9d52", //orange_base
  chart_orange_dark: "#FF6F00", //orangeB_16

  cell_state_green: "#55e5bb",
  cell_state_red: "#f88e7d",
  cell_state_yellow: "#f4dd87",
  cell_state_white: "#ffffff",

  georg_table_border_grey: "#FAFAFA",
};
