/* eslint-disable max-len */
// IList Component Documentation
// The IList component dynamically renders different types of list items based on provided props.
// Below is a table that describes when each component is created and its children components.

/**
 * 1. **renderNestedList()**:
 *    | **Condition**            | **Description**
 *    |--------------------------|----------------------------
 *    | `subItems` provided      | Renders a nested expandable list
 *    |--------------------------|----------------------------
 *    |**Children**                                                    |
 *    |---------------------------------------|
 *    | | `ListItemContent`, `ListItemIconWrapper`, `AvatarIconOrImage`, `ExpandLess`, `ExpandMore`, `subItems`
 * 
 * 2. **renderListLinkItem()**:
 *    | **Condition**            | **Description**
 *    |--------------------------|----------------------------
 *    | `route` provided         | Renders a list item as a clickable link
 *    |--------------------------|----------------------------
 *    |**Children**                                                    |
 *    |---------------------------------------|
 *    | `ListItemIconWrapper`, `AvatarIconOrImage`, `ListItemContent`, `ListItemContentRight`
 * 
 * 3. **renderListItem()**:
 *    | **Condition**            | **Description**
 *    |--------------------------|----------------------------
 *    | `subItems` not provided   |  Renders a standard list item with icons, labels, etc.
 *    |--------------------------|----------------------------
 *    |**Children**                                                    |
 *    |---------------------------------------|
 *    | `ListItemIconWrapper`, `AvatarIconOrImage`, `ListItemContentWithSecondaryIcon`, `ListItemContentRightWithIcon`

 */

import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import {
  Avatar,
  Grid2 as Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Skeleton,
  SvgIconProps,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material/";
import { OverridableStringUnion } from "@mui/types";
import { Variant } from "@mui/material/styles/createTypography";
import { TypographyPropsVariantOverrides } from "@mui/material/Typography/Typography";
import { animated, useTransition } from "react-spring";
import ItemIconWithTooltip from "./components/itemIconWithTooltip";
import { ITooltipInfo } from "../../../components/withTooltip";
import SecondaryActionItem from "./components/secondaryActionItem";

//------------------------------------------------------------------------------------------------------------
// Interfaces & Types.
type StyleObject = {
  hiddenIcon?: string;
  listItem?: string;
  label?: string;
  sublabel?: string;
  simpleListItem?: string;
  GridActionItems?: string;
  actionItem?: string;
};

type Colors =
  | "inherit"
  | "initial"
  | "primary"
  | "secondary"
  | "textPrimary"
  | "textSecondary"
  | "error"
  | undefined;

interface IProps {
  actionComponent?: JSX.Element;
  actionComponentPosition?: "left" | "right";
  actionIcon?: React.FunctionComponent;
  align?: "center" | "flex-start" | undefined;
  avatarIcon?: (props: SvgIconProps) => JSX.Element;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  avatarImage?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  button?: any;
  disablePadding?: boolean;
  icon?: JSX.Element;
  iconPosition?: "left" | "right";
  secondIcon?: JSX.Element;
  onSecondaryIconHover?: (event: React.MouseEvent) => void;
  iconToLabelSpace?: number;
  LabelToSecondIconSpace?: number;
  secondIconTooltip?: ITooltipInfo;
  label?: string | null;
  labelColor?: Colors;
  labelVariant?: OverridableStringUnion<
    Variant | "inherit",
    TypographyPropsVariantOverrides
  >;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  route?: string;
  showIcon?: boolean;
  subItems?: JSX.Element;
  sublabel?: string | JSX.Element | null;
  sublabelColor?: Colors;
  sublabelVariant?: OverridableStringUnion<
    Variant | "inherit",
    TypographyPropsVariantOverrides
  >;
  styles?: StyleObject;
  isCollapsed?: boolean;
  isOpen?: boolean;
  isLoading?: boolean;
  isActionComponentCentered?: boolean;
  isActionComponentHasTooltip?: boolean;
}

//------------------------------------------------------------------------------------------------------------
// Component.
function IList({
  actionComponent: ActionComponent,
  actionIcon: ActionIcon,
  actionComponentPosition = "right",
  align = "center",
  avatarIcon: AvatarIcon,
  avatarImage,
  button,
  disablePadding,
  icon: Icon,
  iconPosition = "left",
  iconToLabelSpace = 56,
  LabelToSecondIconSpace = 32,
  secondIcon,
  onSecondaryIconHover,
  secondIconTooltip,
  label,
  labelColor,
  labelVariant,
  onClick,
  route = "",
  showIcon = true,
  subItems,
  sublabel,
  sublabelColor,
  sublabelVariant,
  styles = {
    hiddenIcon: "",
    listItem: "",
    label: "",
    sublabel: "",
    simpleListItem: "",
    GridActionItems: "",
    actionItem: "",
  },
  isCollapsed = false,
  isOpen = false,
  isLoading = false,
  isActionComponentCentered = false,
  isActionComponentHasTooltip = false,
}: IProps): React.ReactElement {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [open, setOpen] = React.useState(isOpen || isCollapsed);
  const isNestedListOpen = useMemo(
    () => open || isCollapsed || isOpen,
    [open, isCollapsed, isOpen],
  );
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };
  const parentNestedlistDisplay = useMemo(() => {
    return isCollapsed ? "none" : "flex";
  }, [isCollapsed]);
  const actionJustifyContent = useMemo(() => {
    return isActionComponentCentered ? "center" : "flex-end";
  }, [isActionComponentCentered]);
  const transitions = useTransition(isNestedListOpen, {
    keys: null,
    from: { opacity: 0, transform: "translateY(-10%)" },
    enter: { opacity: 1, transform: "translateY(0%)" },
    leave: { opacity: 0, transform: "translateY(-10%)" },
    config: { duration: isLoaded ? 200 : 0 },
  });

  /*const areEqualForListItemIcon = (prevProps: IProps, nextProps: IProps) => {
    // shallow comparison for relevant props in this component
    return (
      prevProps.icon === nextProps.icon &&
      prevProps.showIcon === nextProps.showIcon
    );
  };*/

  const ListItemContent = () => (
    <ListItemText
      classes={{
        primary: styles.label,
        secondary: styles.sublabel,
      }}
      sx={{ flex: secondIcon ? "0 1 auto" : "1 1 auto" }}
      primary={label}
      primaryTypographyProps={{
        color: labelColor,
        variant: labelVariant,
      }}
      secondaryTypographyProps={{
        color: sublabelColor,
        variant: sublabelVariant,
      }}
      secondary={sublabel}
    />
  );

  const ListItemContentWithSecondaryIcon = () => (
    <Grid style={{ display: "flex", alignItems: "center" }}>
      <ListItemContent />
      <SecondaryIconItemContent />
    </Grid>
  );

  const ListItemIconWrapper = () => (
    <ListItemIcon
      color="primary"
      style={
        iconToLabelSpace
          ? {
              justifyContent: "start",
              minWidth: iconToLabelSpace,
            }
          : { justifyContent: "start" }
      }
    >
      {showIcon ? Icon : null}
    </ListItemIcon>
  );

  const SecondaryIconItemContent = () => (
    <>
      {secondIcon && showIcon ? (
        <ItemIconWithTooltip
          icon={secondIcon}
          tooltipInfo={secondIconTooltip}
          style={
            LabelToSecondIconSpace
              ? {
                  justifyContent: "end",
                  minWidth: LabelToSecondIconSpace,
                }
              : { justifyContent: "end" }
          }
          onHover={onSecondaryIconHover}
        />
      ) : null}
    </>
  );

  const ListItemContentRight = () => (
    <>
      {Icon && iconPosition === "right" ? (
        <ListItemIcon
          style={
            iconToLabelSpace
              ? {
                  justifyContent: "end",
                  minWidth: iconToLabelSpace,
                }
              : { justifyContent: "end" }
          }
        >
          {showIcon ? Icon : null}
        </ListItemIcon>
      ) : null}
    </>
  );

  const ListItemContentRightWithIcon = () => (
    <>
      <ListItemContentRight />
      {ActionIcon ? (
        <Grid>
          <ListItem secondaryAction={<IconButton>ActionIcon</IconButton>} />
        </Grid>
      ) : null}
      {ActionComponent && actionComponentPosition === "right" ? (
        <Grid className={styles.GridActionItems}>
          <ListItem
            secondaryAction={
              <SecondaryActionItem
                content={ActionComponent}
                onHover={
                  isActionComponentHasTooltip ? onSecondaryIconHover : undefined
                }
              />
            }
            disableGutters={true}
            className={styles.actionItem}
            style={{
              display: "flex",
              justifyContent: actionJustifyContent,
            }}
          />
        </Grid>
      ) : null}
    </>
  );

  const AvatarIconOrImage = () => (
    <>
      {AvatarIcon || avatarImage ? (
        <ListItemAvatar
          style={
            iconToLabelSpace
              ? {
                  minWidth: iconToLabelSpace,
                }
              : {}
          }
        >
          {AvatarIcon ? (
            <Avatar>AvatarIcon</Avatar>
          ) : (
            <Avatar alt="Remy Sharp" src={avatarImage} />
          )}
        </ListItemAvatar>
      ) : null}
    </>
  );

  const ListItems = () => (
    <ListItem
      component={"div"}
      alignItems={align}
      role={button ? "button" : "listitem"}
      // button={button}
      className={styles.listItem}
      key={label}
      onClick={onClick}
      style={disablePadding ? { padding: 0 } : {}}
    >
      {ActionComponent && actionComponentPosition === "left" ? (
        <ListItemIcon>{ActionComponent}</ListItemIcon>
      ) : null}
      {Icon && iconPosition === "left" ? <ListItemIconWrapper /> : null}
      <AvatarIconOrImage />
      {!isCollapsed ? (
        <Grid style={{ overflow: "hidden" }} className={styles.GridActionItems}>
          {isLoading ? (
            <Skeleton variant="text" animation={"pulse"} height={18}>
              <ListItemContentWithSecondaryIcon />
            </Skeleton>
          ) : (
            <ListItemContentWithSecondaryIcon />
          )}
        </Grid>
      ) : null}
      {!isCollapsed ? <ListItemContentRightWithIcon /> : null}
    </ListItem>
  );

  const renderListItem = () => (
    <Grid container>
      <ListItems />
    </Grid>
  );

  const ListLinkItems = () => (
    <ListItem
      // button
      className={styles.listItem}
      component={Link}
      role="button"
      key={label}
      to={route}
    >
      <div className={styles.simpleListItem}>
        {Icon && iconPosition === "left" ? <ListItemIconWrapper /> : null}
        <AvatarIconOrImage />
        {!isCollapsed ? <ListItemContent /> : null}
        {!isCollapsed ? <ListItemContentRight /> : null}
      </div>
    </ListItem>
  );

  const renderListLinkItem = () => {
    return <ListLinkItems />;
  };

  const NestedList = () => (
    <List disablePadding>
      <ListItem
        component={"div"}
        alignItems={align}
        // button
        className={styles.listItem}
        key={label}
        onClick={handleClick}
        style={{ display: parentNestedlistDisplay }}
      >
        {Icon && iconPosition === "left" ? <ListItemIconWrapper /> : null}
        <AvatarIconOrImage />
        {!isCollapsed ? <ListItemContent /> : null}
        {!isCollapsed ? (
          isNestedListOpen ? (
            <ExpandLess />
          ) : (
            <ExpandMore />
          )
        ) : null}
      </ListItem>
      <ListItemContentRight />
      {transitions((Expandstyles, item) =>
        item ? (
          <animated.div style={Expandstyles}>{subItems}</animated.div>
        ) : null,
      )}
    </List>
  );

  const renderNestedList = () => <NestedList />;

  const renderContent = () => {
    if (subItems) {
      return renderNestedList();
    } else if (route !== "") {
      return renderListLinkItem();
    } else {
      return renderListItem();
    }
  };

  return renderContent();
}
export default React.memo(IList);
