import { makeStyles } from "tss-react/mui";
import { rootTheme } from "../../../shared/theme";

const useStyles = makeStyles()({
  container: {
    ...rootTheme.mainContainer,
  },
  textContainer: {
    "& > h4": {
      "&.MuiTypography-root": {
        fontSize: 18,
      },
    },
    "& > h4:first-of-type": {
      "&.MuiTypography-root": {
        fontWeight: "bold",
      },
    },
    "& > h4:nth-of-type(2)": {
      "&.MuiTypography-root": {
        fontWeight: 300,
      },
    },
  },
});

export default useStyles;
