import { IStateListEntryDataValue } from "../../IStateListEntryData";
import { IHeatMapRowLayerPart } from "../../../../../../shared/components/charts/barChartHeatmap/components/heatmapRow/api/IHeatMapRow";
import { calcTimeDiff } from "../../../../../../helper/time/calculateTimeDiffHourMinute";
import secondsToDisplayFormat from "../../../../../../helper/time/formatting/secondsToDisplayFormat";
import { getTimeFormatFromDateTimeString } from "../../../../../../api/shifts/app/transformAppToApiFormat";
import moment from "moment-timezone";

interface IScaleEntry {
  tsStart: string;
  tsEnd: string;
}
/*
  Determines the number of chart units time ranges are
  inserted.

  E.g. 10

  0                   10
  | | | | | | | | | | |
  ^                   ^
  00:00              24:00

  const example = timeRange: {
    start: 00:00
    end: 12: 00
  }
  example would be put between 0 and 5
 */
// export const AXIS_RESOLUTION_UNIT_NUMBER = 5208; // Bugfix 1154-4 (a): LCM(7, 12, 24, 31,) = 5208
export const DAY_AXIS_RESOLUTION_UNIT_NUMBER = 1440; // Bugfix 1154-4 (b)
export const MONTH_AXIS_RESOLUTION_UNIT_NUMBER = 1860; // Bugfix 1154-4 (b)
export const WEEKS_AXIS_RESOLUTION_UNIT_NUMBER = 1435; // Bugfix 1154-4 (b)

export const getAxisResolutionUnitNumber = (scale: string) => {
  switch (scale) {
    case "months":
      return MONTH_AXIS_RESOLUTION_UNIT_NUMBER;
    case "weeks":
      return WEEKS_AXIS_RESOLUTION_UNIT_NUMBER;
    case "days":
    case "hours":
    case "years":
      return DAY_AXIS_RESOLUTION_UNIT_NUMBER;
    default: // TODO: default case should return 0 when scale is not recognized.
      return 0;
  }
};

const timeMap = new Map<string, number>([
  ["hours", getAxisResolutionUnitNumber("hours") / 24],
  ["days", getAxisResolutionUnitNumber("days") / 24],
  ["day", getAxisResolutionUnitNumber("day") / 24], // this is a workaround for a backend bug and should be removed when the backend bug is fixed
  ["months", getAxisResolutionUnitNumber("months") / 31],
  ["weeks", getAxisResolutionUnitNumber("weeks") / 7],
  ["years", getAxisResolutionUnitNumber("years") / 12],
]);

/*
 Returns the gap between two slots in the chart period
 example: chart period = days => gap = 60
          chart period = months => gap = 46.45 (rounded to 46)
 */

export function getGapForPeriod(period: string) {
  return timeMap.get(period) || DAY_AXIS_RESOLUTION_UNIT_NUMBER / 24;
}

export function getTimePeriodFromIndex(
  index: number,
  scale: string,
  locale: string,
  isStartIndex = true,
) {
  if (scale != null) {
    const tempIndex = isStartIndex
      ? Math.round(index / getGapForPeriod(scale))
      : Math.round(index / getGapForPeriod(scale));
    switch (scale) {
      case "weeks": {
        const weekIndex = (tempIndex + 1) % 7 || 7;
        return moment().locale(locale).day(weekIndex).format("dd");
      }
      case "months": {
        const monthIndex = (tempIndex + 1) % 31 || 31;
        return monthIndex < 10 ? `0${monthIndex}` : `${monthIndex}`;
      }
      case "years":
        return moment().locale(locale).month(tempIndex).format("MMMM");
      default: {
        const step = tempIndex * 60 * 60 * 1000;
        return moment
          .utc(new Date(new Date(0).getTime() + step).getTime())
          .format("HH:mm");
      }
    }
  }
  const time = new Date(index * getGapForPeriod(scale));
  return time.toLocaleDateString(locale, { month: "short" });
}

function normalize(x: number, min: number, max: number): number {
  return (x - min) / (max - min);
}

/*
    Scales a list of date time ranges represented in time format "yyyy-mm-ddThh:mm:ssZ".
    The Scaler extends the list of entries with a normalized number between 0 and 100.
    For examples please refer to dateRangeScaler.test.ts.
 */
export default function dateRangeScaler(
  start: number,
  end: number,
  entries: IStateListEntryDataValue[],
  scale: string,
  resolution = DAY_AXIS_RESOLUTION_UNIT_NUMBER,
): IHeatMapRowLayerPart[] {
  const scaledResolution =
    getAxisResolutionUnitNumber(scale.toLowerCase()) || resolution;
  const startTimeStamp = new Date(start).getTime();
  const endTimeStamp = new Date(end).getTime();
  return entries.map((entry: IScaleEntry) => {
    const entryStartDate = new Date(entry.tsStart);
    const entryEndDate = new Date(entry.tsEnd);

    const entryStartTimeStamp = entryStartDate.getTime();
    const entryEndTimeStamp = entryEndDate.getTime();

    const minutes = calcTimeDiff(entryStartDate, entryEndDate, "minutes");
    // TODO: please delete displayStringParts if not needed anymore
    return {
      ...entry,
      timeDuration:
        getTimeFormatFromDateTimeString(entry.tsStart) +
        " - " +
        getTimeFormatFromDateTimeString(entry.tsEnd),
      tsStart:
        normalize(entryStartTimeStamp, startTimeStamp, endTimeStamp) *
        scaledResolution,
      tsEnd:
        normalize(entryEndTimeStamp, startTimeStamp, endTimeStamp) *
        scaledResolution,
      displayStringParts: secondsToDisplayFormat(minutes * 60 + ""),
      dateStart: entryStartDate.toISOString(),
      dateEnd: entryEndDate.toISOString(),
    };
  });
}
