import { useLayoutEffect, useState } from "react";
import IShiftOption from "../../components/selections/shiftSelection/IShiftOption";
import useWorkShifts from "./useWorkShifts";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import resources from "../../@types/resources";

interface IShiftOptionsResponse {
  isShiftsLoading: boolean;
  hasError: boolean;
  shiftOptions: IShiftOption[];
}
type shiftKeys = keyof typeof resources.dataSelection.defaultShiftEntries;

export function getDefaultLabelOrEmptyString(
  t: TFunction,
  i18n,
  value: string,
): string {
  const prefix = "dataSelection.defaultShiftEntries";
  if ((value as shiftKeys) !== value || i18n.exists(t(`${prefix}.${value}`)))
    return "";
  return t(`${prefix}.${value}`);
}

// This is a custom order for the Shift-List (Ticket: 1027).
const stateOrder = ["day_24", "early", "midday", "late"];

function shiftComparator(a: IShiftOption, b: IShiftOption): number {
  const stateIndexA = stateOrder.indexOf(a.value);
  const stateIndexB = stateOrder.indexOf(b.value);

  return stateIndexA - stateIndexB;
}

export default function useShiftOptions(
  selectedMachine: number,
  timezone: string,
): IShiftOptionsResponse {
  const { data, isShiftsLoading, hasError } = useWorkShifts(
    selectedMachine,
    timezone,
  );
  const [shiftOptions, setShiftOptions] = useState<IShiftOption[]>([]);
  const { t, i18n } = useTranslation();
  useLayoutEffect(() => {
    if (data && !isShiftsLoading && !hasError) {
      const keys = Object.keys(data);
      if (keys.length > 0) {
        const shiftOptions = keys
          .map((k: string) => {
            return {
              value: k,
              label: getDefaultLabelOrEmptyString(t, i18n, k),
            };
          })
          .sort(shiftComparator);

        setShiftOptions(shiftOptions);
      }
    }
  }, [data, isShiftsLoading, hasError]);
  return { shiftOptions, isShiftsLoading, hasError };
}
