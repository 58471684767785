import { makeStyles } from "tss-react/mui";
import { colors } from "../../../../../theme";

const useStyles = makeStyles()({
  gray: {
    background: colors.grey_base,
    height: 30,
  },
  Icontainer: {
    "& .recharts-tooltip-wrapper": {
      zIndex: 9999,
    },
    alignItems: "center",
    display: "flex",
    height: `max(100%, 30px)`,
    //paddingLeft: 0,
    //paddingRight: 16,
    width: "100%",
    padding: 0,
    margin: 0,
    border: 0,
  },
  chartArea: {
    position: "relative",
    height: "100%",
    width: "100%",
    minWidth: 100,
    padding: 0,
    margin: 0,
    border: 0,
    justifyContent: "center",
    display: "grid",
    verticalAlign: "top",
  },
  chart: {
    width: "100%",
    minWidth: 100,
  },
  legendLabel: {
    minWidth: 100,
  },
  legendContainer: {
    alignItems: "center",
    display: "flex",
    //flexWrap: "nowrap",
    justifyContent: "left",
  },
  legendColorBox: {
    height: 12,
    marginLeft: 12,
    marginRight: 8,
    width: 12,
  },
});

export default useStyles;
