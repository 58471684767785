import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  doneButton: {
    height: 42,
    width: "100%",
    minWidth: "unset !important",
    maxWidth: 72,
  },
  noPadding: {
    padding: "0 !important",
  },
  fixedMinHeight: {
    minHeight: 60,
  },
}));

export default useStyles;
