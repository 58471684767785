import gql from "graphql-tag";

export default gql`
  query getOeeForMachineLast24h($machineTypeId: Int) {
    getOeeForMachineLast24h(machineTypeId: $machineTypeId) {
      availability {
        value
        unit
      }
      performance {
        value
        unit
      }
      productivity {
        value
        unit
      }
      quantity {
        value
        unit
      }
    }
  }
`;
