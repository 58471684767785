import React from "react";
import { Box, Typography } from "@mui/material";

interface IProps {
  classes: {
    textContainer: string;
    text: string;
    boldText: string;
  };
  texts: {
    primary: string;
    bold: string;
    secondary: string;
  };
  textWidth: string;
}

export default function TypographyBlock({
  classes,
  texts,
  textWidth,
}: IProps): React.ReactElement {
  return (
    <Box
      style={{
        marginBottom: 24,
        width: textWidth,
      }}
      className={classes.textContainer}
    >
      <Typography color="primary" variant="body1" className={classes.text}>
        {texts.primary}
      </Typography>
      <Typography color="primary" variant="body1" className={classes.boldText}>
        {texts.bold}
      </Typography>
      <Typography color="primary" variant="body1" className={classes.text}>
        {texts.secondary}
      </Typography>
    </Box>
  );
}
