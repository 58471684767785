import {
  AccountInfo,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";

export default function useInitAuth(msalInstance: PublicClientApplication) {
  if (
    !msalInstance.getActiveAccount() &&
    msalInstance.getAllAccounts().length > 0
  ) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }
  msalInstance.addEventCallback((event) => {
    if (
      event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS
    ) {
      const account = event.payload as AccountInfo;
      msalInstance.setActiveAccount(account);
    }
  });
}
