import React from "react";
import { Typography } from "@mui/material";

export default function NotFound(): React.ReactElement {
  return (
    <>
      <Typography color="primary" variant="h3">
        404
      </Typography>
      <Typography color="primary" variant="body2">
        Sorry but we couldn't find this page.
      </Typography>
    </>
  );
}
