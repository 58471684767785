import IButton from "../button";
import React from "react";
import useStyles from "./useStyles";

export enum EButtonType {
  DARK = "DARK",
  LIGHT = "LIGHT",
}

export enum EButtonSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

interface IProps {
  label: string;
  onClick: () => void;
  buttonType?: EButtonType;
  disabled?: boolean;
  buttonSize?: EButtonSize;
  endIcon?: React.ReactElement;
  isNotAction?: boolean;
}

export default function GeorgButton({
  disabled = false,
  label,
  onClick,
  buttonType = EButtonType.DARK,
  buttonSize = EButtonSize.LARGE,
  endIcon,
  isNotAction,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  const buttonStylesMap = {
    [EButtonSize.LARGE]: classes.base,
    [EButtonSize.MEDIUM]: classes.mediumButton,
    [EButtonSize.SMALL]: classes.smallButton,
  };
  return (
    <IButton
      endIcon={endIcon}
      disabled={disabled}
      styles={{
        button: buttonStylesMap[buttonSize],
        label: buttonSize === EButtonSize.LARGE ? "" : classes.smallFont,
      }}
      buttonVariant={"contained"}
      buttonColor={buttonType === EButtonType.DARK ? "primary" : "secondary"}
      labelColor={buttonType === EButtonType.DARK ? "secondary" : "primary"}
      label={label}
      onClick={onClick}
      isNotAction={isNotAction}
    />
  );
}
