import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()((theme) => ({
  footer: {
    height: 50,
    display: "flex",
    justifyContent: "center",
  },
  contentContainer: {
    backgroundColor: "unset",
    flexGrow: 1,
    marginTop: 63,
    overflow: "auto",
    position: "relative",
    // padding: theme.spacing(3),
    "& > div:first-of-type": {
      padding: `calc(${theme.spacing(1)} + 35px)`,
      [theme.breakpoints.down("sm")]: {
        padding: "24px 16px",
      },
    },
  },
  fab: {
    position: "absolute",
    right: "1rem",
    top: "1rem",
  },
  root: {
    display: "flex",
    height: "100%",
  },
  toolbar: {
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
  },
}));

export default useStyles;
