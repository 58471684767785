import b2cPolicies from "./b2cPolicies";
import { Configuration } from "@azure/msal-browser/dist/config/Configuration";
import force_prod_tenant from "../force_prod_tenant";
let clientId = "6e0e6ea5-8708-4105-8d2e-e507498c5c1f";
let redirectUri = "https://mygeorg.com";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/.exec(
      window.location.hostname,
    ),
);

if (isLocalhost) {
  if (force_prod_tenant.value) {
    clientId = "8d84194d-1b45-404e-a8c3-1f28bf4fe6e4";
    redirectUri = `http://${window.location.hostname}:3000`;
  } else {
    clientId = "3e23a59b-ffa3-437c-8a73-a5124862ca1e";
    redirectUri = `http://${window.location.hostname}:3000`;
  }
} else {
  redirectUri = `https://${window.location.hostname}`;
}
export const msalConfig: Configuration = {
  auth: {
    clientId,
    authority: b2cPolicies.authorities.signIn.authority,
    redirectUri: redirectUri,
    knownAuthorities: [b2cPolicies.authorityDomain],
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [clientId],
};
