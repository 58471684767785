import { makeStyles } from "tss-react/mui";
import { colors } from "../../../../../../shared/theme";

const useStyles = makeStyles()(() => ({
  staff_card: {
    display: "inline-block",
    fontFamily: "Titillium Web",
    fontColor: colors.blue_base,
    // marginRight: 16,
    // marginBottom: 16,
    // paddingLeft: 16,
    // padding: 16,
    width: "100%",
    borderRadius: 0,
    verticalAlign: "midday",
    transform: "perspective(1px) translateZ(0)",
    transitionDuration: "0.3s",
    transitionProperty: "transform",
    // maxWidth: "fit-content"
  },
  staff_card_content: {
    textAlign: "center",
    padding: "0 !important",
  },
  staff_card_gap: {
    paddingBottom: 16,
  },
  staff_card_grid_item_left: {
    paddingRight: 16,
  },
  staff_card_grid_item_right: {
    paddingLeft: 16,
  },
  staff_card_mail_jumbotron: {
    backgroundColor: colors.blue_base,
    color: colors.white_base,
    display: "flex",
  },
  staff_card_buissnescard_jumbotron: {
    backgroundColor: colors.blue_base,
    color: colors.white_base,
    padding: 16,
    margin: 16,
  },
  staff_card_jumbotron_text: {
    color: colors.white_base,
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    padding: "16px",
  },
  staff_phone_icon: {
    position: "relative",
    top: 6,
    right: 16,
  },
}));

export default useStyles;
