import { EDisplayState } from "../../../../../shared/components/georgDataGrid/components/stateCell";
import { getPercentageOfUrgency } from "./getPercentageOfUrgency";

export function calculateUrgencyState(
  currentState: number,
  lastService: number,
  interval: number,
  preWarning: number,
): EDisplayState {
  const percentageUrgency = getPercentageOfUrgency(
    currentState,
    lastService,
    interval,
  );
  const nextService = lastService + interval;
  if (currentState >= nextService) {
    return EDisplayState.DANGER;
  } else if (percentageUrgency >= preWarning && preWarning !== 0) {
    return EDisplayState.WARNING;
  } else {
    return EDisplayState.OK;
  }
}
