import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  alert: {
    marginBottom: "20px",
  },
}));

export default useStyles;
