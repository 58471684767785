import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  legendColorBox: {
    height: 12,
    marginLeft: 12,
    marginRight: 8,
    width: 12,
  },

  tooltipContainer: { padding: 20 },
});

export default useStyles;
