import createLayout from "../../../../../shared/components/gridPage/helper/createLayout";

export const PIE_AVAILABILITY_LAYOUT_KEY: TGridLayoutOEEKEY =
  "kpi_availability";
export const PIE_PRODUCTIVITY_LAYOUT_KEY: TGridLayoutOEEKEY =
  "kpi_productivity";
export const PIE_PERFORMANCE_LAYOUT_KEY: TGridLayoutOEEKEY = "kpi_performance";
export const PIE_QUALITY_LAYOUT_KEY: TGridLayoutOEEKEY = "kpi_quantity";
export const LINE_CURRENT_TIMEFRAME_LAYOUT_KEY: TGridLayoutOEEKEY =
  "CurrentTimeFrame";
export const LINE_REFERENCE_TIMEFRAME_LAYOUT_KEY: TGridLayoutOEEKEY =
  "ReferenceTimeFrame";

type TGridLayoutOEEKEY =
  | "kpi_availability"
  | "kpi_productivity"
  | "kpi_performance"
  | "kpi_quantity"
  | "CurrentTimeFrame"
  | "ReferenceTimeFrame";

const pieChartTripple = {
  w: 2,
  h: 5,
};
const pieChartDouble = {
  w: 6,
  h: 5,
};
const pieChartSingle = {
  w: 12,
  h: 5,
};

const lineChartTripple = { w: 8, h: 5 };
const lineChartSingle = {
  w: 12,
  h: 5,
};
const layoutLarge = [
  { i: PIE_AVAILABILITY_LAYOUT_KEY, x: 0, y: 0, ...pieChartTripple },
  { i: PIE_PRODUCTIVITY_LAYOUT_KEY, x: 2, y: 0, ...pieChartTripple },
  { i: PIE_PERFORMANCE_LAYOUT_KEY, x: 0, y: 5, ...pieChartTripple },
  { i: PIE_QUALITY_LAYOUT_KEY, x: 2, y: 5, ...pieChartTripple },

  { i: LINE_CURRENT_TIMEFRAME_LAYOUT_KEY, x: 6, y: 0, ...lineChartTripple },
  {
    i: LINE_REFERENCE_TIMEFRAME_LAYOUT_KEY,
    x: 6,
    y: 5,
    ...lineChartTripple,
  },
];
const layoutMedium = [
  { i: PIE_AVAILABILITY_LAYOUT_KEY, x: 0, y: 0, ...pieChartDouble },
  { i: PIE_PRODUCTIVITY_LAYOUT_KEY, x: 6, y: 0, ...pieChartDouble },
  { i: PIE_PERFORMANCE_LAYOUT_KEY, x: 0, y: 5, ...pieChartDouble },
  { i: PIE_QUALITY_LAYOUT_KEY, x: 6, y: 5, ...pieChartDouble },

  { i: LINE_CURRENT_TIMEFRAME_LAYOUT_KEY, x: 0, y: 10, ...lineChartSingle },
  {
    i: LINE_REFERENCE_TIMEFRAME_LAYOUT_KEY,
    x: 0,
    y: 15,
    ...lineChartSingle,
  },
];

const layoutSMedium = [
  { i: PIE_AVAILABILITY_LAYOUT_KEY, x: 0, y: 0, ...pieChartDouble },
  { i: PIE_PRODUCTIVITY_LAYOUT_KEY, x: 6, y: 0, ...pieChartDouble },
  { i: PIE_PERFORMANCE_LAYOUT_KEY, x: 0, y: 5, ...pieChartDouble },
  { i: PIE_QUALITY_LAYOUT_KEY, x: 6, y: 5, ...pieChartDouble },

  { i: LINE_CURRENT_TIMEFRAME_LAYOUT_KEY, x: 0, y: 10, ...lineChartSingle },
  {
    i: LINE_REFERENCE_TIMEFRAME_LAYOUT_KEY,
    x: 0,
    y: 15,
    ...lineChartSingle,
  },
];
const layoutSmall = [
  { i: PIE_AVAILABILITY_LAYOUT_KEY, x: 0, y: 0, ...pieChartSingle },
  { i: PIE_PRODUCTIVITY_LAYOUT_KEY, x: 2, y: 5, ...pieChartSingle },
  { i: PIE_PERFORMANCE_LAYOUT_KEY, x: 0, y: 10, ...pieChartSingle },
  { i: PIE_QUALITY_LAYOUT_KEY, x: 0, y: 15, ...pieChartSingle },

  { i: LINE_CURRENT_TIMEFRAME_LAYOUT_KEY, x: 0, y: 20, ...lineChartSingle },
  {
    i: LINE_REFERENCE_TIMEFRAME_LAYOUT_KEY,
    x: 0,
    y: 25,
    ...lineChartSingle,
  },
];

const layoutXSmall = [
  { i: PIE_AVAILABILITY_LAYOUT_KEY, x: 0, y: 0, ...pieChartSingle },
  { i: PIE_PRODUCTIVITY_LAYOUT_KEY, x: 2, y: 5, ...pieChartSingle },
  { i: PIE_PERFORMANCE_LAYOUT_KEY, x: 0, y: 10, ...pieChartSingle },
  { i: PIE_QUALITY_LAYOUT_KEY, x: 0, y: 15, ...pieChartSingle },

  { i: LINE_CURRENT_TIMEFRAME_LAYOUT_KEY, x: 0, y: 20, ...lineChartSingle },
  {
    i: LINE_REFERENCE_TIMEFRAME_LAYOUT_KEY,
    x: 0,
    y: 25,
    ...lineChartSingle,
  },
];

export type TResizeConfig = {
  [key in TGridLayoutOEEKEY]: number;
};

// export default KPI_VIEW_LAYOUTS;
export default function getLayoutInfo(resizeConfig: TResizeConfig) {
  return createLayout<TResizeConfig>(
    resizeConfig,
    layoutLarge,
    layoutLarge,
    layoutMedium,
    layoutSMedium,
    layoutSmall,
    layoutXSmall,
  );
}
