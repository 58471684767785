/*
 *
 *   Selection and filtering bar for the machine view, which is implemented in machinery and shopfloor.
 *
 */

import React, { useLayoutEffect, useMemo } from "react";
import { Box, Paper, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useTranslation } from "react-i18next";
// Custom Imports.
import { IButton, IInput } from "../../../shared/components";
import useStyles from "./styles";
import {
  EFilterType,
  ESortBy,
  IFilterSettings,
  ISort,
} from "../../views/machineryView";
import { EOrder } from "../../views/machineryView/sort/sortByMachineKey";
import SortButton from "./components/sortButton";

type FilterOptions = {
  location: { value: string; label: string }[];
  machine: { value: string; label: string }[];
  technology: { value: string; label: string }[];
};

interface IProps {
  filterOptions: FilterOptions;
  filterProps: IFilterSettings;
  handleFilter: (type: EFilterType, value: string) => void;
  onSort: (sortBy: ESortBy, order: EOrder) => void;
  sortProps: ISort;
}

const isValidValue = (
  value: string,
  options: { value: string; label: string }[],
) => {
  return options.some((item) => item.value === value);
};

const optionKeys = {
  by_machine: "machine",
  by_tech: "technology",
  by_location: "location",
};

export default function SelectMachineBar({
  filterOptions,
  filterProps,
  handleFilter,
  onSort,
  sortProps,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useTranslation();

  useLayoutEffect(() => {
    // Checks if options (excluding the default option) are empty
    if (filterOptions.machine.length < 2) return;

    Object.entries(filterProps).forEach(([key, value]) => {
      const optionsKey = optionKeys[key];
      if (value && optionsKey && filterOptions[optionsKey]) {
        const isValid = isValidValue(value, filterOptions[optionsKey]);
        if (!isValid) {
          handleFilter(EFilterType[key.toUpperCase()], "");
        }
      }
    });
  }, [filterProps, handleFilter, filterOptions]);

  const handleInputChange = (type: EFilterType) => (event) => {
    const selectedValue = event.target.value;
    if (selectedValue || selectedValue === "") {
      handleFilter(type, selectedValue);
    }
  };

  const filterComponents = useMemo(
    () =>
      Object.entries(optionKeys).map(([filterKey, optionsKey]) => (
        <IInput
          key={optionsKey}
          disableUnderline
          name={`select-${optionsKey}`}
          onChange={handleInputChange(
            EFilterType[filterKey.toUpperCase() as keyof typeof EFilterType],
          )}
          select={true}
          selectOptions={filterOptions[optionsKey]}
          styles={{
            rootInputStyles: classes.root,
          }}
          value={
            isValidValue(filterProps[filterKey], filterOptions[optionsKey])
              ? filterProps[filterKey]
              : ""
          }
        />
      )),
    [filterProps, filterOptions, classes],
  );

  const sortComponents = useMemo(
    () =>
      [
        { label: t("common.technology"), type: ESortBy.TECH },
        { label: t("common.location"), type: ESortBy.LOCATION },
        { label: t("common.state"), type: ESortBy.STATE },
      ].map(({ label, type }) => (
        <SortButton
          key={type}
          label={label}
          sortType={type}
          currentSort={sortProps}
          onSortChange={onSort}
          buttonStyle={classes.button}
        />
      )),
    [t, sortProps, onSort, classes],
  );

  return (
    <Paper className={classes.container}>
      <Box className={classes.boxFilter}>
        <Typography className={classes.label} color="primary" variant="body1">
          {t("machineSelectBar.filteredby")}
        </Typography>
        {filterComponents}
      </Box>
      <Box className={classes.boxSort}>
        <Typography className={classes.label} color="primary" variant="body1">
          {t("machineSelectBar.sortedby")}
        </Typography>
        {sortComponents}
      </Box>
      <Box className={classes.boxIcon}>
        <IButton
          disableRipple={false}
          buttonColor="secondary"
          icon={<RefreshIcon color="secondary" />}
          onClick={() => {
            handleFilter(EFilterType.NO_FILTER, "");
            onSort(ESortBy.NO_SORT, EOrder.ASC);
          }}
          name="reset-sort-filters"
        />
      </Box>
    </Paper>
  );
}
