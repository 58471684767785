import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Box, Grid2 as Grid } from "@mui/material";
import useStyles from "../../../styles";

// Skeleton component for StatusFooterContent
export default function StatusFooterContentSkeleton(): React.ReactElement {
  const { classes } = useStyles();
  return (
    <Grid container>
      <Grid>
        <Box className={classes.skeletonStatusContainer}>
          <Skeleton
            variant="rectangular"
            width={"100%"}
            height={"100%"}
            // animation={"wave"}
          />
        </Box>
      </Grid>
      <Grid className={classes.gridActionItems}>
        <Box className={classes.messageContainer}>
          <div style={{ paddingLeft: 10 }}>
            <Skeleton variant="text" width={"50%"} animation={"pulse"} />
            <Skeleton variant="text" width={"60%"} animation={"pulse"} />
          </div>
        </Box>
      </Grid>
    </Grid>
  );
}
