import { IServiceOrderSettingsFromServer } from "../../api/IServiceOrderSettings";
import { useEffect, useState } from "react";
import { SERVICE_ORDERS } from "../../api/MOCKS";
import { EApiState } from "../../../../../../../api";

export function useQueryServiceOrders(machineId: string): {
  serviceOrders: IServiceOrderSettingsFromServer[];
  apiState: EApiState;
} {
  const [apiState, setApiState] = useState(EApiState.LOADING);
  const [serviceOrders, setServiceOrders] = useState<
    IServiceOrderSettingsFromServer[]
  >([]);
  useEffect(() => {
    setApiState(EApiState.LOADING);
    const t = setTimeout(() => {
      setServiceOrders(SERVICE_ORDERS);
      setApiState(EApiState.OK);
    }, 600);
    return () => {
      clearTimeout(t);
    };
  }, [machineId]);
  return { apiState, serviceOrders };
}
