import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import WarningIcon from "@mui/icons-material/Warning";
// Custom Imports.
import {
  ITimeSelectionState,
  selectTimeSelection,
} from "../../../redux/reducers/timeSelectReducer";
import { selectMachine } from "../../../redux/reducers/machineSelectReducer";
import { selectIndicator } from "../../../redux/reducers/indicatorReducer";
import useKPIPieChartQuery from "../kpiPieChartCard/api/useKPIPieChartQuery";
import LabelWithBoxCard from "../../../shared/components/labelWithBoxCard";
import { getTimezone } from "../../../helper/time/timezone";
import NestedKPIPieChart, {
  IExtendedPieChartDataEntry,
} from "../../../shared/components/charts/nestedKpiPieChart";
import { ITooltipInfo } from "../../withTooltip";

//------------------------------------------------------------------------------------------------------------
// Interfaces & Types.
export interface IAnomalyInfo {
  icon?: React.ReactElement;
  tooltipInfo?: ITooltipInfo;
  current: boolean;
  reference: boolean;
}

interface IProps {
  icon: React.ReactElement;
  label: string;
  tooltipLabel: string;
  tooltipSublabel: string;
  tooltipDescription: string;
  tooltipFooter: string;
  indicator?: string;
}
//------------------------------------------------------------------------------------------------------------
// Component.
function NestedKPIPieChartCard({
  icon,
  indicator,
  label,
  tooltipLabel,
  tooltipSublabel,
  tooltipDescription,
  tooltipFooter,
}: IProps): React.ReactElement {
  const { t } = useTranslation();
  const [indicatorId, setIndicatorId] = React.useState("0");
  const timeSelection: ITimeSelectionState = useSelector(selectTimeSelection);
  const timezone = getTimezone();
  const selectedMachine = useSelector(selectMachine);
  const indicators = useSelector(selectIndicator);
  React.useEffect(() => {
    // Filtern des indicators aus dem Redux State.
    // Zur Steuerung der Abfrage eines Indicators muss der passende Name des Indicators der Komponente übergeben werden.
    const indicatorFound = indicators.filter((item) => {
      return item.indicatorName === indicator;
    })[0];
    if (indicatorFound != null) {
      setIndicatorId(indicatorFound.id);
    }
  }, [timeSelection]);
  const {
    data: pieChartData,
    isLoading,
    isEmpty,
    hasError,
  } = useKPIPieChartQuery<IExtendedPieChartDataEntry>(
    indicatorId,
    selectedMachine,
    timeSelection,
    timezone,
    true,
  );
  //For values < - JavaScript string conversion automatically insert string -

  const loadingText = t("common.kpi_skeleton.description");

  const anomalyInfo: IAnomalyInfo = useMemo(() => {
    const hasAnomaly =
      pieChartData.isAnomaly.current || pieChartData.isAnomaly.reference;

    return {
      icon: hasAnomaly ? <WarningIcon color={"error"} /> : undefined,
      tooltipInfo: hasAnomaly
        ? {
            label: t("common.kpi_anomaly.label"),
            description: t("common.kpi_anomaly.description"),
            sublabel: t("common.kpi_anomaly.sublabel"),
            footer: t("common.kpi_anomaly.footer"),
          }
        : undefined,
      current: pieChartData.isAnomaly.current,
      reference: pieChartData.isAnomaly.reference,
    };
  }, [t, pieChartData.isAnomaly.current, pieChartData.isAnomaly.reference]);

  return (
    <LabelWithBoxCard
      icon={icon}
      secondIcon={anomalyInfo.icon}
      secondIconTooltip={anomalyInfo.tooltipInfo}
      label={label}
      tooltipLabel={tooltipLabel}
      tooltipSublabel={tooltipSublabel}
      tooltipDescription={isLoading ? loadingText : tooltipDescription}
      tooltipFooter={isLoading ? "" : tooltipFooter}
      isEmpty={isEmpty && !isLoading}
      hasError={hasError}
    >
      <NestedKPIPieChart
        delta={pieChartData.delta}
        outerCircleDataEntries={
          pieChartData.dataEntries as IExtendedPieChartDataEntry[]
        }
        innerCircleDataEntries={
          (pieChartData.innerDataEntries ?? []) as IExtendedPieChartDataEntry[]
        }
        isLoading={isLoading}
        anomalyInfo={anomalyInfo}
      />
    </LabelWithBoxCard>
  );
}
export default React.memo(NestedKPIPieChartCard);
