import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import StateTooltip from "../../../../stateTooltip";
import { ITooltipName } from "../..";

interface ITooltipPayload {
  dataKey: string;
  payload: {
    name: ITooltipName;
    color: string;
    value: string;
  };
}

export interface ITooltipProps {
  payload?: ITooltipPayload[];
}

interface IProps {
  tooltipData: ITooltipProps;
}

function RowTooltip({ tooltipData }: IProps): React.ReactElement {
  const { t } = useTranslation();

  if (!tooltipData.payload) return <></>;
  const xAxisLabel = "";

  const conf = useMemo(() => {
    return (tooltipData.payload ?? [])
      .filter((ttPayload: ITooltipPayload) => {
        const part = ttPayload.payload;
        const isActive = part != null && part.name !== "";
        return ttPayload.payload != null && isActive;
      })
      .map((ttPayload: ITooltipPayload) => {
        // xAxisLabel = ttPayload.payload.name;
        const part = ttPayload.payload;
        const color = part.color ?? "#777";
        const name = t(
          `dashboard.kpiPieChart.${
            part.name !== "" ? part.name : "missingValue"
          }`,
        );
        const prefix = name;
        const displayValue =
          part.value !== ""
            ? `${part.value}%`
            : t("dashboard.kpiPieChart.missingValue");
        const label = `${prefix}: ${displayValue}`;

        return {
          label,
          color,
        };
      });
  }, [tooltipData.payload, t]);
  return <StateTooltip label={xAxisLabel} config={conf} />;
}
export default React.memo(RowTooltip);
