/*
 *   Reducer for storing the currentFilter and referenceFilter for querying data on machine dashboard.
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import TScale from "../../api/time/TScale";
import createUtcTimestamp from "../../services/createUtcTimestamp";
import { timezoneUTC } from "../../helper/time/timezone";

export type TTimeSelectionKey =
  | "today"
  | "this-week"
  | "this-month"
  | "this-year"
  | "last-day"
  | "last-week"
  | "last-month"
  | "last-year"
  | "individual";
export interface ITimeSlot {
  startedAfter: string;
  endedBefore: string;
  scale: TScale;
  shift: string;
  selectedPeriod: TTimeSelectionKey;
}
export interface ITimeSelectionState {
  currentFilter: ITimeSlot;
  referenceFilter: ITimeSlot;
}

const initialState: ITimeSelectionState = {
  currentFilter: {
    startedAfter: createUtcTimestamp(true, "this-week", timezoneUTC),
    endedBefore: createUtcTimestamp(false, "this-week", timezoneUTC),
    scale: "HOURS",
    shift: "day_24h",
    selectedPeriod: "this-week",
  },
  referenceFilter: {
    startedAfter: createUtcTimestamp(true, "this-month", timezoneUTC),
    endedBefore: createUtcTimestamp(false, "this-month", timezoneUTC),
    scale: "HOURS",
    shift: "day_24h",
    selectedPeriod: "this-month",
  },
};

export const timeSelectionSlice = createSlice({
  name: "timeSelection",
  initialState,
  reducers: {
    setTimeSelection: (state, action: PayloadAction<ITimeSelectionState>) => {
      state.currentFilter = { ...action.payload.currentFilter };
      state.referenceFilter = { ...action.payload.referenceFilter };
    },
  },
});

export const selectTimeSelection = (state: RootState): ITimeSelectionState =>
  state.timeSelection;

export const { setTimeSelection } = timeSelectionSlice.actions;

export default timeSelectionSlice.reducer;
