import { ICard } from "../../../../../../shared/components";
import { Typography } from "@mui/material";
import React from "react";
import useStyles from "./styles";

interface IProps {
  header: string;
  content: string;
  image: string;
  uri?: string;
}
export default function NewsPreviewCard({
  header,
  content,
  image,
  uri = "/",
}: IProps): React.ReactElement {
  const { classes } = useStyles();

  return (
    <a href={uri} target={"_blank"} className={classes.previewCardWrapper}>
      <ICard
        align="left"
        elevation={1}
        cardMedia={{ image, height: "200" }}
        cardContent={
          <>
            <Typography
              key={"header1"}
              className={classes.header}
              color="primary"
              variant="h2"
              gutterBottom
            >
              {header}
            </Typography>
            <Typography
              key={"content"}
              className={[classes.margin, classes.content].join(" ")}
              color="primary"
              variant="body1"
            >
              {content}
            </Typography>
          </>
        }
        cardFooter={
          <Typography color="primary" variant="button" key={"footer"}>
            <p>Read More</p>
          </Typography>
        }
        styles={{
          cardFooter: classes.cardFooter,
          cardContent: classes.cardContent,
        }}
      />
    </a>
  );
}
