import React, { useMemo } from "react";
import { Typography } from "@mui/material";
import useStyles from "./styles";
import formatNumberString from "../../../../helper/valueRepresentation/formatNumberString";
import { IShopfloorAnomalyInfo } from "../../../../components/views/machineryView/components/machineKPIFooter/component/lastDayKpiPerformance";
import WithToggableTooltip from "../../../../components/withToggableTooltip";

interface IProps {
  value?: number;
  metric?: string;
  anomalyInfo?: IShopfloorAnomalyInfo;
}

function ShopfloorValueDisplay({
  value,
  metric,
  anomalyInfo,
}: IProps): React.ReactElement {
  const { classes } = useStyles();

  const displayValue = useMemo(() => {
    if (typeof value === "undefined") {
      return "";
    }
    return formatNumberString(value.toString());
  }, [value]);

  return (
    <WithToggableTooltip
      tooltipInfo={anomalyInfo?.tooltipInfo}
      icon={anomalyInfo?.icon}
      placement="top"
      isContainerRequired={false}
      forceEnable={true}
    >
      <div className={classes.container}>
        <div style={{ display: "flex" }}>
          <Typography className={classes.valueContainer} color={"primary"}>
            {displayValue}
          </Typography>
          {metric != undefined && (
            <Typography
              color={"textSecondary"}
              className={classes.metricContainer}
            >{`${metric}`}</Typography>
          )}
        </div>
      </div>
    </WithToggableTooltip>
  );
}
export default React.memo(ShopfloorValueDisplay);
