import React from "react";
import { Container } from "@mui/material";
// Custom Imports.
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { MachineView } from "../../../components";
import { IHeader } from "../../../shared/components";

//------------------------------------------------------------------------------------------------------------
// Component.
export default function Shopfloor(): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useTranslation();

  // Machineinfo & ShopfloorData
  return (
    <Container className={classes.container}>
      <IHeader
        headline={t("headers.dashboards_shopfloor.headline")}
        headlineColor="primary"
        headlineVariant="h3"
        tagline={t("headers.dashboards_shopfloor.tagline")}
        taglineColor="primary"
        taglineVariant="body1"
        styles={{ tagline: classes.paddingBottom }}
      />
      <MachineView kpi={true} />
    </Container>
  );
}
