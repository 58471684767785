import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import WarningIcon from "@mui/icons-material/Warning";
// Custom Imports.
import {
  ITimeSelectionState,
  TTimeSelectionKey,
} from "../../redux/reducers/timeSelectReducer";
import { selectMachine } from "../../redux/reducers/machineSelectReducer";
import LabelWithBoxCard from "../../shared/components/labelWithBoxCard";
import ValueDisplay from "../../shared/components/valueDisplay";
import { getVariableObject } from "../../redux/reducers/variableIdentificationReducer";
import { getPeriodSelectOptions } from "../selections/timePeriodSelection/getPeriodSelectOptions";
import { getDefaultLabelOrEmptyString } from "../../api/shifts/useShiftOptions";
import { customRoundedRepresentation } from "../../helper/valueRepresentation/customRoundedCurrentReference";
import ITimeSelectionPeriodOption from "../selections/timePeriodSelection/ITimeSelectionPeriodOption";
import useAggregatedVaribaleQuery from "./api/useAggregatedVariableQuery";
import { IAnomalyInfo } from "../charts/nestedKpiPieChartCard";

interface IProps {
  icon: React.ReactElement;
  label: string;
  tooltipLabel: string;
  tooltipSublabel: string;
  tooltipDescription: string;
  tooltipFooter: string;
  variableIdentification: string;
  timeSelection: ITimeSelectionState;
  isUnitHidden?: boolean;
}

export function deepCompare(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

function findLabel(
  options: ITimeSelectionPeriodOption[],
  filterValue: TTimeSelectionKey,
) {
  return options.find((item) => item.value === filterValue)?.label || "";
}

function KPIValueDisplayCard({
  icon,
  label,
  tooltipLabel,
  tooltipSublabel,
  tooltipDescription,
  tooltipFooter,
  variableIdentification,
  timeSelection,
  isUnitHidden = true,
}: IProps): React.ReactElement {
  const { t, i18n } = useTranslation();
  const selectedMachine = useSelector(selectMachine);
  const variableObject = useSelector(
    getVariableObject(selectedMachine, variableIdentification),
  );
  const periodSelectOptions = getPeriodSelectOptions();
  const { currentFilter, referenceFilter } = timeSelection;

  const currentPeriodLabel = findLabel(
    periodSelectOptions,
    currentFilter.selectedPeriod,
  );
  const referencePeriodLabel = findLabel(
    periodSelectOptions,
    referenceFilter.selectedPeriod,
  );
  // TODO: Add this code when the shifts are implemented in Backend.
  /*const currentShiftLabel = getDefaultLabelOrEmptyString(
    t,
    i18n,
    currentFilter.shift,
  );
  const referenceShiftLabel = getDefaultLabelOrEmptyString(
    t,
    i18n,
    referenceFilter.shift,
  );*/
  // TODO: Remove this code after the shifts are implemented in Backend.
  const currentShiftLabel = getDefaultLabelOrEmptyString(t, i18n, "day_24h7");
  const referenceShiftLabel = getDefaultLabelOrEmptyString(t, i18n, "day_24h7");

  const { aggregatedVariable, isLoading, isEmpty, hasError } =
    useAggregatedVaribaleQuery(variableObject, timeSelection);
  const { current: roundedCurrentData, reference: roundedReferenceData } =
    useMemo(
      () =>
        customRoundedRepresentation(
          aggregatedVariable.current.value,
          aggregatedVariable.reference.value,
        ),
      [aggregatedVariable.current.value, aggregatedVariable.reference.value],
    );

  // the unit text should be also displayed near the value when the isUnitHidden is set to false
  const unitText = isUnitHidden
    ? ""
    : ` ${aggregatedVariable.unit.displayName}`;

  // this data should be displayed in tooltip
  /*const translatedMetric = roundedCurrentData.unitSuffix
    ? t(`common.number.${roundedCurrentData.unitSuffix}`)
    : "";*/
  const magnitudeSuffixes = `\nK: ${t("common.number.inThousands")}; M: ${t(
    "common.number.inMillions",
  )}`;
  const hasMagnitudeInValue = useMemo(() => {
    const currentUnitHasText =
      roundedCurrentData?.unit && roundedCurrentData?.unit.trim().length > 0;
    const referenceUnitHasText =
      roundedReferenceData?.unit &&
      roundedReferenceData?.unit.trim().length > 0;

    return currentUnitHasText || referenceUnitHasText;
  }, [roundedCurrentData?.unit, roundedReferenceData?.unit]);

  const anomalyInfo: IAnomalyInfo = useMemo(() => {
    const hasAnomaly =
      aggregatedVariable.current.isAnomaly ||
      aggregatedVariable.reference.isAnomaly;

    return {
      icon: hasAnomaly ? <WarningIcon color={"error"} /> : undefined,
      tooltipInfo: hasAnomaly
        ? {
            label: t("common.kpi_anomaly.label"),
            description: t("common.kpi_anomaly.description"),
            sublabel: t("common.kpi_anomaly.sublabel"),
            footer: t("common.kpi_anomaly.footer"),
          }
        : undefined,
      current: aggregatedVariable.current.isAnomaly,
      reference: aggregatedVariable.reference.isAnomaly,
    };
  }, [
    t,
    aggregatedVariable.current.isAnomaly,
    aggregatedVariable.reference.isAnomaly,
  ]);

  return (
    <LabelWithBoxCard
      icon={icon}
      secondIcon={anomalyInfo.icon}
      secondIconTooltip={anomalyInfo.tooltipInfo}
      // label={`${label} ${translatedMetric}`}
      label={label}
      tooltipLabel={tooltipLabel}
      tooltipSublabel={tooltipSublabel}
      tooltipDescription={tooltipDescription}
      tooltipFooter={`${tooltipFooter} [${aggregatedVariable.unit.displayName}]. ${hasMagnitudeInValue ? magnitudeSuffixes : ""}`}
      isEmpty={isEmpty && !isLoading && !hasError}
      hasError={hasError}
    >
      <ValueDisplay
        current={roundedCurrentData?.value}
        target={roundedReferenceData?.value}
        currentMetric={
          (roundedCurrentData && roundedCurrentData?.unit) + unitText
        }
        referenceMetric={
          (roundedReferenceData && roundedReferenceData?.unit) + unitText
        }
        currentPeriodLabel={currentPeriodLabel}
        referencePeriodLabel={referencePeriodLabel}
        currentShiftLabel={currentShiftLabel}
        referenceShiftLabel={referenceShiftLabel}
        isLoading={isLoading}
        anomalyInfo={anomalyInfo}
      />
    </LabelWithBoxCard>
  );
}
export default React.memo(KPIValueDisplayCard);
