import { makeStyles } from "tss-react/mui";
import { colors } from "../../../../shared/theme";

const useStyles = makeStyles()(() => ({
  button: {
    textAlgin: "center",
    minWidth: 100,
    width: "min-content",
    height: 36,
    opacity: 0.85,
    transition: "opacity color 0.3s ease", // Add a transition for smooth effect
    "&:hover": {
      opacity: 1,
    },
  },
  menuItems: {
    padding: 10,
    width: "inherit",
    "&:hover p, &:hover svg": {
      color: colors.blueB40,
    },
    "&.Mui-disabled": {
      pointerEvents: "auto",
      backgroundColor: "inherit",
      "& p, & svg": {
        color: colors.grey_base,
      },
      "&:hover p, &:hover svg": {
        color: colors.grey_base,
      },
    },
  },
  menuItemLabel: {
    marginLeft: 8,
  },
}));

export default useStyles;
