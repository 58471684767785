/*
 *
 *   Service for creating priority values on priority selection for querying data to backend.
 *
 */

import {
  EJobPriorityKey,
  TJobPriorityKey,
} from "./components/jobPrioritySelection";

// eslint-disable-next-line complexity
export default function getPriorityValue(value: TJobPriorityKey): number {
  // Urgent.
  if (value === EJobPriorityKey.URGENT) {
    return 1000;
  }

  // High.
  if (value === EJobPriorityKey.HIGH) {
    return 667;
  }

  // Middle.
  if (value === EJobPriorityKey.MIDDLE) {
    return 334;
  }

  // Low.
  if (value === EJobPriorityKey.LOW) {
    return 1;
  }

  return 0;
}

export function getPriorityKey(value: number): TJobPriorityKey | null {
  // Urgent.
  if (value === 1000) {
    return EJobPriorityKey.URGENT;
  }

  // High.
  if (value >= 667 && value < 1000) {
    return EJobPriorityKey.HIGH;
  }

  // Middle.
  if (value >= 334 && value < 667) {
    return EJobPriorityKey.MIDDLE;
  }

  // Low.
  if (value >= 1 && value < 334) {
    return EJobPriorityKey.LOW;
  }

  return null;
}

function getFilterValue(
  value: number,
  range1: number,
  range2: number,
): boolean {
  return value >= range1 && value < range2;
}

export function getPriorityFilter(filterValue: string, val: number): boolean {
  if (filterValue === EJobPriorityKey.LOW) {
    return getFilterValue(val, 0, 334);
  } else if (filterValue === EJobPriorityKey.MIDDLE) {
    return getFilterValue(val, 334, 667);
  } else if (filterValue === EJobPriorityKey.HIGH) {
    return getFilterValue(val, 667, 1000);
  } else if (filterValue === EJobPriorityKey.URGENT) {
    return val === 1000;
  }
  return false;
}
