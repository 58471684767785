import { ITableUserInfo } from "../../components/userTable";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import GetAzureB2cUsersQuery from "./GetAzureB2cUsersQuery";
import IB2CUser from "./IB2CUser";
import { EApiState } from "../../../../../../api";

interface IQueryUserResponse {
  state: EApiState;
  data: ITableUserInfo[];
}

interface IGetAzureB2cUsersQueryResponse {
  getAzureB2cUsers: IB2CUser[];
}

function b2cUserToITableUserInfo(b2cUser: IB2CUser): ITableUserInfo {
  const { id, givenName, surname, mail } = b2cUser;
  return {
    id,
    removeUser: true,
    eMail: mail || "",
    resetPassword: true,
    lastName: surname || "",
    firstName: givenName || "",
  };
}

export default function useQueryUsers(
  isRefreshNeeded = true,
): IQueryUserResponse {
  const {
    data: responsePayload,
    loading: isLoading,
    error: hasError,
    startPolling,
    stopPolling,
  } = useQuery<IGetAzureB2cUsersQueryResponse>(GetAzureB2cUsersQuery);
  const [queryState, setQueryState] = useState<EApiState>(EApiState.LOADING);
  const [data, setData] = useState<ITableUserInfo[]>([]);

  /*
    Attention!
    Since backend is talking to MS Rest-API the update of the user list
    takes a little delay. Since refetch mechanism in apollo is too fast,
    the correct updated user list is not delivered in time which results
    in outdated table.
    The polling mechanism mitigates this issue but is not a fix but a work-around.
   */
  useEffect(() => {
    if (isRefreshNeeded) {
      startPolling(3000);
    }
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling, isRefreshNeeded]);

  useEffect(() => {
    const newUsers = responsePayload?.getAzureB2cUsers;
    if (queryState === EApiState.OK && newUsers) {
      setData(newUsers.map(b2cUserToITableUserInfo));
    }
  }, [responsePayload, queryState]);

  useEffect(() => {
    if (!isLoading && !hasError) {
      setQueryState(EApiState.OK);
    } else if (isLoading) {
      setQueryState(EApiState.LOADING);
    } else {
      setQueryState(EApiState.ERROR);
    }
  }, [isLoading, hasError]);

  return { state: queryState, data };
}
