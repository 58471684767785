import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import UpdateWorkShiftMutation from "./UpdateWorkShiftMutation";
import { IWorkShiftEntry } from "../../../../../../../api/shifts/server/IGetWorkShiftsResponse";
import IUpsertWorkShiftEntry from "../../../../../../../api/shifts/app/IGetWorkShiftsResponse";

interface IUpsertWorkShiftsResponse {
  upsertWorkShift: IWorkShiftEntry[];
}

export default function useUpsertWorkShift(): {
  mutate: (workShiftEntries: IUpsertWorkShiftEntry[]) => void;
  isLoading: boolean;
  hasError: boolean;
  response?: IUpsertWorkShiftsResponse | null;
} {
  const [mutateFunction, { data, loading, error }] =
    useMutation<IUpsertWorkShiftsResponse>(UpdateWorkShiftMutation);

  const mutate = useCallback(
    (workShiftEntries: IUpsertWorkShiftEntry[]): void => {
      mutateFunction({
        variables: { workShifts: workShiftEntries },
      }).catch(
        // eslint-disable-next-line no-console
        console.error,
      );
    },
    [],
  );
  return {
    mutate,
    isLoading: loading,
    hasError: error != null,
    response: data,
  };
}
