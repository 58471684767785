import IManualStateConfigEntry, {
  IManualStateConfigEntryFromServer,
} from "../../../../../../api/manualState/IManualStateConfigEntry";
import IModifyMutation from "../api/IModifyMutation";

export default function uiToServerFormat(
  fromServerEntries: IManualStateConfigEntryFromServer[],
  actualUiEntries: IManualStateConfigEntry[],
): IModifyMutation {
  const toRemove: string[] = fromServerEntries
    .filter(
      (fromServer) =>
        !actualUiEntries.some((uiEntry) => uiEntry.id === fromServer.id),
    )
    .map((entry) => entry.id);
  const added: IManualStateConfigEntry[] = actualUiEntries.filter(
    (actualEntry) =>
      !fromServerEntries.some(
        (fromServerEntry) => actualEntry.id === fromServerEntry.id,
      ),
  );
  const modified: IManualStateConfigEntry[] = actualUiEntries.filter(
    (actualEntry) =>
      fromServerEntries.some(
        (fromServerEntry) =>
          actualEntry.id === fromServerEntry.id &&
          JSON.stringify(actualEntry) !== JSON.stringify(fromServerEntry),
      ),
  );
  return {
    add: added,
    delete: toRemove,
    update: modified,
  };
}
