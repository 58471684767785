import { makeStyles } from "tss-react/mui";
import { colors, rootTheme } from "../../../../../../shared/theme";

const useStyles = makeStyles()((theme) => ({
  cardContent: {
    flex: 1,
  },
  cardFooter: {
    padding: 16,
  },
  cardFooterSkeleton: {
    marginTop: theme.spacing(3),
    padding: 16,
  },
  container: {
    ...rootTheme.viewsContainer,
  },
  header: {
    fontSize: 24,
    textAlign: "left",
  },
  content: {
    textAlign: "justify",
  },
  contentSkeleton: {
    textAlign: "justify",
    marginTop: theme.spacing(3),
  },
  margin: {
    marginTop: 20,
  },
  previewCardWrapper: {
    cursor: "pointer",
    textDecoration: "none",
  },
  previewCardWrapperSkeleton: {
    textDecoration: "none",
    boxShadow: "0px 2px 4px 0px " + colors.whiteB_12,
  },
  outerGrid: {
    padding: 16,
  },
}));

export default useStyles;
