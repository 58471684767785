import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  container: {
    minHeight: 536,
    paddingTop: 48,
  },
  placeHolderRow: {
    minHeight: 57,
  },
}));

export default useStyles;
