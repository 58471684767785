import React, { useEffect } from "react";
import DocumentSearchField from "../../../../components/service/common/DocumentSearchField";
import DocumentTypeSelection from "../../../../components/service/common/DocumentTypeSelection";
import DocumentSearchResult from "../../../../components/service/documentSearchView/DocumentSearchResult";
import useStyles from "../../../../components/service/common/styles";
import { showDocumentation } from "../../../../redux/reducers/serviceReducer";
import { useDispatch } from "react-redux";

interface IProps {
  keywordSearch?: boolean;
}

export default function DocumentSearchView(props: IProps): React.ReactElement {
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.keywordSearch) {
      dispatch(showDocumentation(false));
    } else if (!props.keywordSearch) {
      dispatch(showDocumentation(true));
    }
  });

  const cssClasses = useStyles();

  return (
    <div className={cssClasses.classes.docsearch_container}>
      <DocumentSearchField keywordSearch={props.keywordSearch} />
      <DocumentTypeSelection />
      <DocumentSearchResult
        keywordSearch={props.keywordSearch}
        // searchCriteria={{ and: ["searchbox", "doctypeFilter"] }}
      />
    </div>
  );
}
