import gql from "graphql-tag";

export default gql`
  mutation upsertWorkShift($workShifts: [WorkShiftInputType]!) {
    upsertWorkShift(workShifts: $workShifts, timezone: "Europe/Berlin") {
      dayOfWeek
      id
      shiftEnd
      shiftName
      shiftStart
      virtual
      machineTypeId
    }
  }
`;
