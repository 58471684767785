import gql from "graphql-tag";

export default gql`
  query indicators($indicatorListName: String!, $machineTypeId: Int) {
    indicators(
      indicatorListName: $indicatorListName
      machineTypeId: $machineTypeId
    ) {
      machineTypeId
      indicatorIdentificationTypeIds
    }
  }
`;
