import { IJobFromServer } from "../../../../../../api/jobs/interfaces/IJobSettings";
import { useEffect, useState } from "react";
import { EApiState } from "../../../../../../api";
import { useQuery } from "@apollo/client";
import jobs from "../../../../../../api/jobs/queries/getJobList";

export interface IGetJobLists {
  jobs: IJobFromServer[];
}

export interface IJobQueryVariables {
  aborted?: boolean;
  deleted?: boolean;
  done?: boolean;
  machineTypeId?: number;
}

export default function useQueryJobs(variables: IJobQueryVariables): {
  apiState: EApiState;
  Jobs: IJobFromServer[];
  refreshData: () => void;
} {
  const {
    data: dataFromServer,
    error,
    loading: isLoading,
    startPolling,
    stopPolling,
    refetch,
  } = useQuery<IGetJobLists>(jobs, {
    fetchPolicy: "cache-and-network",
    variables: {
      filter: {
        aborted: variables.aborted,
        deleted: variables.deleted,
        done: variables.done,
        machineTypeId: variables.machineTypeId,
      },
    },
  });
  const [Jobs, setJobs] = useState<IJobFromServer[]>([]);
  const [apiState, setApiState] = useState<EApiState>(EApiState.EMPTY);

  useEffect(() => {
    startPolling(10000);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  useEffect(() => {
    const newData = dataFromServer?.jobs;
    if (apiState === EApiState.OK) {
      setJobs(newData ?? []);
    }
  }, [dataFromServer, apiState]);

  const hasError = error != undefined;

  useEffect(() => {
    if (!isLoading && !hasError) {
      setApiState(EApiState.OK);
    } else if (hasError) {
      setApiState(EApiState.ERROR);
    } else if (isLoading) {
      setApiState(EApiState.LOADING);
    }
  }, [isLoading, hasError]);

  const refreshData = () => {
    refetch();
  };

  return {
    apiState,
    Jobs,
    refreshData,
  };
}
