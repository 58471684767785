import gql from "graphql-tag";

export default gql`
  {
    getAllIndicatorIdentificationList {
      id
      indicatorName
    }
  }
`;
