import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  stateTooltipWrapper: {
    padding: 10,
    "& .recharts-tooltip-wrapper": {
      zIndex: 9999,
      position: "relative",
    },
  },
});
export default useStyles;
