import { MachineCardStatusType } from "../helper";
import { TFunction } from "i18next";
import STATE_COLOR_MAP, {
  TIndicatorName,
} from "../config/states/STATE_COLOR_MAP";
import getIconForStateName from "../config/states/getIconForStateName";
import resources from "../@types/resources";

export interface MachineCardStatusTypeWithColor extends MachineCardStatusType {
  color: string;
  indicatorName: TIndicatorName;
}
type machineCardIndicatorTranslationKeys =
  keyof typeof resources.machinery.machineCard.statusFooter.label;

export default function getStatusLabel(
  t: TFunction,
  indicatorName: TIndicatorName,
): MachineCardStatusTypeWithColor | undefined {
  const icon = getIconForStateName(indicatorName);
  let statusMessage = "";
  if (
    (indicatorName as machineCardIndicatorTranslationKeys) === indicatorName
  ) {
    statusMessage = t(
      `machinery.machineCard.statusFooter.label.${indicatorName}`,
    );
  }

  const color = STATE_COLOR_MAP[indicatorName as TIndicatorName];
  return {
    Icon: icon,
    color,
    indicatorName,
    statusMessage: statusMessage,
  };
}
