import React, { useCallback, useState } from "react";
import { IList } from "../index";
import { Card } from "@mui/material";
import { ResponsiveContainer } from "recharts";
import useStyles from "./styles";
import ServerDataStatusWrapper from "../serverDataStatusWrapper";
import WithToggleableTooltip from "../../../components/withToggableTooltip";
import { ITooltipInfo } from "../../../components/withTooltip";

interface IProps {
  label: string;
  tooltipLabel: string;
  tooltipDescription: string;
  tooltipSublabel: string;
  tooltipFooter: string;
  icon?: React.ReactElement;
  secondIcon?: React.ReactElement;
  secondIconTooltip?: ITooltipInfo;
  children: React.ReactElement;
  isDisplayingTextOnly?: boolean;
  isLoading?: boolean;
  hasError?: boolean;
  isEmpty?: boolean;
  actionComponent?: JSX.Element;
}
function LabelWithBoxCard({
  label,
  tooltipLabel,
  tooltipSublabel,
  tooltipDescription,
  tooltipFooter,
  icon,
  secondIcon,
  secondIconTooltip,
  isDisplayingTextOnly = false,
  children,
  isLoading,
  hasError,
  isEmpty,
  actionComponent = undefined,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  const [disableHeaderTooltip, setDisableHeaderTooltip] = useState(false);
  const handleHover = useCallback((event: React.MouseEvent) => {
    if (event.type === "mouseenter") {
      setDisableHeaderTooltip(true);
    } else if (event.type === "mouseleave") {
      setDisableHeaderTooltip(false);
    }
  }, []);

  const isCenteringElement =
    isLoading || hasError || isEmpty || isDisplayingTextOnly;
  return (
    <Card className={classes.container}>
      <WithToggleableTooltip
        tooltipInfo={{
          label: tooltipLabel,
          description: tooltipDescription,
          sublabel: tooltipSublabel,
          footer: tooltipFooter,
        }}
        isHidden={disableHeaderTooltip}
      >
        <IList
          label={label}
          labelColor="textPrimary"
          labelVariant="body1"
          icon={icon}
          secondIcon={secondIcon}
          secondIconTooltip={secondIconTooltip}
          iconPosition="right"
          styles={{
            label: classes.label,
            GridActionItems: classes.gridActionItems,
          }}
          iconToLabelSpace={30}
          actionComponent={actionComponent}
          onSecondaryIconHover={handleHover}
        />
      </WithToggleableTooltip>
      <div className={classes.chartContainer}>
        <ResponsiveContainer
          width={"100%"}
          height={"100%"}
          className={
            isCenteringElement ? classes.chartAreaError : classes.chartArea
          }
        >
          <ServerDataStatusWrapper
            isLoading={isLoading}
            isEmpty={isEmpty}
            hasError={hasError}
          >
            {children}
          </ServerDataStatusWrapper>
        </ResponsiveContainer>
      </div>
    </Card>
  );
}
export default React.memo(LabelWithBoxCard);
