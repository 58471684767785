/*
 *   Screenmode local storing the current screenmodes like fullscreen, shopfloormode etc.
 */
import { useCallback, useEffect, useState } from "react";
import useLocalStorage from "../persistence/useLocalStorage";
import {
  ESortBy,
  IFilterSettings,
  ISort,
} from "../../components/views/machineryView";
import { EOrder } from "../../components/views/machineryView/sort/sortByMachineKey";

interface IMachineViewSettingsApi {
  machineViewSort: ISort;
  setMachineViewSort: (newSort: ISort) => void;
  machineViewFilter: IFilterSettings;
  setMachineViewFilter: (newFilter: IFilterSettings) => void;
}

interface IMachineView {
  sort: ISort;
  filter: IFilterSettings;
}

const SortAndFilterStorageKey = "SortAndFilter";

/*
Please use this not directly but with GeorgStorageProvider
 */
export default function useMachineViewSettings(): IMachineViewSettingsApi {
  const { load, set } = useLocalStorage();

  const initialSort: ISort = { sortBy: ESortBy.STATE, order: EOrder.DESC };
  const initialFilter: IFilterSettings = {
    by_location: "",
    by_machine: "",
    by_tech: "",
  };

  // Initialize screen settings
  const storedDataString = load(SortAndFilterStorageKey);
  const storedData: IMachineView = storedDataString
    ? JSON.parse(storedDataString)
    : { sort: initialSort, filter: initialFilter };

  const machineViewSortFromLS = storedData?.sort || initialSort;
  const [machineViewSort, setMachineViewSortInternal] = useState<ISort>(
    machineViewSortFromLS,
  );

  const machineViewFilterFromLS = storedData?.filter || initialFilter;
  const [machineViewFilter, setMachineViewFilterInternal] =
    useState<IFilterSettings>(machineViewFilterFromLS);

  useEffect(() => {
    const dataToStore = { sort: machineViewSort, filter: machineViewFilter };
    set(SortAndFilterStorageKey, JSON.stringify(dataToStore));
  }, [machineViewSort, machineViewFilter, set]);

  const setMachineViewSort = useCallback((newSort: ISort) => {
    setMachineViewSortInternal(newSort);
  }, []);

  const setMachineViewFilter = useCallback(
    (newFilter: IFilterSettings) => {
      setMachineViewFilterInternal(newFilter);
    },
    [set],
  );

  return {
    machineViewSort,
    setMachineViewSort,
    machineViewFilter,
    setMachineViewFilter,
  };
}
