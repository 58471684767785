import { IMachine } from "../../../../api/machine/IMachine";
import { IIndicatorStateSince } from "../components/machineStatusFooter/api/useCurrentIndicatorStateList/useCurrentIndicatorStateList";
import { getFromMapOrThrow } from "../machineStateMap";
import TMachineStateMap from "../machineStateMap/TMachineStateMap";

export enum EOrder {
  ASC = "ASC",
  DESC = "DESC",
}

// This is a custom order for the machine states.
const stateOrder = [
  "indicator_edgedevice_offline",
  "indicator_state_notavailable",
  "indicator_state_executing",
  "indicator_state_idle",
  "indicator_state_idle_long",
  "indicator_state_notexecuting",
  "indicator_state_undefined",
  "indicator_state_waiting",
  "indicator_state_warning",
];

function sortElementPairByStateAndDuration(
  a: IIndicatorStateSince,
  b: IIndicatorStateSince,
  order: EOrder,
): number {
  const stateIndexA = stateOrder.indexOf(a.indicatorState);
  const stateIndexB = stateOrder.indexOf(b.indicatorState);

  if (stateIndexA !== stateIndexB) {
    const stateComparison = stateIndexA - stateIndexB;
    return order === EOrder.ASC ? stateComparison : -stateComparison;
  } else {
    // If The indicator state is the same, so we sort by duration.
    // The duration is always sorted in ascending order.
    const durationA = a.stateStartedSince;
    const durationB = b.stateStartedSince;
    const durationComparison =
      (durationA?.getTime() ?? 0) - (durationB?.getTime() ?? 0);
    return durationComparison;
  }
}

export default function sortByMachineStateAndDuration(
  order: EOrder,
  machines: IMachine[],
  machineStateMap: TMachineStateMap,
): IMachine[] {
  const machinesToSort = machines.slice();
  return machinesToSort.sort((a: IMachine, b: IMachine): number => {
    const machineStateA = getFromMapOrThrow(machineStateMap, a.id);
    const machineStateB = getFromMapOrThrow(machineStateMap, b.id);
    return sortElementPairByStateAndDuration(
      machineStateA,
      machineStateB,
      order,
    );
  });
}
