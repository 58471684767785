import i18next, { TFunction } from "i18next";
import { TIndicatorName } from "../../config/states/STATE_COLOR_MAP";
import resources from "../../@types/resources";

export interface IIndicatorTranslation {
  name: string;
  description: string;
  footer: string;
}

type indicatorTranslationKeys = keyof typeof resources.indicator;

export default function getIndicatorTranslationByStateName(
  indicatorName: TIndicatorName,
  t: TFunction,
): IIndicatorTranslation {
  let name = "",
    description = "",
    footer = "";
  if ((indicatorName as indicatorTranslationKeys) === indicatorName) {
    name = t(`indicator.${indicatorName}.name`);
    description = t(`indicator.${indicatorName}.description`);
    footer = t(`indicator.${indicatorName}.footer`);
  }
  if (name === "" || description === "") {
    // eslint-disable-next-line no-console
    console.error(`missing translation for indicator.${indicatorName}`);
  }
  if (
    !i18next.exists(`indicator.${indicatorName}.name`) ||
    !i18next.exists(`indicator.${indicatorName}.description`)
  ) {
    // eslint-disable-next-line no-console
    console.error(
      `Attention! Could not translate the dynamic state name >>${indicatorName}<<. Please check the translation files for this specific key!`,
    );
  }
  return { name, description, footer };
}
