import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  button: {
    borderLeftWidth: 0.5,
    borderLeftStyle: "solid",
    textAlgin: "center",
    width: 160,
    opacity: 0.85,
    "@media (max-width: 899px) ": {
      width: 100,
    },
    transition: "opacity 0.3s ease", // Add a transition for smooth effect
    "&:hover": {
      opacity: 1,
    },
  },
  menuPanel: {
    top: "64px !important",
  },
  menuItems: {
    padding: 10,
    // width: 160
  },
  menuItemLabel: {
    marginLeft: 20,
  },
  menuList: {
    width: 160,
  },
}));

export default useStyles;
