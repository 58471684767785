import IConfigElement from "./IIndicatorConfigElement";
import { TIndicatorType } from "../../helper";
import STATE_COLOR_MAP, { TIndicatorName } from "./STATE_COLOR_MAP";
/*
  returns conf without ids filled in
 */
function getBaseConfig() {
  const keys = Object.keys(STATE_COLOR_MAP) as TIndicatorName[];
  const baseConf: IConfigElement[] = keys.map((key: TIndicatorName) => {
    return {
      displayName: key,
      id: "",
      color: STATE_COLOR_MAP[key as TIndicatorName],
    };
  });
  return baseConf;
}
function mapBaseConfIntoConfig(
  baseConf: IConfigElement[],
  allIndicators: TIndicatorType[],
) {
  return baseConf.map((c: IConfigElement) => {
    const found = allIndicators.find((indicator: TIndicatorType) => {
      return indicator.indicatorName === c.displayName;
    });
    let id = "";
    if (found) {
      id = found.id;
    }
    return { ...c, id };
  });
}
export default function getIndicatorConfigList(
  allIndicatorIds: TIndicatorType[],
  wantedIds: string[],
): IConfigElement[] {
  const baseConf = getBaseConfig();
  const configElements = mapBaseConfIntoConfig(baseConf, allIndicatorIds);
  return wantedIds.map((wantedId: string) => {
    const found = configElements.find((el) => el.id === wantedId);
    if (!found) {
      throw new Error(
        `Could not find wanted id ${wantedId} in config elements ${JSON.stringify(
          configElements,
        )}`,
      );
    }
    return found;
  });
}
