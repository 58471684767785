import IShiftConfigEntry from "../../../../../../api/shifts/app/IShiftConfigEntry";

export function getUpdatedObjects(
  backendData: IShiftConfigEntry[],
  frontendData: IShiftConfigEntry[],
): IShiftConfigEntry[] {
  const updatedObjects: IShiftConfigEntry[] = [];

  frontendData.forEach((frontendEntry) => {
    const backendEntry = backendData.find(
      (entry) => parseInt(entry.id, 10) === parseInt(frontendEntry.id, 10),
    );
    if (backendEntry) {
      // Compare the properties of backendEntry and frontendEntry
      if (
        backendEntry.startTime !== frontendEntry.startTime ||
        backendEntry.endTime !== frontendEntry.endTime ||
        backendEntry.isActive !== frontendEntry.isActive
      ) {
        updatedObjects.push(frontendEntry);
      }
    } else {
      if (frontendEntry.isActive === true) {
        // if the entry is not in the backendData, but is active, it should be added
        if (frontendEntry.isActive) {
          updatedObjects.push(frontendEntry);
        }
      }
    }
  });

  return updatedObjects;
}
