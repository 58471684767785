import React from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useStyles from "./styles";
import { TooltipCell } from "../../../../../../../shared/components/georgDataGrid/components/tooltipCell";
interface IProps {
  onUpClick: () => void;
  onDownClick: () => void;
  upTooltipText: string;
  downTooltipText: string;
}
export default function UpDownArrowButtons({
  onUpClick,
  onDownClick,
  upTooltipText,
  downTooltipText,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  return (
    <div>
      <TooltipCell tooltipText={upTooltipText}>
        <div className={classes.upArrow} onClick={() => onUpClick()}>
          <ArrowDropUpIcon />
        </div>
      </TooltipCell>
      <TooltipCell tooltipText={downTooltipText}>
        <div className={classes.downArrow} onClick={() => onDownClick()}>
          <ArrowDropDownIcon />
        </div>
      </TooltipCell>
    </div>
  );
}
