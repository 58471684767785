import { Locale, de, enUS, fr, it, ru, tr, zhCN, ar } from "date-fns/locale";
export default function getDateFnsLocaleByString(localeStr: string): Locale {
  switch (localeStr) {
    case "de":
      return de;
    case "ar":
      return ar;
    case "fr":
      return fr;
    case "it":
      return it;
    case "ru":
      return ru;
    case "tr":
      return tr;
    case "zh":
      return zhCN;
    case "en":
    default:
      return enUS;
  }
}
