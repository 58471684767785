import React from "react";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import GeorgButton, { EButtonType } from "../GeorgButton";

interface IProps {
  onSaveClicked: () => void;
  onCancelClicked: () => void;
  disabled?: boolean;
  isCancelAlwaysEnabled?: boolean;
}

export default function SaveCancelBtnPair({
  onSaveClicked,
  onCancelClicked,
  disabled = false,
  isCancelAlwaysEnabled = true,
}: IProps): React.ReactElement {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const isCancelDisabled = disabled && !isCancelAlwaysEnabled;
  return (
    <div className={classes.saveCancelBox}>
      <GeorgButton
        buttonType={isCancelDisabled ? EButtonType.DARK : EButtonType.LIGHT}
        label={t("common.cancelSaveBtns.cancelBtn")}
        onClick={() => onCancelClicked()}
        disabled={isCancelDisabled}
      />
      <GeorgButton
        disabled={disabled}
        label={t("common.cancelSaveBtns.saveBtn")}
        onClick={() => onSaveClicked()}
      />
    </div>
  );
}
