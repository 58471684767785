import React, { useLayoutEffect, useState } from "react";
import GeorgButton from "../GeorgButton";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { useMediaQuery, Alert, AlertTitle } from "@mui/material";
import { useTheme } from "@mui/material/styles";
interface IProps {
  children: React.ReactElement;
}

export default function ScreenResolutionWarning({
  children,
}: IProps): React.ReactElement {
  const theme = useTheme();
  const isWindowLarge = useMediaQuery(theme.breakpoints.up("md"));
  const [isShowingWarning, setIsShowingWarning] =
    useState<boolean>(!isWindowLarge);
  useLayoutEffect(() => {
    setIsShowingWarning(!isWindowLarge);
  }, [isWindowLarge]);
  const { t } = useTranslation();
  const { classes } = useStyles();
  if (isShowingWarning) {
    return (
      <div className={classes.container}>
        <div className={classes.alert}>
          <Alert severity="info">
            <AlertTitle>{t("shared.deviceWarning.title")}</AlertTitle>
            {t("shared.deviceWarning.text")}
          </Alert>
        </div>
        <GeorgButton
          label={t("shared.deviceWarning.buttonLabel")}
          onClick={() => {
            setIsShowingWarning(false);
          }}
        />
      </div>
    );
  } else {
    return children;
  }
}
