import { makeStyles } from "tss-react/mui";

const baseStyle = {
  width: "100%",
  display: "flex",
};

const useStyles = makeStyles()(() => ({
  submitBtnWrapper: {
    "& .MuiButtonBase-root": {
      height: 42,
    },
    flexDirection: "column",
    ...baseStyle,
  },
}));
export default useStyles;
