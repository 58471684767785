import gql from "graphql-tag";

export default gql`
  mutation updateJob(
    $jobId: String!
    $jobData: JobUpdateInputType!
    $vid: Int!
  ) {
    updateJob(jobId: $jobId, jobUpdateData: $jobData, vid: $vid) {
      vid
      abortMessage
      abortedAt
      createdBy
      createdById
      deletedAt
      description
      doneAt
      id
      jobId
      machineType {
        id
      }
      priority
      scheduledAt
    }
  }
`;
