import gql from "graphql-tag";

export default gql`
  query {
    getAzureB2cUsers {
      displayName
      givenName
      id
      mail
      surname
      preferredLanguage
    }
  }
`;
