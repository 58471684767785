import { useMemo } from "react";
import { EApiState } from "../../../../../../api";
import { IJobFromServer } from "../../../../../../api/jobs/interfaces/IJobSettings";
import uncompleteJob from "../../../../../../api/jobs/mutations/uncompleteJob";
import completeJob from "../../../../../../api/jobs/mutations/completeJob";
import { IJobMutationVariables } from "../../../../../../api/jobs/interfaces/IJobMutationVariables";
import getJobList from "../../../../../../api/jobs/queries/getJobList";
import { useMutation } from "@apollo/client";
import { IJobQueryVariables } from "../queries";

export interface IChangeIsDoneCallback {
  jobId: string;
  vid: number;
  fetchVariables: IJobQueryVariables;
}

export function useChangeActiveMutation(isActiveMutation: boolean): {
  changeIsActiveMutation: (changeActiveParams: IChangeIsDoneCallback) => void;
  apiState: EApiState;
  changeActivateError?: string;
} {
  const mutation = isActiveMutation ? completeJob : uncompleteJob;
  const [mutateFunction, { data, loading, error }] =
    useMutation<IJobFromServer>(mutation);

  const getApiState = useMemo(() => {
    const hasError = error != undefined;
    if (!hasError && !loading && data != null) {
      return EApiState.OK;
    } else if (hasError) {
      return EApiState.ERROR;
    } else if (loading) {
      return EApiState.LOADING;
    } else {
      return EApiState.EMPTY;
    }
  }, [loading, error, data]);

  return {
    changeIsActiveMutation: (
      changeActiveParams: IChangeIsDoneCallback,
    ): void => {
      const variables: IJobMutationVariables = {
        jobId: changeActiveParams.jobId,
        vid: changeActiveParams.vid,
      };
      mutateFunction({
        variables,
        refetchQueries: [
          {
            query: getJobList,
            variables: {
              filter: {
                aborted: changeActiveParams.fetchVariables.aborted,
                deleted: changeActiveParams.fetchVariables.deleted,
                done: changeActiveParams.fetchVariables.done,
                machineTypeId: changeActiveParams.fetchVariables.machineTypeId,
              },
            },
          },
        ],
      }).catch(
        // eslint-disable-next-line no-console
        console.error,
      );
    },
    apiState: getApiState,
    changeActivateError: error?.message,
  };
}
