import React from "react";
import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
// Custom Imports.
import { IHeader, ITabs } from "../../shared/components";
import useStyles from "./styles";
import NewsView from "./views/newsView";
import { useLocation } from "react-router-dom";
import NotFound from "../notFound";

// Refactor here
export default function Home(): React.ReactElement {
  const location = useLocation();
  const knownPaths = ["/home"];
  const isKnownPath = knownPaths.includes(location.pathname);
  if (!isKnownPath) {
    return <NotFound />;
  }
  const { classes } = useStyles();
  const { t } = useTranslation();

  // useDefaultPage("/home", "/home/news");

  return (
    <Container className={classes.container}>
      <IHeader
        headline={t("headers.startPage.headline")}
        headlineColor="primary"
        headlineVariant="h3"
        tagline={t("headers.startPage.tagline")}
        taglineColor="primary"
        taglineVariant="body1"
      />
      <ITabs
        disableContentPadding
        disableRipple
        tabs={[{ label: t("navigations.newsAndUpdates"), path: "home" }]}
        tabContent={[<NewsView />]}
        tabVariant="fullWidth"
      />
    </Container>
  );
}
