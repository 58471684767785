/*
 *
 *   Stacklight view for machine dashboard.
 *
 */

import React, { useMemo } from "react";
import GridPage from "../../../../../shared/components/gridPage";
import getLayoutInfo, {
  BAR_CHART_LAOUT_KEY,
  HEATMAP_LAYOUT_KEY,
  TResizeConfig,
} from "./layoutConfig";
import MachineStateBarChartStacked from "../../../../../components/charts/machineStateBarChartStacked";
import MachineStateHeatmapCard from "../../../../../components/charts/machineStateHeatmapCard";
import indicatorNamesToIndicatorIds from "../../../../../helper/indicator/indicatorNamesToIndicatorIds";
import { useSelector } from "react-redux";
import { selectIndicator } from "../../../../../redux/reducers/indicatorReducer";

export default function StacklightView(): React.ReactElement {
  const allIndicators = useSelector(selectIndicator);
  const indicatorIds = useMemo(() => {
    if (allIndicators.length > 0) {
      return indicatorNamesToIndicatorIds(
        [
          "indicator_state_undefined",
          "indicator_state_notavailable",
          "indicator_state_idle",
          "indicator_state_executing",
        ],
        allIndicators,
      );
    }
    return [];
  }, [allIndicators]);

  const hiddenTooltipDataKeys = useMemo(() => {
    if (allIndicators.length > 0) {
      return indicatorNamesToIndicatorIds(
        ["indicator_state_notavailable"],
        allIndicators,
      );
    }
    return [];
  }, [allIndicators]);

  const layoutConf = useMemo(
    () => getLayoutInfo({} as TResizeConfig),
    [indicatorIds],
  );

  return (
    <GridPage layouts={layoutConf} containerPadding={[10, 10]}>
      <div key={BAR_CHART_LAOUT_KEY}>
        <MachineStateBarChartStacked indicatorIds={indicatorIds} />
      </div>
      <div key={HEATMAP_LAYOUT_KEY}>
        <MachineStateHeatmapCard
          indicatorIds={indicatorIds}
          hiddenTooltipDataKeys={hiddenTooltipDataKeys}
        />
      </div>
    </GridPage>
  );
}
