import IVariable from "./IVariable";
import ICategory from "./ICategory";
import {
  IResponsible,
  IServiceOrderSettingsFromServer,
} from "./IServiceOrderSettings";
import IComponent from "./IComponent";

export const VARIABLES: IVariable[] = [
  { name: "Produced KM", unit: "km", value: 4950, id: "0" },
  { name: "Runtime in hours", unit: "h", value: 1403, id: "1" },
  { name: "Rotation in thousands", unit: "k", value: 8000.232, id: "2" },
  { name: "Times pressed in thousands", unit: "k", value: 42.543, id: "3" },
];

export const CATEGORIES: ICategory[] = [
  { name: "Lubrication", id: "1" },
  { name: "Oil change", id: "2" },
  { name: "Adjustments", id: "3" },
  { name: "Polishing", id: "4" },
  { name: "Cleaning", id: "5" },
];

export const COMPONENTS: IComponent[] = [
  { id: "0", name: "Joints" },
  { id: "1", name: "Main Gearbox" },
  { id: "2", name: "Viewing window" },
];

const defaultResponsible: IResponsible = {
  id: "00175402-8b82-4791-8a1d-6a868c31284c",
  firstName: "Matthias",
  lastName: "Wennemer",
};

/*
  Nested object structure due to GraphQL
 */
export const SERVICE_ORDERS: IServiceOrderSettingsFromServer[] = [
  {
    id: "0",
    category: CATEGORIES[0],
    name: "Lubrication",
    component: COMPONENTS[0],
    description: "Lubricate all joints",
    isActive: true,
    preWarning: 0.95,
    responsible: defaultResponsible,
    variable: VARIABLES[2],
    interval: 4500,
  },
  {
    id: "1",
    name: "Oil change",
    category: CATEGORIES[1],
    component: COMPONENTS[1],
    description: "Oil change for the main gearbox",
    isActive: true,
    preWarning: 0.8,
    responsible: defaultResponsible,
    variable: VARIABLES[1],
    interval: 1800,
  },
  {
    id: "2",
    name: "Polishing",
    category: CATEGORIES[3],
    component: COMPONENTS[2],
    description: "Polishing all safety viewing windows",
    isActive: true,
    preWarning: 1,
    responsible: defaultResponsible,
    variable: VARIABLES[0],
    interval: 2100,
  },
];
