import gql from "graphql-tag";

export default gql`
  mutation createAzureB2cUser($azureB2cUserData: AzureB2cUserInputType!) {
    createAzureB2cUser(azureB2cUserData: $azureB2cUserData) {
      message
      statusCode
    }
  }
`;
