import TKPIType from "../../../../../api/kpi/TKPIType";
import { TTimeScaleKey } from "../../../../../api/time/TimeScaleUnitMap";
import KpiAvgDatalakeDataType from "../IkpiAvgDatalakeDataType";
import IKpiAvgResponse from "../IKpiAvgResponse";
import kpiAvgResponseToEmptyLineChart from "./kpiAvgResponseToEmptyLineChart";
import * as luxon from "luxon";
import TScale from "../../../../../api/time/TScale";
import { TIndicatorType } from "../../../../../helper";
import indicatorIdToIndicatorType from "../../../../../helper/indicator/indicatorIdToIndicatorType";
import {
  createDateTime,
  processShiftEntries,
  createAdjustedUnit,
  calculateNewDate,
  parseNullableValue,
} from "./helper/prcocessShiftEntries";
import { IsoDateToTimezoneDate } from "../../../../../helper/time/formatting/dateToIsoUtils";

export type IKPILineChartDataEntry = {
  unit: { [key in TTimeScaleKey]: string };
  value: number | null;
  indicatorName: string;
};

export interface IKPILineChartDataNew {
  indicatorTypeId: number;
  indicator: TKPIType;
  lineChartDataEntries: IKPILineChartDataEntry[];
}
export interface IKPILineChartData {
  [key: string]: IKPILineChartDataNew | undefined;
}
export type TKPILineChartDataKey = "kpiTechAvailability" | "kpiOrgAvailability";

/**
 * Adjusts the entry time to align with the shift start or end time.
 */
type ShiftData = {
  [key: string]: {
    shiftStartTimestamp: string;
    shiftEndTimestamp: string;
  };
};

function getDateFromWeekAndYear(
  year: number | undefined,
  week: number | undefined,
  timezone: string,
): luxon.DateTime {
  if (year === undefined || week === undefined) {
    throw new Error("Year and week must be defined by week scale");
  }
  // Create a DateTime for the first day of the year
  let dt = luxon.DateTime.fromObject(
    { year: year, month: 1, day: 1 },
    { zone: timezone },
  );

  // Adjust to the first ISO week start date
  if (dt.weekday !== 1) {
    dt = dt.plus({ weeks: 1 }).startOf("week");
  } else {
    dt = dt.startOf("week");
  }

  // Move to the correct week (subtract 1 because dt is already the first week)
  dt = dt.plus({ weeks: week - 1 });

  return dt;
}

function createDateTimeFromEntry(
  entry: IKPILineChartDataEntry,
  timezone: string,
): luxon.DateTime {
  return luxon.DateTime.fromObject(
    {
      year: parseNullableValue(entry.unit.year),
      month:
        entry.unit.monthIndex !== "null"
          ? parseInt(entry.unit.monthIndex, 10) + 1
          : undefined,
      day: parseNullableValue(entry.unit.day),
      hour: parseNullableValue(entry.unit.hour),
    },
    { zone: timezone },
  );
}

// Sample function to list all entries for each date in the shift data
function listEntriesForEachShiftDate(
  entries: KpiAvgDatalakeDataType[],
  workShiftData: ShiftData,
  timezone: string,
) {
  return Object.entries(workShiftData).map(
    ([shiftDate, { shiftStartTimestamp, shiftEndTimestamp }]) => {
      const shiftStart = IsoDateToTimezoneDate(shiftStartTimestamp, timezone);
      const shiftEnd = IsoDateToTimezoneDate(shiftEndTimestamp, timezone);

      // Filter entries that fall within the shift's day
      const relevantEntries = entries.filter((entry) => {
        // Create a DateTime for each entry based on year, month, day, and hour
        const entryDate = luxon.DateTime.fromObject(
          {
            year: entry.year,
            month: entry.monthIndex + 1, // Adjust for zero-based month index
            day: entry.day,
            hour: entry.hour,
          },
          { zone: timezone },
        );

        // Adjust to include entries that are within one hour before the shift start
        const isEntryNearShiftStart =
          entryDate >= shiftStart.minus({ hours: 1 });
        const isEntryWithinShift = entryDate <= shiftEnd;

        return isEntryNearShiftStart && isEntryWithinShift;
      });

      return {
        shiftDate,
        shiftStart: shiftStart.toISO(),
        shiftEnd: shiftEnd.toISO(),
        entries: relevantEntries,
      };
    },
  );
}

function fillMissingDataEntries(
  series: IKPILineChartDataNew,
  missingValues: number,
  interval: luxon.Duration,
  lastEntry: IKPILineChartDataEntry,
  timezone: string,
  indicator: TIndicatorType,
) {
  let dateCursor = calculateNewDate(lastEntry, timezone);
  for (let i = 1; i < missingValues; i++) {
    dateCursor = dateCursor.plus(interval);
    series.lineChartDataEntries.push({
      unit: createAdjustedUnit(dateCursor, true),
      value: null, // No data available for these intervals
      indicatorName: indicator.indicatorName,
    });
  }
}

function initializeSeries(
  entry: KpiAvgDatalakeDataType,
  indicator: TIndicatorType,
): IKPILineChartDataNew | null {
  if (!indicator) return null; // Ensure null is returned if no indicator is found

  return {
    indicatorTypeId: parseInt(indicator.id, 10),
    indicator: indicator.indicatorName as TKPIType,
    lineChartDataEntries: [], // Directly return a single series object
  };
}

function updateAndFillChartData(
  series: IKPILineChartDataNew | undefined,
  currentEntry: KpiAvgDatalakeDataType,
  indicators: TIndicatorType[],
  timezone: string,
  scale: TScale,
) {
  if (!series) {
    return;
  }
  const { year, monthIndex, week, day, hour, indicatorIdentificationTypeId } =
    currentEntry;
  const currentDate =
    scale === "WEEKS"
      ? getDateFromWeekAndYear(year, week, timezone)
      : createDateTime(year, monthIndex, day, hour, undefined, timezone);
  const currentIndicator = indicatorIdToIndicatorType(
    indicators,
    `${indicatorIdentificationTypeId}`,
  );

  const existingEntry = series.lineChartDataEntries.at(-1);
  if (existingEntry) {
    const existingDate =
      scale === "WEEKS"
        ? getDateFromWeekAndYear(
            parseNullableValue(existingEntry.unit.year),
            parseNullableValue(existingEntry.unit.week),
            timezone,
          )
        : createDateTimeFromEntry(existingEntry, timezone);
    const { missingValues, interval } = calculateMissingEntries(
      currentDate,
      existingDate,
      scale,
    );
    fillMissingDataEntries(
      series,
      missingValues,
      interval,
      existingEntry,
      timezone,
      currentIndicator,
    );
  }
  appendCurrentDataEntry(series, currentEntry, currentIndicator, currentDate);
}

function calculateMissingEntries(
  currentDate: luxon.DateTime,
  existingDate: luxon.DateTime,
  scale: TScale,
) {
  const intervalMapping = {
    YEARS: { years: 1 },
    MONTHS: { months: 1 },
    WEEKS: { weeks: 1 },
    DAYS: { days: 1 },
  };

  const interval = intervalMapping[scale];
  const adjustedScale = Object.keys(interval)[0];
  let missingValues = currentDate
    .diff(existingDate, adjustedScale)
    .as(adjustedScale);

  if (missingValues % 1 !== 0) {
    missingValues = 0;
  }

  return { missingValues, interval };
}

function appendCurrentDataEntry(
  series: IKPILineChartDataNew,
  entry: KpiAvgDatalakeDataType,
  indicator: TIndicatorType,
  currentEntry: luxon.DateTime,
) {
  const { year, monthIndex, week, day } = entry;
  series.lineChartDataEntries.push({
    unit: {
      dayNameShort: currentEntry.weekdayShort,
      monthNameShort: currentEntry.monthShort,
      year: `${year}`,
      monthIndex: `${monthIndex}`,
      week: `${week ? week : "null"}`,
      day: `${day ? day : "null"}`,
      hour: `null`,
      minute: `null`,
    },
    value: entry.value,
    indicatorName: indicator.indicatorName,
  });
}

/*
    This code transforms a flat list of KPI Entries for DIFFERENT indicatorIdentificationTypes into a structured object format.
    Currently this code is statically structured for org availability and tech availability only due to YAGNI principle.
 */
export default function kpiEntryReducer(
  fromServer: IKpiAvgResponse,
  endPeriod: string,
  indicators: TIndicatorType[],
  scale: TScale,
  timezone: string,
): IKPILineChartData | null {
  const emptyLineChartData: IKPILineChartData | null =
    kpiAvgResponseToEmptyLineChart(fromServer, indicators);

  // If server response or initial chart data is missing, return early
  if (!fromServer.getAvgOfKpiEntriesWithWorkShifts || !emptyLineChartData) {
    return emptyLineChartData;
  }

  // Extract entries from server response
  const entries: KpiAvgDatalakeDataType[] | null =
    fromServer.getAvgOfKpiEntriesWithWorkShifts.kpiData;

  // Extract work shift data for time adjustments
  const workShiftData =
    fromServer.getAvgOfKpiEntriesWithWorkShifts.workShiftData;

  // Return null if no entries are present
  if (!entries) {
    return null;
  }

  // When the scale is HOURS, process entries based on work shifts
  if (scale === "HOURS") {
    const shiftsWithEntries = listEntriesForEachShiftDate(
      entries,
      workShiftData,
      timezone,
    );

    processShiftEntries(
      shiftsWithEntries,
      endPeriod,
      emptyLineChartData,
      indicators,
      scale,
      timezone,
    );

    return emptyLineChartData;
  }

  entries.forEach((entry) => {
    const indicator = indicatorIdToIndicatorType(
      indicators,
      `${entry.indicatorIdentificationTypeId}`,
    );
    if (!emptyLineChartData[indicator.indicatorName]) {
      const newSeries = initializeSeries(entry, indicator);
      if (newSeries) {
        emptyLineChartData[indicator.indicatorName] = newSeries;
      } else {
        // Handle the case where no series could be initialized
        throw new Error("Failed to initialize series for LineChart data");
      }
    }
    updateAndFillChartData(
      emptyLineChartData[indicator.indicatorName],
      entry,
      indicators,
      timezone,
      scale,
    );
  });
  return JSON.parse(JSON.stringify(emptyLineChartData));
}
