import { useMemo } from "react";
import { EApiState } from "../../../../../../api";
import {
  IJobData,
  IJobFromServer,
} from "../../../../../../api/jobs/interfaces/IJobSettings";
import { useMutation } from "@apollo/client";
import deleteJob from "../../../../../../api/jobs/mutations/deleteJob";
import getJobList from "../../../../../../api/jobs/queries/getJobList";
import { IJobQueryVariables } from "../queries";

export interface IRemoveJobMutation {
  jobDelete: IJobData;
  fetchVariables: IJobQueryVariables;
}
interface IDeleteJobResponse {
  deleteJob: IJobFromServer;
}

export function useRemoveJobMutation(): {
  removeJobMutation: (removeJobParams: IRemoveJobMutation) => void;
  apiState: EApiState;
  deleteError?: string;
} {
  const [mutateFunction, { data, loading, error }] =
    useMutation<IDeleteJobResponse>(deleteJob);

  const getApiState = useMemo(() => {
    const hasError = error != undefined;
    if (!hasError && !loading && data != null) {
      return EApiState.OK;
    } else if (hasError) {
      return EApiState.ERROR;
    } else if (loading) {
      return EApiState.LOADING;
    } else {
      return EApiState.EMPTY;
    }
  }, [loading, error, data]);

  return {
    removeJobMutation: (removeJobParams: IRemoveJobMutation): void => {
      mutateFunction({
        variables: {
          jobId: removeJobParams.jobDelete.jobId,
          vid: removeJobParams.jobDelete.vid,
        },
        refetchQueries: [
          {
            query: getJobList,
            variables: {
              filter: {
                aborted: removeJobParams.fetchVariables.aborted,
                deleted: removeJobParams.fetchVariables.deleted,
                done: removeJobParams.fetchVariables.done,
                machineTypeId: removeJobParams.fetchVariables.machineTypeId,
              },
            },
          },
        ],
      }).catch(
        // eslint-disable-next-line no-console
        console.error,
      );
    },
    apiState: getApiState,
    deleteError: error?.message,
  };
}
