import React, { useContext } from "react";
import { FormLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import GeorgStorageContext from "../../../../../../context/GeorgStorageContext";
import Toggle from "../../../../../../shared/components/Toggle";

export default function PreviewEnableSelection(): React.ReactElement {
  const { isPreviewEnabled, setPreviewEnabled } =
    useContext(GeorgStorageContext);

  const { t } = useTranslation();
  return (
    <>
      <div>
        <FormLabel id="demo-controlled-radio-buttons-group">
          {t("profile.dashboard.preview.title")}
        </FormLabel>
      </div>
      <Toggle
        isChecked={isPreviewEnabled}
        onChange={() => {
          setPreviewEnabled(!isPreviewEnabled);
        }}
      />
    </>
  );
}
