import { AreaChart, ResponsiveContainer, XAxis } from "recharts";
import React, { useMemo } from "react";
import useStyles from "./styles";
import { TScaleValueFromServer } from "../../../../../../components/charts/machineStateHeatmapCard/api/transformation/calculateStartEndDateOffset/calculateStartEndDateOffset";
import moment from "moment";
import { colors } from "../../../../../theme";
import {
  getAxisResolutionUnitNumber,
  getGapForPeriod,
} from "../../../../../../components/charts/machineStateHeatmapCard/api/transformation/dateRangeScaler/dateRangeScaler";

export interface IAxisPoints {
  t: string;
  value: number;
}
interface IProps {
  scale: TScaleValueFromServer;
  locale: string;
  startIndex?: number;
  endIndex?: number;
}
interface IAxisEntry {
  t: string;
}
function createSteps(
  duration: number,
  format: string,
  startIndex: number,
): IAxisEntry[] {
  const totalSteps = 24;
  const stepSize = duration / totalSteps;
  const arr: IAxisEntry[] = [];
  for (let i = 0; i <= totalSteps; i++) {
    const step = stepSize * i;
    arr.push({
      t: moment
        .utc(new Date(new Date(0).getTime() + step + startIndex).getTime())
        .format(format),
    });
  }
  return arr;
}

function getDateFormat(startIndex: number, endIndex: number): IAxisEntry[] {
  const hourDuration = 60 * 60 * 1000;
  const duration = (endIndex - startIndex) * hourDuration;
  return createSteps(duration, "HH:mm", startIndex * hourDuration);
}

function getYearFormat(startIndex, endIndex, locale): IAxisEntry[] {
  const monthNames: IAxisEntry[] = [];
  for (let i = startIndex; i <= endIndex; i++) {
    monthNames.push({
      t: moment()
        .locale(locale)
        .month(i % 12 || 12)
        .format("MMMM"),
    });
  }
  return monthNames;
}

function getMonthFormat(startIndex, endIndex): IAxisEntry[] {
  const days: IAxisEntry[] = [];
  if (endIndex - startIndex === 1) {
    return getDateFormat(0, 24);
  }
  for (let i = startIndex + 1; i <= endIndex + 1; i++) {
    const dayIndex = i % 31 || 31;
    days.push({
      t: dayIndex < 10 ? `0${dayIndex}` : `${dayIndex}`,
    });
  }
  return days;
}
//
function getWeekFormat(startIndex, endIndex, locale): IAxisEntry[] {
  const dayNames: IAxisEntry[] = [];
  if (endIndex - startIndex === 1) {
    dayNames.push({
      t: moment()
        .locale(locale)
        .day((startIndex + 1) % 7 || 7)
        .format("dd"),
    });
    dayNames.push(...getDateFormat(1, 23));
    dayNames.push({
      t: moment()
        .locale(locale)
        .day((endIndex + 1) % 7 || 7)
        .format("dd"),
    });
    return dayNames;
  }
  for (let i = startIndex + 1; i <= endIndex + 1; i++) {
    const dayIndex = i % 7 || 7; // Ensures Sunday is 7 and not 0
    dayNames.push({ t: moment().locale(locale).day(dayIndex).format("dd") });
    if (i < endIndex + 1) {
      dayNames.push({
        t: moment()
          .startOf("day")
          .add(i, "days")
          .add(12, "hours")
          .format("HH:mm"),
      });
    }
  }
  return dayNames;
}

export default function HeatmapXAxis({
  scale,
  locale,
  startIndex = 0,
  endIndex = getAxisResolutionUnitNumber(scale || "days") - 1,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  const arr: { t: string }[] = useMemo(() => {
    const xAxisData: IAxisEntry[] = [];
    if (scale != null) {
      const startXIndex = Math.round(startIndex / getGapForPeriod(scale));
      const endXIndex = Math.round((endIndex + 1) / getGapForPeriod(scale));
      switch (scale) {
        case "weeks":
          return getWeekFormat(startXIndex, endXIndex, locale);
        case "months":
          return getMonthFormat(startXIndex, endXIndex);
        case "years":
          return getYearFormat(startXIndex, endXIndex, locale);
        default:
          return getDateFormat(startXIndex, endXIndex);
      }
    }
    return xAxisData;
  }, [scale, startIndex, endIndex]);

  return (
    <div>
      <div className={classes.specialcontainer}>
        <ResponsiveContainer width={"100%"} height={40}>
          <AreaChart
            data={arr}
            margin={{ top: 0, bottom: 5, left: 1, right: 1 }}
          >
            <XAxis
              type={"category"}
              dataKey={"t"}
              interval="preserveStartEnd"
              tick={{
                fill: colors.blue_base,
                fontSize: 14,
                fontWeight: 300,
              }}
              axisLine={false}
              minTickGap={10}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
