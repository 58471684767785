/*
 *
 *   ItemIconWithTooltip Component
 *
 */
import React from "react";
import { ListItemIcon } from "@mui/material";
// Custom Imports.
import { ITooltipInfo } from "../../../../../components/withTooltip";
import WithToggableTooltip from "../../../../../components/withToggableTooltip";

//------------------------------------------------------------------------------------------------------------
// Interfaces & Types.
interface IProps {
  icon: JSX.Element;
  onHover?: (event: React.MouseEvent) => void;
  style?: React.CSSProperties;
  tooltipInfo?: ITooltipInfo;
}

//------------------------------------------------------------------------------------------------------------
// Component.
export default function ItemIconWithTooltip({
  icon,
  onHover,
  style,
  tooltipInfo,
}: IProps): React.ReactElement {
  return (
    <div onMouseEnter={onHover} onMouseLeave={onHover}>
      <WithToggableTooltip
        tooltipInfo={tooltipInfo}
        icon={icon}
        placement={"top"}
        forceEnable={true}
      >
        <ListItemIcon style={style}>{icon}</ListItemIcon>
      </WithToggableTooltip>
    </div>
  );
}
