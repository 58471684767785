import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // margin: 10,
  },
}));

export default useStyles;
