import { Paper } from "@mui/material";
import TooltipRow from "./tooltipRow";
import React, { useMemo } from "react";
import useStyles from "./styles";

interface IStateTooltipConfig {
  label: string;
  color?: string;
}
interface IProps {
  label?: string;
  subLabel?: string;
  config: IStateTooltipConfig[];
}

function StateTooltip({ config, label, subLabel }: IProps): React.ReactElement {
  const { classes } = useStyles();
  const rows = useMemo(() => {
    return config
      .filter((t) => t.label.length > 0)
      .map((item, index) => {
        return <TooltipRow key={index} color={item.color} label={item.label} />;
      });
  }, [config]);
  if (rows.length === 0) return <></>;
  return (
    <Paper className={classes.stateTooltipWrapper}>
      <TooltipRow label={label != null ? label : ""} />
      <TooltipRow label={subLabel != null ? subLabel : ""} />
      {rows}
    </Paper>
  );
}
export default React.memo(StateTooltip);
