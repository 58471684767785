import { Skeleton } from "@mui/material";
import React from "react";
import useStyles from "../styles";

export default function LineChartLegendSkeleton(): React.ReactElement {
  const { classes } = useStyles();
  // Indicator to color mapping.
  return (
    <div className={classes.legendContainer}>
      <Skeleton
        variant="text"
        width="10%"
        height="10%"
        animation="pulse"
        style={{ alignSelf: "center" }}
      />
    </div>
  );
}
