import React from "react";
import GeorgModal from "../modal";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import GeorgButton, { EButtonType } from "../GeorgButton";

interface IProps {
  isModalVisible: boolean;
  onClose: () => void;
  title: string;
  question: string;
  onCancel: () => void;
  onSubmit: () => void;
}

export default function YesNoDialog({
  isModalVisible,
  onClose,
  title,
  question,
  onCancel,
  onSubmit,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <GeorgModal isModalVisible={isModalVisible} onClose={onClose} title={title}>
      <>
        <div className={classes.question}>{question}</div>
        <div className={classes.buttons}>
          <GeorgButton
            buttonType={EButtonType.LIGHT}
            label={t("shared.components.yesNoDialog.no")}
            onClick={() => {
              onCancel();
              onClose();
            }}
          />
          <GeorgButton
            label={t("shared.components.yesNoDialog.yes")}
            onClick={() => {
              onSubmit();
              onClose();
            }}
          />
        </div>
      </>
    </GeorgModal>
  );
}
