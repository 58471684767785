import { ApolloError, useLazyQuery } from "@apollo/client";
import indicators from "./getIndicatorIdQuery";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setDowntime } from "../../redux/reducers/downtimeReducer";

type TResponse = [
  doQuery: () => void,
  resp: {
    error: ApolloError | undefined;
    isLoading: boolean;
  },
];

// This hook is used to fetch the indicator list with the given IndicatorListName and dispatch it to the redux store.
export default function useLazyQueryIndicatorIdListAndDispatchToReduxStore(
  indicatorName: string,
): TResponse {
  const dispatch = useDispatch();
  const [doQuery, { data, loading, error: indicatorListQueryErr }] =
    useLazyQuery(indicators, {
      variables: { indicatorListName: indicatorName },
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (
      !indicatorListQueryErr &&
      !loading &&
      data != null &&
      data.indicators != null
    ) {
      dispatch(setDowntime(data?.indicators));
    }
  }, [data]);
  return [doQuery, { isLoading: loading, error: indicatorListQueryErr }];
}
