import React, { useContext, useMemo } from "react";
import { IServiceOrderSettingsUpdate } from "../../api/IServiceOrderSettings";
import GeorgDataGrid from "../../../../../../../shared/components/georgDataGrid";
import getColumnConfig from "./getColumnConfig";
import { useTranslation } from "react-i18next";
import GeorgStorageContext from "../../../../../../../context/GeorgStorageContext";

interface IProps {
  serviceOrders: IServiceOrderSettingsUpdate[];
  onRemoveClick: (serviceOrderId: string) => void;
  onChangeActive: (serviceOrderId: string, newIsActive: boolean) => void;
  onEdit: (serviceOrderId: string) => void;
  isMutationLoading: boolean;
  isLoading: boolean;
}
export default function ServiceOrderAdminTable({
  serviceOrders,
  onRemoveClick,
  onChangeActive,
  onEdit,
  isMutationLoading,
  isLoading,
}: IProps): React.ReactElement {
  const { t } = useTranslation();
  const { isTooltipEnabled } = useContext(GeorgStorageContext);

  const columns = useMemo(() => {
    return getColumnConfig(
      t,
      onRemoveClick,
      onChangeActive,
      onEdit,
      isMutationLoading,
    );
  }, [t, onRemoveClick, onChangeActive, onEdit, isMutationLoading]);

  return (
    <GeorgDataGrid
      disableColumnMenu={false}
      columns={columns}
      rows={serviceOrders}
      isLoading={isLoading || isMutationLoading}
      isTooltipEnabled={isTooltipEnabled}
    />
  );
}
