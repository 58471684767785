import { makeStyles } from "tss-react/mui";
import { colors } from "../../theme";
import colorToRgba from "../../../helper/adjustColor";
import { Theme } from "@mui/material/styles";

const styles = makeStyles()((theme: Theme) => ({
  styledSnackbar: {
    top: theme.spacing(10),
    marginRight: 10,
    marginLeft: 10,
  },
  sncackbarContainer: {
    display: "grid",
    placeItems: "center",
  },
  button: {
    "&.MuiButton-root": {
      fontSize: 14,
      height: 30,
      borderRadius: 8,
      padding: "5px 16px",
      border: `1px solid ${colorToRgba(colors.white_base, 0.5)}`,
      width: "50%",
      boxSizing: "border-box",
    },
  },
  iconButton: {
    padding: "0",
    paddingLeft: 19,
  },
  iconContainer: {
    height: 32,
    backgroundColor: colorToRgba(colors.yellow_base, 0.12),
    borderRadius: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: "0",
    minWidth: 32,
    alignSelf: "flex-start",
  },
  box: {
    borderRight: `1px solid ${colorToRgba(colors.white_base, 0.5)}`,
    height: 42,
    paddingRight: 24,
    alignItems: "center",
    display: "flex",
  },
  actionContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    padding: 0,
    width: "100%",
  },
  firstContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 0,
    justifyContent: "center",
    width: "100%",
  },
  smallSecondContainer: {
    display: "flex",
    alignItems: "center",
    minWidth: "max-content",
    justifyContent: "center",
    width: "100%",
    paddingTop: 16,
  },
  textContainer: {
    paddingLeft: 16,
    paddingRight: 0,
    width: "100%",
  },
  text: {
    "&.MuiTypography-root": {
      fontSize: 18,
      fontWeight: "bold",
    },
  },
  cancelButton: {
    "&.MuiButton-root": {
      marginRight: 16,
    },
  },
}));

export default styles;
