import { makeStyles } from "tss-react/mui";
import { colors, rootTheme } from "../../../../../shared/theme";

const useStyles = makeStyles()(() => ({
  box: {
    backgroundColor: colors.white_base,
    width: 860,
    minHeight: 440,
    ...rootTheme.tabContainer,
  },
  spinnerBox: {
    marginTop: 240,
    marginLeft: 360,
  },
  titleWrapper: {
    marginBottom: 24,
  },
}));

export default useStyles;
