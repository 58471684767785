import React from "react";
import Skeleton from "@mui/material/Skeleton";
import useStyles from "../styles";

// Skeleton component for Heatmap X-axis
export default function HeatmapXAxisSkeleton(): React.ReactElement {
  const { classes } = useStyles();
  return (
    <div className={`${classes.skeletonContainer} ${classes.specialcontainer}`}>
      <Skeleton variant="text" width={"10%"} height={20} />
      <Skeleton variant="text" width={"10%"} height={20} />
      <Skeleton variant="text" width={"10%"} height={20} />
      <Skeleton variant="text" width={"10%"} height={20} />
    </div>
  );
}
