/*
 *   Machinelist Reducer for storing a list of all machines.
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import IGetStackLightMachineLast24Lists from "../../api/machine/IGetStackLightMachineLast24Lists";
import { IStackLightEntry } from "../../api/machine/IStackLightEntry";

export interface ISetStackLightPayload {
  value: IGetStackLightMachineLast24Lists;
  machineId: string;
  updateTime: number;
}

const initialState: IStackLightEntry[] = [];

export const stackLightListReducer = createSlice({
  name: "stackLightList",
  initialState,
  reducers: {
    setStackLightList: (
      state,
      action: PayloadAction<ISetStackLightPayload>,
    ) => {
      if (!action.payload.value.getStateList) {
        return;
      }
      const stackList24h = action.payload.value.getStateList;

      const foundElement = state.findIndex(
        (entry: IStackLightEntry) => entry.id === action.payload.machineId,
      );
      if (foundElement >= 0) {
        state.splice(foundElement, 1);
      }
      state.push({
        id: action.payload.machineId,
        stateListEntryList: stackList24h,
        lastUpdated: action.payload.updateTime,
      });
    },
  },
});

export const selectStackLightList = (state: RootState): IStackLightEntry[] => {
  return state.stackLightList;
};

export const { setStackLightList } = stackLightListReducer.actions;

export default stackLightListReducer.reducer;
