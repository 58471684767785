import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  specialcontainer: {
    "& .recharts-tooltip-wrapper": {
      zIndex: 9999,
    },
    alignItems: "center",
    display: "flex",
    height: 30,
    paddingLeft: 100,
    paddingTop: 3,
  },
  label: {
    minWidth: 100,
  },
  skeletonContainer: {
    justifyContent: "space-around",
    flexDirection: "row",
    justifyItems: "center",
    paddingTop: 0,
  },
}));
export default useStyles;
