import i18next from "i18next";
// import resources from "../@types/resources.json";
import en from "./locales/en/translation.json";
import de from "./locales/de/translation.json";
import fr from "./locales/fr/translation.json";
import it from "./locales/it/translation.json";
import { initReactI18next } from "react-i18next";
// import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

export const defaultNS = "ns1";
export const fallbackNS = "fallback";

// source https://github.com/i18next/react-i18next/blob/master/example/react-typescript/simple/src/%40types/i18next.d.ts
i18next
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    fallbackLng: "en", // Language to use if translations in user language are not available.
    // debug: true,
    lng: "en",
    defaultNS,
    fallbackNS,
    resources: {
      en: {
        ns1: en,
        fallback: en,
      },
      de: {
        ns1: de,
      },
      fr: {
        ns1: fr,
      },
      it: {
        ns1: it,
      },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
export default i18next;
