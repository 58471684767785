import { makeStyles } from "tss-react/mui";
import { colors } from "../../shared/theme";

const useStyles = makeStyles()({
  container: {
    paddingTop: 60,
    color: colors.blue_base,
    "@media (max-width: 719px) ": {
      textAlign: "center",
    },
  },
  updateContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    rowGap: 10,
    paddingTop: 40,
    "@media (min-width: 720px) ": {
      marginLeft: -24,
      marginRight: -24,
    },
  },
  textIconContainer: {
    display: "flex",
    columnGap: 8,
  },
  updateButton: {
    marginTop: 20,
  },
});

export default useStyles;
