import TScale from "../../../../../../api/time/TScale";
import { TIndicatorType } from "../../../../../../helper";
import indicatorIdToIndicatorType from "../../../../../../helper/indicator/indicatorIdToIndicatorType";
import { IsoDateToTimezoneDate } from "../../../../../../helper/time/formatting/dateToIsoUtils";
import KpiAvgDatalakeDataType from "../../IkpiAvgDatalakeDataType";
import {
  IKPILineChartData,
  IKPILineChartDataEntry,
  IKPILineChartDataNew,
  TKPILineChartDataKey,
} from "../kpiEntryReducer";
import * as luxon from "luxon";

// Pads a number with leading zeros if necessary.
export const padZero = (value: number | string): string =>
  value.toString().padStart(2, "0");

/**
 * Constructs a DateTime object from given components.
 */
export const createDateTime = (
  year: number,
  monthIndex: number | undefined,
  day: number | undefined,
  hour: number | undefined,
  minute: number | undefined,
  timezone: string,
): luxon.DateTime => {
  const month = monthIndex !== undefined ? padZero(monthIndex + 1) : "01";
  const dayStr = day !== undefined ? padZero(day) : "01";
  const hourStr = hour !== undefined ? padZero(hour) : "00";
  const minuteStr = minute !== undefined ? padZero(minute) : "00";
  return luxon.DateTime.fromISO(
    `${year}-${month}-${dayStr}T${hourStr}:${minuteStr}:00`,
    {
      zone: timezone,
    },
  ).setLocale("en");
};

const adjustEntryTime = (
  entryDate: luxon.DateTime,
  shiftStart: luxon.DateTime,
): luxon.DateTime => {
  if (entryDate < shiftStart) {
    // Adjust to shift start if entry time is before shift start
    return shiftStart;
  }
  return entryDate; // No adjustment needed
};

/**
 * Update line chart data entries for a specific entry and series.
 */
function updateLineChartData(
  entry: KpiAvgDatalakeDataType,
  shiftStartDate: luxon.DateTime,
  shiftEndDate: luxon.DateTime,
  emptyLineChartData: IKPILineChartData,
  indicators: TIndicatorType[],
  timezone: string,
) {
  const kpiSeriesKeys = Object.keys(emptyLineChartData);

  kpiSeriesKeys.forEach((seriesKey: string) => {
    const series: IKPILineChartDataNew = emptyLineChartData[
      seriesKey as TKPILineChartDataKey
    ] as IKPILineChartDataNew;

    if (series.indicatorTypeId === entry.indicatorIdentificationTypeId) {
      const currentIndicator = indicatorIdToIndicatorType(
        indicators,
        `${entry.indicatorIdentificationTypeId}`,
      );

      const currentDate = adjustEntryTime(
        createDateTime(
          entry.year,
          entry.monthIndex,
          entry.day,
          entry.hour,
          0,
          timezone,
        ),
        shiftStartDate,
      );

      const adjustedUnit = createAdjustedUnit(currentDate);
      const existingEntry = series.lineChartDataEntries.at(-1);

      if (existingEntry) {
        fillMissingEntries(
          existingEntry,
          currentDate,
          series,
          currentIndicator,
          timezone,
        );
      }

      series.lineChartDataEntries.push({
        unit: adjustedUnit,
        value: entry.value,
        indicatorName: currentIndicator.indicatorName,
      });
    }
  });
}

/**
 * Create adjusted time components for the entry.
 */
export function createAdjustedUnit(
  currentDate: luxon.DateTime,
  withoutTime = false,
) {
  return {
    dayNameShort: currentDate.weekdayShort,
    monthNameShort: currentDate.monthShort,
    week: `${currentDate.weekNumber}`,
    year: `${currentDate.year}`,
    hour: withoutTime ? "null" : `${currentDate.hour}`,
    minute: withoutTime ? "null" : `${currentDate.minute}`,
    day: `${currentDate.day}`,
    monthIndex: `${currentDate.month - 1}`,
  };
}

// Fill missing entries between the last existing entry and the current entry.
function fillMissingEntries(
  existingEntry: IKPILineChartDataEntry,
  currentDate: luxon.DateTime,
  series: IKPILineChartDataNew,
  currentIndicator: TIndicatorType,
  timezone: string,
) {
  const existingDate = calculateNewDate(existingEntry, timezone);
  const interval = { hours: 1 };
  let hours = currentDate.diff(existingDate, "hours").as("hours");
  const minutesToAdd = 60 - existingDate.minute; // Minutes to complete the hour
  // Add the remaining minutes to complete the hour
  if (hours > 1 && minutesToAdd > 0) {
    const newDate = existingDate.plus({ minutes: minutesToAdd });
    const newLineChartDataEntry = createNewEntry(newDate, currentIndicator);

    series.lineChartDataEntries.push(newLineChartDataEntry);
  }

  // Add the remaining hours with null values
  while (hours > 1) {
    const lastEntry = series.lineChartDataEntries.at(-1);

    if (!lastEntry) {
      // Handle the case where there is no last entry
      break;
    }

    const newDate = calculateNewDate(lastEntry, timezone, interval);
    const newLineChartDataEntry = createNewEntry(newDate, currentIndicator);

    series.lineChartDataEntries.push(newLineChartDataEntry);
    hours--;
  }
}

/**
 * Fill missing entries between the last existing entry and the shift end date.
 */
function fillEntriesToEnd(
  lastEntry: IKPILineChartDataEntry,
  shiftEndDate: luxon.DateTime,
  series: IKPILineChartDataNew,
  currentIndicator: TIndicatorType,
  timezone: string,
) {
  let lastDate = calculateNewDate(lastEntry, timezone);
  let remainingHours = shiftEndDate.diff(lastDate, "hours").as("hours");

  if (remainingHours <= 0) return;

  const interval = { hours: 1 };
  const fraction = remainingHours % 1; // Fractional part
  let wholeHours = Math.floor(remainingHours); // Whole hours part

  // Add the first fractional hour if it exists
  if (fraction > 0) {
    const fractionalDate = lastDate.plus({ hours: fraction });
    const fractionalEntry = createNewEntry(fractionalDate, currentIndicator);
    fractionalEntry.value = lastEntry.value; // Copy the last value for fractional hours
    series.lineChartDataEntries.push(fractionalEntry);
    lastDate = fractionalDate;
    remainingHours -= fraction; // Subtract the first fraction
  } else {
    const paddingDate = lastDate.plus(interval);
    const paddingLineChartDataEntry = createNewEntry(
      paddingDate,
      currentIndicator,
    );
    paddingLineChartDataEntry.value = lastEntry.value; // Copy the last value for fractional hours
    series.lineChartDataEntries.push(paddingLineChartDataEntry);
    lastDate = paddingDate; // Update lastDate to newDate
    remainingHours -= 1; // Subtract 1 hour
    wholeHours -= 1; // Subtract 1 hour
  }

  // Check if lastDate is a full hour
  const isFullHour = lastDate.minute === 0;

  // If not a full hour, add the remaining fraction to make it a full hour
  if (!isFullHour) {
    const remainingFractionToFullHour = 1 - lastDate.minute / 60;
    if (remainingHours >= remainingFractionToFullHour) {
      const newDate = lastDate.plus({ hours: remainingFractionToFullHour });
      const newLineChartDataEntry = createNewEntry(newDate, currentIndicator);
      series.lineChartDataEntries.push(newLineChartDataEntry);
      lastDate = newDate; // Update lastDate to newDate
      remainingHours -= remainingFractionToFullHour; // Subtract the fraction added to make it a full hour
    }
  }

  // Add the remaining whole hours with new entries
  for (let i = 0; i < wholeHours; i++) {
    const newDate = lastDate.plus(interval);
    const newLineChartDataEntry = createNewEntry(newDate, currentIndicator);
    series.lineChartDataEntries.push(newLineChartDataEntry);
    lastDate = newDate; // Update lastDate to newDate
  }
  remainingHours -= wholeHours; // Subtract the whole hours added

  // Add the final fractional hour with a new value if it exists
  if (remainingHours > 0) {
    const finalFractionalDate = lastDate.plus({ hours: remainingHours });
    const finalFractionalEntry = createNewEntry(
      finalFractionalDate,
      currentIndicator,
    );
    series.lineChartDataEntries.push(finalFractionalEntry);
  }
}

/**
 * Parse nullable string values to integers.
 */
export function parseNullableValue(value: string | "null"): number | undefined {
  return value !== "null" ? parseInt(value, 10) : undefined;
}

/**
 * Parses date components and creates a DateTime object in a specified timezone. Optionally adds an interval.
 *
 * @param entry - Entry containing the date components.
 * @param timezone - Timezone for the resulting DateTime object.
 * @param interval - Optional interval to add to the DateTime object.
 * @returns {DateTime} - The DateTime object adjusted by the specified interval.
 */
export function calculateNewDate(
  entry: IKPILineChartDataEntry,
  timezone: string,
  interval?: { hours: number },
): luxon.DateTime {
  const dateTime = createDateTime(
    parseInt(entry.unit.year, 10),
    parseNullableValue(entry.unit.monthIndex),
    parseNullableValue(entry.unit.day),
    parseNullableValue(entry.unit.hour),
    parseNullableValue(entry.unit.minute),
    timezone,
  );

  // Add interval if provided
  return interval ? dateTime.plus(interval) : dateTime;
}

/**
 * Create a new line chart data entry.
 */
function createNewEntry(
  newDate: luxon.DateTime,
  currentIndicator: TIndicatorType,
): IKPILineChartDataEntry {
  return {
    unit: createAdjustedUnit(newDate),
    value: null,
    indicatorName: currentIndicator.indicatorName,
  };
}

export function processShiftEntries(
  shiftsWithEntries: Array<{
    shiftDate: string;
    shiftStart: string;
    shiftEnd: string;
    entries: KpiAvgDatalakeDataType[];
  }>,
  endPeriod: string,
  emptyLineChartData: IKPILineChartData,
  indicators: TIndicatorType[],
  scale: TScale,
  timezone: string,
) {
  const filteredShifts = shiftsWithEntries.filter(
    (shift) => shift.entries.length > 0,
  );
  filteredShifts.forEach((shift) => {
    const shiftStartDate = IsoDateToTimezoneDate(shift.shiftStart, timezone);
    const shiftEndDate = IsoDateToTimezoneDate(shift.shiftEnd, timezone);
    const periodEndDate = luxon.DateTime.fromISO(endPeriod, {
      zone: "utc",
    });
    const lastEndDate =
      shiftEndDate < periodEndDate ? shiftEndDate : periodEndDate;

    shift.entries.forEach((entry) => {
      updateLineChartData(
        entry,
        shiftStartDate,
        shiftEndDate,
        emptyLineChartData,
        indicators,
        timezone,
      );
    });

    // After processing all entries for this shift, fill any missing entries to the shift end date
    const seriesKeys = Object.keys(
      emptyLineChartData,
    ) as TKPILineChartDataKey[];

    seriesKeys.forEach((seriesKey) => {
      const series = emptyLineChartData[seriesKey];
      if (series) {
        const lastEntry = series.lineChartDataEntries.at(-1);
        if (lastEntry) {
          const currentIndicator = indicatorIdToIndicatorType(
            indicators,
            `${series.indicatorTypeId}`,
          );
          fillEntriesToEnd(
            lastEntry,
            lastEndDate,
            series,
            currentIndicator,
            timezone,
          );
        }
      }
    });
  });
}
