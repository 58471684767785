import React, { Suspense, useEffect } from "react";
import { createRoot } from "react-dom/client";
import "@fontsource/titillium-web"; // Defaults to weight 400.
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "./i18n";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { muiTheme } from "./shared/theme";
//import * as serviceWorker from "./serviceWorker";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import App from "./App";
import { Loader } from "./components";

import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./auth/microsoftApi/msalConfig";
import { MsalProvider } from "@azure/msal-react";
import Apollo from "./components/Apollo";
import LoginGuard from "./auth/components/LoginGuard";
const msalInstance = new PublicClientApplication(msalConfig);
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { TssCacheProvider } from "tss-react";
import GeorgStorageProvider from "./context/GeorgStorageProvider";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorHandler } from "./services/errorHandler";
import useInitAuth from "./auth/hooks/useInitAuth";
import { FlagProvider } from "@unleash/proxy-client-react";
import {
  SW_INIT,
  SW_UPDATE,
  setServiceWorkerToken,
} from "./redux/reducers/serviceWorkerTokenReducer";
import Cookies from "js-cookie";

// Extend the context interface to include custom properties
// interface CustomContext extends IMutableContext {
//   tenantID: string;
//   userrole: USER | ADMIN;
//   isPreviewEnabled?: boolean;
// }

// const updateContext = useUnleashContext<CustomContext>();
const config = {
  url: "https://features.mygeorg.com/api/frontend", // Your front-end API URL or the Unleash proxy's URL (https://<proxy-url>/proxy)
  clientKey:
    "default:production.41910baa9de7b0c8b48cff43e716041c4940f8d4bc198e0b651f306c", // A client-side API token OR one of your proxy's designated client keys (previously known as proxy secrets)
  refreshInterval: 15, // How often (in seconds) the client should poll the proxy for updates
  properties: {
    tenantID: "3p6pk", // The tenant ID of your Unleash instance
    userrole: "USER", // The role of the user. It's only used for identifying your application
    isPreviewEnabled: false, // Whether the preview feature is enabled
  },
  appName: "mygeorg.com", // The application ID of your Unleash instance
  environment: "production", // The environment of your application
};

export const muiCache = createCache({
  key: "mui",
  prepend: true,
  nonce: "react",
});

const tssCache = createCache({
  key: "tss",
});

const handleServiceWorkerRegistration = () => {
  serviceWorkerRegistration.register({
    onSuccess: () =>
      store.dispatch(
        setServiceWorkerToken({
          type: SW_INIT,
          payload: null,
        }),
      ),
    onUpdate: (registration) =>
      store.dispatch(
        setServiceWorkerToken({ type: SW_UPDATE, payload: registration }),
      ),
  });
};

const transformToJson = (str: string) => {
  // Add double quotes around property names and replace single quotes with double quotes
  const jsonString = str
    .replace(/([{,]\s*)([a-zA-Z0-9_]+)\s*:/g, '$1"$2":') // Add double quotes around property names
    .replace(/'/g, '"'); // Replace single quotes with double quotes

  return jsonString;
};

const MainApp = () => {
  // Default to using the first account if no account is active on page load
  useInitAuth(msalInstance);
  useEffect(() => {
    // console.log("CookieConsent", (window as any).Cookiebot.consent);
    // const cookieConsentString = Cookies.get("CookieConsent");
    const cookieConsentString = Cookies.get("CookieConsent");
    if (cookieConsentString) {
      try {
        /*const decodedCookieValue = decodeURIComponent(cookieConsentString);
        console.log("Decoded CookieConsent:", decodedCookieValue);*/

        // Transform the string into valid JSON format
        const jsonString = transformToJson(cookieConsentString);

        // Parse the transformed JSON string into an object
        const jsonObject = JSON.parse(jsonString);
        console.log("Parsed CookieConsent:", jsonObject);
        console.log("Necessary:", jsonObject.necessary);
        console.log("Preferences:", jsonObject.preferences);
        console.log("Statistics:", jsonObject.statistics);
        console.log("Marketing:", jsonObject.marketing);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (error) {
        throw "Error loading CookieConsent";
      }
    } else {
      console.log("CookieConsent cookie not found");
    }
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    handleServiceWorkerRegistration();
  }, []);

  return (
    <ErrorBoundary FallbackComponent={ErrorHandler}>
      <ThemeProvider theme={muiTheme}>
        <CacheProvider value={muiCache}>
          <TssCacheProvider value={tssCache}>
            <MsalProvider instance={msalInstance}>
              <BrowserRouter>
                <React.StrictMode>
                  <FlagProvider config={config}>
                    <Provider store={store}>
                      <Apollo>
                        <Suspense fallback={<Loader />}>
                          <LoginGuard>
                            <GeorgStorageProvider>
                              <App />
                            </GeorgStorageProvider>
                          </LoginGuard>
                        </Suspense>
                      </Apollo>
                    </Provider>
                  </FlagProvider>
                </React.StrictMode>
              </BrowserRouter>
            </MsalProvider>
          </TssCacheProvider>
        </CacheProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

const container = document.getElementById("root");

if (container) {
  const root = createRoot(container);
  root.render(<MainApp />);
}
