import TScale from "../../../../api/time/TScale";
import { DocumentNode } from "graphql";
import KPILineChartQuery from "./AvgOfKpiEntriesQueryWithShifts";

interface IAvgOfKpiEntriesQueryConfig {
  query: DocumentNode;
  parameters: {
    variables: {
      startedAfter: string;
      endedBefore: string;
      indicatorIdentificationTypeIds: string[];
      machineTypeId: string;
      scale: TScale;
      shift: string;
      timezone: string;
    };
  };
}

export default function createAvgOfKpiEntriesQuery(
  indicatorIdentificationTypeIds: string[],
  machineTypeId: string,
  startedAfter: string,
  endedBefore: string,
  shift: string,
  scale: TScale,
  timezone: string,
): IAvgOfKpiEntriesQueryConfig {
  return {
    query: KPILineChartQuery,
    parameters: {
      variables: {
        startedAfter,
        endedBefore,
        indicatorIdentificationTypeIds,
        machineTypeId,
        scale,
        shift,
        timezone,
      },
    },
  };
}
