interface IRoundedRepresentation {
  value: number;
  unit: string;
  unitSuffix: string;
}

const determineDivisorAndUnit = (value: number) => {
  const numOfDigits = value.toString().split(".")[0].length;
  let divisor = 1;
  let unit = "";
  let suffix = "";

  if (numOfDigits > 5 && numOfDigits <= 8) {
    divisor = 1000;
    unit = "K";
    suffix = "inThousands";
  } else if (numOfDigits > 8 && numOfDigits <= 11) {
    divisor = 1000000;
    unit = "M";
    suffix = "inMillions";
  } else if (numOfDigits > 11 && numOfDigits <= 14) {
    divisor = 1000000000;
    unit = "B";
    suffix = "inBillions";
  } else if (numOfDigits > 14) {
    divisor = 1000000000000;
    unit = "T";
    suffix = "inTrillions";
  }

  return {
    divisor,
    unit,
    suffix,
  };
};

const roundValue = (value: number, divisor: number): number => {
  // If the value is float, round it to 2 decimal places, else round it to the nearest integer
  if (value % 1 !== 0) {
    return Number(value.toFixed(2));
  } else {
    return Math.round(value / divisor);
  }
};

export const ProcessValue = (
  value: number | null,
): IRoundedRepresentation | null => {
  if (value === null) {
    return null;
  }

  const { divisor, unit, suffix } = determineDivisorAndUnit(value);

  return {
    value: roundValue(value, divisor),
    unitSuffix: suffix,
    unit,
  };
};

export function customRoundedRepresentation(
  currentValue: number | null,
  referenceValue: number | null,
): {
  current: IRoundedRepresentation | null;
  reference: IRoundedRepresentation | null;
} {
  return {
    current: ProcessValue(currentValue),
    reference: ProcessValue(referenceValue),
  };
}
