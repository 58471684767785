import { IManualStateConfigEntryFromServer } from "../../../../../../api/manualState/IManualStateConfigEntry";

const MOCK_MANUAL_STATES: IManualStateConfigEntryFromServer[] = [
  {
    id: "test-id-1",
    isMarkedAsDeleted: true,
    isVisible: true,
    displayName: "Warte auf Kran",
    version: "0",
    displayPrio: 0,
    isNowActive: false,
  },
  {
    id: "test-id-2",
    isMarkedAsDeleted: true,
    isVisible: true,
    displayName: "Warte auf Abtransport",
    version: "0",
    displayPrio: 1,
    isNowActive: false,
  },
  {
    id: "test-id-3",
    isMarkedAsDeleted: true,
    isVisible: true,
    displayName: "Dienstbesprechung",
    version: "0",
    displayPrio: 2,
    isNowActive: false,
  },
  {
    id: "test-id-4",
    isMarkedAsDeleted: false,
    isVisible: true,
    displayName: "Warte auf Material",
    version: "0",
    displayPrio: 3,
    isNowActive: false,
  },
];

export default MOCK_MANUAL_STATES;
