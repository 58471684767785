import { makeStyles } from "tss-react/mui";
import { rootTheme } from "../../../../shared/theme";

const useStyles = makeStyles()({
  cardWrapper: {
    marginTop: 30,
  },
  container: {
    ...rootTheme.viewsContainer,
    ...rootTheme.tabContainer,
  },
  outerGrid: {
    height: "100%",
  },
});

export default useStyles;
