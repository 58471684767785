import React from "react";
import { Card, CardContent } from "@mui/material";
import { MachineCard } from "../index";
import useStyles from "../../styles";
import { TIndicatorType } from "../../../../../helper";
import { IMachine } from "../../../../../api/machine/IMachine";
import { IIndicatorStateSince } from "../machineStatusFooter/api/useCurrentIndicatorStateList/useCurrentIndicatorStateList";
interface IProps {
  handleClick: (machineId: string) => void;
  kpi: boolean;
  allIndicators: TIndicatorType[];
  onStateChange: (state: IIndicatorStateSince, id: string) => void;
  machine: IMachine;
  timezone?: string;
}
function MachineCardGridElement({
  handleClick,
  kpi,
  allIndicators,
  onStateChange,
  machine: {
    id,
    deviceLabel,
    deviceLabelExtension,
    deviceSublabel,
    deviceClass,
    statusCode,
    image,
  },
  timezone = "Europe/Berlin",
}: IProps): React.ReactElement {
  const { classes } = useStyles();

  return (
    <div key={id + deviceLabel + deviceLabelExtension + deviceSublabel}>
      <Card className={classes.cardContainer} onClick={() => handleClick(id)}>
        <CardContent
          className={
            kpi
              ? classes.contentKPIContainer
              : classes.contentMachineryContainer
          }
          style={{ padding: 0 }} // Padding in der Klasse contentContainer funktioniert nicht.
        >
          <MachineCard
            id={id}
            deviceLabelId={(id.slice(0, 1) === "1" && id.slice(6)) || ""}
            // componentName={componentName}
            deviceLabel={deviceLabel}
            deviceLabelExtension={deviceLabelExtension}
            deviceSublabel={deviceSublabel}
            image={image}
            deviceClass={deviceClass}
            statusCode={statusCode}
            kpi={kpi}
            allIndicators={allIndicators}
            onStateChange={(state) => onStateChange(state, id)}
            timezone={timezone}
          />
        </CardContent>
      </Card>
    </div>
  );
}

export default React.memo(MachineCardGridElement);
