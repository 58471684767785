import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
// Icons.
import BuildIcon from "@mui/icons-material/Build";
import SettingsIcon from "@mui/icons-material/Settings";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import WarningIcon from "@mui/icons-material/Warning";
// Custom Imports.
import useOeeForMachineLast24hIntervalQuery from "../../api/useOeeForMachineLast24hIntervalQuery";
import KPIDetailChart from "../../../kpiDetailChart";
import ServerDataStatusWrapper from "../../../../../../../shared/components/serverDataStatusWrapper";
import { EApiState } from "../../../../../../../api/state/EApiState";
import useStyles from "./styles";
import ShopfloorComponent from "./shopfloorComponent";
import { IOEEShopfloor24h } from "../../../../../../../api/machine/IOEEShopfloor";
import { ITooltipInfo } from "../../../../../../withTooltip";

export interface IShopfloorAnomalyInfo {
  icon?: React.ReactElement;
  tooltipInfo?: ITooltipInfo;
}

interface IProps {
  machineId: string;
  timezone: string;
}

function LastDayKpiPerformance({
  machineId,
  timezone,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { stateListResponse, apiState } = useOeeForMachineLast24hIntervalQuery(
    machineId,
    timezone,
  );

  const kpiData = useMemo<IOEEShopfloor24h>(() => {
    if (!stateListResponse) {
      return {
        availability: null,
        productivity: null,
        performance: null,
        quantity: null,
      };
    }

    return {
      availability: stateListResponse.availability,
      productivity: stateListResponse.productivity,
      performance: stateListResponse.performance,
      quantity: stateListResponse.quantity,
    };
  }, [stateListResponse]); // Dependency array includes stateListResponse

  const isValueAnomaly = useCallback(
    (value?: number | null, unit?: string | null): boolean => {
      // TODO: This should be fetched from the backend.
      const machineMaxPercentage = 160;
      if (!value) return false; // Covers both null and undefined

      return value < 0 || (unit === "%" && value > machineMaxPercentage);
    },
    [],
  );

  const anomalyInfo: IShopfloorAnomalyInfo = useMemo(() => {
    return {
      icon: <WarningIcon color={"error"} />,
      tooltipInfo: {
        label: t("common.kpi_anomaly.label"),
        description: t("common.kpi_anomaly.description"),
        sublabel: t("common.kpi_anomaly.sublabel"),
        footer: t("common.kpi_anomaly.footer"),
      },
    };
  }, [t]);

  const availabilityChart = useMemo(() => {
    const anomaly = isValueAnomaly(
      kpiData.availability?.value,
      kpiData.availability?.unit,
    );
    return (
      <KPIDetailChart
        icon={<BuildIcon />}
        description={t("common.kpi_availability.description")}
        label={t("common.kpi_availability.label")}
        sublabel={t("common.kpi_availability.sublabel")}
        footer={t("common.kpi_availability.footer")}
        isVisible={
          kpiData.availability !== null && kpiData.availability.value != null
        }
        isLoading={apiState === EApiState.LOADING}
        isAnomaly={anomaly}
      >
        <ShopfloorComponent
          value={kpiData.availability?.value ?? undefined}
          anomalyInfo={anomaly ? anomalyInfo : undefined}
        />
      </KPIDetailChart>
    );
  }, [kpiData.availability?.value, apiState]);
  const productivityChart = useMemo(() => {
    const anomaly = isValueAnomaly(
      kpiData.productivity?.value,
      kpiData.productivity?.unit,
    );
    return (
      <KPIDetailChart
        icon={<SettingsIcon />}
        description={t("common.kpi_productivity.description")}
        label={t("common.kpi_productivity.label")}
        sublabel={t("common.kpi_productivity.sublabel")}
        footer={t("common.kpi_productivity.footer")}
        isVisible={
          kpiData?.productivity !== null && kpiData.productivity.value != null
        }
        isLoading={apiState === EApiState.LOADING}
        isAnomaly={anomaly}
      >
        <ShopfloorComponent
          value={kpiData.productivity?.value ?? undefined}
          anomalyInfo={anomaly ? anomalyInfo : undefined}
        />
      </KPIDetailChart>
    );
  }, [kpiData.productivity?.value, apiState]);
  const performanceChart = useMemo(() => {
    const anomaly = isValueAnomaly(
      kpiData.performance?.value,
      kpiData.performance?.unit,
    );
    return (
      <KPIDetailChart
        icon={<ShowChartIcon />}
        description={t("common.kpi_performance.description")}
        label={t("common.kpi_performance.label")}
        sublabel={t("common.kpi_performance.sublabel")}
        footer={`${t("common.kpi_performance.footer")} ${kpiData.performance?.unit ?? ""}`}
        isVisible={
          kpiData.performance !== null && kpiData.performance.value != null
        }
        isLoading={apiState === EApiState.LOADING}
        isAnomaly={anomaly}
      >
        <ShopfloorComponent
          value={kpiData.performance?.value ?? undefined}
          metric={kpiData.performance?.unit ?? undefined}
          anomalyInfo={anomaly ? anomalyInfo : undefined}
        />
      </KPIDetailChart>
    );
  }, [kpiData.performance?.value, kpiData.performance?.unit, apiState]);
  const quantityChart = useMemo(() => {
    const anomaly = isValueAnomaly(
      kpiData.quantity?.value,
      kpiData.quantity?.unit,
    );
    return (
      <KPIDetailChart
        icon={<AddCircleOutlineIcon />}
        description={t("common.kpi_quantity.description")}
        label={t("common.kpi_quantity.label")}
        sublabel={t("common.kpi_quantity.sublabel")}
        footer={`${t("common.kpi_quantity.footer")} ${kpiData.quantity?.unit ?? ""}`}
        isVisible={kpiData?.quantity !== null && kpiData.quantity.value != null}
        isLoading={apiState === EApiState.LOADING}
        isPieChart={false}
        isAnomaly={anomaly}
      >
        <ShopfloorComponent
          value={kpiData.quantity?.value ?? undefined}
          metric={kpiData.quantity?.unit ?? undefined}
          anomalyInfo={anomaly ? anomalyInfo : undefined}
        />
      </KPIDetailChart>
    );
  }, [kpiData.quantity?.value, kpiData.quantity?.unit, apiState]);

  const retObject = useMemo(() => {
    return (
      <>
        {availabilityChart}
        {productivityChart}
        {performanceChart}
        {quantityChart}
      </>
    );
  }, [availabilityChart, productivityChart, performanceChart, quantityChart]);
  return (
    <>
      <ServerDataStatusWrapper
        wrapperStyle={classes.wrapperContainer}
        isEmpty={false}
      >
        {retObject}
      </ServerDataStatusWrapper>
    </>
  );
}

export default React.memo(LastDayKpiPerformance);
