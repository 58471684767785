/*
 *   Machinelist Reducer for storing a list of all machines.
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  IOEEShopfloor24h,
  IOEEShopfloor24hOLD,
  IOEEShopfloorEntry,
} from "../../api/machine/IOEEShopfloor";
import IGetOeeMachineLast24hList, {
  IOEEShopfloor24hTypes,
} from "../../api/machine/IGetOeeMachineLast24hLists";
import { IMachineShopFloor } from "../../api/machine/IMachineShopFloor";
import IMachineLists from "../../api/machine/IMachineLists";

export interface SetDataPayload {
  value: IGetOeeMachineLast24hList;
  machineId: string;
  updateTime: number;
}
export interface SetAllDataPayload {
  value: IMachineLists[];
  updateTime: number;
}

const initialState: IMachineShopFloor[] = [];

function safeTrunc(value: number | null): number | null {
  return value != null ? Math.trunc(value) : null;
}

// TODO: Delete all OLD types/function-code when the API is synchronized.
function isOLDType(
  oeeShopfloorData: IOEEShopfloor24hOLD[] | undefined,
): oeeShopfloorData is IOEEShopfloor24hOLD[] {
  return (oeeShopfloorData as IOEEShopfloor24hOLD[]) !== undefined;
}

export function getShopFloorEntryByName(
  oeeShopFloor: IOEEShopfloor24h | undefined,
  name: string,
): IOEEShopfloorEntry | null {
  if (!oeeShopFloor?.[name]) {
    return null;
  }
  return {
    value: safeTrunc(oeeShopFloor[name]?.value),
    unit: oeeShopFloor[name]?.unit,
  };
}

export function getShopfloorData(
  oeeShopFloor: IOEEShopfloor24hTypes,
): IOEEShopfloor24h {
  if (Array.isArray(oeeShopFloor)) {
    const oeeShopFloorData = oeeShopFloor as IOEEShopfloor24hOLD[];
    return getShopFloorEntries(oeeShopFloorData, undefined, true);
  } else {
    const oeeShopFloorData = oeeShopFloor as IOEEShopfloor24h;
    return getShopFloorEntries(undefined, oeeShopFloorData, false);
  }
}

// TODO: Delete this when the API is synchronized.
function getShopFloorEntryByNameOLD(
  oeeShopfloor: IOEEShopfloor24hOLD[] | undefined,
  name: string,
): IOEEShopfloorEntry | null {
  if (!oeeShopfloor) {
    return null;
  }
  let machineShopfloorEntry: IOEEShopfloorEntry | null = null;
  const shopFloorEntry: IOEEShopfloor24hOLD | undefined = oeeShopfloor.find(
    (oeeShopFloorEntry: IOEEShopfloor24hOLD) => {
      return (
        oeeShopFloorEntry.indicatorIdentificationType.indicatorName === name
      );
    },
  );
  if (shopFloorEntry) {
    machineShopfloorEntry = {
      value: shopFloorEntry.value,
      unit: null,
    };
  }
  return machineShopfloorEntry;
}

function getShopFloorEntry(
  oeeShopfloor: IOEEShopfloor24hOLD[] | undefined,
  oeeShopFloor: IOEEShopfloor24h | undefined,
  name: string,
  isOldType: boolean,
): IOEEShopfloorEntry | null {
  return isOldType
    ? getShopFloorEntryByNameOLD(oeeShopfloor, `kpi_${name}`)
    : getShopFloorEntryByName(oeeShopFloor, name);
}

// Get all the shopfloor entries for the machine.
// TODO: Delete all OLD types/function-code when the API is synchronized.
function getShopFloorEntries(
  oeeShopfloor: IOEEShopfloor24hOLD[] | undefined,
  oeeShopFloor: IOEEShopfloor24h | undefined,
  isOldType: boolean,
): IOEEShopfloor24h {
  return {
    availability: getShopFloorEntry(
      oeeShopfloor,
      oeeShopFloor,
      "availability",
      isOldType,
    ),
    productivity: getShopFloorEntry(
      oeeShopfloor,
      oeeShopFloor,
      "productivity",
      isOldType,
    ),
    performance: getShopFloorEntry(
      oeeShopfloor,
      oeeShopFloor,
      "performance",
      isOldType,
    ),
    quantity: getShopFloorEntry(
      oeeShopfloor,
      oeeShopFloor,
      "quantity",
      isOldType,
    ),
  };
}

export const oeeMachineListReducer = createSlice({
  name: "oeeMachineList",
  initialState,
  reducers: {
    setOeeMachineList: (state, action: PayloadAction<SetDataPayload>) => {
      if (!action.payload.value.getOeeForMachineLast24h) {
        return;
      }
      const oeeForMachine24h = action.payload.value.getOeeForMachineLast24h;

      const foundElement = state.findIndex(
        (machine: IMachineShopFloor) => machine.id === action.payload.machineId,
      );
      if (foundElement >= 0) {
        state.splice(foundElement, 1);
      }
      const oeeShopfloorObject = getShopfloorData(oeeForMachine24h);
      state.push({
        id: action.payload.machineId,
        ooeShopfloor: oeeShopfloorObject,
        lastUpdated: action.payload.updateTime,
      });
    },
    setAllOeeMachineList: (state, action: PayloadAction<SetAllDataPayload>) => {
      state.splice(0, state.length);
      action.payload.value.map((machineLists: IMachineLists) => {
        const { id, oeeShopFloor, oeeShopfloor } = machineLists.machineType;
        const foundElement = state.findIndex(
          (machine: IMachineShopFloor) => machine.id === id,
        );
        if (foundElement >= 0) {
          state.splice(foundElement, 1);
        }
        const isOldDataType = isOLDType(oeeShopfloor);
        state.push({
          id: id,
          ooeShopfloor: getShopFloorEntries(
            oeeShopfloor,
            oeeShopFloor,
            isOldDataType,
          ),
          lastUpdated: action.payload.updateTime,
        });
      });
    },
  },
});

export const selectOeeMachineList = (state: RootState): IMachineShopFloor[] => {
  return state.oeeMachineList;
};

export const { setOeeMachineList, setAllOeeMachineList } =
  oeeMachineListReducer.actions;

export default oeeMachineListReducer.reducer;
