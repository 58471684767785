import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import TabsWithMachineTimePeriodSelection from "../../../shared/components/tabsWithMachineTimePeriodSelection";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ConfigureOccupiedTime from "./views/occupiedTime";
import ConfigureShifts from "./views/configureShifts";
import ConfigureServiceOrders from "./views/serviceOrders";
import ScreenResolutionWarning from "../../../shared/components/screenResolutionWarning";
import ConfigureManualStates from "./views/manualStates";
import GeorgStorageContext from "../../../context/GeorgStorageContext";

export default function MachineSettings(): React.ReactElement {
  const { t } = useTranslation();
  const { isPreviewEnabled } = useContext(GeorgStorageContext);
  const tabConfigPreview = useMemo(
    () => [
      {
        label: t("settings.profile.tabTitles.shifts"),
        path: "/shifts",
      },
      /*
      {
        label: t("settings.profile.tabTitles.occupiedTime"),
        path: "/occupiedtime",
      },
      */
      {
        label: t("settings.profile.tabTitles.manualStates"),
        path: "/manualstates",
      },
      {
        label: t("settings.profile.tabTitles.serviceOrders"),
        path: "/serviceorders",
      },
    ],
    [],
  );
  const tabConfig = useMemo(
    () => [
      {
        label: t("settings.profile.tabTitles.shifts"),
        path: "/shifts",
      },
    ],
    [],
  );
  if (isPreviewEnabled) {
    return (
      <ScreenResolutionWarning>
        <TabsWithMachineTimePeriodSelection
          basePath={"/settings/machine"}
          tabConfig={tabConfigPreview}
        >
          <ConfigureShifts />
          {/*
          <ConfigureOccupiedTime />
          */}
          <ConfigureManualStates />
          <ConfigureServiceOrders />
        </TabsWithMachineTimePeriodSelection>
      </ScreenResolutionWarning>
    );
  } else {
    return (
      <ScreenResolutionWarning>
        <TabsWithMachineTimePeriodSelection
          basePath={"/settings/machine"}
          tabConfig={tabConfig}
        >
          <ConfigureShifts />
        </TabsWithMachineTimePeriodSelection>
      </ScreenResolutionWarning>
    );
  }
}
