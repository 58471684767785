/* eslint-disable max-len */
import {
  ReactiveBase,
  // MultiList,
  ReactiveList,
  // SelectedFilters,
} from "@appbaseio/reactivesearch";
import { useSelector } from "react-redux";
import { selectCheckboxes } from "../../../redux/reducers/serviceReducer";
// import useStyles from "../common/styles";
import GeorgDocumentSearchDataGrid from "../../../shared/components/georgDocumentSearchDataGrid";
import getGridColDef from "./ResultsGridDefinition";
import { Alert, Skeleton } from "@mui/material";
import { react } from "@appbaseio/reactivesearch/lib/types";
import { useTranslation } from "react-i18next";
import { token } from "../../../redux/reducers/tokenReducer";
import Loader from "../../loader";
import { isNull } from "lodash";
import useClaims from "../../../auth/hooks/useClaims";
import { selectMachine } from "../../../redux/reducers/machineSelectReducer";
import useStyles from "./styles";
import { domainUrlMapping } from "../../Apollo";

interface IProps {
  keywordSearch?: boolean;
  searchCriteria?: react;
  sortCriteria?: { and: [] };
}

export default function DocumentTypeResult(props: IProps) {
  const selectedDoctypes = useSelector(selectCheckboxes);
  const { t } = useTranslation();
  const headerLabels = [
    t("service.documentsearch.result_table_header.preview"),
    t("service.documentsearch.result_table_header.documentName"),
  ];
  const downloadLabel = t("service.documentsearch.downloadLabel");

  // const classes = useStyles();
  const { ResultListWrapper } = ReactiveList;
  // get the authentication token from local storage if it exists
  const jwt = useSelector(token);
  const { classes } = useStyles();
  const selectedMachine = useSelector(selectMachine);
  const claims = useClaims();
  let url = "";
  if (!isNull(claims)) {
    const domain =
      domainUrlMapping[claims.extension_tenant] || domainUrlMapping.default;
    url = `https://${domain}/${claims.extension_tenant}/es`;
  }
  const app = selectedMachine;

  function AddIdToSearchResults(input) {
    const output: object[] = [];
    let currDocument = 0;
    input.forEach((item) => {
      item.id = currDocument;
      output.push(item);
      currDocument++;
    });
    return output;
  }

  if (!jwt) return <Loader />;

  const chosenColumnType = getGridColDef(
    headerLabels,
    downloadLabel,
    classes.image,
  );
  const loadingColumns = chosenColumnType.map((column) => ({
    ...column,
    sortable: false,
    renderCell: () => {
      // Ensure to include params if you might use them
      if (column.field === "image") {
        return (
          <Skeleton
            variant="rectangular"
            width={339}
            height={272}
            animation="pulse"
            sx={{ borderRadius: 8 }}
          />
        );
      }
      // Default skeleton for other types of fields
      return (
        <div>
          <Skeleton
            variant="text"
            animation="pulse"
            width="100%"
            height={28}
            sx={{ margin: "10px 0" }}
          />
          <Skeleton variant="text" animation="pulse" width={60} height={24} />
        </div>
      );
    },
  }));

  return (
    <ReactiveBase
      url={url}
      app={app}
      headers={{
        Authorization: `Bearer ${jwt}`,
        Accept: "application/x-ndjson, json",
      }}
    >
      <ReactiveList
        componentId="SearchResult"
        showLoader={true}
        size={5}
        pages={!props.keywordSearch ? 10 : 1}
        // loader="Loading results..."
        showResultStats={false}
        infiniteScroll
        defaultQuery={() => {
          if (!props.keywordSearch) {
            if (selectedDoctypes.length > 0) {
              return {
                query: {
                  terms: {
                    contentType: selectedDoctypes,
                  },
                },
              };
            } else if (selectedDoctypes.length <= 0) {
              return {
                query: {
                  match_none: {},
                },
              };
            }
          }
        }}
        onQueryChange={function (prevQuery, nextQuery) {
          if ("match_all" in nextQuery["query"]) {
            nextQuery["query"] = { match_none: {} };
          }
        }}
        renderNoResults={function () {
          if (selectedDoctypes.length > 0) {
            return (
              <Alert severity="info">
                {t("service.documentsearch.messages.no_results_found")}
              </Alert>
            );
          }
        }}
        renderError={(error) => {
          if (error.responses[0].status != "404") {
            return (
              <Alert severity="error">
                {t("service.documentsearch.messages.server_error")}
              </Alert>
            );
          }
        }}
        pagination={!props.keywordSearch}
        dataField={"contentType"}
        excludeFields={["pages"]}
        // react={{
        //   and: ["doctypeFilter"],
        // }}
      >
        {({ error, data, loading }) => {
          if (data.length > 0 || loading) {
            AddIdToSearchResults(data);
            const columnVisibilityModel = {
              // Hide columns id, the other columns will remain visible
              id: false,
            };
            return (
              <ResultListWrapper>
                <div className={classes.searchGridWrapper}>
                  <GeorgDocumentSearchDataGrid
                    autoHeightProp={true}
                    rows={data}
                    columns={chosenColumnType}
                    loadingColumns={loadingColumns}
                    rowSize={304}
                    resultsPerPage={6}
                    columnVisibilityModel={columnVisibilityModel}
                    isLoading={loading}
                  />
                </div>
              </ResultListWrapper>
            );
          } else if (data.length <= 0 && !error) {
            return <div />;
          }
        }}
      </ReactiveList>
    </ReactiveBase>
  );
}
