import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import ShopfloorPieChartSkeleton from "../../../../charts/shopfloorPieChart/skeleton";
import IconSkeleton from "./skeleton/iconSkeleton";
import useStyles from "./styles";
import WithToggleableTooltip from "../../../../withToggableTooltip";
import { IList } from "../../../../../shared/components";

interface IProps {
  footer: string;
  description: string;
  label: string;
  sublabel: string;
  isVisible: boolean;
  isLoading?: boolean;
  icon?: React.ReactElement;
  children?: React.ReactElement;
  isPieChart?: boolean;
  isAnomaly?: boolean;
}

function KPIDetailChart({
  description,
  footer,
  label,
  sublabel,
  isVisible,
  isLoading,
  icon,
  children,
  isPieChart = true,
  isAnomaly = false,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const [disableHeaderTooltip, setDisableHeaderTooltip] = useState(false);
  const handleHover = useCallback(
    (event: React.MouseEvent) =>
      setDisableHeaderTooltip(event.type === "mouseenter"),
    [],
  );

  const isLoadingContent = useMemo(
    () =>
      isPieChart ? (
        <ShopfloorPieChartSkeleton />
      ) : (
        <Skeleton variant="text" width={120} height={60} />
      ),
    [isPieChart],
  );

  const anomalyData = useMemo(
    () => ({
      icon: isAnomaly ? <WarningIcon color="error" /> : undefined,
      tooltipInfo: isAnomaly
        ? {
            label: t("common.kpi_anomaly.label"),
            description: t("common.kpi_anomaly.description"),
            sublabel: t("common.kpi_anomaly.sublabel"),
            footer: t("common.kpi_anomaly.footer"),
          }
        : undefined,
    }),
    [isAnomaly, t],
  );

  const renderContent = useCallback(
    () => (
      <IList
        actionComponent={isLoading ? isLoadingContent : children}
        isActionComponentHasTooltip={isAnomaly}
        isActionComponentCentered={true}
        icon={isLoading ? <IconSkeleton /> : icon}
        secondIcon={anomalyData.icon}
        secondIconTooltip={anomalyData.tooltipInfo}
        iconToLabelSpace={40}
        LabelToSecondIconSpace={32}
        label={label}
        labelColor="primary"
        labelVariant="caption"
        styles={{
          listItem: classes.kpiListItem,
          actionItem: classes.actionItem,
          GridActionItems: classes.gridActionItems,
        }}
        isLoading={isLoading}
        onSecondaryIconHover={handleHover}
      />
    ),
    [isLoading, children, icon, label, classes.kpiListItem],
  );

  const renderNoData = useCallback(
    () => (
      <IList
        label={`${t("dashboard.shopfloor.kpiDetailChart.noData")} ${label}`}
        labelColor="primary"
        labelVariant="caption"
        styles={{
          listItem: classes.kpiListItem,
        }}
      />
    ),
    [label, t, classes.kpiListItem],
  );

  const tooltipDescription = isLoading
    ? t("common.kpi_skeleton.description")
    : description;
  const tooltipLabel = isLoading ? t("common.kpi_skeleton.label") : label;
  const tooltipSublabel = isLoading
    ? t("common.kpi_skeleton.sublabel")
    : sublabel;
  const tooltipFooter = isLoading ? t("common.kpi_skeleton.footer") : footer;

  const content = useMemo(() => {
    if (isLoading || isVisible) {
      return renderContent();
    } else {
      return renderNoData();
    }
  }, [isLoading, isVisible, renderContent, renderNoData]);

  return (
    <WithToggleableTooltip
      tooltipInfo={{
        label: tooltipLabel,
        description: tooltipDescription,
        sublabel: tooltipSublabel,
        footer: tooltipFooter,
      }}
      isHidden={disableHeaderTooltip}
    >
      {content}
    </WithToggleableTooltip>
  );
}
export default React.memo(KPIDetailChart);
