import React from "react";
import { IList } from "../../../../../../../shared/components";
import WithToggleableTooltip from "../../../../../../withToggableTooltip";

interface FooterButtonProps {
  label: string;
  icon: React.ReactElement;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  listItemStyles: { [key: string]: string };
  iconMinWidth: number;
  isOpen: boolean;
}

function FooterButton({
  label,
  icon,
  onClick,
  listItemStyles,
  iconMinWidth,
  isOpen,
}: FooterButtonProps): React.ReactElement {
  return (
    <WithToggleableTooltip
      tooltipInfo={{
        label,
      }}
      smallTooltip={true}
      forceEnable={!isOpen}
      placement="right"
    >
      <IList
        icon={icon}
        label={label}
        labelVariant="body1"
        onClick={onClick}
        styles={listItemStyles}
        iconToLabelSpace={iconMinWidth}
        isCollapsed={!isOpen}
      />
    </WithToggleableTooltip>
  );
}

export default React.memo(FooterButton);
