import { useCallback, useState } from "react";
import { EApiState } from "../../../../../../../api";

export type TChangeIsActiveCallback = (
  serviceOrderId: string,
  newIsActive: boolean,
) => void;
export function useChangeActiveMutation(): {
  changeIsActive: TChangeIsActiveCallback;
  apiState: EApiState;
} {
  const [apiState, setApiState] = useState<EApiState>(EApiState.OK);
  const changeIsActive = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (serviceOrderId: string, newIsActive: boolean) => {
      setApiState(EApiState.LOADING);
      setTimeout(() => {
        alert(
          `Todo: Implement chance active mutation for ${serviceOrderId}=${newIsActive}`,
        );
        setApiState(EApiState.OK);
      }, 400);
    },
    [],
  );
  return { changeIsActive, apiState };
}
