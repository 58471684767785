import { ITimeSlot } from "../../../../redux/reducers/timeSelectReducer";
import { useEffect, useMemo, useState } from "react";
import createAvgOfKpiEntriesQuery from "./createAvgOfKpiEntriesQuery";
import { useLazyQuery } from "@apollo/client";
import kpiEntryReducer, { IKPILineChartData } from "./reducer/kpiEntryReducer";
import IKpiAvgResponse from "./IKpiAvgResponse";
import TScale from "../../../../api/time/TScale";
import { TIndicatorType } from "../../../../helper";

interface IKPILineChartQueryState {
  kpiLineChartData: IKPILineChartData | null;
  isLoading: boolean;
  hasError: boolean;
  isEmpty: boolean;
}

/*
  A hook fetching line chart data.
  It re-fetches data from server if timeSlot or machineTypeId changes.
 */
export default function useLineChartQuery(
  indicators: TIndicatorType[],
  timeSlot: ITimeSlot,
  machineTypeId: string,
  scale: TScale,
  timezone: string,
): IKPILineChartQueryState {
  const [isEmpty, setIsEmpty] = useState(true);
  const { shift, startedAfter, endedBefore } = timeSlot;

  const { query, parameters } = createAvgOfKpiEntriesQuery(
    indicators.map((indicator) => indicator.id),
    machineTypeId,
    startedAfter,
    endedBefore,
    shift,
    scale,
    timezone,
  );

  const [doQuery, { data: dataFromServer, loading: isLoading, error }] =
    useLazyQuery<IKpiAvgResponse>(query, parameters);

  useEffect(() => {
    if (shift !== "") {
      doQuery();
    }
  }, [shift, machineTypeId, timeSlot, doQuery]);

  const kpiLineChartData: IKPILineChartData | null = useMemo(() => {
    let newData: IKPILineChartData | null = null;
    const isEmptyDate = dataFromServer === undefined;
    if (!isLoading && error == null && !isEmptyDate) {
      newData = kpiEntryReducer(
        dataFromServer,
        endedBefore,
        indicators,
        scale,
        timezone,
      );
    }
    setIsEmpty(isEmptyDate || newData === null);
    return newData;
  }, [
    dataFromServer,
    isLoading,
    error,
    endedBefore,
    indicators,
    scale,
    timezone,
  ]);

  return {
    kpiLineChartData,
    isLoading,
    hasError: error != null,
    isEmpty,
  };
}
