import React from "react";
import Skeleton from "@mui/material/Skeleton";
import useStyles from "./styles";

// Skeleton component for BarStackChart
export default function BarStackChartSkeleton(): React.ReactElement {
  const { classes } = useStyles();
  return (
    <div style={{ height: "100%" }}>
      <div className={classes.rowContainer}>
        <Skeleton variant="rectangular" width={"18%"} height={"70%"} />
        <Skeleton variant="rectangular" width={"18%"} height={"30%"} />
        <Skeleton variant="rectangular" width={"18%"} height={"90%"} />
        <Skeleton variant="rectangular" width={"18%"} height={"90%"} />
      </div>
      <div className={classes.lineContainer} /> {/* X-axis */}
      <div className={classes.axisContainer}>
        <Skeleton variant="text" width={"15%"} height={20} />
        <Skeleton variant="text" width={"15%"} height={20} />
        <Skeleton variant="text" width={"15%"} height={20} />
        <Skeleton variant="text" width={"15%"} height={20} />
      </div>
    </div>
  );
}
