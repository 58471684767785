import injectStyle from "../../../../../helper/style/injectStyle";
import colors from "../../../../theme/colors";
import useStyles from "./styles";
import React, { useEffect } from "react";

export enum EDisplayState {
  UNDEFINED = "UNDEFINED",
  OK = "OK",
  WARNING = "WARNING",
  DANGER = "DANGER",
}

interface IProps {
  children: React.ReactElement;
  onClick?: () => void;
  disabled?: boolean;
  displayState: EDisplayState;
  pulse?: boolean;
}

export const stateClassMap = {
  [EDisplayState.UNDEFINED]: "undefined",
  [EDisplayState.OK]: "ok",
  [EDisplayState.DANGER]: "danger",
  [EDisplayState.WARNING]: "warning",
};

export default function StateCell({
  children,
  onClick,
  displayState,
  pulse = false,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  const classFromState = stateClassMap[displayState];
  useEffect(() => {
    if (pulse) {
      const keyframesStyle =
        `
      @-webkit-keyframes pulse {
        0%   { background-color: #FFFFFF; }
        50% { background-color: ` +
        colors.cell_state_red +
        `; }
        100%   { background-color: #FFFFFF; }
      }`;
      injectStyle(keyframesStyle);
    }
  }, []);
  return (
    <div
      className={`${classes.stateCell} ${classes[classFromState]} ${
        pulse ? classes.pulse : null
      }`}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {children}
    </div>
  );
}
