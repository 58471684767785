import { makeStyles } from "tss-react/mui";
import { colors } from "../../../../../shared/theme";

const useStyles = makeStyles()({
  container: {
    padding: 15,
  },
  emptyStacklight: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 8,
    paddingRight: 16,
  },
  listItem: {
    padding: 0,
    marginLeft: 16,
  },
  stacklightContainer: {
    borderBottomColor: colors.whiteB_12,
    borderBottom: 1,
    borderBottomStyle: "solid",
    display: "flex",
  },
});

export default useStyles;
