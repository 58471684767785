import { useQuery } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import IQueryShiftsFormat from "./app/IQueryShiftsFormat";
import getWorkShiftsQuery from "./server/getWorkShiftsQuery";
import IGetWorkShiftsResponse from "./server/IGetWorkShiftsResponse";
import transformApiToAppFormat from "./server/transformApiToAppFormat";
import IShifts from "./app/IShifts";
import IShiftConfigEntry from "./app/IShiftConfigEntry";

export default function useWorkShifts(
  machineId: number,
  timezone: string,
): IQueryShiftsFormat {
  const [shifts, setShifts] = useState<IShifts>({});
  const { t } = useTranslation();
  const { data, error, loading, refetch } = useQuery<IGetWorkShiftsResponse>(
    getWorkShiftsQuery,
    {
      variables: {
        machineTypeId: machineId,
        timezone,
      },
    },
  );
  const transformedShifts: IShifts | undefined = useMemo(() => {
    if (data) {
      return transformApiToAppFormat(data, t);
    }
  }, [data, t]);
  useEffect(() => {
    if (transformedShifts && !error && !loading) {
      let shiftsWithIsActiveFlagMocked: IShifts = {};
      Object.keys(transformedShifts).forEach((shiftNameKey: string) => {
        const shiftsForKey: IShiftConfigEntry[] =
          transformedShifts[shiftNameKey];
        shiftsWithIsActiveFlagMocked = {
          ...shiftsWithIsActiveFlagMocked,
          [shiftNameKey]: shiftsForKey.map((s: IShiftConfigEntry) => {
            return { ...s, isActive: true };
          }),
        };
      });
      setShifts(shiftsWithIsActiveFlagMocked);
    }
  }, [transformedShifts, error, loading]);
  const refreshData = () => {
    refetch();
  };
  return {
    data: shifts,
    hasError: error != null,
    isShiftsLoading: loading,
    refreshData,
  };
}
