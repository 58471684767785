import React, { useCallback } from "react";
import useLanguage from "../../../../../../helper/language/useLanguage";
import { IInput } from "../../../../../../shared/components";
import { FormLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";

export default function LanguageSelection(): React.ReactElement {
  const { t } = useTranslation();
  const { language, setLanguage } = useLanguage();
  const { classes } = useStyles();

  const handleLanguage = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const lang = event.target.value || "en";
      await setLanguage(lang);
    },
    [setLanguage],
  );

  const allLanguages = [
    { label: t("common.internationalLang.en"), value: "en" },
    { label: t("common.internationalLang.de"), value: "de" },
    { label: t("common.internationalLang.it"), value: "it" },
  ];

  return (
    <>
      <div>
        <FormLabel id="demo-controlled-radio-buttons-group">
          {t("profile.dashboard.chooseLanguage")}
        </FormLabel>
      </div>
      <div className={classes.inputContainer}>
        <IInput
          disableUnderline
          selectDisplayEmpty
          name="select-language"
          onChange={handleLanguage}
          select={true}
          selectOptions={allLanguages}
          styles={{
            rootInputStyles: classes.rootInput,
            textInputStyles: classes.textInput,
            selectIcon: classes.selectIcon,
          }}
          value={language}
        />
      </div>
    </>
  );
}
