import { makeStyles } from "tss-react/mui";
import { colors } from "../../../../../shared/theme";

const useStyles = makeStyles()({
  textContainer: {
    paddingLeft: 10,
    textAlign: "left",
  },
  messageContainer: {
    alignItems: "center",
    borderBottom: 1,
    borderBottomStyle: "solid",
    display: "grid",
    alignContent: "center",
    height: 70,
    width: "100%",
  },
  statusContainer: {
    alignItems: "center",
    borderBottom: 1,
    borderBottomStyle: "solid",
    display: "flex",
    height: 70,
    justifyContent: "center",
    width: 70,
    color: "white",
  },
  skeletonStatusContainer: {
    alignItems: "center",
    borderBottom: 1,
    borderBottomStyle: "solid",
    display: "flex",
    height: 70,
    justifyContent: "center",
    width: 70,
  },
  lightColor: {
    color: colors.white_base,
  },
  darkColor: {
    color: colors.grey_base,
  },
  pulse: {
    WebkitAnimation: "pulse 3s linear infinite",
  },
  offlinePulse: {
    WebkitAnimation: "offlinePulse 3s linear infinite",
  },
  boldText: {
    "&.MuiTypography-root": {
      fontWeight: "bold",
    },
    display: "contents",
    lineHeight: "normal",
  },
  text: {
    display: "contents",
    lineHeight: "normal",
  },
  wrapperContainer: {
    minHeight: 72,
  },
  gridActionItems: {
    "&.MuiGrid2-root": {
      flex: 1,
    },
  },
});

export default useStyles;
