import getFormatDependingOnLang, { TMode } from "./getFormatDependingOnLang";
import moment from "moment-timezone";
import TScale from "../../../api/time/TScale";
import { TFunction } from "i18next";
import "moment/min/locales.min";

export default function formatDateToString(
  d: Date,
  lang: string | undefined,
  t: TFunction,
  formatType: TMode = "datetime",
  scale: TScale,
  timezone: string,
): string {
  let translated = moment
    .tz(d, timezone)
    .locale(lang ?? "en")
    .format(getFormatDependingOnLang(lang, formatType, scale));
  if (scale === "WEEKS") {
    translated = `${t("common.time.calendarWeekUnit")}${translated}`;
  }
  return translated;
}
