/*
 *   Screenmode local storing the current screenmodes like fullscreen, shopfloormode etc.
 */
import { useCallback, useState } from "react";
import useLocalStorage from "../persistence/useLocalStorage";

interface IScreenModeSettingsApi {
  isFullscreenEnabled: boolean;
  setFullscreenEnabled: (enabled: boolean) => void;
  isDesktopEnabled: boolean;
  setDesktopEnabled: (enabled: boolean) => void;
}

const Fullscreen_KEY = "Fullscreen";
const Desktop_KEY = "Desktop";

/*
Please use this not directly but with GeorgStorageProvider
 */
export default function useScreenModeSettings(): IScreenModeSettingsApi {
  const { load, set } = useLocalStorage();

  // Initialize screen settings
  const isFullscreenEnabledFromLS = load(Fullscreen_KEY) === "true";
  const [isFullscreenEnabled, setIsFullscreenEnabledInternal] =
    useState<boolean>(isFullscreenEnabledFromLS);

  const isDesktopEnabledFromLS = load(Desktop_KEY) === "true";
  const [isDesktopEnabled, setIsDesktopEnabled] = useState<boolean>(
    isDesktopEnabledFromLS,
  );

  const setFullscreenEnabled = useCallback((enabled: boolean) => {
    setIsFullscreenEnabledInternal(enabled);
    set(Fullscreen_KEY, `${enabled}`);
  }, []);

  const setDesktopEnabled = useCallback(
    (enabled: boolean) => {
      setIsDesktopEnabled(enabled);
      set(Desktop_KEY, `${enabled}`);
    },
    [set],
  );

  return {
    isFullscreenEnabled,
    setFullscreenEnabled,
    isDesktopEnabled,
    setDesktopEnabled,
  };
}
