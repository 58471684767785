import gql from "graphql-tag";

export default gql`
  mutation resetAzureB2cUserPassword($userId: String!) {
    resetAzureB2cUserPassword(userId: $userId) {
      message
      statusCode
    }
  }
`;
