import { Theme } from "@mui/material/styles";
import { colors } from "../../../../shared/theme";
import { makeStyles } from "tss-react/mui";
const topBarHeight = 63;

const useStyles = makeStyles()((theme: Theme) => ({
  bottomElement: {
    borderTop: `1px solid ${colors.grey_base}`,
  },
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: topBarHeight,
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
    },
    [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
      marginTop: 0,
    },
  },
  hiddenIcon: {
    fill: colors.blue_base,
  },
  listItem: {
    [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
      paddingLeft: 35,
    },
    cursor: "pointer",
    borderLeft: `2px solid transparent`,
    color: colors.white_base,
    borderBottom: `1px solid ${colors.grey_base}`,
    "&:hover": {
      borderLeft: `2px solid ${colors.yellowB_16}`,
    },
    "&:last-element": {
      borderLeft: "none",
    },
  },
}));

export default useStyles;
