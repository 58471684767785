import { DateTime } from "luxon";

/**
 *
 * @param date
 * @param timezone
 * @returns DateTime object with the date and time in the specified timezone
 * e.x., createTimezoneDateFromDate("Thu Aug 15 2024 14:11:00 GMT+0200", "Turkey") will return a DateTime object with the value 2024-08-15T14:11:00+03:00
 */
export function createTimezoneDateFromDate(
  date: Date,
  timezone: string,
): DateTime {
  return DateTime.fromObject(
    {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
      hour: date.getHours(),
      minute: date.getMinutes(),
    },
    { zone: timezone },
  );
}

/** get the current time in the local timezone
 * e.x., GetCurrentTime("Europe/Berlin")
 * the current time in utc is "2021-09-01T12:00:00Z"
 * will return =>  2021-09-01T14:00:00+02:00
 *  */

export function GetCurrentTime(timezone: string): Date {
  // get the current time in utc, then convert it to the specified timezone and then get the iso string
  const isoString = DateTime.utc().setZone(timezone).toISO();
  // convert the iso string to a Date object for the TimePicker component
  return IsoDateToTimePickerDate(isoString, timezone);
}

// converts a TimePicker component Date to an ISO date string
/**
 *
 * @param date
 * @param timezone
 * @returns string with the date and time IsoString
 * e.x., TimePickerDateToIsoString("Thu Aug 15 2024 15:12:00 GMT+0200", "Turkey")
 * will return => 2024-08-15T12:00:00.00Z
 */
export function TimePickerDateToIsoString(
  date: Date,
  timezone: string,
): string {
  const customDate = createTimezoneDateFromDate(date, timezone);
  return customDate
    .toUTC()
    .toISO()
    .replace(/\.000Z$/, ".00Z");
}

// converts an ISO date string to a Date object for the TimePicker component
/**
 *
 * @param date: string with the date and time in IsoString
 * @param timezone
 * @returns Date object with the date and time for the DateTime picker
 * e.x., IsoDateToTimePickerDate("2024-08-13T21:00:00Z", "Turkey")
 * will return => Thu Aug 14 2024 00:00:00 GMT+0200 if the browser timezone is Europe/Berlin
 */
export function IsoDateToTimePickerDate(date: string, timezone: string): Date {
  const dt = DateTime.fromISO(date, { zone: timezone });
  return new Date(dt.year, dt.month - 1, dt.day, dt.hour, dt.minute);
}

/**
 *
 * @param date
 * @param timezone
 * @returns DateTime object with the date and time in the specified timezone
 * e.x., IsoDateToTimezoneDate("2024-08-13T21:00:00Z", "Turkey")
 * will return => 2024-08-14T00:00:00+03:00
 */
export function IsoDateToTimezoneDate(
  date: string,
  timezone: string,
): DateTime {
  return DateTime.fromISO(date, {
    zone: "utc",
  }).setZone(timezone);
}

export default function dateToIsoString(date: Date): string {
  return `${date.toISOString().slice(0, -5)}Z`;
}
