import { IStateListEntry } from "../../IStateListEntry";
import IHeatMapRow, {
  IHeatmapRowLayer,
} from "../../../../../../shared/components/charts/barChartHeatmap/components/heatmapRow/api/IHeatMapRow";
import IStateListEntryData, {
  IStateListEntryDataValue,
} from "../../IStateListEntryData";
import dateRangeScaler from "../dateRangeScaler/dateRangeScaler";
import formatDateToString from "../../../../../../helper/time/formatting/formatDateToString";
import { TFunction } from "i18next";
import TScale from "../../../../../../api/time/TScale";
import findStartAndEndTimeForHeatmap from "../startEndTimeFinder/findStartAndEndTimeForHeatmap";

const ServerScaleToFrontendScaleMap = {
  years: "YEARS",
  months: "MONTHS",
  weeks: "WEEKS",
  days: "DAYS",
  hours: "HOURS",
};

export default function serverFormatToChartFormat(
  t: TFunction,
  lang: string,
  startTime: string,
  data: IStateListEntry[],
  timezone: string,
): IHeatMapRow[] {
  return data.map((entry: IStateListEntry): IHeatMapRow => {
    const scale = ServerScaleToFrontendScaleMap[entry.scale] as TScale;
    if (!scale) {
      throw new Error("Mapping error: Scale value is not exist or null.");
    }
    const { start, end } = findStartAndEndTimeForHeatmap(
      new Date(entry.tsStart).getTime(),
      new Date(entry.tsEnd).getTime(),
      scale,
      timezone,
    );
    const label = formatDateToString(
      new Date(start),
      lang,
      t,
      "date",
      scale,
      timezone,
    );
    return {
      layers: entry.data.map(
        (entryData: IStateListEntryData): IHeatmapRowLayer => {
          const combinedParts = combineConsecutiveParts(entryData.values);
          return {
            indicatorIdentificationTypeId:
              entryData.indicatorIdentificationTypeId,
            parts: dateRangeScaler(start, end, combinedParts, scale as string),
          };
        },
      ),
      label: label,
    };
  });
}

export function serverFormatToChartFormatWithoutScaling(
  t: TFunction,
  lang: string,
  start: string,
  end: string,
  data: IStateListEntry[],
  timezone: string,
): IHeatMapRow[] {
  return data.map((entry: IStateListEntry): IHeatMapRow => {
    const scale = ServerScaleToFrontendScaleMap[entry.scale] as TScale;
    if (!scale) {
      throw new Error("Mapping error: Scale value is not exist or null.");
    }
    const label = formatDateToString(
      new Date(start),
      lang,
      t,
      "date",
      scale,
      timezone,
    );
    return {
      layers: entry.data.map(
        (entryData: IStateListEntryData): IHeatmapRowLayer => {
          const combinedParts = combineConsecutiveParts(entryData.values);
          const parts = dateRangeScaler(
            new Date(start).getTime(),
            new Date(end).getTime(),
            combinedParts,
            scale as string,
          );

          return {
            indicatorIdentificationTypeId:
              entryData.indicatorIdentificationTypeId,
            parts: parts,
          };
        },
      ),
      label: label,
    };
  });
}

// Function to combine consecutive part values
function combineConsecutiveParts(
  parts: IStateListEntryDataValue[],
): IStateListEntryDataValue[] {
  const combinedParts: IStateListEntryDataValue[] = [];

  let currentIndex = 0;
  while (currentIndex < parts.length) {
    const currentPart = { ...parts[currentIndex] };
    let nextIndex = currentIndex + 1;

    while (
      nextIndex < parts.length &&
      currentPart.tsEnd === parts[nextIndex].tsStart &&
      currentPart.duration
    ) {
      // Combine consecutive parts
      currentPart.tsEnd = parts[nextIndex].tsEnd;
      currentPart.duration += parts[nextIndex].duration ?? 0;
      nextIndex++;
    }
    combinedParts.push(currentPart);
    currentIndex = nextIndex;
  }

  return combinedParts;
}
