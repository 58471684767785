import React from "react";
import GeorgModal from "../../shared/components/modal";
import generatedGitInfo from "../../generatedGitInfo.json";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import {
  serviceWorkerRegistrationState,
  serviceWorkerUpdated,
} from "../../redux/reducers/serviceWorkerTokenReducer";
import { useSelector } from "react-redux";
import { updateServiceWorkerAndReload } from "../../serviceWorkerRegistration";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import SideButton from "../../shared/components/sideButton";
import ErrorIcon from "@mui/icons-material/Error";
import { Typography } from "@mui/material";

export default function VersionModal(): React.ReactElement {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const updateText = t("version.update");
  const isServiceWorkerUpdated = useSelector(serviceWorkerUpdated);
  const serviceWorkerTokenRegistration = useSelector(
    serviceWorkerRegistrationState,
  );

  return (
    <GeorgModal
      isModalVisible={true}
      onClose={() => navigate(-1)}
      title={t("version.title")}
      isScrollerShown={false}
    >
      <div className={classes.container}>
        <Typography>
          {t("version.branch")}:{" "}
          {generatedGitInfo.gitBranch || "unknown git branch"}
        </Typography>
        <Typography>
          {t("version.commit")}:{" "}
          {generatedGitInfo.gitCommitHash || "unknown git hash"}
        </Typography>
        {isServiceWorkerUpdated && (
          <div className={classes.updateContainer}>
            <div className={classes.textIconContainer}>
              {<ErrorIcon color="warning" />}
              {t("version.updateAvailable")}
            </div>
            <SideButton
              label={updateText}
              onClick={() =>
                updateServiceWorkerAndReload(serviceWorkerTokenRegistration)
              }
              disabled={false}
              endIcon={<SystemUpdateAltIcon color="secondary" />}
            />
          </div>
        )}
      </div>
    </GeorgModal>
  );
}
