import React from "react";
import { Button, Typography } from "@mui/material";
import useStyles from "./styles";

interface IProps {
  name: string;
  isActive: boolean;
  id: string;
  onClick: (stateId: string) => void;
}

export default function StateCard({
  id,
  name,
  isActive,
  onClick,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  return (
    <Button
      className={`${classes.default} ${
        isActive ? classes.active : classes.inactive
      }`}
      onClick={() => onClick(id)}
    >
      <Typography variant={"h5"}>{name}</Typography>
    </Button>
  );
}
