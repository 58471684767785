import React from "react";
// Custom imports.
import useClaims from "./auth/hooks/useClaims";
import { Router } from "./services";
import { Container } from "./components";
import IFreshChat from "./components/freshChat";
interface IProps {
  adapterLocale: string;
}

export default function MainContainer({
  adapterLocale,
}: IProps): React.ReactElement {
  const claims = useClaims();

  return (
    <Container>
      <>
        <Router />
        <IFreshChat claims={claims} adapterLocale={adapterLocale} />
      </>
    </Container>
  );
}
