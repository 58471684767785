import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectIndicator } from "../../../../../../../redux/reducers/indicatorReducer";
import IHeatMapRow from "../../../../../../../shared/components/charts/barChartHeatmap/components/heatmapRow/api/IHeatMapRow";
import IConfigElement from "../../../../../../../config/states/IIndicatorConfigElement";
import getIndicatorConfigList from "../../../../../../../config/states/getIndicatorConfigList";
import { serverFormatToChartFormatWithoutScaling } from "../../../../../../charts/machineStateHeatmapCard/api/transformation/serverFormatToChartFormat/serverFormatToChartFormat";
import { useTranslation } from "react-i18next";
import HeatmapRow from "../../../../../../../shared/components/charts/barChartHeatmap/components/heatmapRow";
import useStyles from "./styles";
import useStateList24hIntervalQuery from "../../api/useStateList24hIntervalQuery";
import indicatorNamesToIndicatorIds from "../../../../../../../helper/indicator/indicatorNamesToIndicatorIds";
import { IStateListEntry } from "../../../../../../charts/machineStateHeatmapCard/api/IStateListEntry";
import ServerDataStatusWrapper from "../../../../../../../shared/components/serverDataStatusWrapper";
import { EApiState } from "../../../../../../../api/state/EApiState";
import HeatMapRowSkeleton from "../../../../../../../shared/components/charts/barChartHeatmap/components/heatmapRow/components/skeleton";

interface IProps {
  machineId: string;
  timezone: string;
}

function mergeTwoDaysIntoOne(days: IStateListEntry[]): IStateListEntry[] {
  if (days.length !== 0 && days.length !== 2) {
    throw Error(
      `Something went wrong, time period to long: ${JSON.stringify(days)}`,
    );
  } else if (days.length !== 0) {
    const [firstDay, secDay] = days;
    const newEntry: IStateListEntry = {
      tsStart: firstDay.tsStart,
      tsEnd: secDay.tsEnd,
      data: [...firstDay.data, ...secDay.data],
      scale: firstDay.scale,
      day: secDay.day,
      monthNameShort: secDay.monthNameShort,
      month: secDay.month,
      dayName: secDay.dayName,
      dayNameShort: secDay.dayNameShort,
    };
    return [newEntry];
  } else {
    return [];
  }
}

function LastDayStacklight({
  machineId,
  timezone,
}: IProps): React.ReactElement {
  const { classes } = useStyles();

  const { t, i18n } = useTranslation();
  const allIndicators = useSelector(selectIndicator);
  const indicatorIds = useMemo(() => {
    if (allIndicators.length > 0) {
      return indicatorNamesToIndicatorIds(
        [
          "indicator_state_undefined",
          "indicator_state_notavailable",
          "indicator_state_idle",
          "indicator_state_executing",
        ],
        allIndicators,
      );
    }
    return null;
  }, [allIndicators]);

  const config: IConfigElement[] = useMemo(() => {
    return getIndicatorConfigList(allIndicators, indicatorIds ?? []);
  }, [allIndicators]);
  const { stateListResponse, apiState } = useStateList24hIntervalQuery(
    indicatorIds ?? [],
    machineId,
    timezone,
  );
  const data = useMemo(() => {
    if (stateListResponse != null && stateListResponse.length > 0) {
      const mergedData = mergeTwoDaysIntoOne(stateListResponse);
      const newData = serverFormatToChartFormatWithoutScaling(
        t,
        i18n.language,
        mergedData[0].tsStart,
        mergedData[0].tsEnd,
        mergedData,
        timezone,
      );
      return newData;
    }
    return [];
  }, [stateListResponse]);

  const rows = useMemo(() => {
    if (apiState === EApiState.LOADING) {
      return (
        <div className={classes.stacklightBox}>
          <HeatMapRowSkeleton height={30} />
        </div>
      );
    }
    return data.map((row: IHeatMapRow) => (
      <div className={classes.stacklightBox} key={row.label}>
        <HeatmapRow
          label={row.label}
          rowData={row}
          config={config}
          useResponsiveContainer={false}
          t={t}
          language={i18n.language}
          timezone={timezone}
          isActive={true}
          scale={stateListResponse?.[0]?.scale ?? "days"}
        />
      </div>
    ));
  }, [data, classes.stacklightBox, config, apiState]);

  return (
    <>
      <ServerDataStatusWrapper isEmpty={false}>
        {apiState == EApiState.ERROR ? (
          <>{t("machinery.machineCard.statusFooter.error")}</>
        ) : (
          <>{rows}</>
        )}
      </ServerDataStatusWrapper>
    </>
  );
}

export default React.memo(LastDayStacklight);
