import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  Container: {
    marginLeft: 0,
    height: "100%",
    maxWidth: "400px",
  },
  title: {
    paddingBottom: 50,
  },
  android: {
    paddingBottom: 50,
  },
  ios: {
    paddingBottom: 50,
  },
}));
export default useStyles;
