import gql from "graphql-tag";

export default gql`
  mutation setUserChatRestoreId($userId: String!, $chatRestoreId: String!) {
    setUserChatRestoreId(userId: $userId, chatRestoreId: $chatRestoreId) {
      message
      statusCode
    }
  }
`;
