import IManualStateConfigEntry from "../../../../../../api/manualState/IManualStateConfigEntry";

const moveDown = (pos) => pos + 1;
const moveUp = (pos) => pos - 1;

function getActualPositionOfState(
  states: IManualStateConfigEntry[],
  idToMove: string,
): number {
  const manualStateToLift = states.find((s) => s.id === idToMove);
  if (!manualStateToLift) {
    throw Error(
      `Error: Could not find manual state with id ${idToMove}. Was it deleted?`,
    );
  }
  return manualStateToLift.displayPrio;
}

export function moveStateUp(
  states: IManualStateConfigEntry[],
  idToMove: string,
): IManualStateConfigEntry[] {
  const actualPos = getActualPositionOfState(states, idToMove);
  if (actualPos === 0) {
    return states;
  }
  const nextPosForMovedState = moveUp(actualPos);
  return states.map((state) => {
    if (state.id === idToMove) {
      return { ...state, displayPrio: nextPosForMovedState };
    }
    if (state.displayPrio === nextPosForMovedState && state.id !== idToMove) {
      return {
        ...state,
        displayPrio: moveDown(nextPosForMovedState),
      };
    } else {
      return state;
    }
  });
}

export function moveStateDown(
  states: IManualStateConfigEntry[],
  idToMove: string,
): IManualStateConfigEntry[] {
  const actualPos = getActualPositionOfState(states, idToMove);
  if (actualPos + 1 === states.length) {
    return states;
  }
  const nextPosForMovedState = moveDown(actualPos);
  return states.map((state) => {
    if (state.id === idToMove) {
      return { ...state, displayPrio: nextPosForMovedState };
    }
    if (state.displayPrio === nextPosForMovedState && state.id !== idToMove) {
      return {
        ...state,
        displayPrio: moveUp(nextPosForMovedState),
      };
    } else {
      return state;
    }
  });
}
