/*
 *
 *   View for user profile. Implemented in profile-page.
 *
 */

import React, { useEffect } from "react";
import { Container, Typography } from "@mui/material";
import useStyles from "./styles";

export default function UserProfileView(): React.ReactElement {
  const { classes } = useStyles();
  useEffect(() => {
    // eslint-disable-next-line max-len
    window.location.href = `https://b2cmygeorg.b2clogin.com/b2cmygeorg.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_PROFILEEDIT&client_id=6e0e6ea5-8708-4105-8d2e-e507498c5c1f&nonce=defaultNonce&redirect_uri=https%3A%2F%2F${window.location.host}&scope=openid&response_type=id_token&prompt=login`;
  }, []);
  return (
    <Container className={classes.container}>
      <Typography color="secondary" variant="h3">
        ...
      </Typography>
    </Container>
  );
}
