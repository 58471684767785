import { responsiveFontSizes } from "@mui/material/styles";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import colors from "./colors";
import rootTheme from "./root";
import { createTheme } from "@mui/material";
import { ExtendedTypographyOptions } from "./customTypographyTypes";
import colorToRgba from "../../helper/adjustColor";

let muiTheme = createTheme({
  // Typography.
  typography: {
    fontFamily: ["Titillium Web", "Roboto"].join(","),
    // import "@fontsource/titillium-web"; // Defaults to weight 400.
    // Weights: [200,300,400,600,700,900]
    // Styles: [italic,normal]
    // Supported subsets: [latin,latin-ext]
    h1: { fontSize: 20, fontWeight: 600 }, // highlight_24_500_blue_base
    h2: { fontSize: 20, fontWeight: 400 }, // body_20_400_blue_base
    h3: { fontSize: 20, fontWeight: 300 }, // body_20_300_blue/grey_base:
    h4: { fontSize: 18, fontWeight: 300 }, // body_18_300_blue/grey_base:
    // h5: {},
    // h6: {},
    // subtitle1: {},
    // subtitle2: {},
    body1: { fontSize: 16, fontWeight: 300 }, // body_16_300_blue/grey/white_base
    body2: { fontSize: 14, fontWeight: 300 }, // body_14_300_blue/grey_base
    // button: {},
    caption: { fontSize: 12, fontWeight: 300 }, // body_12_300_blue/grey_base
    // Tab: {
    //   fontSize: 30,
    // }
    d1: {
      fontSize: 48,
      lineHeight: "100%",
      fontFamily: "Titillium Web",
    },
  } as ExtendedTypographyOptions,

  // Colors.
  palette: {
    primary: {
      main: colors.blue_base,
    },
    secondary: {
      main: colors.white_base,
    },
    text: {
      primary: colors.grey_base,
      secondary: colors.greyB16,
    },
    warning: {
      main: colors.yellow_base,
    },
  },

  // Components.
  components: {
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: "black",
        },
      },
    },
    // Button.
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: colors.blue_base,
          // boxShadow: rootTheme.shadow,
          padding: 20,
          "&:hover": {
            backgroundColor: colors.blue_base,
            // boxShadow: rootTheme.shadow,
            color: colors.white_base,
          },
        },
        containedSecondary: {
          backgroundColor: colors.white_base,
          borderColor: colors.greyB32,
          borderStyle: "hidden",
          borderWidth: 1,
          padding: 20,
          "&:hover": {
            backgroundColor: colorToRgba(colors.blue_base, 0.04),
            // boxShadow: rootTheme.shadow,
          },
          "&:active": {
            backgroundColor: colors.greyB48,
          },
          /*":disabled": {
            // backgroundColor: colors.greyB16,
            // color: colors.greyB32,
          },*/
        },
        root: {
          borderRadius: 0,
          height: rootTheme.elementHeight,
          textTransform: "none",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          color: colors.grey_base,
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          height: rootTheme.elementHeight,
          // fontSize: "18px",
          // fontWeight: 300,
          // textTransform: "none",
        },
      },
    },

    // Icons.
    MuiIcon: {
      styleOverrides: {
        colorPrimary: { color: colors.white_base },
        colorSecondary: { color: colors.grey_base },
        colorAction: { color: colors.blue_base },
        fontSizeSmall: { height: 20, width: 20 },
        fontSizeLarge: { height: 30, width: 30 },
        root: {
          height: 24,
          width: 24,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorPrimary: { color: colors.white_base },
        colorSecondary: { color: colors.grey_base },
        colorAction: { color: colors.blue_base },
        fontSizeSmall: { height: 20, width: 20 },
        fontSizeLarge: { height: 30, width: 30 },
        root: {
          height: 24,
          width: 24,
        },
      },
    },

    // Paper.
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          boxShadow: rootTheme.shadow,
          color: colors.grey_base,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          color: colors.blue_base,
          fontSize: 16,
          fontWeight: 300,
          "&:focus": {
            backgroundColor: "unset",
          },
        },
      },
    },
    // Necessary for select menu on select components.
    MuiListItem: {
      styleOverrides: {
        root: {
          color: colors.blue_base,
          fontSize: 16,
          fontWeight: 300,
          height: rootTheme.elementHeight,
        },
      },
    },
    MuiDateTimePickerToolbar: {
      styleOverrides: {
        separator: {
          display: "flex",
          alignItems: "center",
        },
      },
    },
    MuiPickersToolbarButton: {
      styleOverrides: {
        root: {
          height: "100%",
        },
      },
    },
    // ignore this ts because it's not in base theme but in lab
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    MuiPickersDay: {
      styleOverrides: {
        root: {
          color: colors.grey_base,
        },
      },
    },
    // This is not found in "@mui/x-date-pickers/themeAugmentation"
    /*MuiPickersTime: {
      styleOverrides: {
        colorPrimary: colors.grey_base,
        root: {
          color: colors.grey_base,
        },
      },
    },
    MuiClockPicker: {
      styleOverrides: {
        root: {
          color: colors.grey_base,
        },
      },
    },*/
    // ignore this ts because it's not in base theme but in lab
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    MuiSelected: {
      styleOverrides: {
        root: {
          color: colors.grey_base,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: colors.grey_base,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          color: colors.grey_base,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          color: colors.grey_base,
        },
        paper: {
          color: colors.grey_base,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: colors.blue_base,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "--DataGrid-containerBackground": colorToRgba(
            colors.white_base,
            0.02,
          ),
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          "@media (max-width: 1199px)": {
            left: "auto",
            transform: "none",
          },
          "@media (max-width: 599px)": {
            left: 8,
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: colors.blue_base,
          color: colors.white_base,
          borderRadius: 8,
          padding: 16,
          minWidth: 355,
          maxWidth: 579,
          "@media (max-width: 599px)": {
            minWidth: 300,
            maxWidth: 355,
          },
        },
        action: {
          paddingLeft: 0,
          marginLeft: 0,
          marginRight: 0,
          width: "100%",
        },
        message: {
          display: "none",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: "inherit",
          boxShadow: "none",
          "&.Mui-expanded": {
            backgroundColor: "inherit",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: "inherit",
          padding: 0,
          "&.MuiButtonBase-root": {
            minHeight: 24,
          },
        },
        content: {
          margin: 0,
          "&.Mui-expanded": {
            margin: 0,
          },
        },
        expandIconWrapper: {
          color: colorToRgba(colors.white_base, 0.5),
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: colors.blue_base,
        },
        root: {
          height: 3 + "px !important",
        },
        bar: {
          backgroundColor: colors.yellow_base,
          borderRadius: 8,
          paddingLeft: 3,
          paddingRight: 3,
        },
      },
    },
  },
});

muiTheme = responsiveFontSizes(muiTheme);

export default muiTheme;
