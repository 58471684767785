import { makeStyles } from "tss-react/mui";

const styles = makeStyles()(() => ({
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  titleText: {
    "&.MuiTypography-root": {
      fontWeight: "bold",
      lineHeight: "24px",
      fontSize: 18,
    },
    display: "contents",
  },
  titleText1: {
    "&.MuiTypography-root": {
      fontWeight: "bold",
      lineHeight: "24px",
      fontSize: 18,
    },
    display: "contents",
  },
}));

export default styles;
