import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()((theme: Theme) => ({
  chartContainer: {
    height: 230,
    position: "relative",
  },
  chartArea: {
    position: "absolute",
    left: 0,
    right: 0,
    height: 160,
  },
  chartAreaError: {
    alignItems: "center",
    display: "flex",
    height: 160,
    justifyContent: "center",
  },
  container: {
    height: "100%",
    width: "100%",
    // Hover.
    [theme.breakpoints.up("sm")]: {
      transform: "perspective(1px) translateZ(0)",
      transitionDuration: "0.3s",
      transitionProperty: "transform",
      "&:hover": {
        transform: "scale(1.03)",
      },
    },
  },
  label: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  gridActionItems: {
    "&.MuiGrid2-root": {
      flex: 1,
    },
  },
}));
export default useStyles;
