import { TIndicatorType } from "../../../../helper";
import IBarChartDataElement from "../../../../shared/components/charts/barStackChartCard/api/IBarChartDataElement";
import { IBarChartPart } from "../../../../shared/components/charts/barStackChartCard";
import { ICondensedState, IStackEntryData } from "./useCondensedStateListQuery";
import STATE_COLOR_MAP, {
  TIndicatorName,
} from "../../../../config/states/STATE_COLOR_MAP";
import getScaleForStartEndTimestamps from "../../../../helper/time/scale/getScaleForStartEndTimestamps";
import formatDateToString from "../../../../helper/time/formatting/formatDateToString";
import moment from "moment";
import { TFunction } from "i18next";
import indicatorIdToIndicatorType from "../../../../helper/indicator/indicatorIdToIndicatorType";

function getLabel(
  locale: string,
  t: TFunction,
  start: string,
  end: string,
  timezone: string,
): string {
  const scale = getScaleForStartEndTimestamps(
    new Date(start),
    new Date(end),
    timezone,
  );
  return formatDateToString(
    moment.utc(start).toDate(),
    locale,
    t,
    "date",
    scale,
    timezone,
  );
}

function createBarChartData(
  condensedStates: ICondensedState[],
  locale: string,
  timezone: string,
  t: TFunction,
  allIndicators: TIndicatorType[],
): IBarChartDataElement[] {
  return condensedStates.map((entry: ICondensedState): IBarChartDataElement => {
    const label = getLabel(locale, t, entry.tsStart, entry.tsEnd, timezone);
    return {
      name: label,
      data: entry.data.reduce((acc, entryData: IStackEntryData) => {
        const { indicatorIdentificationTypeId, value } = entryData;
        const type = indicatorIdToIndicatorType(
          allIndicators,
          `${indicatorIdentificationTypeId}`,
        );
        return { ...acc, [type.indicatorName]: value };
      }, {}),
    };
  });
}

function createBarChartParts(
  entries: IStackEntryData[],
  allIndicators: TIndicatorType[],
  dataEntries: ICondensedState[],
  indicatorIdsForOrder: number[],
): IBarChartPart[] {
  const allDataEntries: IStackEntryData[][] = dataEntries.map(
    (d: ICondensedState) => d.data,
  );
  const partMap = {};
  allDataEntries.forEach((entries: IStackEntryData[]) => {
    entries.forEach((entry: IStackEntryData) => {
      const { indicatorIdentificationTypeId }: IStackEntryData = entry;
      if (!(indicatorIdentificationTypeId in partMap)) {
        const type = indicatorIdToIndicatorType(
          allIndicators,
          `${indicatorIdentificationTypeId}`,
        );
        const dynamicColor =
          STATE_COLOR_MAP[type.indicatorName as TIndicatorName];
        if (dynamicColor == null) {
          throw new Error(
            `Missing color for indicator name ${type.indicatorName}. Please extend color map!`,
          );
        }
        partMap[indicatorIdentificationTypeId] = {
          color: dynamicColor,
          name: type.indicatorName,
        };
      }
    });
  });

  return indicatorIdsForOrder
    .map((indicatorId: number) => {
      if (indicatorId in partMap) {
        return partMap[indicatorId];
      } else {
        return null;
      }
    })
    .filter((el) => el != null);
}

export default function transformDataFromServer(
  locale: string,
  timezone: string,
  t: TFunction,
  dataEntries: ICondensedState[],
  allIndicators: TIndicatorType[],
  indicatorIdsForOrder: number[],
): {
  data: IBarChartDataElement[];
  parts: IBarChartPart[];
} {
  if (dataEntries.length <= 0 || allIndicators.length <= 0) {
    return { data: [], parts: [] };
  }
  const data: IBarChartDataElement[] = createBarChartData(
    dataEntries,
    locale,
    timezone,
    t,
    allIndicators,
  );
  const firstEntry: ICondensedState | undefined = dataEntries.find(
    (value: ICondensedState) => {
      return value.data != null && value.data.length > 0;
    },
  );
  let parts: IBarChartPart[] = [];

  if (firstEntry != null) {
    parts = createBarChartParts(
      firstEntry.data,
      allIndicators,
      dataEntries,
      indicatorIdsForOrder,
    );
  }

  return { data, parts };
}
