import { useEffect, useCallback } from "react";

export function useKeyPressed(
  key: string,
  callback: () => void,
  targetDomNode: Element | null,
) {
  const keyPressedHandler = useCallback(
    (event) => {
      if (event.key === key) {
        callback();
      }
    },
    [key, callback],
  );

  useEffect(() => {
    if (targetDomNode) {
      targetDomNode.addEventListener("keydown", keyPressedHandler, false);

      return () => {
        targetDomNode.removeEventListener("keydown", keyPressedHandler, false);
      };
    }
  }, [targetDomNode, key, callback]);
}
