import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { IIndicatorStateSince } from "../../api/useCurrentIndicatorStateList/useCurrentIndicatorStateList";
import WithToggleableTooltip from "../../../../../../withToggableTooltip";
import { useTranslation } from "react-i18next";
import useStyles from "../../styles";
import getIndicatorTranslationByStateName from "../../../../../../../helper/indicator/getIndicatorTranslationByStateName";
import { TIndicatorName } from "../../../../../../../config/states/STATE_COLOR_MAP";
import calculateTimeDiffHourMinute from "../../../../../../../helper/time/calculateTimeDiffHourMinute";

interface IProps {
  indicatorName: TIndicatorName;
  statusMessage: string;
  indicatorStateSince?: IIndicatorStateSince;
}

function StatusFooterInformation({
  indicatorName,
  statusMessage,
  indicatorStateSince,
}: IProps): React.ReactElement {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const { name, description, footer } = useMemo(() => {
    return getIndicatorTranslationByStateName(indicatorName, t);
  }, [indicatorName]);

  return (
    <WithToggleableTooltip
      tooltipInfo={{
        description,
        label: name,
        footer,
      }}
    >
      <div className={classes.textContainer}>
        <Typography color="textPrimary" variant="body1">
          {statusMessage}
        </Typography>
        <Box>
          <Typography
            color="textPrimary"
            variant="body1"
            className={classes.text}
          >
            {t("machinery.machineCard.statusFooter.since")}{" "}
          </Typography>
          <Typography
            color="textPrimary"
            variant="body1"
            className={classes.boldText}
          >
            {calculateTimeDiffHourMinute(
              indicatorStateSince?.stateStartedSince || new Date(),
              new Date(),
              t,
            )}
          </Typography>
        </Box>
      </div>
    </WithToggleableTooltip>
  );
}
export default React.memo(StatusFooterInformation);
