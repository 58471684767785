import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  populateDoctypeList,
  filterByDocumentType,
  selectCheckboxes,
  selectDoctypeList,
  setServiceMachine,
} from "../../../redux/reducers/serviceReducer";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { store } from "../../../redux/store";
import { token } from "../../../redux/reducers/tokenReducer";
import { selectMachine } from "../../../redux/reducers/machineSelectReducer";
import useClaims from "../../../auth/hooks/useClaims";
import { isNull } from "lodash";
import resources from "../../../@types/resources";
import { domainUrlMapping } from "../../Apollo";

let isServerError = false;
type docTypes = keyof typeof resources.service.documentsearch.doctypes;

/**
 * Retrieves the doctype list from the Elasticsearch server and mirrors the
 * results in the redux store
 */
function loadAllDoctypes(jwt: string, claims, selectedMachine) {
  let result: string[] = [];
  isServerError = false;
  // This block prevents the multiple rendering of the doctype form group.
  // Custom elasticsearch query which shows every value which is a contentType
  const currData = {
    aggs: {
      contentTypes: {
        terms: { field: "contentType", size: 10 },
      },
    },
    size: 0,
  };

  if (!isNull(claims) && selectedMachine != store.getState().Service.machine) {
    const domain =
      domainUrlMapping[claims.extension_tenant] || domainUrlMapping.default;
    fetch(
      `https://${domain}/${claims.extension_tenant}/es/${selectedMachine}/_search`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify(currData),
      },
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          store.dispatch(populateDoctypeList([]));
        }
      })
      .then((data) => {
        store.dispatch(populateDoctypeList([]));
        store.dispatch(setServiceMachine(selectedMachine));
        if (data.aggregations) {
          const buckets = data.aggregations.contentTypes.buckets;
          result = ["all"];
          buckets.map((bucket) => {
            result.push(bucket.key);
          });
          store.dispatch(populateDoctypeList(result));
        }
      })
      .catch(() => {
        isServerError = true;
      });
  }
}

export default function DocumentTypeSelection(): React.ReactElement {
  const dispatch = useDispatch();
  const doctypes = useSelector(selectDoctypeList);
  const selectedDoctypes = useSelector(selectCheckboxes);
  const { t } = useTranslation();
  // get the authentication token from local storage if it exists
  const jwt = useSelector(token);
  const selectedMachine = useSelector(selectMachine);
  const claims = useClaims();

  // useEffect(effect,deps) without any dependencies is like an init() function
  useEffect(() => {
    /*
    if (store.getState().Service.availableDocumentTypes.length > 0) {
      return;
    }*/
    loadAllDoctypes(jwt, claims, selectedMachine);
  }, [selectedMachine]);

  /**
   * Deals with the user input whenever a checkbox is selected
   * @param event event object which contains all information
   */
  function handleCheckboxEvent(event) {
    const selectedCheckboxName: string = event.target.value;
    dispatch(filterByDocumentType(selectedCheckboxName));
  }

  if (doctypes.length <= 0) {
    if (isServerError) {
      return (
        <Alert severity="error">
          {t("service.documentsearch.messages.server_error")}
        </Alert>
      );
    } else {
      return (
        <Alert severity="info">
          {t("service.documentsearch.messages.no_doctypes_found")}
        </Alert>
      );
    }
  } else {
    return (
      <div>
        <FormGroup>
          {doctypes.map((doctype) => {
            const doctypeLabel = doctype as docTypes;
            return (
              <FormControlLabel
                key={doctypeLabel}
                control={
                  <Checkbox id={doctypeLabel} onChange={handleCheckboxEvent} />
                }
                label={t(`service.documentsearch.doctypes.${doctypeLabel}`)}
                value={doctypeLabel}
                checked={selectedDoctypes.includes(doctypeLabel)}
              />
            );
          })}
        </FormGroup>
      </div>
    );
  }
}
