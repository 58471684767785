import React, { ChangeEvent } from "react";
import { FormControl, FormHelperText, Input, InputLabel } from "@mui/material";

interface IProps {
  value: string;
  onChange: (s: string) => void;
  id: string;
  label: string;
  description?: string;
}

export default function GeorgFormInput({
  value,
  onChange,
  id,
  label,
  description,
}: IProps): React.ReactElement {
  const helperId = `${id}-helper`;
  return (
    <FormControl>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Input
        id={id}
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChange(e.target.value)
        }
        placeholder={label}
        aria-describedby={helperId}
      />
      {description && (
        <FormHelperText id={helperId}>{description}</FormHelperText>
      )}
    </FormControl>
  );
}
