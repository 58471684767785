import React, { useMemo } from "react";
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridInputRowSelectionModel,
  GridRowModel,
  GridRowParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import useStyles from "./styles";
import { rowSelectionBG } from "./styles";

interface IProps<T> {
  dataGridClassName?: string;
  rows: T;
  columns: GridColDef[];
  loadingColumns?: GridColDef[];
  columnVisibilityModel: GridColumnVisibilityModel;
  rowSelectionModel?: GridInputRowSelectionModel;
  //onEditCellPropsChange?: (editRowsModel: GridEditCellPropsParams) => void;
  onRowSelectionModelChange?: (
    rowSelectionModel: GridRowSelectionModel,
  ) => void;
  onRowClick?: (p: GridRowParams) => void;
  disableRowSelectionOnClick?: boolean;
  disableColumnSelector?: boolean;
  rowSize?: number;
  resultsPerPage?: number;
  autoHeightProp?: boolean;
  gridStyles?: string;
  gridClassName?: string;
  isLoading?: boolean;
}

export default function GeorgDocumentSearchDataGrid<T>({
  dataGridClassName,
  rows,
  columns,
  loadingColumns,
  columnVisibilityModel,
  rowSelectionModel,
  //onEditCellPropsChange,
  onRowSelectionModelChange,
  onRowClick,
  disableRowSelectionOnClick,
  disableColumnSelector,
  rowSize,
  resultsPerPage,
  autoHeightProp,
  isLoading = false,
}: IProps<T>): React.ReactElement {
  const { classes } = useStyles();

  const tableData = useMemo(
    () =>
      isLoading
        ? Array(4)
            .fill(null)
            .map(() => ({ id: Math.random().toString() }))
        : rows,
    [isLoading, rows],
  );

  const tableColumns = useMemo(
    () => (isLoading && loadingColumns ? loadingColumns : columns),
    [isLoading, loadingColumns, columns],
  );

  return (
    <div className={classes.georgDataGridWrapper}>
      <DataGrid
        className={dataGridClassName}
        rows={tableData as unknown as GridRowModel[]}
        /*rowsPerPageOptions={[7, 14, 21]}*/
        pagination
        sx={{
          "&.MuiDataGrid-row.Mui-selected:hover, .MuiDataGrid-row:hover ": {
            backgroundColor: isLoading ? "unset" : rowSelectionBG,
          },
        }}
        columns={tableColumns}
        columnVisibilityModel={columnVisibilityModel}
        rowSelectionModel={rowSelectionModel}
        //onEditCellPropsChange={onEditCellPropsChange}
        onRowSelectionModelChange={
          isLoading ? undefined : onRowSelectionModelChange
        }
        onRowClick={isLoading ? undefined : onRowClick}
        disableRowSelectionOnClick={disableRowSelectionOnClick || isLoading}
        disableColumnSelector={disableColumnSelector || isLoading}
        disableColumnMenu={true}
        hideFooter={isLoading}
        rowHeight={rowSize || 52}
        pageSizeOptions={[5]}
        paginationModel={{ page: 0, pageSize: resultsPerPage || 10 }}
        hideFooterSelectedRowCount
        hideFooterPagination
        scrollbarSize={0}
        autoHeight={autoHeightProp || false}
      />
    </div>
  );
}
