import { makeStyles } from "tss-react/mui";
import colors from "../../../shared/theme/colors";
const commonInputRoot = {
  height: 60,
  paddingTop: 0,
  paddingBottom: 0,
};
const useStyles = makeStyles()(() => ({
  inputContainer: {
    width: "100%",
  },
  textContainer: {
    height: 24,
  },
  boxContainer: {
    minWidth: "unset",
  },
  inputRoot: {
    ...commonInputRoot,
  },
  pendingInputRoot: {
    ...commonInputRoot,
    backgroundColor: colors.greyB56,
  },
  textInput: {
    paddingTop: 20,
    paddingBottom: 20,
  },
}));

export default useStyles;
