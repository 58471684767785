import gql from "graphql-tag";

export default gql`
  query jobs($filter: JobFilterParamsInputType) {
    jobs(filter: $filter) {
      vid
      abortMessage
      abortedAt
      createdBy
      createdById
      createdByName
      deletedAt
      description
      doneAt
      id
      jobId
      machineType {
        id
      }
      name
      priority
      scheduledAt
    }
  }
`;
