import { makeStyles } from "tss-react/mui";
import { colors, rootTheme } from "../../shared/theme";

const useStyles = makeStyles()({
  contentContainer: {
    display: "flex",
    flexDirection: "column",
  },
  arrow: {
    color: colors.blue_base,
  },
  sublabel: {
    marginTop: 5,
  },
  description: {
    marginTop: 10,
    display: "block",
    whiteSpace: "pre-line",
    textAlign: "justify",
  },
  footer: {
    marginTop: 0,
    display: "block",
    whiteSpace: "pre-line",
  },
  smallTooltip: {
    backgroundColor: colors.white_base,
    boxShadow: rootTheme.shadow,
    color: colors.blue_base,
    border: "0.6px solid black",
  },
  tooltip: {
    backgroundColor: colors.white_base + "!important",
    boxShadow: rootTheme.shadow,
    color: colors.blue_base,
    marginLeft: 10,
    padding: 20,
    width: 280,
    border: "0.6px solid black",
  },
  tooltip_wrapper: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  fullWidth: {
    width: "100%",
    display: "flex",
    height: "100%",
    alignItems: "center",
  },
  no_overflow: {
    overflow: "hidden",
  },
  icon: {
    marginRight: 8,
  },
});

export default useStyles;
