import { GridRowId } from "@mui/x-data-grid";

interface DataWithID {
  id: string;
}

export default function updateDataField(
  data: DataWithID[],
  id: GridRowId,
  field: string,
  value: string | Date | boolean,
): DataWithID[] {
  return data.map((data: DataWithID) => {
    if (data.id === id) {
      return {
        ...data,
        [field]: value,
      };
    }
    return data;
  });
}
