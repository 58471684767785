import { DateTime } from "luxon";
import { createTimezoneDateFromDate } from "../../../helper/time/formatting/dateToIsoUtils";

export enum EErrorState {
  HAS_END_DATE_ERROR = "HAS_END_DATE_ERROR",
  HAS_START_DATE_ERROR = "HAS_START_DATE_ERROR",
  END_TIME_BEFORE_START_TIME = "END_TIME_BEFORE_START_TIME",
  START_TIME_IN_FUTURE = "START_TIME_IN_FUTURE",
  IS_END_TIME_IN_FUTURE = "IS_END_TIME_IN_FUTURE",
  NO_ERROR = "NO_ERROR",
}

export function validateTimeRange(
  startDate: Date,
  endDate: Date,
  timezone: string,
  hasStartDateError = false,
  hasEndDateError = false,
  isFutureAllowed = true,
): EErrorState {
  let error;
  const momentEnd = createTimezoneDateFromDate(endDate, timezone);

  const momentStart = createTimezoneDateFromDate(startDate, timezone);

  const momentNow = DateTime.local();

  const isEndTimeBeforeStartTime = momentStart > momentEnd;
  const isStartTimeInFuture = momentNow < momentStart;
  const isEndTimeInFuture = momentNow < momentEnd;

  if (hasStartDateError) {
    error = EErrorState.HAS_START_DATE_ERROR;
  } else if (hasEndDateError) {
    error = EErrorState.HAS_END_DATE_ERROR;
  } else if (isEndTimeBeforeStartTime) {
    error = EErrorState.END_TIME_BEFORE_START_TIME;
  } else if (!isFutureAllowed && isStartTimeInFuture) {
    error = EErrorState.START_TIME_IN_FUTURE;
  } else if (!isFutureAllowed && isEndTimeInFuture) {
    error = EErrorState.IS_END_TIME_IN_FUTURE;
  } else {
    error = EErrorState.NO_ERROR;
  }
  return error;
}
