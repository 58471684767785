import { Layout } from "react-grid-layout";
import createLayout from "../../../../../shared/components/gridPage/helper/createLayout";

export const BAR_CHART_LAOUT_KEY = "kpi_productivity";
export const HEATMAP_LAYOUT_KEY = "kpi_availability";

const layout_LG: Layout[] = [
  { i: BAR_CHART_LAOUT_KEY, x: 0, y: 0, w: 4, h: 8 },
  { i: HEATMAP_LAYOUT_KEY, x: 4, y: 0, w: 8, h: 8 },
];

const layoutsXS: Layout[] = [
  { i: BAR_CHART_LAOUT_KEY, x: 0, y: 0, w: 12, h: 8 },
  { i: HEATMAP_LAYOUT_KEY, x: 0, y: 8, w: 12, h: 8 },
];
const layoutsXXS: Layout[] = [
  { i: BAR_CHART_LAOUT_KEY, x: 0, y: 0, w: 12, h: 8 },
  { i: HEATMAP_LAYOUT_KEY, x: 0, y: 8, w: 12, h: 8 },
];

export type TResizeConfig = {
  [key in TGridLayoutStacklightKEY]: number;
};
type TGridLayoutStacklightKEY = "kpi_productivity" | "kpi_availability";

// export default KPI_VIEW_LAYOUTS;
export default function getLayoutInfo(resizeConfig: TResizeConfig) {
  return createLayout<TResizeConfig>(
    resizeConfig,
    layout_LG,
    layout_LG,
    layoutsXS,
    layoutsXS,
    layoutsXS,
    layoutsXXS,
  );
}
