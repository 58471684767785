import { IMachine } from "../../../../api/machine/IMachine";

export function getEndIndex(
  nextPage: number,
  displayedMachinesAfterSortAndFilter: IMachine[],
): number {
  const paginationLength = 8;
  const isAtEndOfList =
    nextPage * paginationLength > displayedMachinesAfterSortAndFilter.length;

  return isAtEndOfList
    ? displayedMachinesAfterSortAndFilter.length
    : nextPage * paginationLength;
}
