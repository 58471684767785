import { TFunction } from "i18next";
import { IExportEnum } from "../components/charts/components/exportButton";
import formatDateToString from "../helper/time/formatting/formatDateToString";

const getFormattedDate = (
  lang: string,
  t: TFunction,
  timezone: string,
): string => {
  const date = new Date();
  const currentDate = formatDateToString(
    date,
    lang,
    t,
    "fulldatetime",
    "DAYS",
    timezone,
  );
  return currentDate.replace(/[:. ]/g, "_");
};

// this function is used to generate the file name for the export button
// fileName = "baseText_currentDate.fileExtension" (e.g. "export_We_23_09_01_12_00_00.csv")
export default function generateFileName(
  machineId: string,
  lang: string,
  t: TFunction,
  timezone: string,
  baseText: string,
  fileExtesion: IExportEnum,
): string {
  const formattedDate = getFormattedDate(lang, t, timezone);
  return `${baseText}_${machineId}_${formattedDate}.${fileExtesion}`;
}
