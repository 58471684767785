import React from "react";
import useStyles from "./styles";
import { Grid2 as Grid } from "@mui/material";
import CardGridItem from "../../../components/CardGridItem";
import { useSelector } from "react-redux";
import { selectDrawer } from "../../../../../redux/reducers/drawerReducer";
import { useTranslation } from "react-i18next";
import LinkCardWrapper from "../components/linkCardWrapper";
import TypographyBlock from "../components/typographyBlock";
import { useIsSmallView } from "./useIsSmallView";

export default function ServicePartsView(): React.ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-require-imports
  const Image = require("../../../../../assets/img/serviceParts/cad_share.png");
  const { classes } = useStyles();
  const { t } = useTranslation();
  const isSidebarOpen = useSelector(selectDrawer);
  const isSmallView = useIsSmallView(isSidebarOpen);

  const padding = isSmallView ? 16 : 40;
  const divMaxWidth = isSmallView ? 720 : 1054;
  const maxWidth = isSmallView ? 460 : 1134;
  const textWidth = isSmallView ? "90%" : "100%";

  const renderTypographyBlock = (
    primary: string,
    bold: string,
    secondary: string,
  ) => (
    <TypographyBlock
      classes={{
        textContainer: classes.textContainer,
        text: classes.text,
        boldText: classes.boldText,
      }}
      texts={{
        primary,
        bold,
        secondary,
      }}
      textWidth={textWidth}
    />
  );

  return (
    <div className={classes.container} style={{ maxWidth: divMaxWidth }}>
      <Grid
        container
        spacing={2}
        className={classes.subContainer}
        style={{
          padding: padding,
          maxWidth: maxWidth,
          flex: 1,
        }}
        columns={12}
      >
        <CardGridItem
          id="1"
          isSidebarOpen={isSidebarOpen}
          isSmallView={isSmallView}
          cardStyle={classes.cardContainer}
          elementsPerRow={2}
          children={
            <>
              {renderTypographyBlock(
                t("service.serviceParts.firstText1"),
                t("service.serviceParts.firstTextBold"),
                t("service.serviceParts.firstText2"),
              )}
              {renderTypographyBlock(
                t("service.serviceParts.secondText1"),
                t("service.serviceParts.secondTextBold"),
                t("service.serviceParts.secondText2"),
              )}
              {!isSmallView && (
                <LinkCardWrapper
                  classes={{
                    disabledLink: classes.disabledLink,
                    link: classes.link,
                    linkContainer: classes.linkContainer,
                  }}
                  t={t}
                />
              )}
            </>
          }
        />
        <CardGridItem
          children={
            <div className={isSmallView ? "" : classes.imageContainer}>
              <img
                className={
                  isSmallView ? classes.smallImage : classes.largeImage
                }
                src={Image}
                alt="Image"
              />
            </div>
          }
          id="2"
          isSidebarOpen={isSidebarOpen}
          isSmallView={isSmallView}
          elementsPerRow={2}
        />
        {isSmallView && (
          <LinkCardWrapper
            classes={{
              disabledLink: classes.disabledLink,
              link: classes.link,
              linkContainer: classes.linkContainer,
            }}
            t={t}
          />
        )}
      </Grid>
    </div>
  );
}
