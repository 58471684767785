// gets a color and a percent and returns a new color with the percent adjusted
function colorToRgba(color: string, percent: number): string {
  // Parse the hex color into RGB components
  const r = parseInt(color.slice(1, 3), 16);
  const g = parseInt(color.slice(3, 5), 16);
  const b = parseInt(color.slice(5, 7), 16);

  const adjustedColor = `rgba(${r}, ${g}, ${b}, ${percent})`;

  return adjustedColor;
}
export default colorToRgba;
