import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  valueContainer: {
    display: "flex",
    whiteSpace: "nowrap",
    alignItems: "baseline",
  },
  metricContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
  wrapperContainer: {
    minHeight: 256,
  },
});

export default useStyles;
