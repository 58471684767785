import React from "react";
import { Container, Grid2 as Grid, Typography } from "@mui/material";
import useStyles from "./styles";
import QrCode from "../../../../shared/components/qrCode";
import { ICard } from "../../../../shared/components";

export interface IQRCodeData {
  link: string;
  name: string;
}

interface IProps {
  qrLinks: IQRCodeData[];
}

export default function QRView({ qrLinks }: IProps): React.ReactElement {
  const { classes } = useStyles();
  const isSingleElement = qrLinks.length < 2;

  return (
    <Container className={classes.container}>
      <Grid className={classes.outerGrid} container spacing={3}>
        {qrLinks != null &&
          qrLinks.length > 0 &&
          qrLinks.map((item: IQRCodeData) => {
            return (
              <Grid
                size={{
                  xl: isSingleElement ? 12 : 6,
                  lg: isSingleElement ? 12 : 6,
                  md: 12,
                  sm: 12,
                  xs: 12,
                }}
                key={item.name + item.link}
              >
                <ICard
                  cardContent={
                    <>
                      <Typography variant="h6" color="primary">
                        {item.name}
                      </Typography>
                      <div className={classes.qrCode}>
                        <QrCode qrLink={item.link} />
                      </div>
                    </>
                  }
                />
              </Grid>
            );
          })}
      </Grid>
    </Container>
  );
}
