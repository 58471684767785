import moment from "moment-timezone";

// returns the timezone offset in the format "UTC+1" or "UTC-1"
// e.x. getTimezoneOffset("Europe/Berlin") => "UTC+2"
export default function getTimezoneOffset(timezone: string): string {
  const offset = moment.tz(timezone).utcOffset();

  if (offset === 0) {
    return "UTC";
  } else if (offset > 0) {
    return `UTC+${offset / 60}`;
  } else {
    return `UTC-${Math.abs(offset) / 60}`;
  }
}
