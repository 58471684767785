import { ISecondDisplayFormat } from "./secondsToDisplayFormat";
import { TFunction } from "i18next";

export default function displayFormatToString(
  t: TFunction,
  format: ISecondDisplayFormat,
): string {
  const { days, hours, minutes } = format;
  return days > 0
    ? `${days} ${t("common.time.helper.days")} ${hours} ${t(
        "common.time.helper.hours",
      )} ${minutes} ${t("common.time.helper.minutes")}`
    : `${hours} ${t("common.time.helper.hours")} ${minutes} ${t(
        "common.time.helper.minutes",
      )}`;
}
