import gql from "graphql-tag";

export default gql`
  mutation abortJob($abortMessage: String!, $jobId: String!, $vid: Int!) {
    abortJob(abortMessage: $abortMessage, jobId: $jobId, vid: $vid) {
      vid
      abortMessage
      abortedAt
      createdBy
      createdById
      deletedAt
      description
      doneAt
      id
      jobId
      machineType {
        id
      }
      priority
      scheduledAt
    }
  }
`;
