/*
 *
 *   Loader component which is used for displaying a loading status.
 *
 */

import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import useStyles from "./styles";

//------------------------------------------------------------------------------------------------------------
// Component.
export default function Loader(): React.ReactElement {
  const { classes } = useStyles();
  return (
    <div className={classes.aligner}>
      <div className={classes.aligneritem}>
        <ScaleLoader />
      </div>
    </div>
  );
}
