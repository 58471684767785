const injectStyle = (style) => {
  const styleElement = document.createElement("style");
  let styleSheet: CSSStyleSheet | null = null;

  document.head.appendChild(styleElement);

  styleSheet = styleElement.sheet;
  if (styleSheet != null) {
    styleSheet.insertRule(style, styleSheet.cssRules.length);
  }
};

export default injectStyle;
