import React, { useEffect, useState } from "react";
import { Box, Grid2 as Grid } from "@mui/material";
import { MachineCardStatusTypeWithColor } from "../../../../../../../services/getStatusLabel";
import injectStyle from "../../../../../../../helper/style/injectStyle";
import StatusFooterInformation from "../statusFooterInformation";
import { IIndicatorStateSince } from "../../api/useCurrentIndicatorStateList/useCurrentIndicatorStateList";
import useStyles from "../../styles";
import StatusFooterContentSkeleton from "./skeleton";

interface IProps {
  result?: MachineCardStatusTypeWithColor;
  indicatorStateSince: IIndicatorStateSince;
  isLoading?: boolean;
}

function MachineStatusFooterContent({
  result,
  indicatorStateSince,
  isLoading,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  const [stableResult, setStableResult] = useState<
    MachineCardStatusTypeWithColor | undefined
  >(result);

  useEffect(() => {
    if (!isLoading && result) {
      setStableResult(result);
    }
  }, [result, isLoading]);

  useEffect(() => {
    if (
      stableResult?.indicatorName === "indicator_state_idle_long" ||
      stableResult?.indicatorName === "indicator_edgedevice_offline"
    ) {
      const animationName =
        stableResult.indicatorName === "indicator_state_idle_long"
          ? "pulse"
          : "offlinePulse";
      const keyframesStyle = `
        @-webkit-keyframes ${animationName} {
          0%   { background-color: #FFFFFF; }
          50% { background-color: ${stableResult.color}; }
          100%   { background-color: #FFFFFF; }
        }`;

      injectStyle(keyframesStyle);
    }
  }, [stableResult?.indicatorName]);

  const renderContent = () => {
    const { color, indicatorName, Icon } = stableResult || {};
    const containerClassName = `${classes.statusContainer} ${
      indicatorName !== "indicator_state_undefined"
        ? classes.lightColor
        : classes.darkColor
    } ${indicatorName === "indicator_state_idle_long" ? classes.pulse : ""} ${
      indicatorName === "indicator_edgedevice_offline"
        ? classes.offlinePulse
        : ""
    }`;

    return (
      <Grid container>
        <Grid>
          <Box
            className={containerClassName}
            style={{ backgroundColor: color, borderBottomColor: color }}
          >
            {Icon ? <Icon /> : <div />}
          </Box>
        </Grid>
        <Grid className={classes.gridActionItems}>
          <Box
            className={classes.messageContainer}
            style={{
              borderBottomColor: color,
              borderRightColor: color,
            }}
          >
            <StatusFooterInformation
              indicatorName={
                indicatorStateSince?.indicatorState ||
                "indicator_state_undefined"
              }
              statusMessage={stableResult?.statusMessage ?? ""}
              indicatorStateSince={indicatorStateSince}
            />
          </Box>
        </Grid>
      </Grid>
    );
  };

  if (isLoading && !stableResult) {
    return <StatusFooterContentSkeleton />;
  }
  return stableResult ? renderContent() : <div />;
}

export default React.memo(MachineStatusFooterContent);
