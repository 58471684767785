import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import SaveCancelBtnPair from "../../../../../shared/components/saveCancelBtnPair";
import ShiftHeader from "./components/shiftHeader";
import useStyles from "./styles";
import ShiftTable from "./components/shiftTable";
import { useTranslation } from "react-i18next";
import IShiftConfigEntry from "../../../../../api/shifts/app/IShiftConfigEntry";
import useWorkShifts from "../../../../../api/shifts/useWorkShifts";
import { useSelector } from "react-redux";
import { selectMachine } from "../../../../../redux/reducers/machineSelectReducer";
import useShiftOptions from "../../../../../api/shifts/useShiftOptions";
import IShifts from "../../../../../api/shifts/app/IShifts";
import transformAppToApiFormat from "../../../../../api/shifts/app/transformAppToApiFormat";
import useUpsertWorkShift from "./api/useUpdateWorkShift/useUpdateWorkShift";
import { TFunction } from "i18next";
import { getUpdatedObjects } from "./helper/getUpdatedObjects";
import { getTimezone } from "../../../../../helper/time/timezone";
import { getDayName } from "../../../../../api/shifts/server/transformApiToAppFormat";
import { v4 as uuidv4 } from "uuid";

interface IShowErrorProps {
  hasLoadShiftError: boolean;
  hasErrorUpdateWorkShifts: boolean;
  t: TFunction;
}
function ShowError({
  hasLoadShiftError,
  t,
  hasErrorUpdateWorkShifts,
}: IShowErrorProps): React.ReactElement {
  return (
    <>
      {hasLoadShiftError && <div>{t("settings.holiday.loadingError")}</div>}
      {hasErrorUpdateWorkShifts && (
        <div>{t("settings.holiday.updateError")}</div>
      )}
    </>
  );
}

export default function ConfigureShifts(): React.ReactElement {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const timezone = getTimezone();
  const [selectedShift, setSelectedShift] = useState<string | undefined>(
    undefined,
  );

  const selectedMachine = useSelector(selectMachine);
  const { shiftOptions } = useShiftOptions(
    parseInt(selectedMachine, 10),
    timezone,
  );
  const machineIdNumber = parseInt(selectedMachine, 10);
  const { data, isShiftsLoading, hasError, refreshData } = useWorkShifts(
    machineIdNumber,
    timezone,
  );
  const shiftData = useMemo(() => {
    if (
      !isShiftsLoading &&
      !hasError &&
      selectedShift &&
      data &&
      data[selectedShift]
    ) {
      const existingShiftData = data[selectedShift];
      const existingDays = existingShiftData.map((shift) =>
        Number(shift.dayOfWeek),
      );
      const missingDays = [...Array(7)]
        .map((_, index) => index + 1)
        .filter((day) => !existingDays.includes(day));

      const defaultTime = "00:00:00";
      const missingShifts = missingDays.map((day) => ({
        /* All new shifts have empty ids in frontEnd
        and it will be deleted before sending to backend.*/
        id: uuidv4(),
        isNew: true,
        displayName: getDayName(day, t),
        startTime: defaultTime,
        endTime: defaultTime,
        dayOfWeek: day,
        isActive: false,
        machineTypeId: machineIdNumber,
      }));

      return [...existingShiftData, ...missingShifts].sort(
        (a: IShiftConfigEntry, b: IShiftConfigEntry) =>
          a.dayOfWeek - b.dayOfWeek,
      );
    }
    return [];
  }, [selectedShift, data, isShiftsLoading, hasError]);

  const [localData, setLocalData] = useState<IShiftConfigEntry[]>(shiftData);
  const updatedShifts: IShifts = useMemo(() => {
    if (selectedShift && shiftData && localData) {
      const updatedData: IShifts = {
        [selectedShift]: getUpdatedObjects(shiftData, localData),
      };
      return updatedData;
    }
    return {};
  }, [shiftData, localData, selectedShift]);
  const {
    mutate,
    isLoading: isLoadingUpdateWorkShifts,
    hasError: hasErrorUpdateWorkShifts,
  } = useUpsertWorkShift();
  useEffect(() => {
    if (shiftData) {
      setLocalData(shiftData);
    }
  }, [shiftData, selectedShift, data]);
  useEffect(() => {
    if (!isLoadingUpdateWorkShifts && !hasErrorUpdateWorkShifts) {
      refreshData();
    }
  }, [isLoadingUpdateWorkShifts, hasErrorUpdateWorkShifts]);
  useLayoutEffect(() => {
    if (shiftOptions && shiftOptions.length > 0) {
      setSelectedShift(shiftOptions[0].value);
    }
  }, [shiftOptions, setSelectedShift]);
  const isError = hasError || hasErrorUpdateWorkShifts;
  const isSomethingLoading = isShiftsLoading || isLoadingUpdateWorkShifts;
  const isDisabled = useMemo(
    () =>
      !selectedShift ||
      updatedShifts[selectedShift]?.length === 0 ||
      isSomethingLoading,
    [updatedShifts, selectedShift, isLoadingUpdateWorkShifts, isShiftsLoading],
  );
  return (
    <div className={classes.shiftConfigBox}>
      <ShiftHeader
        onShiftChange={setSelectedShift}
        machineId={machineIdNumber}
        isLoading={isShiftsLoading || isLoadingUpdateWorkShifts}
        selectedShift={selectedShift}
        timezone={timezone}
      />
      {isError ? (
        <ShowError
          t={t}
          hasLoadShiftError={hasError}
          hasErrorUpdateWorkShifts={hasErrorUpdateWorkShifts}
        />
      ) : (
        <>
          <ShiftTable
            data={localData}
            onTableUpdate={(newData: IShiftConfigEntry[]) => {
              setLocalData(newData);
            }}
            timezone={timezone}
            isLoading={isSomethingLoading}
          />
          <SaveCancelBtnPair
            onSaveClicked={() => {
              if (selectedShift && updatedShifts[selectedShift].length > 0) {
                mutate(transformAppToApiFormat(updatedShifts));
              }
            }}
            disabled={isDisabled}
            onCancelClicked={() => {
              setLocalData(shiftData);
            }}
            isCancelAlwaysEnabled={false}
          />
        </>
      )}
    </div>
  );
}
