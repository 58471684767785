import {
  IJobCreateOrUpdate,
  IJobFromServer,
} from "../../../../../../api/jobs/interfaces/IJobSettings";
import { useMemo } from "react";
import { EApiState } from "../../../../../../api";
import addJob from "../../../../../../api/jobs/mutations/addJob";
import updateJob from "../../../../../../api/jobs/mutations/updateJob";
import {
  IAddJobMutationVariables,
  IUpdateJobMutationVariables,
} from "../../../../../../api/jobs/interfaces/IJobMutationVariables";
import { useMutation } from "@apollo/client";
import getJobList from "../../../../../../api/jobs/queries/getJobList";
import { IJobQueryVariables } from "../queries";

export interface IUpsertJobCallback {
  jobInput: IJobCreateOrUpdate;
  fetchVariables: IJobQueryVariables;
}

export function useUpsertJobMutation(isCreateMutation: boolean): {
  upsertJobMutation: (upsertJob: IUpsertJobCallback) => void;
  apiState: EApiState;
  upsertError?: string;
} {
  const mutation = isCreateMutation ? addJob : updateJob;
  const [mutateFunction, { data, loading, error }] =
    useMutation<IJobFromServer>(mutation);

  const getApiState = useMemo(() => {
    const hasError = error != undefined;
    if (!hasError && !loading && data != null) {
      return EApiState.OK;
    } else if (hasError) {
      return EApiState.ERROR;
    } else if (loading) {
      return EApiState.LOADING;
    } else {
      return EApiState.EMPTY;
    }
  }, [loading, error, data]);

  return {
    upsertJobMutation: (upsertJob: IUpsertJobCallback): void => {
      const addJobVariables: IAddJobMutationVariables = {
        jobData: {
          createdBy: upsertJob.jobInput.createdBy,
          jobId: upsertJob.jobInput.jobId,
          description: upsertJob.jobInput.description,
          name: upsertJob.jobInput.name,
          priority: upsertJob.jobInput.priority,
          machineTypeId: upsertJob.jobInput.machineId,
          scheduledAt: upsertJob.jobInput.scheduledAt,
        },
      };
      const updateJobVariables: IUpdateJobMutationVariables = {
        jobId: upsertJob.jobInput.jobId,
        jobData: {
          description: upsertJob.jobInput.description,
          name: upsertJob.jobInput.name,
          priority: upsertJob.jobInput.priority,
          machineTypeId: upsertJob.jobInput.machineId,
          scheduledAt: upsertJob.jobInput.scheduledAt,
        },
        vid: upsertJob.jobInput.vid,
      };
      const variables = isCreateMutation ? addJobVariables : updateJobVariables;

      mutateFunction({
        variables,
        refetchQueries: [
          {
            query: getJobList,
            variables: {
              filter: {
                aborted: upsertJob.fetchVariables.aborted,
                deleted: upsertJob.fetchVariables.deleted,
                done: upsertJob.fetchVariables.done,
                machineTypeId: upsertJob.fetchVariables.machineTypeId,
              },
            },
          },
        ],
      }).catch(
        // eslint-disable-next-line no-console
        console.error,
      );
    },
    apiState: getApiState,
    upsertError: error?.message,
  };
}
