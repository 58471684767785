import useLocalStorage from "../persistence/useLocalStorage";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { timezoneUTC } from "./timezone";

const TZ_KEY = "ZONE";
const IGNORE_TZ_KEY = "IgnoreTimezone";
const SYSTEM_TZ = Intl.DateTimeFormat().resolvedOptions().timeZone;

interface ITimezoneApi {
  timezone: string;
  setTimezone: (zoneKey: string) => void;
  ignoreTimezone: boolean;
  setIgnoreTimezone: (newValue: boolean) => void;
  isLoading: boolean;
}

export default function useTimezone(): ITimezoneApi {
  const { load, set } = useLocalStorage();
  const zone = load(TZ_KEY);
  const ignoreTimezoneFromLS = load(IGNORE_TZ_KEY) === "true";

  // initialize timezone with system timezone or UTC
  const [timezone, setTimezoneState] = useState(
    zone || SYSTEM_TZ || timezoneUTC,
  );
  const [ignoreTimezone, setIgnoreTimezoneState] = useState<boolean>(
    ignoreTimezoneFromLS || false,
  );
  const [isLoading, setIsLoading] = useState(true);
  const setTimezone = useCallback((zone: string) => {
    set(TZ_KEY, zone);
    setTimezoneState(zone);
  }, []);
  const setIgnoreTimezone = useCallback((newValue: boolean) => {
    set(IGNORE_TZ_KEY, `${newValue}`);
    setIgnoreTimezoneState(newValue);
  }, []);

  useLayoutEffect(() => {
    setIsLoading(true);

    if (zone) {
      if (timezone !== zone) {
        setTimezone(zone);
      }
    } else {
      if (zone == null) {
        set(TZ_KEY, SYSTEM_TZ || timezoneUTC);
      }
    }

    setIsLoading(false);
  }, [timezone]);

  useLayoutEffect(() => {
    if (zone) {
      if (timezone !== zone) {
        setTimezone(zone);
      }
    } else {
      if (zone == null) {
        set(TZ_KEY, SYSTEM_TZ || timezoneUTC);
      }
    }
  }, []);

  return useMemo(() => {
    return {
      timezone,
      setTimezone,
      ignoreTimezone,
      setIgnoreTimezone,
      isLoading,
    };
  }, [timezone, isLoading, ignoreTimezone]);
}
