/*
 *
 *   MachineCard component which renders the machineCard header, body and footer.
 *   Depending on shopfloordata, the machineCard will be extended by kpiFooter.
 *
 */
// import React from "react";
import React from "react";
import { Box, CardMedia, Typography } from "@mui/material";
// Custom Imports.
import useStyles from "./styles";
import { MachineKPIFooter, MachineStatusFooter } from "../index";
import { TIndicatorType } from "../../../../../helper";
import { IIndicatorStateSince } from "../machineStatusFooter/api/useCurrentIndicatorStateList/useCurrentIndicatorStateList";

//------------------------------------------------------------------------------------------------------------
// Interfaces & Types.
interface IProps {
  id: string;
  // componentName: string;
  deviceLabelId: string;
  deviceLabel: string;
  deviceLabelExtension?: string;
  deviceSublabel: string;
  image: string;
  deviceClass: string;
  statusCode: number;
  kpi: boolean;
  allIndicators: TIndicatorType[];
  onStateChange?: (state: IIndicatorStateSince) => void;
  timezone: string;
}

//------------------------------------------------------------------------------------------------------------
// Component.
function MachineCard({
  id,
  deviceLabelId,
  deviceLabel,
  deviceLabelExtension,
  deviceSublabel,
  image,
  deviceClass,
  kpi,
  allIndicators,
  onStateChange,
  timezone,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  return (
    <>
      <Box className={classes.headerContainer}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Typography className={classes.machineSerialNumber} color="primary">
            {deviceLabelId}
          </Typography>
        </Box>
        <Box>
          <Box style={{ display: "flex", alignItems: "end" }}>
            <Typography
              className={classes.deviceLabel}
              color="primary"
              variant="h1"
            >
              {deviceLabel}
            </Typography>
            <Typography
              className={classes.deviceLabelExtension}
              color="primary"
              variant="h3"
            >
              {deviceLabelExtension}
            </Typography>
          </Box>
          <Typography
            className={classes.deviceSublabel}
            color="primary"
            variant="h3"
          >
            {deviceSublabel}
          </Typography>
          <Typography
            className={classes.machineDeviceClass}
            color="primaryText"
            variant="body1"
          >
            {deviceClass}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.imageContainer}>
        <CardMedia
          className={classes.machineImage}
          component="img"
          height={210}
          image={image}
          alt={id}
        />
      </Box>
      <div className={classes.machineCardFooterWrapper}>
        <MachineStatusFooter
          machineId={id}
          allIndicators={allIndicators}
          onStateChange={onStateChange}
          timezone={timezone}
        />
        {kpi && <MachineKPIFooter machineId={id} timezone={timezone} />}
      </div>
    </>
  );
}

export default React.memo(MachineCard);
