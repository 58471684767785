import React, { useMemo } from "react";
import IConfigElement from "../../../../../../../../config/states/IIndicatorConfigElement";
import StateTooltip from "../../../../../../stateTooltip";
import { useTranslation } from "react-i18next";
import { ISecondDisplayFormat } from "../../../../../../../../helper/time/formatting/secondsToDisplayFormat";
import getIndicatorTranslationByStateName from "../../../../../../../../helper/indicator/getIndicatorTranslationByStateName";
import { TFunction } from "i18next";

interface IPayLoadData {
  name: string;
  displayDurationParts: ISecondDisplayFormat;
  displayPeriodParts: string;
  pointDate: string;
  indicatorValue: number;
  ts: number;
}
interface IPayLoadDataArray {
  data: IPayLoadData[];
}
interface ITooltipPayload {
  dataKey: string;
  payload: IPayLoadDataArray;
  name: string;
}

export interface ITooltipProps {
  payload?: ITooltipPayload[];
}

interface IProps {
  tooltipData: ITooltipProps;
  config: IConfigElement[];
  hiddenTooltipDataKeys: string[];
}

interface RowConfig {
  label: string;
  color: string;
  ts: number;
}

interface RowConfigWithPointDate {
  rowConfig: RowConfig;
  pointDate: string;
}

// This function returns the Config-Data and the pointDate for each filtered tooltipPayload
function getRowConfig(
  ttPayload: ITooltipPayload,
  config: IConfigElement[],
  t: TFunction,
): RowConfigWithPointDate {
  const defaultRowConfig: RowConfig = {
    label: "",
    color: "#777",
    ts: 0,
  };

  const partData = ttPayload.payload.data.find(
    (data) => data.indicatorValue.toString() === ttPayload.name,
  );

  if (!partData) return { rowConfig: defaultRowConfig, pointDate: "" };

  const confElementFound: IConfigElement | undefined = config.find(
    (conf: IConfigElement) => conf.id === ttPayload.name,
  );

  const rowConfig: RowConfig = {
    ...defaultRowConfig,
    ts: partData.ts,
  };

  let name = "unknown";
  if (confElementFound) {
    const machineStateName = confElementFound.displayName;
    rowConfig.color = confElementFound.color;
    const translatedName = getIndicatorTranslationByStateName(
      machineStateName,
      t,
    ).name;
    name = translatedName !== "" ? translatedName : machineStateName;
  }

  const displayFormat =
    partData.displayPeriodParts !== ""
      ? partData.displayPeriodParts
      : t(
          "dashboard.stacklight.machineStates.machineStateHeatmap.tooltip.missingTimeInfo",
        );

  rowConfig.label = `${name}: ${displayFormat}`;

  return { rowConfig, pointDate: partData.pointDate };
}

function isDataKeyActive(
  hiddenTooltipDataKeys: string[],
  part: IPayLoadDataArray,
  dataKey: string,
): boolean {
  if (hiddenTooltipDataKeys.length > 0) {
    const dataKeyOnesCount = Object.keys(part).filter(
      (key) => part[key as string] === 1 && key !== "background",
    );
    const isMoreDataKeyActive = dataKeyOnesCount.length > 1;
    // if more than one dataKey is active, remove the dataKey in hiddenTooltipDataKeys array.
    return isMoreDataKeyActive
      ? !hiddenTooltipDataKeys.includes(dataKey)
      : true;
  }
  return true;
}

// Refactored code for heatmap tooltip that displays the Indicator name and duration for each active indicator
function RowTooltip({
  tooltipData,
  config,
  hiddenTooltipDataKeys,
}: IProps): React.ReactElement {
  if (!tooltipData.payload) return <></>;

  const { t } = useTranslation();
  const xAxisLabel = "";

  const { conf, pointDate } = useMemo(() => {
    const filteredPayload = (tooltipData.payload ?? []).filter(
      (ttPayload: ITooltipPayload) => {
        const part = ttPayload.payload;
        const isActive = part && part[ttPayload.dataKey as string] === 1;

        // Bugfix: Fehler 1142
        // Check if there is more than one datakey active, if so, remove the datakey in hiddenTooltipDataKeys array.
        const isNotHidden = isDataKeyActive(
          hiddenTooltipDataKeys,
          part,
          ttPayload.dataKey,
        );

        return (
          ttPayload.dataKey !== "background" &&
          ttPayload.payload != null &&
          isActive &&
          isNotHidden
        );
      },
    );

    const rows: RowConfig[] = [];
    let latestPointDate = "";
    let maxTs = 0;

    for (const ttPayload of filteredPayload) {
      const { rowConfig, pointDate: currentPointDate } = getRowConfig(
        ttPayload,
        config,
        t,
      );
      rows.push(rowConfig);
      if (currentPointDate !== "" && rowConfig.ts > maxTs) {
        maxTs = rowConfig.ts;
        latestPointDate = currentPointDate;
      }
    }

    // Sort the rows array based on ts values
    const sortedRows = rows.sort((a, b) => a.ts - b.ts);

    const confWithoutTs = sortedRows.map((rowConfigElement) => ({
      label: rowConfigElement.label,
      color: rowConfigElement.color,
    }));

    return { conf: confWithoutTs, pointDate: latestPointDate };
  }, [tooltipData.payload, config, hiddenTooltipDataKeys, t]);
  return <StateTooltip label={xAxisLabel} subLabel={pointDate} config={conf} />;
}
export default React.memo(RowTooltip);
