import gql from "graphql-tag";

export default gql`
  mutation deleteJob($jobId: String!, $vid: Int!) {
    deleteJob(jobId: $jobId, vid: $vid) {
      vid
      abortMessage
      abortedAt
      createdBy
      createdById
      deletedAt
      description
      doneAt
      id
      jobId
      machineType {
        id
      }
      priority
      scheduledAt
    }
  }
`;
