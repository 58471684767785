import { useEffect, useLayoutEffect, useState } from "react";
import { ApolloError, useLazyQuery } from "@apollo/client";
import useInterval from "../../../../../../helper/time/interval/useInterval";
import getOeeForMachineLast24h from "../../../../../../api/machine/getOeeForMachineLast24Query";
import getOeeForMachineLast24hOLD from "../../../../../../api/machine/getOeeForMachineLast24QueryOLD";
import IGetOeeMachineLast24hList from "../../../../../../api/machine/IGetOeeMachineLast24hLists";
import { useDispatch, useSelector } from "react-redux";
import {
  selectOeeMachineList,
  setOeeMachineList,
  SetDataPayload,
  getShopfloorData,
} from "../../../../../../redux/reducers/oeeMachineListReducer";
import { IMachineShopFloor } from "../../../../../../api/machine/IMachineShopFloor";
import { EApiState } from "../../../../../../api/state/EApiState";
import moment from "moment-timezone";
import { IOEEShopfloor24h } from "../../../../../../api/machine/IOEEShopfloor";

function getElementData(
  shopFloorList: IMachineShopFloor[],
  machineId: string,
): IMachineShopFloor | undefined {
  return shopFloorList.find((element) => element.id === machineId);
}
// Define the maximum age of data in milliseconds
const MAX_DATA_AGE_MS = 10 * 60 * 1000; // 10 minute
/*
  This Hook fetches the state list for the last 24h on a
 */
export default function useOeeForMachineLast24hIntervalQuery(
  machineTypeId: string,
  timezone: string,
): {
  apiState: EApiState;
  stateListResponse: IOEEShopfloor24h | undefined;
} {
  const dispatch = useDispatch();
  const shopFloorList = useSelector(selectOeeMachineList);

  const [doQuery, { data: dataFromServer, error, loading: isLoading }] =
    useLazyQuery<IGetOeeMachineLast24hList>(getOeeForMachineLast24h, {
      variables: {
        machineTypeId: parseInt(machineTypeId, 10),
      },
      fetchPolicy: "network-only",
      errorPolicy: "all",
    });

  // TODO: Remove this query and any OLD type/Code when the API is synchronized
  const [
    doAlternativeQuery,
    { data: altData, loading: altLoading, error: altError },
  ] = useLazyQuery<IGetOeeMachineLast24hList>(getOeeForMachineLast24hOLD, {
    variables: {
      machineTypeId: parseInt(machineTypeId, 10),
    },
    fetchPolicy: "network-only",
  });

  const [stateListResponse, setStateListResponse] = useState<
    IOEEShopfloor24h | undefined
  >(undefined);
  const [apiState, setApiState] = useState<EApiState>(EApiState.EMPTY);

  useLayoutEffect((): void => {
    const oeeElement = getElementData(shopFloorList, machineTypeId);
    const now = moment.tz(timezone).valueOf();

    if (oeeElement) {
      setStateListResponse(oeeElement.ooeShopfloor);
      setApiState(EApiState.OK);
      if (now - oeeElement.lastUpdated >= MAX_DATA_AGE_MS) {
        doQuery();
      }
    } else {
      doQuery();
    }
  }, [shopFloorList, machineTypeId, timezone]);

  // then start polling
  useInterval(() => {
    doQuery();
    // poll 10 minutes
  }, MAX_DATA_AGE_MS);

  const handleQueryResult = (data: IGetOeeMachineLast24hList | undefined) => {
    if (data && data.getOeeForMachineLast24h) {
      const payloadData: SetDataPayload = {
        value: data,
        machineId: machineTypeId,
        updateTime: moment.tz(timezone).valueOf(),
      };
      dispatch(setOeeMachineList(payloadData));
      const shopFloorList = getShopfloorData(data.getOeeForMachineLast24h);
      setStateListResponse(shopFloorList);
      setApiState(EApiState.OK);
    }
  };

  const handleError = (
    error: ApolloError | undefined,
    isLoading: boolean,
    isAlternative: boolean = false,
  ) => {
    if (!error) {
      if (isLoading) {
        setApiState(EApiState.LOADING);
        return;
      }
      setApiState(EApiState.OK);
      return;
    }

    if (error.message.includes("Cannot query field") && !isAlternative) {
      setApiState(EApiState.LOADING);
      doAlternativeQuery();
    } else {
      setApiState(EApiState.ERROR);
    }
  };

  useEffect(() => {
    handleError(error, isLoading);
    if (!isLoading && !error) {
      handleQueryResult(dataFromServer);
    }
  }, [dataFromServer, isLoading, error]);

  useEffect(() => {
    handleError(altError, altLoading, true);
    handleQueryResult(altData);
  }, [altData, altLoading, altError]);

  return {
    apiState,
    stateListResponse,
  };
}
