import { Skeleton } from "@mui/material";
import React from "react";
import useStyles from "../newsPreviewCard/styles";

export default function NewsPreviewCardSkeleton(): React.ReactElement {
  const { classes } = useStyles();

  return (
    <div className={classes.previewCardWrapperSkeleton}>
      <Skeleton variant="rectangular" height={200} />
      <div className={classes.outerGrid}>
        <Skeleton variant="text" height={60} className={classes.header} />
        <Skeleton variant="text" className={classes.contentSkeleton} />
        <Skeleton variant="text" className={classes.content} />
        <Skeleton variant="text" className={classes.content} />
        <Skeleton variant="text" className={classes.content} />
        <Skeleton variant="text" className={classes.content} />
        <Skeleton variant="text" width="80%" className={classes.content} />
        <Skeleton variant="text" width="60%" className={classes.cardFooter} />
      </div>
    </div>
  );
}
