import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDoneMutation, useQueryMachinesWithServiceOrders } from "./hooks";
import { selectMachine } from "../../../../redux/reducers/machineSelectReducer";
import { useSelector } from "react-redux";
import {
  IServiceOrderDone,
  IServiceOrderSettingsTableFormat,
} from "./api/interfaces";
import { ClipLoader } from "react-spinners";
import useStyles from "./styles";
import ServiceOrderTable from "./components/serviceOrderTable";
import { selectMachineList } from "../../../../redux/reducers/machineListReducer";
import { useTranslation } from "react-i18next";
import GeorgTextBase, {
  EStyleType,
} from "../../../../shared/components/text/base";
import { EApiState } from "../../../../api";
import {
  calculateDurationUntilNextService,
  calculateUrgencyState,
  getPercentageOfUrgency,
} from "./urgency";
import Toggle from "../../../../shared/components/Toggle";
import { GridColumnVisibilityModel, GridSortModel } from "@mui/x-data-grid";
import ExpandButton from "../../../../shared/components/expandButton";
import DoneDialog from "./components/doneDialog";
import { IMachine } from "../../../../api/machine/IMachine";

function findById(
  orders: IServiceOrderSettingsTableFormat[],
  id: string,
): IServiceOrderSettingsTableFormat | null {
  return orders.find((o) => o.id === id) || null;
}

function getWidthClassByTableSize(
  isExpanded: boolean,
  isAllMachinesSelected: boolean,
  classes,
) {
  if (!isExpanded && !isAllMachinesSelected) {
    return classes.boxCompact;
  } else if (!isExpanded && isAllMachinesSelected) {
    return classes.boxCompactExtended;
  } else if (isExpanded && !isAllMachinesSelected) {
    return classes.boxExpanded;
  } else {
    return classes.boxExpandedExtended;
  }
}

enum EDisplayState {
  TABLE = "TABLE",
  DONE_DIALOG = "DONE_DIALOG",
}

export default function ServiceOrdersView(): React.ReactElement {
  const machineId = useSelector(selectMachine);
  const [displayState, setDisplayState] = useState<EDisplayState>(
    EDisplayState.TABLE,
  );
  const [targetServiceOrder, setTargetServiceOrder] =
    useState<IServiceOrderDone>();
  const machines = useSelector(selectMachineList);
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [isAllMachinesSelected, setIsAllMachinesSelected] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const { state: doneMutationState, doneMutation } = useDoneMutation();
  const machineIds = useMemo(() => {
    if (isAllMachinesSelected) {
      return machines.map((m) => m.id);
    } else {
      return [machineId];
    }
  }, [machineId, isAllMachinesSelected]);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "urgencyPercentage", sort: "desc" },
  ]);

  const getMachineId = useCallback(
    (machineName: string, machines: IMachine[]) => {
      const machine = machines.find((m) => m.name === machineName);
      if (machine) {
        return machine.id;
      }
      return "";
    },
    [],
  );

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      machineName: isAllMachinesSelected,
      description: isExpanded,
      lastService: isExpanded,
      interval: isExpanded,
      currentValue: isExpanded,
      responsibleName: isExpanded,
    });
  const { apiState, machinesWithServiceOrders } =
    useQueryMachinesWithServiceOrders(machineIds);

  const serviceOrdersTableFormat: IServiceOrderSettingsTableFormat[] =
    useMemo(() => {
      if (apiState === EApiState.OK) {
        return machinesWithServiceOrders.flatMap((machineWithOrders) => {
          const {
            machine: { serviceOrder, name: machineName },
          } = machineWithOrders;
          return serviceOrder.map((so) => {
            const { lastService, interval, variable, preWarning } = so;
            const urgencyValue = calculateDurationUntilNextService(
              variable.value,
              lastService,
              interval,
            );
            return {
              ...so,
              machineName,
              urgency: `${urgencyValue} ${variable.unit}`,
              urgencyState: calculateUrgencyState(
                variable.value,
                lastService,
                interval,
                preWarning,
              ),
              urgencyPercentage: getPercentageOfUrgency(
                variable.value,
                lastService,
                interval,
              ),
            };
          });
        });
      } else {
        return [];
      }
    }, [machinesWithServiceOrders, apiState]);
  const handleDoneSubmit = useCallback(
    (updatedFields: Partial<IServiceOrderDone>) => {
      setTargetServiceOrder((prevOrder) => {
        if (!prevOrder) {
          console.error("No target service order to update");
          return prevOrder;
        }

        // Merge the updated fields into the existing targetServiceOrder
        const updatedServiceOrder = {
          ...prevOrder,
          ...updatedFields, // Apply new data fields
        };

        // Call the mutation function after updating the service order
        doneMutation(updatedServiceOrder);

        return updatedServiceOrder;
      });
    },
    [setTargetServiceOrder, doneMutation],
  );
  const classByTableSize = getWidthClassByTableSize(
    isExpanded,
    isAllMachinesSelected,
    classes,
  );

  const onDoneCancelCallback = useCallback(() => {
    setTargetServiceOrder(undefined);
    setDisplayState(EDisplayState.TABLE);
  }, []);

  const handleSortChange = useCallback(
    (newSortModel: GridSortModel) => {
      setSortModel(newSortModel);
    },
    [sortModel],
  );

  const handleColumnVisibilityChange = useCallback(
    (newColumnVisibilityModel: GridColumnVisibilityModel) => {
      setColumnVisibilityModel(newColumnVisibilityModel);
    },
    [columnVisibilityModel],
  );

  useEffect(() => {
    setColumnVisibilityModel({
      ...columnVisibilityModel,
      description: isExpanded,
      lastService: isExpanded,
      interval: isExpanded,
      currentValue: isExpanded,
      responsibleName: isExpanded,
    });
  }, [isExpanded]);

  useEffect(() => {
    setColumnVisibilityModel({
      ...columnVisibilityModel,
      machineName: isAllMachinesSelected,
    });
  }, [isAllMachinesSelected]);

  return (
    <div className={`${classes.box} ${classByTableSize} `}>
      <Toggle
        isChecked={isAllMachinesSelected}
        onChange={() => {
          setIsAllMachinesSelected(!isAllMachinesSelected);
        }}
      />
      <GeorgTextBase
        type={EStyleType.DARK}
        text={t("taskManager.serviceOrders.allMachinesButton")}
      />
      <div>
        {apiState === EApiState.ERROR && (
          <GeorgTextBase
            type={EStyleType.DARK}
            text={t(
              "taskManager.serviceOrders.errors.queryMachinesWithServiceOrders",
            )}
          />
        )}
        {apiState === EApiState.LOADING && (
          <div className={classes.spinnerBox}>
            <ClipLoader />
          </div>
        )}
        {apiState === EApiState.OK && (
          <div className={classes.tableWithButtonWrapper}>
            <div className={classes.expandButtonWrapper}>
              <ExpandButton
                isExpanded={isExpanded}
                onExpandClick={() => {
                  setIsExpanded(!isExpanded);
                }}
                disabled={false}
              />
            </div>

            <ServiceOrderTable
              isExpanded={isExpanded}
              serviceOrders={serviceOrdersTableFormat}
              onClickDone={(serviceOrderId, version) => {
                const found = findById(
                  serviceOrdersTableFormat,
                  serviceOrderId,
                );
                if (!found || !found.version) {
                  // eslint-disable-next-line no-console
                  console.error(
                    `Illegal State: Tried to change service order done state without id or without stete`,
                  );
                  return;
                }
                // setOrderToFinishId(serviceOrderId);
                // setVersionForFinish(version);
                setTargetServiceOrder({
                  id: serviceOrderId,
                  version,
                  description: found.description,
                  lastService: found.lastService,
                  interval: found.interval,
                  name: found.name,
                  responsibleId: found.responsible.id,
                  urgencyState: found.urgencyState,
                  serviceTimestamp: "",
                  machineId: getMachineId(found.machineName, machines),
                });
                setDisplayState(EDisplayState.DONE_DIALOG);
              }}
              isActionDisabled={doneMutationState === EApiState.LOADING}
              onSortChange={handleSortChange}
              sortModel={sortModel}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={handleColumnVisibilityChange}
            />
            <DoneDialog
              isModalVisible={displayState === EDisplayState.DONE_DIALOG}
              onClose={onDoneCancelCallback}
              title={t("taskManager.serviceOrders.doneDialog.title")}
              question={
                targetServiceOrder?.urgencyState !== "OK"
                  ? t("taskManager.serviceOrders.doneDialog.question")
                  : t("taskManager.serviceOrders.doneDialog.earlyServiceOrder")
              }
              serviceOrderToUpdate={targetServiceOrder}
              onCancel={onDoneCancelCallback}
              onSubmit={handleDoneSubmit}
            />
          </div>
        )}
      </div>
    </div>
  );
}
