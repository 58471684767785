import React, { useContext, useMemo } from "react";
import TabsWithMachineTimePeriodSelection from "../../shared/components/tabsWithMachineTimePeriodSelection";
import { useTranslation } from "react-i18next";
import ServiceOrdersView from "./views/serviceOrders";
import JobManagementView from "./views/jobManagement/index";
import ScreenResolutionWarning from "../../shared/components/screenResolutionWarning";
import GeorgStorageContext from "../../context/GeorgStorageContext";

export default function TaskManagerWithMachineSelection(): React.ReactElement {
  const { t } = useTranslation();
  const { isPreviewEnabled } = useContext(GeorgStorageContext);
  const tabConfig = useMemo(
    () =>
      isPreviewEnabled
        ? [
            {
              label: t("taskManager.serviceOrders.title"),
              path: "/serviceorders",
            },
            {
              label: t("taskManager.jobManagement.title"),
              path: "/jobmanagement",
            },
          ]
        : [
            {
              label: t("taskManager.jobManagement.title"),
              path: "/jobmanagement",
            },
          ],
    [isPreviewEnabled],
  );
  if (isPreviewEnabled) {
    return (
      <ScreenResolutionWarning>
        <TabsWithMachineTimePeriodSelection
          basePath={"/taskmanager/machine"}
          tabConfig={tabConfig}
        >
          <ServiceOrdersView />
          <JobManagementView />
        </TabsWithMachineTimePeriodSelection>
      </ScreenResolutionWarning>
    );
  } else {
    return (
      <ScreenResolutionWarning>
        <TabsWithMachineTimePeriodSelection
          basePath={"/taskmanager/machine"}
          tabConfig={tabConfig}
        >
          <JobManagementView />
        </TabsWithMachineTimePeriodSelection>
      </ScreenResolutionWarning>
    );
  }
}
