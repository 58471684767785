import React, { useContext, useMemo } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { handleDrawer } from "../../../../../../redux/reducers/drawerReducer";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import GeorgStorageContext from "../../../../../../context/GeorgStorageContext";
import FooterButton from "./footerButton";

interface IProps {
  isMobile: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isOpen: boolean;
}

export default function FooterButtons({
  isMobile,
  onClick,
  isOpen,
}: IProps): React.ReactElement {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { isFullscreenEnabled, isDesktopEnabled } =
    useContext(GeorgStorageContext);
  const iconMinWidth = useMemo(() => {
    return isOpen ? 56 : 30;
  }, [isOpen]);

  const listItemStyles = {
    listItem: classes.listItem,
    hiddenIcon: classes.hiddenIcon,
  };

  // Handling screen-mode button depending on current screenmode (fullscreen...).
  let label = "";
  if (!isDesktopEnabled && !isFullscreenEnabled) {
    label = t("navigations.fullscreenMode");
  } else if (!isDesktopEnabled && isFullscreenEnabled) {
    label = t("navigations.shopfloorMode");
  }

  const screenModeButton = (
    <FooterButton
      label={label}
      icon={<FullscreenIcon color="primary" />}
      onClick={onClick}
      listItemStyles={listItemStyles}
      iconMinWidth={iconMinWidth}
      isOpen={isOpen}
    />
  );

  if (!isMobile) {
    return (
      <div className={classes.bottomElement}>
        {screenModeButton}
        <FooterButton
          label={t("navigations.collapse")}
          icon={
            !isOpen ? (
              <MenuIcon color="primary" />
            ) : (
              <ChevronLeftIcon color="primary" />
            )
          }
          onClick={() => dispatch(handleDrawer(!isOpen))}
          listItemStyles={listItemStyles}
          iconMinWidth={iconMinWidth}
          isOpen={isOpen}
        />
      </div>
    );
  } else {
    return <></>;
  }
}
