/*
 *
 *   Kpi-view for the machine dashboard.
 *
 */

import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// Icons.
import BuildIcon from "@mui/icons-material/Build";
import SettingsIcon from "@mui/icons-material/Settings";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// Custom Imports.
import { LineChartCard } from "../../../../../components";
import { selectTimeSelection } from "../../../../../redux/reducers/timeSelectReducer";
import GridPage from "../../../../../shared/components/gridPage";
import {
  LINE_CURRENT_TIMEFRAME_LAYOUT_KEY,
  LINE_REFERENCE_TIMEFRAME_LAYOUT_KEY,
  PIE_AVAILABILITY_LAYOUT_KEY,
  PIE_PRODUCTIVITY_LAYOUT_KEY,
  PIE_PERFORMANCE_LAYOUT_KEY,
  PIE_QUALITY_LAYOUT_KEY,
  TResizeConfig,
} from "./layoutConfig";
import getLayoutInfo from "./layoutConfig";
import KPIValueDisplayCard from "../../../../../components/kpiValueDisplayCard";
import NestedKPIPieChartCard from "../../../../../components/charts/nestedKpiPieChartCard";

//------------------------------------------------------------------------------------------------------------
// Component.
export default function OEEView(): React.ReactElement {
  const { t } = useTranslation();
  const timeSelection = useSelector(selectTimeSelection);
  /*const [resizeConf, setResizeconf] = useState<TResizeConfig>(
    {} as TResizeConfig
  );
  const layoutConf = useMemo(() => getLayoutInfo(resizeConf), [resizeConf]);*/
  const layoutConf = useMemo(() => getLayoutInfo({} as TResizeConfig), []);
  return (
    <GridPage layouts={layoutConf} containerPadding={[10, 10]}>
      <div key={PIE_AVAILABILITY_LAYOUT_KEY}>
        <NestedKPIPieChartCard
          icon={<BuildIcon />}
          label={t("common.kpi_availability.label")}
          tooltipLabel={t("common.kpi_availability.label")}
          tooltipSublabel={t("common.kpi_availability.sublabel")}
          tooltipDescription={t("common.kpi_availability.description")}
          tooltipFooter={t("common.kpi_availability.footer")}
          indicator={"kpi_availability"}
        />
      </div>
      <div key={PIE_PRODUCTIVITY_LAYOUT_KEY}>
        <NestedKPIPieChartCard
          icon={<SettingsIcon />}
          label={t("common.kpi_productivity.label")}
          tooltipLabel={t("common.kpi_productivity.label")}
          tooltipSublabel={t("common.kpi_productivity.sublabel")}
          tooltipDescription={t("common.kpi_productivity.description")}
          tooltipFooter={t("common.kpi_productivity.footer")}
          indicator={"kpi_productivity"}
        />
      </div>
      <div key={PIE_PERFORMANCE_LAYOUT_KEY}>
        <KPIValueDisplayCard
          icon={<ShowChartIcon />}
          label={t("common.kpi_performance.label")}
          tooltipLabel={t("common.kpi_performance.label")}
          tooltipSublabel={t("common.kpi_performance.sublabel")}
          tooltipDescription={t("common.kpi_performance.description")}
          tooltipFooter={t("common.kpi_performance.footer")}
          variableIdentification={"OEEspeed"}
          timeSelection={timeSelection}
          isUnitHidden={false}
        />
      </div>
      <div key={PIE_QUALITY_LAYOUT_KEY}>
        <KPIValueDisplayCard
          icon={<AddCircleOutlineIcon />}
          label={t("common.kpi_quantity.label")}
          tooltipLabel={t("common.kpi_quantity.label")}
          tooltipSublabel={t("common.kpi_quantity.sublabel")}
          tooltipDescription={t("common.kpi_quantity.description")}
          tooltipFooter={t("common.kpi_quantity.footer")}
          variableIdentification={"OEEquantity"}
          timeSelection={timeSelection}
        />
      </div>
      <div key={LINE_CURRENT_TIMEFRAME_LAYOUT_KEY}>
        <LineChartCard
          indicatorNames={[
            "kpi_availability",
            "kpi_productivity",
            "kpi_performance_ratio",
            "kpi_quality_ratio",
          ]}
          label={t("dataSelection.current-timeframe.label")}
          tooltipLabel={t("dataSelection.current-timeframe.label")}
          tooltipSublabel={""}
          tooltipDescription={t("dataSelection.current-timeframe.description")}
          tooltipFooter={""}
          timeSlot={timeSelection.currentFilter}
        />
      </div>
      <div key={LINE_REFERENCE_TIMEFRAME_LAYOUT_KEY}>
        <LineChartCard
          indicatorNames={[
            "kpi_availability",
            "kpi_productivity",
            "kpi_performance_ratio",
            "kpi_quality_ratio",
          ]}
          label={t("dataSelection.reference-timeframe.label")}
          tooltipLabel={t("dataSelection.reference-timeframe.label")}
          tooltipSublabel={""}
          tooltipDescription={t(
            "dataSelection.reference-timeframe.description",
          )}
          tooltipFooter={""}
          timeSlot={timeSelection.referenceFilter}
        />
      </div>
    </GridPage>
  );
}
