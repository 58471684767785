/*
 *   Reducer to set the id of a selected machine. Necessary for the machine dashboard.
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

type State = {
  selectedMachine: string;
};

type Props = string;

const initialState: State = {
  selectedMachine: "",
};

export const machineSelectReducer = createSlice({
  name: "machineSelect",
  initialState,
  reducers: {
    setMachine: (state, action: PayloadAction<Props>): void => {
      state.selectedMachine = action.payload;
    },
    resetMachine: (state): void => {
      state.selectedMachine = "";
    },
  },
});

export const selectMachine = (state: RootState): string =>
  state.machineSelect.selectedMachine;

export const { setMachine, resetMachine } = machineSelectReducer.actions;

export default machineSelectReducer.reducer;
