import useLocalStorage from "../persistence/useLocalStorage";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import i18next from "i18next";

const LS_LANG_KEY = "LANG";
const DEFAULT_LANG = "en";

interface ILanguageApi {
  language: string;
  setLanguage: (langKey: string) => void;
  isLoading: boolean;
}

export default function useLanguage(): ILanguageApi {
  const { load, set } = useLocalStorage();
  const lang = load(LS_LANG_KEY);

  const [language, setLanguageState] = useState(lang || DEFAULT_LANG);
  const [isLoading, setIsLoading] = useState(true);
  const setLanguage = useCallback((lang: string) => {
    set(LS_LANG_KEY, lang);
    setLanguageState(lang);
  }, []);

  useLayoutEffect(() => {
    setIsLoading(true);
    i18next
      .changeLanguage(language, (err) => {
        if (err) {
          // eslint-disable-next-line no-console
          console.warn(err);
        }
      })
      .then(() => {
        setIsLoading(false);
      })
      // eslint-disable-next-line no-console
      .catch(console.error);
  }, [language]);

  useLayoutEffect(() => {
    if (lang) {
      if (language !== lang) {
        setLanguage(lang);
      }
    } else {
      if (lang == null) {
        set(LS_LANG_KEY, DEFAULT_LANG);
      }
    }
  }, []);

  return useMemo(() => {
    return {
      language,
      setLanguage,
      isLoading,
    };
  }, [language, isLoading]);
}
