import React from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { EOrder } from "../../../../views/machineryView/sort/sortByMachineKey";
import getNewSort from "../../../../views/machineryView/sort/getNewSort";
import { ESortBy, ISort } from "../../../../views/machineryView";
import { IButton } from "../../../../../shared/components";

interface SortButtonProps {
  label: string;
  sortType: ESortBy;
  currentSort: ISort;
  onSortChange: (sortBy: ESortBy, order: EOrder) => void;
  buttonStyle: string;
}
function SortButton({
  label,
  sortType,
  currentSort,
  onSortChange,
  buttonStyle,
}: SortButtonProps): React.ReactElement {
  const icon =
    currentSort.sortBy !== sortType ? (
      <UnfoldMoreIcon color="secondary" />
    ) : currentSort.order === EOrder.ASC ? (
      <ArrowUpwardIcon color="secondary" />
    ) : (
      <ArrowDownwardIcon color="secondary" />
    );

  return (
    <IButton
      buttonColor="secondary"
      icon={icon}
      label={label}
      labelColor="primary"
      labelVariant="body1"
      name={`sort-${label.toLowerCase()}`}
      onClick={() => {
        const newSort = getNewSort(
          currentSort.sortBy,
          sortType,
          currentSort.order,
        );
        onSortChange(newSort.sortBy, newSort.order);
      }}
      styles={{ button: buttonStyle }}
    />
  );
}

export default React.memo(SortButton);
