import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  container: {
    minHeight: 306,
    paddingTop: 48,
    paddingBottom: 48,
    width: "100%",
  },
  buttons: {
    display: "flex",
    marginTop: 60,
    marginBottom: 50,
    alignContent: "space-between",
    justifyContent: "space-evenly",
  },
  question: {
    marginTop: 50,
    maxWidth: 400,
  },
  inputs: {
    display: "flex",
    gap: "10px",
    width: "100%",
  },
  textComponent: {
    marginTop: 20,
  },
  checkbox: {
    margin: "20px 0",
  },
  dateTimeGrid: {
    height: "100%",
    backgroundColor: `rgba(${0}, ${0}, ${0}, ${0.06})`,
    "&.Mui-disabled": {
      backgroundColor: `rgba(${0}, ${0}, ${0}, ${0.12})`,
    },
  },
  dateTimeBox: {
    height: 56,
    display: "block",
    width: "100%",
    flexBasis: "100%",
  },
  dateTimeInput: {
    height: "100%",
    margin: 0,
    display: "flex",
    alignItems: "center",
    "& .MuiInputLabel-root": {
      margin: "0 10px",
    },
    "& .MuiInputBase-input": {
      margin: "0 10px",
    },
  },
  dateTimeInputRoot: {
    marginBottom: -11,
  },
  valueInput: {
    flex: "1 1 auto",
    width: "auto",
    // width: "100%",
  },
}));

export default useStyles;
