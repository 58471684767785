import GeorgButton, { EButtonSize, EButtonType } from "../GeorgButton";

interface IProps {
  label: string;
  onClick: () => void;
  disabled: boolean;
  endIcon?: React.ReactElement;
}
export default function SideButton({
  label,
  onClick,
  disabled,
  endIcon,
}: IProps) {
  return (
    <GeorgButton
      disabled={disabled}
      label={label}
      onClick={onClick}
      buttonType={EButtonType.LIGHT}
      buttonSize={EButtonSize.MEDIUM}
      endIcon={endIcon}
      isNotAction={true}
    />
  );
}
