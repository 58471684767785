import React from "react";
import QRCode from "react-qr-code";
import { colors } from "../../theme";

interface IProps {
  qrLink: string;
}

export default function QrCode({ qrLink }: IProps): React.ReactElement {
  return (
    <QRCode
      id="qrLink"
      value={qrLink}
      size={250}
      bgColor={colors.white_base}
      level={"M"}
    />
  );
}
