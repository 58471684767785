import React, { useMemo } from "react";
import { ESortBy, ISort } from "../../index";
import _ from "lodash";
import { IList } from "../../../../../shared/components";
import { EOrder } from "../../sort/sortByMachineKey";
import MachineCardGridElement from "../machineCardGridElement";
import { useSelector } from "react-redux";
import { selectIndicator } from "../../../../../redux/reducers/indicatorReducer";
import { IMachine } from "../../../../../api/machine/IMachine";
import { IIndicatorStateSince } from "../machineStatusFooter/api/useCurrentIndicatorStateList/useCurrentIndicatorStateList";
import TMachineStateMap from "../../machineStateMap/TMachineStateMap";
import useStyles from "../../styles";
import { getTimezone } from "../../../../../helper/time/timezone";

interface IProps {
  machines: IMachine[];
  handleClick: (machineId: string) => void;
  kpi: boolean;
  onStateChange: (state: IIndicatorStateSince, id: string) => void;
  sort: ISort;
  machineStateMap: TMachineStateMap;
}
function GroupedMachineList({
  machines,
  handleClick,
  kpi,
  onStateChange,
  sort,
  machineStateMap,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  const allIndicators = useSelector(selectIndicator);
  const timezone = getTimezone();

  const groupToShow = useMemo(() => {
    if (machineStateMap.size === 0) {
      return {};
    }
    switch (sort.sortBy) {
      case ESortBy.LOCATION:
        return _.groupBy(machines, (e) => e.location) ?? {};
      case ESortBy.TECH:
        return _.groupBy(machines, (e) => e.deviceClass) ?? {};
      default:
        return { "All Machines": machines };
    }
  }, [machines, sort.sortBy, machineStateMap]);

  const renderedTSX: React.ReactElement[] = [];
  Object.keys(groupToShow)
    .sort((a, b) =>
      sort.order === EOrder.ASC ? a.localeCompare(b) : b.localeCompare(a),
    )
    .forEach((label) => {
      const elements = groupToShow[label];
      if (elements != null) {
        if (sort.sortBy !== ESortBy.NO_SORT && sort.sortBy !== ESortBy.STATE) {
          renderedTSX.push(
            <div key={label} className={classes.headerGroupElement}>
              <IList label={label} />
            </div>,
          );
        }
        elements.forEach((machine: IMachine) => {
          renderedTSX.push(
            <MachineCardGridElement
              key={machine.id}
              machine={machine}
              onStateChange={onStateChange}
              kpi={kpi}
              allIndicators={allIndicators}
              handleClick={handleClick}
              timezone={timezone}
            />,
          );
        });
      }
    });
  return <>{renderedTSX}</>;
}

export default React.memo(GroupedMachineList);
