import { Link } from "@mui/material";
import React from "react";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";

const getPrivacyLink = (locale: string): string =>
  `/legal/privacy/index_${locale}.html`;
const getImprintLink = (locale: string): string =>
  `/legal/imprint/index_${locale}.html`;
function Footer(): React.ReactElement {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  const privacyLink = getPrivacyLink(locale);
  const imprintLink = getImprintLink(locale);
  return (
    <div className={classes.footer}>
      <Link className={classes.leftLink} href={imprintLink} target="_blank">
        {t("container.footer.imprint")}
      </Link>
      <Link className={classes.middleLink} href={privacyLink} target="_blank">
        {t("container.footer.privacy")}
      </Link>
      <Link className={classes.rightLink} href={"/app/page"}>
        {"Apps"}
      </Link>
    </div>
  );
}

export default React.memo(Footer);
