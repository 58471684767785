import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  saveCancelBox: {
    marginTop: 32,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "& button.MuiButton-contained": {
      minWidth: 125,
    },
  },
}));

export default useStyles;
