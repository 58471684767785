import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  loginProgressWrapper: {
    marginTop: 180,
    display: "flex",
    justifyContent: "center",
  },
  spinnerBox: {
    height: 50,
    alignSelf: "center",
  },
}));
export default useStyles;
