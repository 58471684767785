import dateToIsoString from "../../../../../../../../helper/time/formatting/dateToIsoUtils";

export function getStartIsoDate(): string {
  const d = new Date();
  d.setDate(d.getDate() - 1);
  return dateToIsoString(d);
}

export function getEndIsoDate(): string {
  return dateToIsoString(new Date());
}
