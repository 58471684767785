import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import { Grid2 as Grid } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import useStyles from "./styles";

interface IProps {
  name: string;
  department: string;
  phone_number: string;
  email: string;
  image: string;
  vcard: string;
}

export default function ContactCard({
  name,
  department,
  phone_number,
  email,
  image,
  vcard,
}: IProps): React.ReactElement {
  const cssClasses = useStyles().classes;
  // eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-require-imports
  const Image = require("../../../../../../assets/img/service/" + image);
  return (
    <Card className={`${cssClasses.staff_card}`} raised>
      <CardMedia
        component="img"
        src={Image}
        height="0"
        sx={{
          height: "140px",
          width: "140px",
          margin: "auto",
          borderRadius: "50%",
        }}
      />
      <CardContent className={cssClasses.staff_card_content}>
        <h4>{name}</h4>
        <p>{department}</p>
        <span>
          <PhoneIcon className={cssClasses.staff_phone_icon} />
          <i>{phone_number}</i>
        </span>
        <div className={cssClasses.staff_card_gap} />
        <Grid container className={cssClasses.staff_card_jumbotron_text}>
          <Grid size={6} className={cssClasses.staff_card_grid_item_left}>
            <Grid className={cssClasses.staff_card_mail_jumbotron}>
              <a
                href={`mailto:${email}`}
                className={cssClasses.staff_card_jumbotron_text}
              >
                Mail
              </a>
            </Grid>
          </Grid>
          <Grid size={6} className={cssClasses.staff_card_grid_item_right}>
            <Grid className={cssClasses.staff_card_mail_jumbotron}>
              <a
                href={`${vcard}`}
                className={cssClasses.staff_card_jumbotron_text}
              >
                VCard
              </a>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
