/*
 *   Indicator Reducer which stores all indicators from backend as key-value pair. Is necessary for example for querying kpiPieChartData.
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { TIndicatorType } from "../../helper";

const initialState: TIndicatorType[] = [];

export const indicatorSlice = createSlice({
  name: "indicatorList",
  initialState,
  reducers: {
    setIndicator: (state, action: PayloadAction<TIndicatorType[]>): void => {
      // Clear the state and push the new indicators
      state.splice(0, state.length);
      state.push(...action.payload);
    },
  },
});

export const selectIndicator = (state: RootState): TIndicatorType[] =>
  state.indicatorList;

export const { setIndicator } = indicatorSlice.actions;

export default indicatorSlice.reducer;
