import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  container: {
    padding: 0,
    margin: 0,
    border: 0,
    height: "100%",
    width: "100%",
  },
  gridActionItems: {
    "&.MuiGrid2-root": {
      flex: 1,
    },
  },
}));

export default useStyles;
