import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import getLayoutInfo, {
  CONTACT_CARD_1_LAYOUT_KEY,
  CONTACT_CARD_2_LAYOUT_KEY,
  TEXT_CARD_LAYOUT_KEY,
  TResizeConfig,
} from "./layoutConfig";
import GridPage from "../../../../shared/components/gridPage";
import TextCard from "./components/textCard";
import ContactCard from "./components/contactCard";
import { selectSupportStaff } from "../../../../redux/reducers/serviceReducer";

export default function ContactsView(): React.ReactElement {
  const layoutConf = useMemo(() => getLayoutInfo({} as TResizeConfig), []);
  const getSupportStaff = useSelector(selectSupportStaff);

  const keyMapping: Record<number, string> = {
    0: CONTACT_CARD_1_LAYOUT_KEY,
    1: CONTACT_CARD_2_LAYOUT_KEY,
  };

  const isContactExist = useMemo(
    () => getSupportStaff != null && getSupportStaff.length > 1,
    [getSupportStaff.length],
  );

  return (
    <div style={{ maxWidth: 1100 }}>
      <GridPage layouts={layoutConf}>
        <div key={TEXT_CARD_LAYOUT_KEY}>
          <TextCard />
        </div>
        <div key={keyMapping[0]}>
          {isContactExist && (
            <ContactCard
              name={getSupportStaff[0].name}
              department={getSupportStaff[0].department}
              phone_number={getSupportStaff[0].phone_number}
              email={getSupportStaff[0].email}
              image={getSupportStaff[0].image}
              vcard={getSupportStaff[0].vcard}
            />
          )}
        </div>
        <div key={keyMapping[1]}>
          {isContactExist && (
            <ContactCard
              name={getSupportStaff[1].name}
              department={getSupportStaff[1].department}
              phone_number={getSupportStaff[1].phone_number}
              email={getSupportStaff[1].email}
              image={getSupportStaff[1].image}
              vcard={getSupportStaff[1].vcard}
            />
          )}
        </div>
      </GridPage>
    </div>
  );
}
