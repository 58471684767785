import { makeStyles } from "tss-react/mui";
import { colors } from "../../shared/theme";
import colorToRgba from "../../helper/adjustColor";

const styles = makeStyles()(() => ({
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    // To prevent the text to be displayed in many lines
    /*"@media (max-width: 1199px)": {
      minWidth: 345,
    },*/
  },
  textContainer: {
    "&.MuiTypography-root": {
      fontWeight: "normal",
    },
  },
  smallTextContainerWithTopPadding: {
    paddingTop: 24,
    lineHeight: "16px",
  },
  smallTextContainer: {
    lineHeight: "16px",
  },
  titleText: {
    "&.MuiTypography-root": {
      fontWeight: "bold",
      lineHeight: "24px",
      fontSize: 18,
    },
    display: "contents",
  },
  boldText: {
    "&.MuiTypography-root": {
      fontWeight: "bold",
      lineHeight: "16px",
    },
    display: "contents",
  },
  text: {
    display: "contents",
    "&.MuiTypography-root": {
      lineHeight: "16px",
    },
  },
  lightText: {
    "&.MuiTypography-root": {
      fontWeight: "normal",
      lineHeight: "16px",
      color: colorToRgba(colors.white_base, 0.5),
    },
    display: "contents",
  },
}));

export default styles;
