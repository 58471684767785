import React from "react";
import { IButton, IMenu } from "../../../../shared/components";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Image as ImageIcon, GridOn as GridOnIcon } from "@mui/icons-material";

export enum IExportEnum {
  CSV = "csv",
  PNG = "png",
  NONE = "none",
}

interface IProps {
  disabled: boolean;
  onClick: (value: IExportEnum) => void;
  isExportCSVDisabled?: boolean;
  isExportPNGDisabled?: boolean;
  itemDisabledTooltipText?: string;
}
export default function ExportButton({
  onClick,
  disabled,
  isExportCSVDisabled = false,
  isExportPNGDisabled = false,
  itemDisabledTooltipText,
}: IProps): React.ReactElement {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // Handling menu open and close.
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const { classes } = useStyles();

  return (
    <>
      <IButton
        endIcon={<KeyboardArrowDownIcon color="secondary" />}
        label={t("dashboard.commonChart.export")}
        labelColor="primary"
        labelVariant="body1"
        buttonVariant="contained"
        buttonColor="secondary"
        onClick={handleMenuClick}
        styles={{
          button: classes.button,
        }}
        disabled={disabled}
        isNotAction={true}
      />

      <IMenu
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        labelVariant="body1"
        itemDisabledTooltipText={itemDisabledTooltipText}
        items={[
          {
            id: 0,
            Icon: <GridOnIcon />,
            label: t("dashboard.commonChart.csv"),
            onClick: () => {
              if (isExportCSVDisabled) return;
              onClick(IExportEnum.CSV);
              handleMenuClose();
            },
            disabled: isExportCSVDisabled,
          },
          {
            id: 1,
            Icon: <ImageIcon />,
            label: t("dashboard.commonChart.png"),
            onClick: () => {
              if (isExportPNGDisabled) return;
              onClick(IExportEnum.PNG);
              handleMenuClose();
            },
            disabled: isExportPNGDisabled,
          },
        ]}
        styles={{
          menuItems: classes.menuItems,
          menuItemLabel: classes.menuItemLabel,
        }}
      />
    </>
  );
}
