import React from "react";
import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import UserManagement from "./userManagement";
import { IHeader } from "../../../shared/components";
import useStyles from "./styles";
import ScreenResolutionWarning from "../../../shared/components/screenResolutionWarning";

export default function UserSettings(): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <ScreenResolutionWarning>
      <Container className={classes.container}>
        <IHeader
          headline={t("settings.userManagement.title")}
          headlineColor="primary"
          headlineVariant="h3"
          tagline={t("settings.userManagement.subtitle")}
          taglineColor="primary"
          taglineVariant="body1"
          styles={{ tagline: classes.title }}
        />
        <UserManagement />
      </Container>
    </ScreenResolutionWarning>
  );
}
