/*
 * This hook is used to fetch the
 *  - machine list,
 *  - indicator list,
 *  - downtime list,
 *  - variables list
 *from the backend and dispatch them to the redux store.
 *It also handles the loading and error states of the queries.
 *Promise is used to fetch all the data at once.
 */

import { useLayoutEffect, useState } from "react";
import useQueryMachineListAndDispatch from "../api/machine/useLazyQueryMachineListAndDispatchToReduxStore";
import useQueryIndicatorListAndDispatch from "../api/indicator/useLazyQueryIndicatorListAndDispatchToReduxStore";
import useQueryDowntimeListAndDispatch from "../api/indicator/useLazyQueryIndicatorIdListAndDispatchToReduxStore";
type TResponse = [
  doQuery: () => void,
  resp: {
    isError: boolean;
    isLoading: boolean;
    errorMessage: string[];
  },
];

interface IErrorMessages {
  machineQueryErr: string;
  indicatorQueryErr: string;
  downtimeQueryErr: string;
}

export function useDataFetchAndUpdate(errorTexts: IErrorMessages): TResponse {
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const [
    doMachineQuery,
    { error: machineQueryErr, isLoading: isMachineLoading },
  ] = useQueryMachineListAndDispatch();

  const [
    doIndicatorListQuery,
    { error: indicatorListQueryErr, isLoading: isIndicatorListLoading },
  ] = useQueryIndicatorListAndDispatch();

  const [
    doDowntimeListQuery,
    { error: downtimeListQueryErr, isLoading: isDowntimeListLoading },
  ] = useQueryDowntimeListAndDispatch("org_downtime_reasons");

  const doQuery = async () => {
    try {
      await Promise.all([
        doMachineQuery(),
        doIndicatorListQuery(),
        doDowntimeListQuery(),
      ]);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      throw new Error("Error in fetching machines data");
    }
  };

  useLayoutEffect(() => {
    doQuery();
  }, []);

  // Handle Apollo query errors
  useLayoutEffect(() => {
    if (
      indicatorListQueryErr &&
      !errorMessages.includes(errorTexts.indicatorQueryErr)
    ) {
      setErrorMessages((prev) => [...prev, errorTexts.indicatorQueryErr]);
    }
    if (
      downtimeListQueryErr &&
      !errorMessages.includes(errorTexts.downtimeQueryErr)
    ) {
      setErrorMessages((prev) => [...prev, errorTexts.downtimeQueryErr]);
    }
    if (
      machineQueryErr &&
      !errorMessages.includes(errorTexts.machineQueryErr)
    ) {
      setErrorMessages((prev) => [...prev, errorTexts.machineQueryErr]);
    }
    /*if (variableListQueryErr) {
      setErrorMessages((prev) => [...prev, errorTexts.variableListQueryErr]);
    }*/
  }, [
    indicatorListQueryErr,
    downtimeListQueryErr,
    // variableListQueryErr,
    machineQueryErr,
  ]);

  // Aggregate loading and error states
  const isLoading =
    isIndicatorListLoading ||
    isDowntimeListLoading ||
    // isVariableListLoading ||
    isMachineLoading;
  const isError = errorMessages.length > 0;

  return [
    doQuery,
    {
      isLoading,
      isError,
      errorMessage: Object.values(errorMessages),
    },
  ];
}
