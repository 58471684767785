import { EDisplayState } from "../../../../../shared/components/georgDataGrid/components/stateCell";

export function calculatePriorityState(currentPriority: number): EDisplayState {
  if (currentPriority > 666 && currentPriority <= 1000) {
    return EDisplayState.DANGER;
  } else if (currentPriority > 333 && currentPriority < 667) {
    return EDisplayState.WARNING;
  } else {
    return EDisplayState.OK;
  }
}
