import { makeStyles } from "tss-react/mui";
import { colors } from "../../../../../shared/theme";

const useStyles = makeStyles()({
  machineCardFooterWrapper: {
    minHeight: 70,
  },
  headerContainer: {
    display: "flex",
    height: 70,
  },
  imageContainer: {
    height: 210,
  },
  machineSerialNumber: {
    borderBottomColor: colors.grey_base,
    borderBottom: 1,
    borderBottomStyle: "solid",
    fontSize: 24,
    fontWeight: 300,
    marginLeft: -5,
    transform: "rotate(-90deg)",
  },
  machineCommissionContainer: {
    alignItems: "center",
    display: "flex",
  },
  deviceLabel: {
    lineHeight: 1.5,
  },
  deviceLabelExtension: {
    lineHeight: 1.5,
    display: "inline",
  },
  machineDeviceClass: {
    marginTop: -8,
  },
  deviceSublabel: {
    lineHeight: 1.5,
    marginTop: -8,
  },
  machineImage: {
    display: "flex",
    flexDirection: "column",
  },
});

export default useStyles;
