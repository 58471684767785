import { GridColDef } from "@mui/x-data-grid";

export default function getGridColDef(
  headerLabels: string[],
  downloadLabel: string,
  imageClass: string,
) {
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 0,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "image",
      headerName: headerLabels[0],
      flex: 1,
      editable: false,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell(params) {
        if (params.row.image != null && params.row.image != "") {
          return (
            <img
              className={imageClass}
              src={`data:gif;base64,${params.row.image || ""}`}
            />
          );
        } else {
          return <div>preview unavailable</div>;
        }
      },
    },
    {
      field: "fileName",
      headerName: headerLabels[1],
      // minWidth:330,
      // maxWidth: 550,
      flex: 2,
      editable: false,
      headerAlign: "left",
      align: "left",
      cellClassName: "docsearch_result_cell",
      // TODO If the documentation is selected, exclude the match part. It's not needed
      renderCell: (params) => {
        return (
          <div>
            <h3> {params.row.fileName}</h3>
            <a
              href={
                "https://georgdocu.blob.core.windows.net/documentation/" +
                params.row.path +
                "/" +
                params.row.fileName
              }
              target="_blank"
            >
              {downloadLabel}
            </a>
          </div>
        );
      },
    },
  ];
  return columns;
}
