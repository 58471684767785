import moment from "moment-timezone";
import TScale from "../../../../../../api/time/TScale";

const ScaleToMomentStartOfMap = {
  HOURS: "hour",
  DAYS: "day",
  WEEKS: "week",
  MONTHS: "month",
  YEARS: "year",
};

/*

    Finds start and end time for interval corresponding to given scale in order to fit heatmap X-Axis.
    Introduces necessary offsets for scaling heatmap bars.

    Examples:

    extend start and end time of month period
    startEndTimeFinder(periodStart: 09.08.22 periodEnd: 10.08.22 scale: MONTH) => start: 01.08.22 end: 31.08.22

    extend start and end time of month period + add days to complete 31 days in total
    startEndTimeFinder(periodStart: 03.04.22 periodEnd: 28.04.22 scale: MONTH) => start: 01.04.22 end: 01.05.22

    extend start and end time of week period + add days to complete first week
    startEndTimeFinder(periodStart: 01.07.22 periodEnd: 03.07.22 scale: WEEK) => start: 27.06.22 end: 03.07.22

 */
export default function findStartAndEndTimeForHeatmap(
  periodStart: number,
  periodEnd: number,
  scale: TScale,
  timezone: string,
): {
  start: number;
  end: number;
} {
  return calculateStartAndEndByScale(
    periodStart,
    periodEnd,
    ScaleToMomentStartOfMap[scale] as moment.unitOfTime.StartOf,
    timezone,
  );
}
function calculateStartAndEndByScale(
  periodStart: number,
  periodEnd: number,
  startOf: moment.unitOfTime.StartOf,
  timezone: string,
): { start: number; end: number } {
  moment.updateLocale("en", {
    week: {
      dow: 1, // Monday is the first day of the week.
    },
  });
  moment.weekdays(true);
  //startOf=day
  let start = moment
    .tz(periodStart, timezone)
    .startOf(startOf)
    .toDate()
    .getTime();
  let end =
    moment
      .tz(periodEnd - 1, timezone)
      .endOf(startOf)
      .toDate()
      .getTime() + 1;
  if (startOf === "hour") {
    start = moment.tz(periodStart, timezone).startOf("day").toDate().getTime();
  }
  if (startOf === "month") {
    end = moment.tz(start, timezone).add(31, "day").toDate().getTime();
  }
  return { start, end };
}
