import { BarStackChartCard } from "../index";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectIndicator } from "../../../redux/reducers/indicatorReducer";
import { useTranslation } from "react-i18next";
import transformDataFromServer from "./api/transformDataFromServer";
import useCondensedStateListQuery from "./api/useCondensedStateListQuery";
import { selectMachine } from "../../../redux/reducers/machineSelectReducer";
import { selectTimeSelection } from "../../../redux/reducers/timeSelectReducer";
import IBarChartDataElement from "../../../shared/components/charts/barStackChartCard/api/IBarChartDataElement";
import { IBarChartPart } from "../../../shared/components/charts/barStackChartCard";
import ServerDataStatusWrapper from "../../../shared/components/serverDataStatusWrapper";
import GeorgStorageContext from "../../../context/GeorgStorageContext";

interface IProps {
  indicatorIds: string[];
}
function MachineStateBarChartStacked({
  indicatorIds,
}: IProps): React.ReactElement {
  const { t, i18n } = useTranslation();
  const selectedMachine = useSelector(selectMachine);
  const timeSelection = useSelector(selectTimeSelection);
  const [data, setData] = useState<IBarChartDataElement[]>([]);
  const [parts, setParts] = useState<IBarChartPart[]>([]);
  const { timezone } = useContext(GeorgStorageContext);
  const { condensedStatelist, isEmpty, isLoading, hasError } =
    useCondensedStateListQuery(
      indicatorIds,
      timeSelection.currentFilter,
      timezone,
      selectedMachine,
    );
  const allIndicators = useSelector(selectIndicator);
  const locale = i18n.language;
  useEffect(() => {
    if (condensedStatelist != null && !hasError && !isLoading && !isEmpty) {
      const { data: newData, parts: newParts } = transformDataFromServer(
        locale,
        timezone,
        t,
        condensedStatelist,
        allIndicators,
        indicatorIds.map((idStr) => parseInt(idStr, 10)),
      );
      setData(newData);
      setParts(newParts);
    }
  }, [
    indicatorIds,
    locale,
    condensedStatelist,
    allIndicators,
    isEmpty,
    isLoading,
    hasError,
  ]);

  return (
    <ServerDataStatusWrapper
      isEmpty={isEmpty && !isLoading}
      hasError={hasError}
    >
      <BarStackChartCard
        label={t("dashboard.stacklight.machineStates.barChartStacked.title")}
        barChartParts={parts}
        chartElements={data}
        isLoading={isLoading}
        isEmpty={isEmpty}
        hasError={hasError}
        timezone={timezone}
        locale={locale}
        machineId={selectedMachine}
      />
    </ServerDataStatusWrapper>
  );
}

export default React.memo(MachineStateBarChartStacked);
