import React, { useContext, useMemo } from "react";
import { IServiceOrderSettingsTableFormat } from "../../api/interfaces";
import GeorgDataGrid from "../../../../../../shared/components/georgDataGrid";
import getColumnConfig from "./getColumnConfig";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { GridColumnVisibilityModel, GridSortModel } from "@mui/x-data-grid";
import GeorgStorageContext from "../../../../../../context/GeorgStorageContext";

interface IProps {
  serviceOrders: IServiceOrderSettingsTableFormat[];
  isExpanded: boolean;
  onClickDone: (serviceOrderId: string, version: string) => void;
  isActionDisabled: boolean;
  sortModel: GridSortModel;
  onSortChange: (newSortModel: GridSortModel) => void;
  columnVisibilityModel?: GridColumnVisibilityModel;
  onColumnVisibilityModelChange?: (
    newGridColumnVisibilityModel: GridColumnVisibilityModel,
  ) => void;
}
export default function ServiceOrderTable({
  serviceOrders,
  isExpanded,
  onClickDone,
  isActionDisabled,
  sortModel,
  onSortChange,
  columnVisibilityModel,
  onColumnVisibilityModelChange,
}: IProps): React.ReactElement {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { isTooltipEnabled } = useContext(GeorgStorageContext);
  const columns = useMemo(() => {
    return getColumnConfig(
      t,
      classes,
      isExpanded,
      onClickDone,
      isActionDisabled,
    );
  }, [classes, isExpanded, onClickDone, isActionDisabled]);

  return (
    <GeorgDataGrid
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={onColumnVisibilityModelChange}
      onSortChange={onSortChange}
      sortModel={sortModel}
      rows={serviceOrders}
      columns={columns}
      disableColumnMenu={false}
      isTooltipEnabled={isTooltipEnabled}
    />
  );
}
