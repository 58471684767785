import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../../../../../shared/theme";

const useStyles = makeStyles()((theme: Theme) => ({
  listItem: {
    [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
      paddingLeft: 35,
    },
    cursor: "pointer",
    borderLeft: `2px solid transparent`,
    color: colors.white_base,
    borderBottom: `1px solid ${colors.grey_base}`,
    transition: "opacity 0.3s ease, border-left 0.3s ease",
    "&:hover": {
      borderLeft: `2px solid ${colors.yellowB_16}`,
      opacity: 1,
    },
    "&:last-element": {
      borderLeft: "none",
    },
    opacity: 0.75,
  },
  SubListItem: {
    backgroundColor: colors.blueB32,
    "&:hover": {
      backgroundColor: colors.blueB32,
    },
  },
  hiddenIcon: {
    fill: colors.blue_base,
  },
  listItemActive: {
    [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
      paddingLeft: 35,
    },
    backgroundColor: colors.yellow_base,
    color: colors.blue_base,
    borderBottom: `1px solid ${colors.grey_base}`,
    "&:hover": {
      backgroundColor: colors.yellow_base,
      color: colors.blue_base,
    },
  },
  hiddenIconActive: {
    fill: colors.yellow_base,
  },
  baseListItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
  },
  simpleSubListItem: {
    paddingLeft: 20,
  },
  label: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));
export default useStyles;
