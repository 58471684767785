import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  container: {
    display: "flex",
    flexDirection: "inherit",
    alignItems: "center",
  },
  valueContainer: {
    display: "flex",
    whiteSpace: "nowrap",
    alignItems: "baseline",
    fontSize: 24,
    lineHeight: "20px",
    marginRight: 1,
  },
  metricContainer: {
    display: "flex",
    alignItems: "flex-end",
    fontSize: 16,
  },
}));

export default useStyles;
