import React, { useRef } from "react";
import { Modal } from "@mui/material";
import useStyles from "./styles";
import Title from "./components/title";
import { Close } from "@mui/icons-material";
import { useKeyPressed } from "../../../helper";

interface IProps {
  isModalVisible: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactElement;
  isScrollerShown?: boolean;
}

export default function GeorgModal({
  isModalVisible,
  onClose,
  title,
  children,
  isScrollerShown = true,
}: IProps): React.ReactElement {
  const modalRef = useRef<HTMLDivElement>(null);
  const handleClose = (
    event: React.MouseEvent<HTMLButtonElement>,
    reason: "backdropClick" | "escapeKeyDown",
  ) => {
    if (reason === "backdropClick") {
      event.stopPropagation();
      return;
    }
    onClose();
  };

  useKeyPressed("escape", onClose, modalRef.current);

  const { classes } = useStyles();
  return (
    <Modal
      open={isModalVisible}
      onClose={handleClose}
      aria-labelledby={title}
      aria-describedby={title}
      ref={modalRef}
      className={isScrollerShown ? classes.container : ""}
    >
      {
        <div className={classes.background}>
          <div className={classes.title}>
            <Title text={title} />
            <Close className={classes.closeIcon} onClick={onClose} />
          </div>

          <div className={classes.content}>
            <div className={classes.inner}>{children}</div>
          </div>
        </div>
      }
    </Modal>
  );
}
