import { makeStyles } from "tss-react/mui";
import { colors } from "../../../../../shared/theme";

const useStyles = makeStyles()({
  kpiListItem: {
    borderBottomColor: `${colors.whiteB_12} !important`,
    borderBottom: 1,
    borderBottomStyle: "solid",
  },
  actionItem: {
    // right: "unset",
    justifyContent: "center",
    // width: 100,
  },
  gridActionItems: {
    "&.MuiGrid2-root": {
      flexGrow: 1,
      flexBasis: 0,
    },
  },
});

export default useStyles;
