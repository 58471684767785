import { IMachine } from "../../../../api/machine/IMachine";
import TMachineStateMap from "./TMachineStateMap";
import { IIndicatorStateSince } from "../components/machineStatusFooter/api/useCurrentIndicatorStateList/useCurrentIndicatorStateList";

export const initializeMachineStateMap = (
  machines: IMachine[],
): TMachineStateMap =>
  new Map(
    machines.map(({ id }) => [
      id,
      {
        indicatorState: "indicator_state_undefined",
        stateStartedSince: undefined,
      },
    ]),
  );

export function getFromMapOrThrow(
  machineStateMap: TMachineStateMap,
  machineId: string,
): IIndicatorStateSince {
  const machineState: IIndicatorStateSince | undefined =
    machineStateMap.get(machineId);
  if (machineState == null)
    throw Error(
      `Illegal State, could not find machine state in Map: ${JSON.stringify(
        machineStateMap,
      )} for machine id ${machineId}`,
    );
  return machineState;
}
