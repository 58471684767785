import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  lineChartWrapper: {
    "& .recharts-text": {
      fontFamily: "Titillium Web",
    },
    "& .recharts-label": {
      fontSize: 40,
      textAlign: "left",
    },
  },
});

export default useStyles;
