import force_prod_tenant from "../force_prod_tenant";
const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/.exec(
      window.location.hostname,
    ),
);

let authorityDomain = "b2cmygeorg.b2clogin.com"; // b2c mandant
let tenantId = "b2cmygeorg.onmicrosoft.com";

if (isLocalhost) {
  if (force_prod_tenant.value) {
    authorityDomain = "b2cmygeorg.b2clogin.com"; // b2c mandant
    tenantId = "b2cmygeorg.onmicrosoft.com";
  } else {
    authorityDomain = "b2cdevmygeorg.b2clogin.com"; // b2c mandant
    tenantId = "b2cdevmygeorg.onmicrosoft.com";
  }
}

export const authPrefix = `https://${authorityDomain}/${tenantId}/`;
const policyPrefix = "B2C_1A_";
const b2cPolicies = {
  authorities: {
    signIn: {
      authority: `${authPrefix}${policyPrefix}SIGNIN`,
    },
    forgotPassword: {
      authority: `${authPrefix}${policyPrefix}PASSWORDRESET`,
    },
    passwordChange: {
      authority: `${authPrefix}${policyPrefix}PASSWORDCHANGE`,
    },
    profileEdit: {
      authority: `${authPrefix}${policyPrefix}PROFILEEDIT`,
    },
  },
  authorityDomain,
};

export default b2cPolicies;
