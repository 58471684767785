/*
 *
 *   Container component which builds the base frame with the topbar and sidebar.
 *   Each component is rendered inside this container component.
 *   Also contains the logic for handling fullscreen- and desktop-mode.
 *
 *
 */

import React, { useCallback, useContext, useEffect, useState } from "react";
import { Fab } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFullScreen } from "react-browser-hooks";
// Custom Imports.
import { SideBar, TopBar } from "../../components";
import useStyles from "./styles";
import Footer from "./components/footer";
import GeorgStorageContext from "../../context/GeorgStorageContext";
import YesNoDialog from "../../shared/components/yesnodialog";
import { useTranslation } from "react-i18next";

//------------------------------------------------------------------------------------------------------------
// Interfaces & Types.
type Props = {
  children: JSX.Element;
  isLoading?: boolean;
};

//------------------------------------------------------------------------------------------------------------
// Component.
export default function ContentContainer({
  children,
  isLoading,
}: Props): React.ReactElement {
  const { classes } = useStyles();
  const fs = useFullScreen();
  const { t } = useTranslation();
  const {
    isFullscreenEnabled,
    setFullscreenEnabled,
    isDesktopEnabled,
    setDesktopEnabled,
  } = useContext(GeorgStorageContext);
  const [isFullscreen, setIsFullscreen] = useState(isFullscreenEnabled);
  const [isDesktop, setIsDesktop] = useState(isDesktopEnabled);
  const [shouldShowFullScreenWarning, setShouldShowFullScreenWarning] =
    useState(false);

  useEffect(() => {
    setFullscreenEnabled(isFullscreen);
  }, [isFullscreen]);
  useEffect(() => {
    setDesktopEnabled(isDesktop);
  }, [isDesktop]);

  const handleNormalMode = useCallback(() => {
    if (isFullscreen) {
      fs.toggle();
    }
    setIsFullscreen(false);
    setIsDesktop(false);
  }, [fs, isFullscreen, setIsFullscreen, setIsDesktop]);

  const handleDesktopMode = () =>
    setIsDesktop((prevDesktopscreen) => !prevDesktopscreen);

  const handleFullscreenMode = useCallback(() => {
    setIsFullscreen((prevFullscreen) => !prevFullscreen);
    fs.toggle();
  }, [fs, setIsFullscreen]);

  const toggleFullscreen = () => {
    fs.toggle();
  };

  useEffect(() => {
    if (isDesktop || (isFullscreen && document.fullscreenElement == null)) {
      setShouldShowFullScreenWarning(true);
    }
  }, []);

  useEffect(() => {
    const exitHandler = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", exitHandler);
    document.addEventListener("webkitfullscreenchange", exitHandler);
    document.addEventListener("mozfullscreenchange", exitHandler);
    document.addEventListener("MSFullscreenChange", exitHandler);

    return () => {
      document.removeEventListener("fullscreenchange", exitHandler);
      document.removeEventListener("webkitfullscreenchange", exitHandler);
      document.removeEventListener("mozfullscreenchange", exitHandler);
      document.removeEventListener("MSFullscreenChange", exitHandler);
    };
  }, []);

  let topBarContent;
  let sideBarContent;

  if (!isDesktop) {
    topBarContent = <TopBar />;
    sideBarContent = (
      <SideBar
        onClick={isFullscreen ? handleDesktopMode : handleFullscreenMode}
      />
    );
  }

  return (
    <div>
      {shouldShowFullScreenWarning && (
        <YesNoDialog
          onSubmit={toggleFullscreen}
          isModalVisible={shouldShowFullScreenWarning}
          onClose={() => {
            setShouldShowFullScreenWarning(false);
          }}
          title={t("shared.screenModeWarning.title")}
          question={t("shared.screenModeWarning.text")}
          onCancel={() => {
            setShouldShowFullScreenWarning(false);
            setIsFullscreen(false);
            setIsDesktop(false);
          }}
        />
      )}
      <div className={`${!isDesktop} ? ${classes.root} : ""`}>
        {topBarContent}
        {sideBarContent}
        {isDesktop && (
          <Fab onClick={handleNormalMode} className={classes.fab}>
            <CloseIcon />
          </Fab>
        )}
        <main className={classes.contentContainer}>
          <div>{children}</div>
        </main>
      </div>
      {!isLoading && <Footer />}
    </div>
  );
}
