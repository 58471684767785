import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  headLine: {
    height: "auto",
    width: "fit-content",
  },
}));

export default useStyles;
