import { makeStyles } from "tss-react/mui";
import { rootTheme } from "../../../shared/theme";

const useStyles = makeStyles()(() => ({
  container: {
    ...rootTheme.mainContainer,
  },
  title: {
    paddingBottom: 20,
  },
}));
export default useStyles;
