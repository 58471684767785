import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  container: {
    display: "flex",
    flexDirection: "inherit",
    alignItems: "center",
  },
  valueMetricContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "baseline",
    alignSelf: "flex-end",
    minWidth: "unset",
    width: "max-content",
  },
  valueContainer: {
    display: "flex",
    whiteSpace: "nowrap",
    alignItems: "baseline",
  },
  metricContainer: {
    display: "flex",
    alignItems: "flex-end",
    whiteSpace: "nowrap",
  },
  belowValueTextContainer: {
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
  },
  lineContainer: {
    height: "4px",
    justifySelf: "center",
  },
}));

export default useStyles;
