import { useMutation } from "@apollo/client";
import setUserChatRestoreIdMutation from "./setUserChatRestoreIdMutation";
import { useCallback } from "react";
import ISetUserChatRestoreIdResponseType from "./ISetUserChatRestoreIdResponseType";

interface ISetUserChatRestoreIdResponseResponse {
  setUserChatRestoreId: ISetUserChatRestoreIdResponseType;
}

export default function useUserChatRestoreId(): {
  mutate: (userInput: string, chatRestoreId: string) => void;
  isLoading: boolean;
  hasError: boolean;
  response?: ISetUserChatRestoreIdResponseResponse | null;
} {
  const [mutateFunction, { data, loading, error }] =
    useMutation<ISetUserChatRestoreIdResponseResponse>(
      setUserChatRestoreIdMutation,
    );
  const mutate = useCallback((userId: string, chatRestoreId: string): void => {
    mutateFunction({
      variables: { userId: userId, chatRestoreId: chatRestoreId },
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
    });
  }, []);
  return {
    mutate,
    isLoading: loading,
    hasError: error != null,
    response: data,
  };
}
