import { makeStyles } from "tss-react/mui";
import { colors } from "../../../../../theme";

const useStyles = makeStyles()({
  legendContainer: {
    alignItems: "center",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "center",
  },
  legendColorBox: {
    height: 12,
    marginLeft: 12,
    marginRight: 8,
    width: 12,
  },
  legendLabel: {
    width: "100%",
  },
  legendLabelActive: {
    color: colors.greyB24,
    textDecoration: "line-through",
  },
  legendLabelInactive: {
    color: colors.blue_base,
  },
  legendWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
});

export default useStyles;
