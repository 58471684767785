export type TTimeScaleKey =
  | "minute"
  | "hour"
  | "dayNameShort"
  | "week"
  | "monthNameShort"
  | "year"
  | "day"
  | "monthIndex";

const TimeScaleUnitMap = {
  HOUR: "hour",
  DAY: "dayNameShort",
  WEEK: "week",
  MONTH: "monthNameShort",
  YEAR: "year",
};
export type TShortDay = "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat" | "Sun";
export type TMonthShort =
  | "Jan"
  | "Feb"
  | "Mar"
  | "Apr"
  | "May"
  | "Jun"
  | "Jul"
  | "Aug"
  | "Sep"
  | "Oct"
  | "Nov"
  | "Dec";

export default TimeScaleUnitMap;
