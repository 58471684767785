import React from "react";
import YesNoDialog from "../../../../../../shared/components/yesnodialog";
import { useTranslation } from "react-i18next";
interface IProps {
  resetPassword: () => void;
  onCancel: () => void;
  isModalVisible: boolean;
}
export default function ResetPasswordModal({
  resetPassword,
  onCancel,
  isModalVisible,
}: IProps): React.ReactElement {
  const { t } = useTranslation();
  return (
    <YesNoDialog
      isModalVisible={isModalVisible}
      onClose={onCancel}
      title={t("settings.userManagement.resetPasswordDialog.title")}
      question={t("settings.userManagement.resetPasswordDialog.description")}
      onCancel={onCancel}
      onSubmit={resetPassword}
    />
  );
}
