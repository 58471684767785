import { GridColDef } from "@mui/x-data-grid";
import dompurify from "dompurify";

/**
 * Takes an array of header labels and converts it into a GridColDefinition which is required by the MUI DataGrid
 * @param headerLabels labels which are applied to each header (*except* the id one)
 * @returns GridColDefinition which includes the translations from the corresponding language file
 */

const sanitizer = dompurify.sanitize;

export default function getGridColDef(
  headerLabels: string[],
  downloadLabel: string,
): GridColDef[] {
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 0,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "image",
      headerName: headerLabels[0],
      flex: 1,
      editable: false,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell(params) {
        return (
          <img height={200} src={`data:gif;base64,${params.row.image || ""}`} />
        );
      },
    },
    {
      field: "fileName",
      headerName: headerLabels[1],
      // minWidth:330,
      // maxWidth: 550,
      flex: 2,
      editable: false,
      headerAlign: "left",
      align: "left",
      cellClassName: "docsearch_result_cell",
      // TODO If the documentation is selected, exclude the match part. It's not needed
      renderCell: (params) => {
        const pageContent = params.row.highlight?.["pages.pageContent"]?.[0];
        return (
          <div>
            <h3> {params.row.fileName}</h3>
            <a
              href={
                "https://georgdocu.blob.core.windows.net/documentation/" +
                params.row.path +
                "/" +
                params.row.fileName
              }
              target="_blank"
            >
              {downloadLabel}
            </a>
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizer(pageContent ? pageContent : "-"),
              }}
            />
          </div>
        );
      },
    },
    {
      field: "page",
      headerName: "page",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell(params) {
        const pageNo =
          params.row.inner_hits?.pages?.hits?.hits[0]?._source?.pageNo;
        return pageNo ?? "-";
      },
    },
    {
      field: "_score",
      headerName: "score",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ];
  return columns;
}
