/*
 *   VariableIdentification Reducer which stores all IdentificationTypes from backend as key-value pair. Is necessary for example for querying Performance Chart.
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { createSelector } from "reselect";

export type IGetVariableType = {
  id: string;
  variableName: string;
  type: string;
  function: string;
};

type IVariableListIdentificationType = {
  variableListName: string;
  id: string;
  type: string;
  function: string;
};
export type IVariableListIdentificationTypeFromServer = {
  machineType: {
    id: string;
    variableListIdentificationTypes: IVariableListIdentificationType[];
  };
};
export type IGetVariableIdentificationTypes = {
  machineTypeId: string;
  variableIdentificationTypeList: IGetVariableType[];
};

const initialState: IGetVariableIdentificationTypes[] = [];

export const variableIdentificationSlice = createSlice({
  name: "variableIdentificationList",
  initialState,
  reducers: {
    setVariableIdentification: (
      state,
      action: PayloadAction<IVariableListIdentificationTypeFromServer[]>,
    ): void => {
      // Clear the state and push the new variable identification types
      state.splice(0, state.length);
      if (!action.payload) return;
      state.push(
        ...action.payload.map(
          (item: IVariableListIdentificationTypeFromServer) => ({
            machineTypeId: item.machineType.id,
            variableIdentificationTypeList:
              item.machineType.variableListIdentificationTypes.map((item) => ({
                id: item.id,
                variableName: item.variableListName,
                type: item.type,
                function: item.function,
              })),
          }),
        ),
      );
    },
  },
});

export const selectVariableIdentification = (
  state: RootState,
): IGetVariableIdentificationTypes[] => state.variableIdentificationList;

export const { setVariableIdentification } =
  variableIdentificationSlice.actions;

export const getVariableObject = (machineId: string, variableName: string) =>
  createSelector(
    [selectVariableIdentification],
    (list: IGetVariableIdentificationTypes[]): IGetVariableType => {
      const emptyVariable = {
        id: "0",
        variableName: "",
        type: "",
        function: "",
      };
      const machine = list.find(
        (machine) => machine.machineTypeId === machineId,
      );
      const variable = machine?.variableIdentificationTypeList.find(
        (varType) => varType.variableName === variableName,
      );
      // Return the variable if it exists, otherwise return an empty variable
      return variable ?? emptyVariable;
    },
  );

export default variableIdentificationSlice.reducer;
