import { Layout } from "react-grid-layout";
import createLayout from "../../../../shared/components/gridPage/helper/createLayout";

export const TEXT_CARD_LAYOUT_KEY = "text_card";
export const CONTACT_CARD_1_LAYOUT_KEY = "contact_card_1";
export const CONTACT_CARD_2_LAYOUT_KEY = "contact_card_2";

const layout_LG: Layout[] = [
  { i: TEXT_CARD_LAYOUT_KEY, x: 0, y: 0, w: 12, h: 4 },
  { i: CONTACT_CARD_1_LAYOUT_KEY, x: 0, y: 0, w: 6, h: 6 },
  { i: CONTACT_CARD_2_LAYOUT_KEY, x: 6, y: 0, w: 6, h: 6 },
];

const layoutsMS: Layout[] = [
  { i: TEXT_CARD_LAYOUT_KEY, x: 0, y: 0, w: 12, h: 4 },
  { i: CONTACT_CARD_1_LAYOUT_KEY, x: 0, y: 7, w: 6, h: 6 },
  { i: CONTACT_CARD_2_LAYOUT_KEY, x: 6, y: 12, w: 6, h: 6 },
];

const layoutsXS: Layout[] = [
  { i: TEXT_CARD_LAYOUT_KEY, x: 0, y: 0, w: 12, h: 5 },
  { i: CONTACT_CARD_1_LAYOUT_KEY, x: 0, y: 6, w: 12, h: 6 },
  { i: CONTACT_CARD_2_LAYOUT_KEY, x: 0, y: 12, w: 12, h: 6 },
];

export type TResizeConfig = {
  [key in TGridLayoutContactKEY]: number;
};
type TGridLayoutContactKEY = "text_card" | "contact_card_1" | "contact_card_2";

// export default CONTACT_VIEW_LAYOUTS;
export default function getLayoutInfo(resizeConfig: TResizeConfig) {
  return createLayout<TResizeConfig>(
    resizeConfig,
    layout_LG,
    layout_LG,
    layoutsMS,
    layoutsMS,
    layoutsXS,
    layoutsXS,
  );
}
