import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import drawerReducer from "./reducers/drawerReducer";
import machineListReducer from "./reducers/machineListReducer";
import machineSelectReducer from "./reducers/machineSelectReducer";
import timeSelectReducer from "./reducers/timeSelectReducer";
import indicatorReducer from "./reducers/indicatorReducer";
import docSearchResult from "./reducers/serviceReducer";
import tokenReducer from "./reducers/tokenReducer";
import oeeMachineListReducer from "./reducers/oeeMachineListReducer";
import stackLightListReducer from "./reducers/stackLightListReducer";
import downtimeReducer from "./reducers/downtimeReducer";
import variableIdentificationReducer from "./reducers/variableIdentificationReducer";
import serviceWorkerTokenReducer from "./reducers/serviceWorkerTokenReducer";

export const store = configureStore({
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware();
  },
  reducer: {
    drawerState: drawerReducer,
    indicatorList: indicatorReducer,
    machineList: machineListReducer,
    machineSelect: machineSelectReducer,
    timeSelection: timeSelectReducer,
    Service: docSearchResult,
    token: tokenReducer,
    oeeMachineList: oeeMachineListReducer,
    stackLightList: stackLightListReducer,
    downtimeList: downtimeReducer,
    variableIdentificationList: variableIdentificationReducer,
    serviceWorkerToken: serviceWorkerTokenReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
