import TScale from "../../../api/time/TScale";

export type TMode = "date" | "datetime" | "time" | "fulldatetime";

function getDateTimeFormat(
  lang: string | undefined,
  dateTimeMode: TMode,
): string {
  const isShowingDate = ["date", "datetime", "fulldatetime"].includes(
    dateTimeMode,
  );
  const isShowingTime = ["time", "datetime", "fulldatetime"].includes(
    dateTimeMode,
  );
  const isFullTime = dateTimeMode === "fulldatetime";
  if (lang === "de") {
    return (
      (isShowingTime ? (isFullTime ? " HH:mm:ss " : " HH:mm ") : "") +
      (isShowingDate ? "dd DD.MM.yyyy" : "")
    );
  } else {
    return (
      (isShowingDate ? "dd YY/MM/DD" : "") +
      (isShowingTime ? (isFullTime ? " hh:mm:ss a" : " hh:mm a") : "")
    );
  }
}

export default function getFormatDependingOnLang(
  lang: string | undefined,
  dateTimeMode: TMode,
  scaleParam?: TScale,
): string {
  const scale = scaleParam != null ? scaleParam : "days";
  switch (scale) {
    case "WEEKS":
      // use iso WW instead of non iso ww
      return "WW";
    case "MONTHS":
      return "MMMM YY";
    case "YEARS":
      return "YYYY";
    default:
      return getDateTimeFormat(lang, dateTimeMode);
  }
}
