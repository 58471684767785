import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import useClaims from "../../auth/hooks/useClaims";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from "../../auth/microsoftApi/msalConfig";
import * as AbsintheSocket from "@absinthe/socket";
import { createAbsintheSocketLink } from "@absinthe/socket-apollo-link";
import { Socket as PhoenixSocket } from "phoenix";
import Cookies from "js-cookie";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  split,
  ApolloLink,
  from,
  DefaultOptions,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { GraphQLFormattedError } from "graphql/error/GraphQLError";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { hasSubscription } from "@jumpn/utils-graphql";
import { setContext } from "@apollo/client/link/context";
import { setToken } from "../../redux/reducers/tokenReducer";
import { useDispatch } from "react-redux";
import { logErrorToSentry } from "../../useSentry";
import { CustomWarning } from "../../helper/exception/warning";

interface IProps {
  children: React.ReactElement;
}

export const domainUrlMapping: Record<string, string> = {
  stage: "stage.mygeorg.com",
  dev: "dev.mygeorg.com",
  default: "mygeorg.com",
};

export default function Apollo({ children }: IProps): React.ReactElement {
  const { instance, inProgress } = useMsal();
  const claims = useClaims();
  const [domain, setDomain] = useState<string>("mygeorg.com");
  const [backend, setBackend] = useState<string>("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (claims != null) {
      const BACKEND_URL = claims.extension_tenant;
      setDomain(domainUrlMapping[BACKEND_URL] || domainUrlMapping.default);
      setBackend(BACKEND_URL);
    }
  }, [claims]);

  const AsyncTokenLookup = async () => {
    const account = instance.getActiveAccount();
    if (account && inProgress === "none") {
      try {
        const result = await instance.acquireTokenSilent({
          ...loginRequest,
          account: account,
        });
        if (result.idToken.length === 0) {
          return instance.logoutPopup();
        } else {
          return result.idToken;
        }
      } catch (err) {
        if (err instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return instance.acquireTokenRedirect(loginRequest);
        }
      }
    }
    if (instance.getAllAccounts.length == 0 && account) {
      return instance.logoutPopup();
    }
    return null;
  };

  const httpLink = createHttpLink({
    uri: `https://${domain}/${backend}/api/graphql`,
  });

  const phoenixSocket = new PhoenixSocket(`wss://${domain}/${backend}/socket`, {
    params: () => {
      if (Cookies.get("token")) {
        return { token: Cookies.get("token") };
      } else {
        return {};
      }
    },
  });
  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = await AsyncTokenLookup();
    // save token in redux
    if (typeof token === "string") {
      dispatch(setToken(token));
    } else {
      dispatch(setToken(""));
    }
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  // Create an error link to capture and report ApolloErrors to Sentry
  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (Array.isArray(graphQLErrors)) {
      graphQLErrors.forEach((error: GraphQLFormattedError) => {
        const { message, locations, path, extensions } = error;
        const errorDetails = extensions?.detail || message;
        logErrorToSentry(new CustomWarning(`GraphQL: ${errorDetails}`), {
          level: "warning",
          tags: {
            operationName: operation.operationName,
          },
          extra: {
            locations,
            path,
            query: operation.query.loc?.source.body,
            variables: operation.variables,
          },
        });
      });
    } else if (graphQLErrors) {
      logErrorToSentry(
        new CustomWarning(`GraphQL: ${JSON.stringify(graphQLErrors)}`),
        {
          level: "warning",
          tags: {
            operationName: operation.operationName,
          },
          extra: {
            query: operation.query.loc?.source.body,
            variables: operation.variables,
          },
        },
      );
    }

    if (networkError) {
      logErrorToSentry(new CustomWarning(networkError.message), {
        level: "warning",
        tags: {
          operationName: operation.operationName,
        },
        extra: {
          query: operation.query.loc?.source.body,
          variables: operation.variables,
        },
      });
    }
  });

  const absintheSocket = AbsintheSocket.create(phoenixSocket);
  const websocketLink = createAbsintheSocketLink(absintheSocket);
  const splitLink = split(
    (operation) => hasSubscription(operation.query),
    // attention!
    // this conversion might be a danger work-around
    // some props are missing from websocketLink (split, concat, onError, setOnError)
    websocketLink as unknown as ApolloLink,
    httpLink,
  );
  const cache = new InMemoryCache();

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  };
  const client = new ApolloClient({
    cache,
    link: from([errorLink, authLink, splitLink]),
    defaultOptions: defaultOptions,
  });
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
