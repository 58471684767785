import React, { useContext, useMemo } from "react";
import { IJobTableFormat } from "../../../../../../api/jobs/interfaces/IJobSettings";
import GeorgDataGrid from "../../../../../../shared/components/georgDataGrid";
import getColumnConfig from "./getColumnConfig";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { GridColumnVisibilityModel, GridSortModel } from "@mui/x-data-grid";
import { getTimezone } from "../../../../../../helper/time/timezone";
import GeorgStorageContext from "../../../../../../context/GeorgStorageContext";

interface IProps {
  jobs: IJobTableFormat[];
  onRemoveClick: (jobId: string) => void;
  onChangeDone: (jobId: string, newIsDone: boolean) => void;
  onEdit: (jobId: string) => void;
  onAbort: (jobId: string, newIsAbort: boolean) => void;
  isMutationLoading: boolean;
  sortModel: GridSortModel;
  onSortChange: (newSortModel: GridSortModel) => void;
  columnVisibilityModel?: GridColumnVisibilityModel;
  onColumnVisibilityModelChange?: (
    newGridColumnVisibilityModel: GridColumnVisibilityModel,
  ) => void;
  isLoading: boolean;
}
export function JobsAdminTable({
  jobs,
  onRemoveClick,
  onChangeDone,
  onEdit,
  onAbort,
  isMutationLoading,
  sortModel,
  onSortChange,
  columnVisibilityModel,
  onColumnVisibilityModelChange,
  isLoading,
}: IProps): React.ReactElement {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();
  const timezone = getTimezone();
  const { isTooltipEnabled } = useContext(GeorgStorageContext);
  const columns = useMemo(() => {
    return getColumnConfig(
      t,
      i18n.language,
      classes,
      onRemoveClick,
      onChangeDone,
      onEdit,
      onAbort,
      isMutationLoading,
      timezone,
    );
  }, [
    classes,
    onRemoveClick,
    onChangeDone,
    onEdit,
    onAbort,
    isMutationLoading,
  ]);
  const isDataLoading = useMemo(() => isLoading, [isLoading]);

  return (
    <GeorgDataGrid
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={onColumnVisibilityModelChange}
      disableColumnMenu={false}
      columns={columns}
      rows={jobs}
      onSortChange={onSortChange}
      sortModel={sortModel}
      isLoading={isDataLoading}
      isTooltipEnabled={isTooltipEnabled}
    />
  );
}
export default React.memo(JobsAdminTable);
