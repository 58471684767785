import moment from "moment";

export enum EErrorState {
  EMPTY_DATE = "EMPTY_DATE",
  HAS_DATE_ERROR = "HAS_DATE_ERROR",
  TIME_IN_PAST = "TIME_IN_PAST",
  NO_ERROR = "NO_ERROR",
}

export function validateTime(
  selectedDate: Date,
  timezone: string,
  hasDateError = false,
  isPastAllowed = true,
): EErrorState {
  let error;
  const momentselectedDate = moment.tz(selectedDate, timezone);
  const momentYesterdy = moment.tz(timezone).subtract(1, "day"); // Time should not be before 24h from the current date
  const isSelectedTimeInPast = momentYesterdy.diff(momentselectedDate) > 0;
  if (hasDateError) {
    error = EErrorState.HAS_DATE_ERROR;
  } else if (!isPastAllowed && isSelectedTimeInPast) {
    error = EErrorState.TIME_IN_PAST;
  } else {
    error = EErrorState.NO_ERROR;
  }
  return error;
}
