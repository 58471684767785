import React, { useContext, useMemo } from "react";
import { WithTooltip } from "../index";
import GeorgStorageContext from "../../context/GeorgStorageContext";
import { ITooltipInfo, TPlacement } from "../withTooltip";

interface IProps {
  children: JSX.Element;
  tooltipInfo?: ITooltipInfo;
  tooltipContent?: React.ReactNode;
  smallTooltip?: boolean;
  centerContent?: boolean;
  noOverflow?: boolean;
  placement?: TPlacement;
  isFullWidthContainer?: boolean;
  forceEnable?: boolean;
  hasBulletPoints?: boolean;
  isFirstHasBulletPoint?: boolean;
  isHidden?: boolean;
  icon?: React.ReactElement;
  isContainerRequired?: boolean;
}

function WithToggleableTooltip(props: IProps): React.ReactElement {
  const { tooltipContent, children, forceEnable, isHidden = false } = props;
  const { label } = props.tooltipInfo || {};
  const { isTooltipEnabled } = useContext(GeorgStorageContext);
  const isTooltipShown = !isHidden && isTooltipEnabled;
  const isTooltipVisible =
    forceEnable !== undefined ? forceEnable : isTooltipShown;

  const shouldRenderTooltip = useMemo(() => {
    return isTooltipVisible && ((label && label.length > 0) || tooltipContent);
  }, [isTooltipVisible, label, tooltipContent]);

  // Update the isHidden prop
  const updatedProps = {
    ...props,
    isHidden: !shouldRenderTooltip,
  };

  return <WithTooltip {...updatedProps}>{children}</WithTooltip>;
}
export default React.memo(WithToggleableTooltip);
