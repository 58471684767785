import { useEffect, useState } from "react";
import { EApiState } from "../../../../../../../api";
import IVariable from "../../api/IVariable";
import { VARIABLES } from "../../api/MOCKS";

export function useQueryVariables(): { variables; apiState } {
  const [apiState, setApiState] = useState(EApiState.LOADING);
  const [variables, setVariables] = useState<IVariable[]>([]);
  useEffect(() => {
    setApiState(EApiState.LOADING);
    const t = setTimeout(() => {
      setVariables(VARIABLES);
      setApiState(EApiState.OK);
    }, 600);
    return () => {
      clearTimeout(t);
    };
  }, []);
  return { apiState, variables };
}
