import React from "react";
import { MenuItem, TextField } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useStyles from "./styles";

type Colors = "primary" | "secondary";

type MarginProps = "none" | "normal" | "dense";

type SelectOption = {
  label: string | React.ReactElement;
  value: string | number;
};

type Size = "medium" | "small";

type StyleObject = {
  helperText?: string;
  menu?: string;
  rootInputStyles?: string;
  selectIcon?: string;
  textInputStyles?: string;
};
// TODO Evaluate if this interface really must be so huge
interface IProps {
  autoFocus?: boolean;
  color?: Colors;
  disabled?: boolean;
  disableUnderline?: boolean;
  endAdornment?: React.ReactElement;
  error?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  InputProps?: any;
  label?: string | React.ReactElement;
  margin?: MarginProps;
  multiline?: boolean;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props?: any;
  select?: boolean;
  selectDisplayEmpty?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectIcon?: any;
  selectOptions?: SelectOption[];
  size?: Size;
  startAdornment?: React.ReactElement;
  styles?: StyleObject;
  type?: string;
  value?: string | number | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  variant?: any; // filled, outlined or normal. Type is not working here.
}

//------------------------------------------------------------------------------------------------------------
// Component.
export default function ITextInput({
  autoFocus,
  color,
  disabled,
  disableUnderline,
  endAdornment,
  error,
  fullWidth = true,
  helperText,
  InputProps,
  label,
  margin = "normal",
  multiline,
  name,
  type,
  onChange,
  props,
  select,
  selectDisplayEmpty = true,
  selectIcon = KeyboardArrowDownIcon,
  selectOptions,
  size,
  startAdornment,
  styles = {
    helperText: "",
    menu: "",
    rootInputStyles: "",
    selectIcon: "",
    textInputStyles: "",
  },
  value,
  variant = "standard",
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  if (select) {
    return (
      <TextField
        autoFocus={autoFocus}
        color={color}
        classes={{ root: styles.rootInputStyles }}
        disabled={disabled}
        fullWidth={fullWidth}
        id={name}
        InputProps={{
          disableUnderline,
          endAdornment,
          ...InputProps,
          startAdornment,
        }}
        label={label}
        margin="none"
        name={name}
        area-label={name}
        onChange={onChange}
        select={select}
        SelectProps={{
          classes: {
            icon: styles.selectIcon,
            select: styles.textInputStyles,
            root: styles.rootInputStyles,
          },
          IconComponent: selectIcon,
          disableUnderline,
          displayEmpty: selectDisplayEmpty,
          defaultValue: "None",
        }}
        size={size}
        type={type}
        value={value}
        variant={variant}
        {...props}
      >
        {selectOptions?.map((item) => {
          if (item.value === "")
            return (
              <MenuItem
                className={styles.menu}
                key={item.value + item.label}
                value={item.value}
              >
                <>{item.label}</>
              </MenuItem>
            );

          return (
            <MenuItem
              className={classes.listElement}
              color={"secondary"}
              key={item.value}
              value={item.value}
            >
              <>{item.label}</>
            </MenuItem>
          );
        })}
      </TextField>
    );
  }

  return (
    <TextField
      autoComplete={name}
      autoFocus={autoFocus}
      classes={{ root: styles.rootInputStyles }}
      color={color}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      FormHelperTextProps={{ className: styles.helperText }}
      helperText={helperText}
      id={name}
      InputProps={{
        ...InputProps,
        disableUnderline,
        classes: { input: styles.textInputStyles },
      }}
      inputProps={{ className: styles.textInputStyles }}
      label={label}
      margin={margin}
      multiline={multiline}
      name={name}
      area-label={name}
      onChange={onChange}
      type={type}
      size={size}
      variant={variant}
      {...props}
    />
  );
}
