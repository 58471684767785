import React from "react";
import useStyles from "./styles";
import ScaleLoader from "react-spinners/ScaleLoader";

export default function LoginProgress(): React.ReactElement {
  const { classes } = useStyles();
  return (
    <div className={classes.loginProgressWrapper}>
      <div>
        <div className={classes.spinnerBox}>
          <ScaleLoader />
        </div>
      </div>
    </div>
  );
}
