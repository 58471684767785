/*
 *
 *   SecondaryActionItem Component
 *
 */
import React from "react";

//------------------------------------------------------------------------------------------------------------
// Interfaces & Types.
interface IProps {
  content: React.ReactElement;
  onHover?: (event: React.MouseEvent) => void;
}

//------------------------------------------------------------------------------------------------------------
// Component.
export default function SecondaryActionItem({
  content,
  onHover,
}: IProps): React.ReactElement {
  return (
    <div onMouseEnter={onHover} onMouseLeave={onHover}>
      {content}
    </div>
  );
}
