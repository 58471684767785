import { ESortBy, ISort } from "../index";
import { EOrder } from "./sortByMachineKey";

export default function getNewSort(
  actualSortBy: ESortBy,
  toggleIfSortBy: ESortBy,
  order: EOrder,
): ISort {
  if (actualSortBy === toggleIfSortBy) {
    if (order === EOrder.ASC) {
      return { sortBy: actualSortBy, order: EOrder.DESC };
    } else {
      return { sortBy: ESortBy.NO_SORT, order: EOrder.ASC };
    }
  } else {
    return { sortBy: toggleIfSortBy, order: EOrder.ASC };
  }
}
