import React from "react";
import { Typography, Menu, MenuItem } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import { Variant } from "@mui/material/styles/createTypography";
import { TypographyPropsVariantOverrides } from "@mui/material/Typography/Typography";
import WithToggleableTooltip from "../../../components/withToggableTooltip";

//------------------------------------------------------------------------------------------------------------
// Interfaces & Types.
type Colors =
  | "inherit"
  | "initial"
  | "primary"
  | "secondary"
  | "textPrimary"
  | "textSecondary"
  | "error"
  | undefined;

type Variants = OverridableStringUnion<
  Variant | "inherit",
  TypographyPropsVariantOverrides
>;

interface IItems {
  id: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Icon: any;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: any;
  disabled?: boolean;
  isHighlighted?: boolean;
}

interface IStyleObject {
  menuItems?: string;
  menuItemLabel?: string;
  menuPanel?: string;
}

interface Props {
  anchorEl: null | HTMLElement;
  items: IItems[];
  labelColor?: Colors;
  labelVariant?: Variants;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose: () => void;
  styles: IStyleObject;
  itemDisabledTooltipText?: string;
}

//------------------------------------------------------------------------------------------------------------
// Component.
export default function IMenu({
  anchorEl,
  items,
  labelVariant,
  onClose,
  styles = {
    menuItems: "",
    menuItemLabel: "",
  },
  itemDisabledTooltipText = "",
}: Props): React.ReactElement {
  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
      classes={{
        paper: styles.menuPanel,
      }}
    >
      {items.map((item, index) => {
        return (
          <MenuItem
            onClick={() => {
              item.onClick();
              onClose();
            }}
            className={styles.menuItems}
            key={index}
            disabled={item.disabled ? item.disabled : false}
            selected={item.isHighlighted ?? false}
          >
            <WithToggleableTooltip
              tooltipInfo={{
                label: item.disabled ? itemDisabledTooltipText : "",
              }}
              smallTooltip={true}
              // noOverflow={true}
              centerContent={false}
              placement="bottom-end"
              isFullWidthContainer={true}
            >
              <>
                {item.Icon}
                <Typography
                  className={styles.menuItemLabel}
                  color={"primary"}
                  variant={labelVariant}
                >
                  {item.label}
                </Typography>
              </>
            </WithToggleableTooltip>
          </MenuItem>
        );
      })}
    </Menu>
  );
}
