import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  skeletonText: {
    position: "absolute",
    top: "75%",
    left: "47%",
    transform: "translate(-50%, -50%)",
  },
}));
export default useStyles;
