import { useCallback, useState } from "react";
import { EApiState } from "../../../../../../../api";

export type TRemoveServiceOrderMutation = (serviceOrderId: string) => void;
export function useRemoveServiceOrderSettingsMutation(): {
  removeServiceStateMutation: TRemoveServiceOrderMutation;
  apiState: EApiState;
} {
  const [apiState, setApiState] = useState<EApiState>(EApiState.OK);
  const removeServiceStateMutation = useCallback((serviceOrderId: string) => {
    setApiState(EApiState.LOADING);
    setTimeout(() => {
      alert(
        `Todo: Implement remove service order mutation for id ${serviceOrderId}`,
      );
      setApiState(EApiState.OK);
    }, 400);
  }, []);

  return { removeServiceStateMutation, apiState };
}
