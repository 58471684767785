import React, { useCallback, useEffect, useRef, useState } from "react";
// Custom imports.
import FreshChat from "react-freshchat-eu";
import { IClaims } from "../../auth/hooks/useClaims";
import useUserChatRestoreId from "./graphql/useUserChatRestoreId";
import { logErrorToSentry } from "../../useSentry";
interface IProps {
  adapterLocale: string;
  claims: IClaims | null;
}

export default function IFreshChat({
  adapterLocale,
  claims,
}: IProps): React.ReactElement {
  const [chatRestoreId, setChatRestoreId] = useState<string | null>(
    claims?.extension_chatRestoreId || null,
  );
  const [initialiseWidget, setInitialiseWidget] = useState(true);
  const freshChatRef = useRef<FreshChat | null>(null);
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLoading: isUserChatResetIdLoading,
    mutate: mutateUserChatResetId,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    response: responseUserChatResetId,
    hasError: hasUserChatResetIdError,
  } = useUserChatRestoreId();

  useEffect(() => {
    if (!isUserChatResetIdLoading && hasUserChatResetIdError) {
      // eslint-disable-next-line no-console
      console.error("Error while resetting user chat restore id");
    }
  }, [hasUserChatResetIdError, isUserChatResetIdLoading]);

  useEffect(() => {
    if (
      claims &&
      !claims.extension_chatRestoreId &&
      chatRestoreId != null &&
      claims.sub &&
      claims.sub.length > 0
    ) {
      if (claims.sub) {
        mutateUserChatResetId(claims.sub, chatRestoreId);
      }
    }
  }, [claims, chatRestoreId]);

  const onInit = useCallback(
    (widget) => {
      widget.on("user:created", (resp) => {
        freshChatRef.current = widget;
        const status = resp && resp.status;
        const data = resp && resp.data;
        if (status === 200 && claims) {
          if (data && data.restoreId) {
            setChatRestoreId(data.restoreId);
            const restoreId = data.restoreId;
            claims.extension_chatRestoreId = restoreId.toString();
          }
        }
      });
      widget.on("user:cleared", () => {
        widget.destroy(true);
      });
      widget.on("widget:destroyed", () => {
        setInitialiseWidget(false); // Unmount the FreshChat component
        setTimeout(() => setInitialiseWidget(true), 0);
      });
      if (!widget.user.isExists) {
        widget.user.create();
      } else {
        widget.user.get((result) => {
          if (result.status !== 200) {
            // Use `widget` instead of `window.fcWidget`
            widget.user.setProperties({
              email: claims != null ? claims.email : "anonymous",
              last_name: claims != null ? claims.given_name : "anonymous",
              first_name: claims != null ? claims.family_name : "anonymous",
              company_name:
                claims != null ? claims.extension_companyName : "anonymous",
              status: "Active",
              restoreId: chatRestoreId,
              plan: "Estate",
              /* TODO: FreshChat should change locale when it's changed by the user
                 and not only when the widget is initialized.
                 Currently the locale can be changed by page-refresh*/
              // locale: adapterLocale,
            });
          }
        });
      }
    },
    [freshChatRef.current],
  );

  useEffect(() => {
    clearAndDestroyExistingWidget();
  }, [adapterLocale]);

  const clearAndDestroyExistingWidget = () => {
    if (freshChatRef.current && freshChatRef.current.isInitialized() == true) {
      freshChatRef.current.user.isExists().then(
        function (data) {
          /** if user exists first clear the user and
           * in user:cleared callback destroy the widget.
           * if user doesn't exists, then directly destroy the widget.
           */
          if (data.data && data.success) {
            freshChatRef.current.user.clear();
          } else {
            freshChatRef.current.destroy();
          }
        },
        function () {
          logErrorToSentry(new Error("Error fetching user in FreshChat"));
        },
      );
    }
  };

  return initialiseWidget ? (
    <FreshChat
      token={"8f993e8f-560c-4df5-87de-4f7edbffe32c"}
      email={claims != null ? claims.email : "anonymous"}
      externalId={claims != null ? claims.sub : "anonymous"}
      restoreId={chatRestoreId}
      firstName={claims != null ? claims.given_name : "anonymous"}
      lastName={claims != null ? claims.family_name : "anonymous"}
      locale={adapterLocale}
      onInit={onInit}
    />
  ) : (
    <></>
  );
}
