import React from "react";
import { Grid2 as Grid, Link } from "@mui/material";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface IProps {
  keyLabel: string;
  label: string;
  link: string;
  styles?: StyleObject;
  icon?: JSX.Element;
}

type StyleObject = {
  linkStyle?: string;
  gridStyle?: string;
};

export default function LinkCard({
  keyLabel,
  label,
  link,
  styles = {
    linkStyle: "",
    gridStyle: "",
  },
  icon,
}: IProps): React.ReactElement {
  return (
    <Grid key={keyLabel} size={12} className={styles.gridStyle}>
      <>
        {
          <Link href={link} className={styles.linkStyle} target="_blank">
            {label}
          </Link>
        }
        {icon}
      </>
    </Grid>
  );
}
