import { makeStyles } from "tss-react/mui";
import { colors } from "../../shared/theme";

const useStyles = makeStyles()((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
  },
  button: {
    borderLeftWidth: 0.5,
    borderLeftStyle: "solid",
    borderLeftColor: colors.grey_base,
    textAlgin: "center",
    width: 160,
  },
  buttonLoginLabel: {
    marginLeft: 10,
  },
  menuItems: {
    padding: 10,
    width: 160,
  },
  menuItemLabel: {
    marginLeft: 20,
  },
  root: {
    height: 63,
    "& > header": {
      height: 63,
      "& > div": {
        height: 63,
        minHeight: 63,
        borderBottom: `1px solid ${colors.grey_base}`,
      },
    },
  },
  gridContainer: {
    "&.MuiGrid2-root": {
      flex: 1,
    },
  },
  dateTimeContainer: {
    width: "max-content",
  },
  timeIcon: {
    alignSelf: "center",
    height: 18,
    marginRight: 5,
    width: 18,
  },
  timeContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  timeLabel: {
    padding: 2,
  },
}));

export default useStyles;
