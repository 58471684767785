import { TFunction } from "i18next";
import TScale from "../../../api/time/TScale";
import { TMonthShort, TTimeScaleKey } from "../../../api/time/TimeScaleUnitMap";

/**
 * Pads a number with leading zeros if necessary.
 */
const padZero = (value: number | string): string =>
  value.toString().padStart(2, "0");

// The hour format should always be HH:30 in line chart
function formatHour(
  hourUnformatted: string,
  minuteUnformatted: string,
): string {
  return `${padZero(hourUnformatted)}:${padZero(minuteUnformatted)}`;
}

// Old unused function
/*export function formatDay(t: TFunction, dayNameShort: TShortDay): string {
  return t(`common.time.shortDay.${dayNameShort}`);
}*/

export function formatWeek(t: TFunction, calendarWeekIdx: string): string {
  return `${t("common.time.calendarWeekUnit")} ${calendarWeekIdx}`;
}

export function formatMonth(t: TFunction, monthShort: TMonthShort): string {
  return t(`common.time.monthIndex.${monthShort}`);
}

function formatDate(lang: string, day: string, monthIndex: string): string {
  const dayStr = day.length === 1 ? `0${day}` : day;
  const monthStr = monthIndex.length === 1 ? `0${monthIndex}` : monthIndex;
  if (lang === "de") {
    return `${dayStr}.${monthStr}`;
  } else {
    return `${monthStr}/${dayStr}`;
  }
}

function createDayLabel(
  scale: TScale,
  lang: string,
  isPeriodOnSameDay: boolean,
  value: { [key in TTimeScaleKey]: string },
): string {
  const hourLabel =
    value.hour != "null" ? `${formatHour(value.hour, value.minute)}` : "";
  const label = isPeriodOnSameDay
    ? hourLabel
    : `${formatDate(
        lang,
        value.day,
        // ugly cast, because value.monthIndex starts with 0 and we want to
        // transform this index into display name
        `${parseInt(value.monthIndex, 10) + 1}`,
      )} ${hourLabel}`;
  return label;
}
export default function createLabelForScale(
  t: TFunction,
  lang: string,
  scale: TScale,
  isPeriodOnSameDay: boolean,
  value: { [key in TTimeScaleKey]: string },
): string {
  switch (scale) {
    case "YEARS":
      return value.year;
    case "MONTHS":
      return `${formatMonth(t, value.monthNameShort as TMonthShort)}`;
    case "WEEKS": // calendar week
      return `${formatWeek(t, value.week)}`;
    case "DAYS": // dayNameShort engl abbrev. e.g. Wed
      return createDayLabel(scale, lang, isPeriodOnSameDay, value);
    case "HOURS": // hour 24 format
      return `${createDayLabel(scale, lang, isPeriodOnSameDay, value)}`;
    default:
      return "";
  }
}
