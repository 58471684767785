import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  logoContainer: {
    cursor: "pointer",
    maxWidth: 220,
    minWidth: 180,
    width: "100%",
    textAlign: "center",
  },
  logo: {
    width: "45%",
    paddingRight: 16,
  },
});

export default useStyles;
