import React, { useContext, useLayoutEffect, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DataSaverOffOutlinedIcon from "@mui/icons-material/DataSaverOffOutlined";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import useStyles from "./styles";
import { selectDrawer } from "../../../../redux/reducers/drawerReducer";
import { selectMachine } from "../../../../redux/reducers/machineSelectReducer";
import SimpleEntry from "./components/simpleEntry";
import MultiEntries from "./components/multiEntries";
import FooterButtons from "./components/footerButtons";
import useClaims from "../../../../auth/hooks/useClaims";
import SearchIcon from "@mui/icons-material/Search";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import GeorgStorageContext from "../../../../context/GeorgStorageContext";
import { TaskOutlined } from "@mui/icons-material";
import { useFlag, useUnleashContext } from "@unleash/proxy-client-react";

interface IProps {
  mobile: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

// eslint-disable-next-line complexity
export default function DrawerList({
  mobile,
  onClick,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [path, setPath] = useState(window.location.pathname);
  const open = useSelector(selectDrawer);
  const selectedMachine = useSelector(selectMachine);
  const claims = useClaims();
  const location = useLocation();

  useLayoutEffect(() => {
    setPath(location.pathname);
    // TODO: add any check for (timezone change) if needed.
  }, [location.key]);

  const isSettingsMachine = path.startsWith("/settings/machine");
  const isSettingsUser = path.startsWith("/settings/user");
  const isDashboardShopfloor = path.startsWith("/dashboards/shopfloor");
  const isDashboardMachine = path.startsWith("/dashboards/machine");
  const isServiceDocumentation = path.startsWith("/service/documentation");
  const isServiceParts = path.startsWith("/service/parts");
  const isServiceContact = path.startsWith("/service/contact");
  const isHome = path.startsWith("/home") || path === "/";
  const isManualState = path.startsWith("/manualstate");
  const isTaskManager = path.startsWith("/taskmanager");
  const { isPreviewEnabled } = useContext(GeorgStorageContext);

  const isMachineryEnabled = useFlag("killswitch_machinery");
  const isDashboardsEnabled = useFlag("killswitch_dashboards");
  const isDashboardsShopfloorEnabled = useFlag(
    "killswitch_dashboards_shopfloor",
  );
  const isDashboardsMachineEnabled = useFlag("killswitch_dashboards_machine");
  const isTaskmanagerEnabled = useFlag("killswitch_taskmanager");
  const isManualstateEnabled = useFlag("killswitch_manualstate");
  const isServiceEnabled = useFlag("killswitch_service");
  const isServiceDocumentationEnabled = useFlag(
    "killswitch_service_documentation",
  );
  const isServicepartsEnabled = useFlag("killswitch_service_serviceparts");
  const isServiceContactEnabled = useFlag("killswitch_service_contact");
  const isSettingsEnabled = useFlag("killswitch_settings");
  const isSettingsUserEnabled = useFlag("killswitch_settings_user");
  const isSettingsMachineEnabled = useFlag("killswitch_settings_machine");

  //dynamicupdate of featureflag context
  const updateContext = useUnleashContext();
  useEffect(() => {
    const { extension_role, extension_tenant } = claims || {
      extension_role: "USER",
      extension_tenant: "",
    };
    // Update context with properties map
    updateContext({
      properties: {
        userrole: extension_role,
        tenantID: extension_tenant,
        isPreviewEnabled: isPreviewEnabled.toString(),
      },
    });
  }, [claims]);
  //dynamicupdate of featureflag context
  return (
    <div className={classes.container}>
      <div>
        <SimpleEntry
          icon={<HomeOutlinedIcon color={isHome ? "action" : "primary"} />}
          isActive={isHome}
          label={t("navigations.startPage")}
          routePath={"/home"}
          isOpen={open}
        />
        {isMachineryEnabled && (
          <SimpleEntry
            icon={
              <LayersOutlinedIcon
                color={path === "/machinery" ? "action" : "primary"}
              />
            }
            isActive={path === "/machinery"}
            label={t("navigations.machinery")}
            routePath={"/machinery"}
            isOpen={open}
          />
        )}
        {isDashboardsEnabled && (
          <MultiEntries
            icon={<DashboardIcon color="primary" />}
            label={t("navigations.dashboards")}
            isOpen={open}
            isActive={isDashboardShopfloor || isDashboardMachine}
          >
            <>
              {isDashboardsShopfloorEnabled && (
                <SimpleEntry
                  icon={
                    <LayersOutlinedIcon
                      color={isDashboardShopfloor ? "action" : "primary"}
                    />
                  }
                  isActive={isDashboardShopfloor}
                  label={t("navigations.dashboards_shopfloor")}
                  routePath={"/dashboards/shopfloor"}
                  isOpen={open}
                  isSubItem={true}
                />
              )}
              {isDashboardsMachineEnabled && (
                <SimpleEntry
                  icon={
                    <DataSaverOffOutlinedIcon
                      color={isDashboardMachine ? "action" : "primary"}
                    />
                  }
                  isActive={isDashboardMachine}
                  label={t("navigations.dashboards_machine")}
                  routePath={`/dashboards/machine${
                    selectedMachine ? `/${selectedMachine}` : ""
                  }/oee`}
                  isOpen={open}
                  isSubItem={true}
                />
              )}
            </>
          </MultiEntries>
        )}
        {isManualstateEnabled && (
          // {isPreviewEnabled && (
          <SimpleEntry
            icon={
              <LayersOutlinedIcon
                color={path === "/manualstate" ? "action" : "primary"}
              />
            }
            isActive={isManualState}
            label={t("navigations.manualInput")}
            routePath={`/manualstate/machine${
              selectedMachine ? `/${selectedMachine}` : ""
            }/manualstate`}
            isOpen={open}
          />
          // )}
        )}
        {isTaskmanagerEnabled && (
          <SimpleEntry
            icon={
              <TaskOutlined
                color={path === "/taskmanager" ? "action" : "primary"}
              />
            }
            isActive={isTaskManager}
            label={t("navigations.taskmanager")}
            routePath={`/taskmanager/machine${
              selectedMachine ? `/${selectedMachine}` : ""
            }${isPreviewEnabled ? `/serviceorders` : `/jobmanagement`}`}
            isOpen={open}
          />
        )}
        {isServiceEnabled && (
          <MultiEntries
            icon={<SearchIcon color="primary" />}
            label={t("navigations.service")}
            isOpen={open}
            isActive={
              isServiceDocumentation || isServiceContact || isServiceParts
            }
          >
            <>
              {isServiceDocumentationEnabled && (
                <SimpleEntry
                  icon={
                    <ArticleOutlinedIcon
                      color={isServiceDocumentation ? "action" : "primary"}
                    />
                  }
                  isActive={isServiceDocumentation}
                  label={t("navigations.service_documentation")}
                  routePath={`/service/documentation/machine${
                    selectedMachine ? `/${selectedMachine}` : ""
                  }/doctype`}
                  isOpen={open}
                  isSubItem={true}
                />
              )}
              {isServicepartsEnabled && (
                //{isPreviewEnabled && (
                <SimpleEntry
                  icon={
                    <ShoppingBasketOutlinedIcon
                      color={isServiceParts ? "action" : "primary"}
                    />
                  }
                  isActive={isServiceParts}
                  label={t("navigations.service_parts")}
                  routePath={`/service/parts`}
                  isOpen={open}
                  isSubItem={true}
                />
              )}
              {isServiceContactEnabled && (
                <SimpleEntry
                  icon={
                    <SupportAgentOutlinedIcon
                      color={isServiceContact ? "action" : "primary"}
                    />
                  }
                  isActive={isServiceContact}
                  label={t("navigations.service_contact")}
                  routePath={`/service/contact/machine${
                    selectedMachine ? `/${selectedMachine}` : ""
                  }/contact`}
                  isOpen={open}
                  isSubItem={true}
                />
              )}
            </>
          </MultiEntries>
        )}
        {claims?.extension_role === "ADMIN" && isSettingsEnabled && (
          <MultiEntries
            icon={<SettingsOutlinedIcon color={"primary"} />}
            label={t("navigations.settings")}
            isOpen={open}
            isActive={isSettingsMachine || isSettingsUser}
          >
            <>
              {isSettingsMachineEnabled && (
                <SimpleEntry
                  icon={
                    <DataSaverOffOutlinedIcon
                      color={isSettingsMachine ? "action" : "primary"}
                    />
                  }
                  isActive={isSettingsMachine}
                  label={t("navigations.settings_machine")}
                  routePath={`/settings/machine${
                    selectedMachine ? `/${selectedMachine}` : ""
                  }/shifts`}
                  isOpen={open}
                  isSubItem={true}
                />
              )}
              {isSettingsUserEnabled && (
                <SimpleEntry
                  icon={
                    <GroupOutlinedIcon
                      color={isSettingsUser ? "action" : "primary"}
                    />
                  }
                  isActive={isSettingsUser}
                  label={t("navigations.settings_user")}
                  routePath={"/settings/user"}
                  isOpen={open}
                  isSubItem={true}
                />
              )}
            </>
          </MultiEntries>
        )}
      </div>
      <FooterButtons onClick={onClick} isMobile={mobile} isOpen={open} />
    </div>
  );
}
