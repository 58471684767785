import { LabelProps } from "recharts";
import React from "react";
import { colors } from "../../../../../theme";

export default function CustomizedLabel({
  stroke,
  value,
}: LabelProps): React.ReactElement {
  return (
    <text
      dy={-4}
      fill={stroke as string | undefined}
      fontSize={20}
      x={35}
      y={91}
      color={colors.grey_base}
      textAnchor="middle"
    >
      {value}
    </text>
  );
}
