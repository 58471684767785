import {
  Cell,
  Label,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import React, { useCallback } from "react";
import useStyles from "./styles";
import RowTooltip, { ITooltipProps } from "./components/RowTooltip";
import CustomCenteredLabel from "./components/customCenteredLabel";
import SkeletonPieChart from "../kpiPieChart/skeleton";
import { IAnomalyInfo } from "../../../../components/charts/nestedKpiPieChartCard";

interface IProps {
  delta: number;
  outerCircleDataEntries: IExtendedPieChartDataEntry[];
  innerCircleDataEntries: IExtendedPieChartDataEntry[];
  isLoading?: boolean;
  isTooltipEnabled?: boolean;
  anomalyInfo: IAnomalyInfo;
}
export type ITooltipName = "current" | "reference" | "";

export interface IExtendedPieChartDataEntry {
  name: ITooltipName;
  value: number;
  color: string;
}

function NestedKPIPieChart({
  delta,
  outerCircleDataEntries,
  innerCircleDataEntries,
  anomalyInfo,
  isLoading = false,
  isTooltipEnabled = true,
}: IProps): React.ReactElement {
  const { classes } = useStyles();

  const renderCenteredLabel = useCallback(
    (
      delta: number,
      actualPoint: IExtendedPieChartDataEntry,
      referencePoint: IExtendedPieChartDataEntry,
      anomalyInfo: IAnomalyInfo,
      props,
    ) => {
      const {
        viewBox: { cx, cy, outerRadius },
      } = props;
      const labelPositioningProps = {
        x: cx,
        y: cy,
      };
      return (
        <CustomCenteredLabel
          labelPosition={labelPositioningProps}
          outerRadius={outerRadius}
          delta={delta}
          actualPoint={actualPoint}
          referencePoint={referencePoint}
          anomalyInfo={anomalyInfo}
        />
      );
    },
    [],
  );

  const tooltipContent = useCallback(
    (tooltipData) => {
      if (
        !tooltipData.active ||
        !tooltipData.payload ||
        tooltipData.payload.length === 0 ||
        tooltipData.payload[0].name === "" ||
        !isTooltipEnabled
      )
        return null;
      return <RowTooltip tooltipData={tooltipData as ITooltipProps} />;
    },
    [outerCircleDataEntries, innerCircleDataEntries, isTooltipEnabled],
  );

  return (
    /*
        For some strange reason, we need to wrap the PieChart Component within
        a Responsive Container where PieChart Component is defined.
        This is necessary even if we wrap the PieChart Component within a
        Responsive Container inside the caller component

        works:
        // caller
        <CallerComponent>
            <ResponsiveContainer>
                // inside commonPieChart
                <ResponsiveContainer>
                    <PieChart/>
                </ResponsiveContainer>
            </ResponsiveContainer>
        // caller
        </CallerComponent>

        broken:
        // caller
        <CallerComponent>
            <ResponsiveContainer>
                // inside commonPieChart
                <PieChart/>
            </ResponsiveContainer>
        // caller
        </CallerComponent>
       */
    isLoading ? (
      <SkeletonPieChart />
    ) : (
      <ResponsiveContainer>
        <PieChart className={classes.pieChartWrapper}>
          <Pie
            data={outerCircleDataEntries}
            dataKey="value"
            activeIndex={0}
            innerRadius="70%"
            outerRadius="90%"
            startAngle={90}
            endAngle={-360}
            labelLine={false}
          >
            {outerCircleDataEntries.map(
              (
                entry: IExtendedPieChartDataEntry,
                index: number,
              ): React.ReactElement => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ),
            )}
            <Label
              position={"center"}
              width={30}
              content={(props) =>
                renderCenteredLabel(
                  delta,
                  outerCircleDataEntries[0],
                  innerCircleDataEntries[0],
                  anomalyInfo,
                  props,
                )
              }
            />
          </Pie>
          <Pie
            data={innerCircleDataEntries}
            dataKey="value"
            activeIndex={0}
            innerRadius="63%"
            outerRadius="70%"
            startAngle={90}
            endAngle={-360}
            labelLine={false}
          >
            {innerCircleDataEntries.map(
              (
                entry: IExtendedPieChartDataEntry,
                index: number,
              ): React.ReactElement => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ),
            )}
          </Pie>
          <Tooltip position={{ x: 1, y: -15 }} content={tooltipContent} />
        </PieChart>
      </ResponsiveContainer>
    )
  );
}
export default React.memo(NestedKPIPieChart);
