import * as React from "react";
import useStyles from "./styles";
import Switch from "@mui/material/Switch";

interface IProps {
  isChecked: boolean;
  onChange: (e: React.ChangeEvent) => void;
  isDisabled?: boolean;
}

export default function Toggle({
  isChecked,
  onChange,
  isDisabled = false,
}: IProps): React.ReactElement {
  const { classes } = useStyles();

  return (
    <Switch
      className={classes.ToggleChecked}
      checked={isChecked}
      onChange={onChange}
      disabled={isDisabled}
      inputProps={{ "aria-label": "controlled" }}
    />
  );
}
