/*
 *   Screenmode local storing the current screenmodes like fullscreen, shopfloormode etc.
 */
import { useCallback, useEffect, useState } from "react";
import useLocalStorage from "../persistence/useLocalStorage";
import { createUtcTimestamp } from "../../services";
import { timezoneUTC } from "../time/timezone";
import {
  ITimeSelectionState,
  ITimeSlot,
} from "../../redux/reducers/timeSelectReducer";

interface ICurrentReferenceTimeSettingsApi {
  currentTimeFilter: ITimeSlot;
  setCurrentTimeFilter: (newFilter: ITimeSlot) => void;
  referenceTimeFilter: ITimeSlot;
  setReferenceTimeFilter: (newFilter: ITimeSlot) => void;
}

const CurrentAndReferenceTimeStorageKey = "CurrentAndReferenceTimeSettings";

/*
Please use this not directly but with GeorgStorageProvider
 */
export default function useCurrentReferenceTimeSettings(): ICurrentReferenceTimeSettingsApi {
  const { load, set } = useLocalStorage();

  const initialState: ITimeSelectionState = {
    currentFilter: {
      startedAfter: createUtcTimestamp(true, "this-week", timezoneUTC),
      endedBefore: createUtcTimestamp(false, "this-week", timezoneUTC),
      scale: "HOURS",
      shift: "day_24h",
      selectedPeriod: "this-week",
    },
    referenceFilter: {
      startedAfter: createUtcTimestamp(true, "this-month", timezoneUTC),
      endedBefore: createUtcTimestamp(false, "this-month", timezoneUTC),
      scale: "HOURS",
      shift: "day_24h",
      selectedPeriod: "this-month",
    },
  };

  // Initialize screen settings
  const storedDataString = load(CurrentAndReferenceTimeStorageKey);
  const storedData: ITimeSelectionState = storedDataString
    ? JSON.parse(storedDataString)
    : {
        currentFilter: initialState.currentFilter,
        referenceFilter: initialState.referenceFilter,
      };

  const currentTimeFilterFromLS =
    storedData?.currentFilter || initialState.currentFilter;
  const [currentTimeFilter, setCurrentTimeFilterInternal] = useState<ITimeSlot>(
    currentTimeFilterFromLS,
  );

  const referenceTimeFilterFromLS =
    storedData?.referenceFilter || initialState.referenceFilter;
  const [referenceTimeFilter, setReferenceTimeFilterInternal] =
    useState<ITimeSlot>(referenceTimeFilterFromLS);

  useEffect(() => {
    const dataToStore = {
      currentFilter: currentTimeFilter,
      referenceFilter: referenceTimeFilter,
    };
    set(CurrentAndReferenceTimeStorageKey, JSON.stringify(dataToStore));
  }, [currentTimeFilter, referenceTimeFilter, set]);

  const setCurrentTimeFilter = useCallback((newFilter: ITimeSlot) => {
    setCurrentTimeFilterInternal(newFilter);
  }, []);

  const setReferenceTimeFilter = useCallback((newFilter: ITimeSlot) => {
    setReferenceTimeFilterInternal(newFilter);
  }, []);

  return {
    currentTimeFilter,
    setCurrentTimeFilter,
    referenceTimeFilter,
    setReferenceTimeFilter,
  };
}
