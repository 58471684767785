import React from "react";
import Skeleton from "@mui/material/Skeleton";
import useStyles from "./styles";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { colors } from "../../../../theme";

// Skeleton component for PieChart
export default function SkeletonPieChart(): React.ReactElement {
  const { classes } = useStyles();

  // Define a skeleton data with arbitrary values
  const skeletonData = [{ name: "Group A", value: 400 }];

  return (
    <div className={classes.outerContainer}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            dataKey="value"
            data={skeletonData}
            activeIndex={1}
            innerRadius="70%"
            outerRadius="90%"
            startAngle={90}
            endAngle={-360}
            isAnimationActive={false}
          >
            {skeletonData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={`${colors.greyB16}${index % 2 ? "99" : "66"}`}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <div className={classes.skeletonText}>
        <Skeleton variant="text" width={60} height={20} />
        <Skeleton variant="text" width={80} height={20} />
      </div>
    </div>
  );
}
