import {
  Legend,
  LegendProps,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useCurrentPng } from "recharts-to-png";
import FileSaver from "file-saver";
import LineChartTooltip, { ITooltipProps } from "./components/LineChartTooltip";
import LineChartLegend from "./components/LineChartLegend";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { colors } from "../../../theme";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import useStyles from "./styles";
import CustomizedLabel from "./components/customizedLabel";
import { IExportEnum } from "../../../../components/charts/components/exportButton";
import { useTranslation } from "react-i18next";
import { getTimezone } from "../../../../helper/time/timezone";
import formatDateToString from "../../../../helper/time/formatting/formatDateToString";
import TKPIType, { KPIKeys } from "../../../../api/kpi/TKPIType";
import generateFileName from "../../../../services/generateFileName";
interface ISeriesDataElement {
  [valueName: string]: number;
}

function areAllTimesTheSame(entries: ILineChartData[]): boolean {
  if (entries.length === 0) return true;

  const firstDate = entries[0].timestamp;
  return entries.every((entry) => {
    return (
      entry.timestamp.getHours() === firstDate.getHours() &&
      entry.timestamp.getMinutes() === firstDate.getMinutes() &&
      entry.timestamp.getSeconds() === firstDate.getSeconds()
    );
  });
}

export interface ILineChartData {
  data: ISeriesDataElement;
  name: string;
  xAxisLabel: string;
  timestamp: Date;
  timestampMillis: number;
}

export interface ISeriesLabelConfig {
  [valueName: string]: string;
}

interface IProps {
  data: ILineChartData[] | undefined;
  isLoading: boolean;
  isEmpty: boolean;
  isTooltipEnabled?: boolean;
  hasError: boolean;
  seriesLabel: ISeriesLabelConfig;
  // keys of ISeriesDataElement, to sort line rendering
  order?: string[];
  triggerExport: IExportEnum;
  onExportHandled: () => void;
  machineId: string;
  isHourScale?: boolean;
}
const AVAILABLE_CHART_COLORS = [
  colors.chart_blue_base,
  colors.chart_green_base,
  colors.chart_red_base,
  colors.chart_yellow_base,
];
const STROKE_WIDTH = 3;

function isKPIKey(value: string): value is TKPIType {
  return KPIKeys.includes(value as TKPIType);
}

export default function CommonLineChart({
  data,
  isLoading,
  isEmpty,
  isTooltipEnabled = true,
  hasError,
  seriesLabel,
  order,
  triggerExport,
  onExportHandled,
  machineId,
  isHourScale = false,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const timezone = getTimezone();
  const isRenderable = !isLoading && !isEmpty && !hasError;
  const theme = useTheme();
  const [disabledKeys, setDisabled] = React.useState<string[]>([]);
  // Indicator to color mapping.
  const chartColors: string[] = useMemo(() => {
    const colors: string[] = [];
    Object.keys(seriesLabel).forEach((_label, index) => {
      colors.push(AVAILABLE_CHART_COLORS[index]);
    });
    return colors;
  }, [seriesLabel]);

  // Click handler for data lines. Removing or including data lines.
  const handleClick = (dataKey: string) => {
    const newState: string[] = [...disabledKeys];
    if (!_.includes(newState, dataKey)) {
      newState.push(dataKey);
    } else {
      _.remove(newState, (item) => item === dataKey);
    }

    setDisabled(newState);
  };
  const isXs = useMediaQuery(theme.breakpoints.down("xs"), {
    defaultMatches: true,
  });
  let chartData: ILineChartData[] = [];
  let dataFields: string[] = [];
  if (isRenderable && data && data.length > 0) {
    chartData = data.map((dataEntry: ILineChartData) => {
      const dataElements: ISeriesDataElement = {};
      for (const key in dataEntry.data) {
        if (!disabledKeys.includes(key)) {
          dataElements[key] = dataEntry.data[key];
        }
      }
      return { ...dataEntry, ...dataElements };
    });
    dataFields = Object.keys(data[0].data);
  }
  let sortedFields = dataFields;
  if (order) {
    sortedFields = order.filter((orderKey: string) => {
      return dataFields.some((df) => df === orderKey);
    });
  }

  useEffect(() => {
    switch (triggerExport) {
      case IExportEnum.PNG:
        handleExportPNG();
        break;
      case IExportEnum.CSV:
        handleExportCSV();
        break;
      default:
        onExportHandled();
        break;
    }
  }, [triggerExport]);

  const areAlltimesTheSame = useMemo(
    () => data && areAllTimesTheSame(data),
    [data],
  );

  // Only recompute the map when chartData changes
  const timestampToLabelMap = useMemo(() => {
    return new Map(
      chartData.map((item) => [item.timestampMillis, item.xAxisLabel]),
    );
  }, [chartData]);

  // Define the tickFormatter using useCallback to avoid unnecessary recreations
  const tickFormatter = useCallback(
    (value) => {
      return timestampToLabelMap.get(value) || "";
    },
    [timestampToLabelMap],
  );

  // useCurrentPng usage (isLoading is optional)
  const [getLinePng, { ref: lineRef }] = useCurrentPng();

  // Can also pass in options for html2canvas
  // const [getPng, { ref }] = useCurrentPng({ backgroundColor: '#000' });

  // get PNG image from chart data
  const handleExportPNG = useCallback(async () => {
    const png = await getLinePng();

    // Verify that png is not undefined
    if (png) {
      // Download with FileSaver
      const fileName = generateFileName(
        machineId,
        i18n.language,
        t,
        timezone,
        "Chart",
        IExportEnum.PNG,
      );
      FileSaver.saveAs(png, fileName);
    }
    if (onExportHandled) {
      onExportHandled();
    }
  }, [getLinePng]);

  // get csv data from chart data
  const converDataToCSV = useCallback(() => {
    if (!data || data.length === 0) return "";
    // return await convertDataToCSV(data);

    // Extract unique value names from the data for headers.
    const valueNames: string[] = [];
    data.forEach((entry) => {
      Object.keys(entry.data).forEach((valueName) => {
        if (!valueNames.includes(valueName)) {
          valueNames.push(valueName);
        }
      });
    });
    const percentText = "(%)";
    const translatedValueNames = valueNames.map((valueName) => {
      if (isKPIKey(valueName)) {
        return `${t(`common.${valueName}.label`)} ${percentText}`;
      }
      return `${valueName} ${percentText}`;
    });

    // Create CSV header.
    const headers = [
      t("dashboard.commonChart.dateTime"),
      ...translatedValueNames,
    ].join("; ");

    // Extract the data rows.
    const rows = data.map((entry) => {
      const timestamp = formatDateToString(
        entry.timestamp,
        i18n.language,
        t,
        areAlltimesTheSame ? "date" : "datetime",
        "DAYS",
        timezone,
      ); // Format the timestamp or use any desired date format.
      const values = valueNames.map(
        (valueName) => entry.data[valueName] || "0",
      );
      return [timestamp, ...values].join("; ");
    });

    return [headers, ...rows].join("\n");
  }, [data]);

  const handleExportCSV = useCallback(async () => {
    const convertedCSV = converDataToCSV();
    const blob = new Blob([convertedCSV], { type: "text/csv;charset=utf-8" });
    const fileName = generateFileName(
      machineId,
      i18n.language,
      t,
      timezone,
      "Chart",
      IExportEnum.CSV,
    );
    FileSaver.saveAs(blob, fileName);
    if (onExportHandled) {
      onExportHandled();
    }
  }, [data]);

  return (
    <ResponsiveContainer>
      <LineChart
        className={classes.lineChartWrapper}
        data={chartData}
        margin={{
          top: isXs ? 0 : 5,
          right: 30,
          left: isXs ? -20 : 20,
          //bottom: 10,
          bottom: 5,
        }}
        ref={lineRef}
      >
        <XAxis
          dataKey="timestampMillis"
          type="number"
          scale="time"
          domain={["dataMin", "dataMax"]}
          tickFormatter={tickFormatter}
          interval={"preserveStartEnd"}
          minTickGap={10}
        />
        <YAxis label={<CustomizedLabel value={"%"} />} />
        <Tooltip
          content={(toolTipData) =>
            isTooltipEnabled && (
              <LineChartTooltip
                seriesLabel={seriesLabel}
                tooltipData={toolTipData as ITooltipProps}
              />
            )
          }
        />
        <Legend
          content={(legendData) => {
            if (!legendData) return;
            return (
              <LineChartLegend
                chartColors={chartColors}
                handleClick={handleClick}
                disabled={disabledKeys}
                legendData={legendData as LegendProps}
                key={"common-linechart"}
              />
            );
          }}
        />
        {sortedFields.map((dataKey: string, index: number) => (
          <Line
            key={dataKey}
            dataKey={dataKey}
            stroke={chartColors[index]}
            strokeWidth={STROKE_WIDTH}
            type={isHourScale ? "stepAfter" : "step"}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
}
