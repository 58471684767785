import GeorgButton from "../../../../../shared/components/GeorgButton";
import React, { useCallback, useMemo, useState } from "react";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import ServiceOrderAdminTable from "./components/serviceOrderAdminTable";
import { IServiceOrderSettingsUpdate } from "./api/IServiceOrderSettings";
import { useSelector } from "react-redux";
import { selectMachine } from "../../../../../redux/reducers/machineSelectReducer";
import useQueryUsers from "../../../user/userManagement/hooks/useQueryUser/useQueryUsers";
import YesNoDialog from "../../../../../shared/components/yesnodialog";
import ServiceOrderCRUDForm from "./components/serviceOrderCrudForm";
import {
  useChangeActiveMutation,
  useQueryCategories,
  useQueryComponents,
  useQueryServiceOrders,
  useQueryVariables,
  useRemoveServiceOrderSettingsMutation,
  useUpsertServiceOrderSettingsMutation,
} from "./hooks";
import { transformServiceOrdersForTable } from "./transformation";
import GeorgTextBase, {
  EStyleType,
} from "../../../../../shared/components/text/base";
import { EApiState } from "../../../../../api";

enum EViewMode {
  TABLE = "TABLE",
  REMOVE_DIALOG = "REMOVE_DIALOG",
  EDIT_CREATE_MODAL = "EDIT_CREATE_MODAL",
}

function findById(
  orders: IServiceOrderSettingsUpdate[],
  id: string,
): IServiceOrderSettingsUpdate | null {
  return orders.find((o) => o.id === id) || null;
}

export default function ServiceOrders(): React.ReactElement {
  const [viewMode, setViewMode] = useState<EViewMode>(EViewMode.TABLE);
  const [targetServiceId, setTargetServiceId] = useState<string | null>(null);
  const [serviceOrderToEdit, setServiceOrderToEdit] =
    useState<IServiceOrderSettingsUpdate | null>(null);
  const { classes } = useStyles();
  const { t } = useTranslation();
  const selectedMachine = useSelector(selectMachine);
  const { apiState, serviceOrders } = useQueryServiceOrders(selectedMachine);
  const { apiState: apiStateVariables, variables } = useQueryVariables();
  const { apiState: apiStateCategories, categories } = useQueryCategories();
  const { apiState: apiStateComponents, components } = useQueryComponents();
  const { state: queryUsersState, data: usersData } = useQueryUsers();
  const { changeIsActive, apiState: changeIsActiveState } =
    useChangeActiveMutation();

  const {
    upsertServiceOrderMutation,
    apiState: upsertServiceOrderMutationState,
  } = useUpsertServiceOrderSettingsMutation();

  const { removeServiceStateMutation, apiState: removeServiceState } =
    useRemoveServiceOrderSettingsMutation();

  const allMutationStates = [
    upsertServiceOrderMutationState,
    changeIsActiveState,
    removeServiceState,
  ];

  const hasMutationError = allMutationStates.some((s) => s === EApiState.ERROR);
  const isMutationLoading = allMutationStates.some(
    (s) => s === EApiState.LOADING,
  );

  const queryStates = [
    apiState,
    apiStateVariables,
    apiStateCategories,
    apiStateComponents,
  ];
  const isLoading =
    queryStates.some((s) => s === EApiState.LOADING) ||
    queryUsersState === EApiState.LOADING;
  const hasError =
    queryStates.some((s) => s === EApiState.ERROR) ||
    queryUsersState === EApiState.ERROR;
  const isOK =
    queryStates.every((s) => s === EApiState.OK) &&
    queryUsersState === EApiState.OK;

  const serviceOrdersForTable: IServiceOrderSettingsUpdate[] = useMemo(() => {
    if (isOK) {
      return transformServiceOrdersForTable(serviceOrders);
    } else {
      return [];
    }
  }, [isOK, serviceOrders]);

  const descriptionForDeletionDialog = useMemo(() => {
    if (targetServiceId != null) {
      const found = findById(serviceOrdersForTable, targetServiceId);
      if (found != null) {
        return found.name;
      }
    }
  }, [targetServiceId, serviceOrders]);

  const onChangeActiveCallback = useCallback(
    (serviceOrderId: string, newIsActive: boolean) => {
      changeIsActive(serviceOrderId, newIsActive);
    },
    [changeIsActive],
  );

  const onEditCallback = useCallback(
    (serviceOrderId: string) => {
      setServiceOrderToEdit(
        findById(serviceOrdersForTable, serviceOrderId) || null,
      );
      setViewMode(EViewMode.EDIT_CREATE_MODAL);
    },
    [findById, serviceOrdersForTable],
  );

  return (
    <div className={classes.box}>
      {hasError && (
        <GeorgTextBase
          type={EStyleType.DARK}
          text={t("settings.serviceOrders.loadingError")}
        />
      )}
      {hasMutationError && (
        <GeorgTextBase
          type={EStyleType.DARK}
          text={t("settings.serviceOrders.mutationError")}
        />
      )}
      {(isOK || isLoading) && (
        <>
          <div>
            <div className={classes.titleWrapper}>
              <GeorgButton
                label={t("settings.serviceOrders.addBtn")}
                onClick={() => {
                  setServiceOrderToEdit(null);
                  setViewMode(EViewMode.EDIT_CREATE_MODAL);
                }}
              />
            </div>
            <ServiceOrderAdminTable
              isMutationLoading={isMutationLoading}
              serviceOrders={serviceOrdersForTable}
              onRemoveClick={(serviceOrderId: string) => {
                setTargetServiceId(serviceOrderId);
                setViewMode(EViewMode.REMOVE_DIALOG);
              }}
              onChangeActive={onChangeActiveCallback}
              onEdit={onEditCallback}
              isLoading={isLoading}
            />
          </div>
          <YesNoDialog
            isModalVisible={viewMode === EViewMode.REMOVE_DIALOG}
            onClose={() => {
              setViewMode(EViewMode.TABLE);
              setTargetServiceId(null);
            }}
            title={t("settings.serviceOrders.removeTitle")}
            question={`${t("settings.serviceOrders.removeMessage")} '${
              descriptionForDeletionDialog || "unknown"
            }'?`}
            onCancel={() => {
              setViewMode(EViewMode.TABLE);
              setTargetServiceId(null);
            }}
            onSubmit={() => {
              if (targetServiceId == null) {
                // eslint-disable-next-line no-console
                console.error(
                  `Illegal State: Tried to remove serviceOrder without id`,
                );
                return;
              }
              removeServiceStateMutation(targetServiceId);
              setTargetServiceId(null);
            }}
          />
          {viewMode === EViewMode.EDIT_CREATE_MODAL && (
            <ServiceOrderCRUDForm
              isModalVisible
              variables={variables}
              categories={categories}
              users={usersData}
              components={components}
              onClose={() => {
                setViewMode(EViewMode.TABLE);
                setTargetServiceId(null);
              }}
              serviceOrderToEdit={serviceOrderToEdit}
              onSubmit={(crudServiceOrder) => {
                setViewMode(EViewMode.TABLE);
                setTargetServiceId(null);
                upsertServiceOrderMutation(crudServiceOrder);
              }}
            />
          )}
        </>
      )}
    </div>
  );
}
