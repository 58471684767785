import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  doneButton: {
    height: 42,
    width: 72,
  },
}));

export default useStyles;
