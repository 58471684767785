import { makeStyles } from "tss-react/mui";
export const useStyles = makeStyles()(() => ({
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  textContainer: {
    "&.MuiTypography-root": {
      fontWeight: "normal",
    },
  },
  textContainerWithBottomPadding: {
    paddingBottom: 24,
  },
  smallTextContainer: {
    "&.MuiTypography-root": {
      fontSize: 10,
    },
  },
  gridItem: {
    paddingLeft: 0 + " !important",
  },
  boldText: {
    "&.MuiTypography-root": {
      fontWeight: "bold",
    },
    display: "contents",
  },
  italicText: {
    "&.MuiTypography-root": {
      fontStyle: "italic",
    },
  },
  text: {
    display: "contents",
    lineHeight: "normal",
  },
}));
