import { makeStyles } from "tss-react/mui";
import { colors } from "../../theme";

const smallResolutionQuery = "@media (min-width: 720px)";

const useStyles = makeStyles()(() => ({
  container: {
    overflowY: "scroll",
  },
  background: {
    fontFamily: "Titillium Web",
    backgroundColor: colors.white_base,
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    minHeight: 320,
    minWidth: 320,
  },
  content: {
    paddingLeft: "5%",
    paddingRight: "5%",
    [smallResolutionQuery]: {
      paddingLeft: 48,
      paddingRight: 48,
    },
  },
  inner: {
    marginTop: 16,
  },
  closeIcon: {
    cursor: "pointer",
    float: "right",
    paddingRight: "5%",
    [smallResolutionQuery]: {
      paddingRight: 48,
    },
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 14,
    paddingLeft: "5%",
    paddingRight: 4,
    paddingBottom: 14,
    alignItems: "center" /* <---- NEW    */,
    height: 36,
    backgroundColor: colors.whiteB_3,
    [smallResolutionQuery]: {
      paddingLeft: 48,
    },
  },
}));

export default useStyles;
