/*
 *
 *   Higher order component for wrapping components with a tooltip.
 *
 */

import React, { useCallback, useMemo } from "react";
import { Tooltip, Typography } from "@mui/material";
import useStyles from "./styles";
export type TPlacement =
  | "bottom-end"
  | "bottom-start"
  | "bottom"
  | "left-end"
  | "left-start"
  | "left"
  | "right-end"
  | "right-start"
  | "right"
  | "top-end"
  | "top-start"
  | "top";

export interface ITooltipInfo {
  description?: string;
  label?: string;
  sublabel?: string;
  footer?: string;
}

//------------------------------------------------------------------------------------------------------------
// Interfaces & Types.
interface Props {
  children: JSX.Element;
  tooltipInfo?: ITooltipInfo;
  tooltipContent?: React.ReactNode;
  smallTooltip?: boolean;
  centerContent?: boolean;
  noOverflow?: boolean;
  placement?: TPlacement;
  isFullWidthContainer?: boolean;
  hasBulletPoints?: boolean;
  isFirstHasBulletPoint?: boolean;
  isHidden?: boolean;
  icon?: React.ReactElement;
  isContainerRequired?: boolean;
}

//------------------------------------------------------------------------------------------------------------
// Component.
export default function WithTooltip({
  children,
  tooltipContent = undefined,
  icon,
  tooltipInfo = {},
  smallTooltip = false,
  centerContent = false,
  noOverflow = false,
  placement = "right-end",
  isFullWidthContainer = false,
  hasBulletPoints = false,
  isFirstHasBulletPoint = false,
  isHidden = false,
  isContainerRequired = true,
}: Props): React.ReactElement {
  const { classes } = useStyles();
  const { description, label, sublabel, footer } = tooltipInfo;
  const [isOpen, setIsOpen] = React.useState(false);

  const sentences = useMemo(() => label?.split("\n").filter(Boolean), [label]);
  const isMultiline = (sentences?.length ?? 0) > 1;

  const renderLine = useCallback(
    (sentence: string, index: number, variant: "body2" | "h2") => (
      <Typography key={index} color="primary" variant={variant}>
        {isMultiline &&
        hasBulletPoints &&
        (index === 0 ? isFirstHasBulletPoint : true)
          ? "• "
          : ""}
        {sentence}
      </Typography>
    ),
    [isMultiline, hasBulletPoints, isFirstHasBulletPoint],
  );

  const renderContent = (variant: "body2" | "h2") => (
    <>
      <div className={classes.contentContainer}>
        <div className={classes.icon}>{icon}</div>
        {sentences?.map((sentence, index) =>
          renderLine(sentence, index, variant),
        )}
      </div>
      {sublabel && (
        <Typography
          className={classes.sublabel}
          color="primary"
          variant="caption"
        >
          {sublabel}
        </Typography>
      )}
      {description && (
        <Typography
          className={classes.description}
          color="primary"
          variant="body1"
        >
          {description}
        </Typography>
      )}
      {footer && (
        <Typography
          className={classes.footer}
          color="primary"
          variant="caption"
        >
          {footer}
        </Typography>
      )}
    </>
  );

  const wrappedChildren = useMemo(
    () =>
      isContainerRequired ? (
        <div
          className={`${centerContent ? classes.tooltip_wrapper : ""} ${
            isFullWidthContainer ? classes.fullWidth : ""
          } ${noOverflow ? classes.no_overflow : ""}`}
        >
          {children}
        </div>
      ) : (
        children
      ),
    [
      isContainerRequired,
      children,
      centerContent,
      isFullWidthContainer,
      noOverflow,
    ],
  );

  const renderTooltip = (variant: "body2" | "h2", tooltipClass: string) => (
    <Tooltip
      key={label || sublabel || description || ""}
      arrow
      title={tooltipContent ?? renderContent(variant)}
      placement={placement}
      classes={{
        arrow: classes.arrow,
        tooltip: tooltipClass,
      }}
      open={!isHidden && isOpen}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
    >
      {wrappedChildren}
    </Tooltip>
  );

  return smallTooltip
    ? renderTooltip("body2", classes.smallTooltip)
    : renderTooltip("h2", classes.tooltip);
}
