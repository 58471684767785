import { useEffect, useState } from "react";
import MOCK_MANUAL_STATES from "../mock/MOCK_MANUAL_STATES";
import { EApiState } from "../../../../../../api";
import { IManualStateConfigEntryFromServer } from "../../../../../../api/manualState/IManualStateConfigEntry";

export function useQueryManualStates(machineId: string): {
  manualStatesFromServer: IManualStateConfigEntryFromServer[];
  apiState: EApiState;
  refreshData: () => void;
} {
  const [apiState, setApiState] = useState(EApiState.LOADING);
  const [manualStatesFromServer, setManualStatesFromServer] = useState<
    IManualStateConfigEntryFromServer[]
  >([]);
  const [refreshCount, setRefreshCount] = useState<number>(0);
  useEffect(() => {
    setApiState(EApiState.LOADING);
    const t = setTimeout(() => {
      setManualStatesFromServer(MOCK_MANUAL_STATES);
      setApiState(EApiState.OK);
    }, 600);
    return () => {
      clearTimeout(t);
    };
  }, [machineId, refreshCount]);
  const refreshData = () => {
    setRefreshCount((prev) => prev + 1);
  };
  return { apiState, manualStatesFromServer, refreshData };
}
