import React from "react";
import clsx from "clsx";
import _ from "lodash";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
} from "@mui/material";
// Custom Imports.
import useStyles from "./styles";

//------------------------------------------------------------------------------------------------------------
// Interfaces & Types.
type CardHeaderProps = {
  action?: React.ReactElement;
  avatar?: React.ReactElement;
  title?: React.ReactElement | string;
  subheader?: React.ReactElement | string;
};

type CardMediaProps = {
  image: string;
  height?: string;
};

type Variants = "elevation" | "outlined";

// TODO Types
interface IStyleObject {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  card?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cardContent?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cardFooter?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cardHeader?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cardMedia?: any;
}

export interface IProps {
  align?: "left" | "center" | "right";
  cardContent?: React.ReactNode;
  cardFooter?: React.ReactNode;
  cardHeader?: CardHeaderProps;
  cardMedia?: CardMediaProps;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cardProps?: any;
  elevation?: number;
  styles?: IStyleObject;
  transparentBg?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: any;
  variant?: Variants;
}

//------------------------------------------------------------------------------------------------------------
// Component.
export function ICard({
  align = "center",
  cardContent = <></>,
  cardFooter = <></>,
  cardHeader,
  cardMedia = { image: "", height: "0" },
  cardProps,
  elevation = 2,
  styles = {
    card: "",
    cardFooter: "",
    cardContent: "",
    cardHeader: "",
  },
  transparentBg,
  onClick,
  variant = "elevation",
}: IProps): React.ReactElement {
  const { classes } = useStyles();

  return (
    <Card
      className={clsx(
        classes.root,
        transparentBg ? classes.transparentBg : {},
        styles.card,
      )}
      elevation={elevation}
      onClick={onClick}
      variant={variant}
      {...cardProps}
    >
      {_.isEmpty(cardHeader) ? null : (
        <CardHeader
          action={cardHeader?.action}
          avatar={cardHeader?.avatar}
          className={styles.cardHeader}
          title={cardHeader?.title}
          subheader={cardHeader?.subheader}
        />
      )}
      {_.isEmpty(cardMedia) ? null : (
        <CardMedia
          className={styles.cardMedia}
          component="img"
          height={cardMedia?.height}
          image={cardMedia?.image}
        />
      )}
      <CardContent
        className={clsx(
          classes[align],
          classes.cardContent,
          styles.cardContent,
        )}
      >
        {cardContent}
      </CardContent>
      <CardActions className={clsx(classes[align], styles.cardFooter)}>
        {cardFooter}
      </CardActions>
    </Card>
  );
}

export default ICard;
