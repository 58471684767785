import { TServiceorderSettingsCreateOrUpdate } from "../../api/IServiceOrderSettings";
import { useCallback, useState } from "react";
import { EApiState } from "../../../../../../../api";

export type TUpsertServiceOrderCallback = (
  serviceOrder: TServiceorderSettingsCreateOrUpdate,
) => void;
export function useUpsertServiceOrderSettingsMutation(): {
  upsertServiceOrderMutation: TUpsertServiceOrderCallback;
  apiState: EApiState;
} {
  const [apiState, setApiState] = useState<EApiState>(EApiState.OK);
  const upsertServiceOrderMutation = useCallback(
    (serviceOrder: TServiceorderSettingsCreateOrUpdate) => {
      setApiState(EApiState.LOADING);
      setTimeout(() => {
        alert(
          `Todo: Implement upsert service order mutation for ${JSON.stringify(
            serviceOrder,
          )}`,
        );
        setApiState(EApiState.OK);
      }, 400);
    },
    [],
  );

  return { upsertServiceOrderMutation, apiState };
}
