import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  searchGridWrapper: {
    minHeight: 0,
    maxHeight: "1934px",
    overflowX: "auto",
    overflowY: "hidden",
  },
  image: {
    height: 250,
  },
});

export default useStyles;
