import React, { useMemo } from "react";
// Custom Imports.
import { useTranslation } from "react-i18next";
import OEEView from "./views/oeeView";
import StacklightView from "./views/stacklightView";
import TabsWithMachineTimePeriodSelection from "../../../shared/components/tabsWithMachineTimePeriodSelection";
import DowntimeReasonsView from "./views/downtimeReasonsView";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import LastWeeksPerformanceView from "./views/lastWeeksPerformanceView";
import { useFlag } from "@unleash/proxy-client-react";

// Component.
export default function Machine(): React.ReactElement {
  const knownPaths = [
    "/oee",
    "/stacklight",
    "/downtimes" /*, "/lastperformance"*/,
  ];

  const { t } = useTranslation();
  interface Tab {
    label: string;
    path: string;
  }

  const iskpiViewEnabled = true; //useFlag("killswitch_dashboards_machine_kpiView");
  const isstacklightViewEnabled = useFlag(
    "killswitch_dashboards_machine_stacklightView",
  );
  const isdowntimesViewEnabled = useFlag(
    "killswitch_dashboards_machine_downtimesView",
  );

  // the commented out code should be used when the backend is ready to provide the data for the last week's performance
  const tabConfig = useMemo<Tab[]>(() => {
    const tabs: Tab[] = [];

    if (iskpiViewEnabled) {
      tabs.push({ label: t("common.kpi_long"), path: knownPaths[0] });
    }

    if (isstacklightViewEnabled) {
      tabs.push({
        label: t("common.stacklightView.label"),
        path: knownPaths[1],
      });
    }

    if (isdowntimesViewEnabled) {
      tabs.push({
        label: t("common.downtimesView"),
        path: knownPaths[2],
      });
    }

    // if (useFlag("killswitch_dashboards_machine_lastWeeksPerformance")) {
    //   tabs.push({
    //     label: t("common.lastWeeksPerformance"),
    //     path: "/lastperformance",
    //   });
    // }

    return tabs;
  }, []);

  return (
    <TabsWithMachineTimePeriodSelection
      basePath={"/dashboards/machine"}
      tabConfig={tabConfig}
      isTimePeriodSelectionShown={true}
      // tabsWithoutTimePeriod={[tabConfig[3].path]}
      tabsWithRefererencePeriod={[tabConfig[0].path]}
    >
      <OEEView />
      <StacklightView />
      <DowntimeReasonsView />
      {/* <LastWeeksPerformanceView /> */}
    </TabsWithMachineTimePeriodSelection>
  );
}
