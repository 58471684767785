import CreateAzureB2cUserMutation from "./CreateAzureB2cUserMutation";
import { useMutation } from "@apollo/client";
import GetAzureB2cUsersQuery from "../useQueryUser/GetAzureB2cUsersQuery";
import IAzureB2cResponseType from "../IAzureB2cResponseType";

export interface IAzureB2cUserInputType {
  emailAddress: string;
  givenName: string;
  surname: string;
}

interface ICreateUserResponse {
  createAzureB2cUser: IAzureB2cResponseType;
}
export default function useCreateUser(): {
  mutate: (userInput: IAzureB2cUserInputType) => void;
  isLoading: boolean;
  hasError: boolean;
  responseCreateUser?: ICreateUserResponse | null;
} {
  const [mutateFunction, { data, loading, error }] =
    useMutation<ICreateUserResponse>(CreateAzureB2cUserMutation);

  return {
    mutate: (userInput: IAzureB2cUserInputType): void => {
      mutateFunction({
        variables: { azureB2cUserData: userInput },
        refetchQueries: [{ query: GetAzureB2cUsersQuery }],
      }).catch(
        // eslint-disable-next-line no-console
        console.error,
      );
    },
    isLoading: loading,
    hasError: error != null,
    responseCreateUser: data,
  };
}
