import { makeStyles } from "tss-react/mui";
import colors from "../../shared/theme/colors";

const useStyles = makeStyles()(() => ({
  reactGridRoot: {
    "& div.MuiDataGrid-root": {
      color: colors.blue_base,
      // color: theme.palette.text.color
    },
    width: "100%",
    height: "100%",
  },
}));

export default useStyles;
