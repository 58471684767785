import { makeStyles } from "tss-react/mui";
import { colors, rootTheme } from "../../../../shared/theme";

const useStyles = makeStyles()(() => ({
  inviteUserBtnWrapper: {
    marginBottom: 24,
  },
  userManagementContainer: {
    backgroundColor: colors.white_base,
    width: 940,
    minHeight: 624,
    ...rootTheme.tabContainer,
  },
  spinnerBox: {
    marginTop: 250,
    marginLeft: 365,
  },
  errorWarningWrapper: {
    flex: "content",
    display: "flex",
    justifyContent: "center",
  },
  errorWarning: {
    paddingTop: 20,
  },
  topRow: {
    display: "flex",
  },
}));
export default useStyles;
