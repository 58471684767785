import { makeStyles } from "tss-react/mui";
import colorToRgba from "../../../../../../helper/adjustColor";
import colors from "../../../../../../shared/theme/colors";

const useStyles = makeStyles()({
  inputContainer: {
    padding: 10,
    "& .MuiInputBase-root": {
      backgroundColor: colorToRgba(colors.blue_base, 0.04),
    },
  },
  rootInput: {
    width: 120,
    height: 52,
  },
  textInput: {
    paddingLeft: 10,
  },
  selectIcon: {
    marginRight: 10,
  },
});

export default useStyles;
