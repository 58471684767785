import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  box: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
    height: "fit-content",
  },
  dropDown: {
    flexGrow: 1,
  },
  dateTimeSelection: {
    display: "block",
    flexBasis: "100%",
    marginTop: 12,
    height: 80,
  },
}));
export default useStyles;
