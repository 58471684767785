/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import "./styles.css";
import { Layouts, Responsive, WidthProvider } from "react-grid-layout";
import { colors } from "../../theme";

interface IProps {
  children: React.ReactElement[];
  layouts: Layouts;
  gridBackgroundColor?: string;
  containerPadding?: [number, number] | { [P: string]: [number, number] };
  itemMargin?: [number, number] | { [P: string]: [number, number] };
  onBreakpointChange?: (newBreakpoint: string) => void;
}

export const SMALL_WIDTH = 12;
export const LARGE_WIDTH = 12;
// https://github.com/react-grid-layout/react-grid-layout
const ResponsiveGridLayout = WidthProvider(Responsive);

// Sorted breakpoints in descending order
const breakpoints = {
  xl: 1200,
  lg: 893,
  md: 601,
  sm: 585,
  xs: 379,
  xxs: 0,
};
export default function GridPage({
  layouts,
  children,
  gridBackgroundColor = colors.white_base,
  containerPadding = {
    xl: [40, 40],
    lg: [40, 40],
    md: [40, 40],
    sm: [30, 30],
    xs: [16, 16],
  },
  itemMargin = [16, 16],
  onBreakpointChange,
}: IProps): React.ReactElement {
  const [initialBreakpoint, setInitialBreakpoint] = useState<string | null>(
    null,
  );
  const getBreakpointFromWidth = (width) => {
    if (initialBreakpoint != null || !onBreakpointChange) return;
    let currentBreakpoint: string | null = null;
    for (const [breakpoint, breakpointWidth] of Object.entries(breakpoints)) {
      if (width >= breakpointWidth) {
        currentBreakpoint = breakpoint;
        break;
      }
    }
    setInitialBreakpoint(currentBreakpoint);
    onBreakpointChange(currentBreakpoint || "");
  };
  return (
    <ResponsiveGridLayout
      className="layout"
      layouts={layouts}
      style={{ backgroundColor: gridBackgroundColor }}
      // If true, the container height swells and contracts to fit contents
      //autoSize: boolean = true,
      autoSize={true}
      // A CSS selector for tags that will not be draggable.
      // For example: draggableCancel:'.MyNonDraggableAreaClassName'
      // If you forget the leading . it will not work.
      // draggableCancel: string = '';
      draggableCancel={"react-nondraggable"}
      // A CSS selector for tags that will act as the draggable handle.
      // For example: draggableHandle:'.MyDragHandleClassName'
      // If you forget the leading . it will not work.
      // draggableHandle = 'react-draggableHandle',
      // Margin between items [x, y] in px.
      // margin: ?[number, number] = [10, 10],
      margin={itemMargin}
      // Padding inside the container [x, y] in px
      // containerPadding: ?[number, number] = [10,10],//margin,
      containerPadding={containerPadding}
      // Rows have a static height, but you can change this based on breakpoints
      // if you like.
      // rowHeight: ?number = 150,
      rowHeight={51} //30 matthias
      // Configuration of a dropping element. Dropping element is a "virtual" element
      // which appears when you drag over some element from outside.
      // It can be changed by passing specific parameters:
      //  i - id of an element
      //  w - width of an element
      //  h - height of an element
      //droppingItem?: { i: string, w: number, h: number },

      //
      // Flags
      //
      // isDraggable: ?boolean = true,
      // isResizable: ?boolean = true,
      // isBounded: ?boolean = true,
      isDraggable={false}
      isResizable={false}
      //isBounded={false} //Patrick
      // Uses CSS3 translate() instead of position top/left.
      // This makes about 6x faster paint performance
      // useCSSTransforms: ?boolean = true,
      useCSSTransforms={true}
      // If parent DOM node of ResponsiveReactGridLayout or ReactGridLayout has "transform: scale(n)" css property,
      // we should set scale coefficient to avoid render artefacts while dragging.
      // transformScale: ?number = 1,
      transformScale={1}
      // If true, grid items won't change position when being
      // dragged over.
      // preventCollision: ?boolean = false;
      preventCollision={false}
      // If true, droppable elements (with `draggable={true}` attribute)
      // can be dropped on the grid. It triggers "onDrop" callback
      // with position and event object as parameters.
      // It can be useful for dropping an element in a specific position
      //
      // NOTE: In case of using Firefox you should add
      // `onDragStart={e => e.dataTransfer.setData('text/plain', '')}` attribute
      // along with `draggable={true}` otherwise this feature will work incorrect.
      // onDragStart attribute is required for Firefox for a dragging initialization
      // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
      // isDroppable: ?boolean = false
      //isDroppable={false}
      // Defines which resize handles should be rendered
      // Allows for any combination of:
      // 's' - South handle (bottom-center)
      // 'w' - West handle (left-center)
      // 'e' - East handle (right-center)
      // 'n' - North handle (top-center)
      // 'sw' - Southwest handle (bottom-left)
      // 'nw' - Northwest handle (top-left)
      // 'se' - Southeast handle (bottom-right)
      // 'ne' - Northeast handle (top-right)
      // resizeHandles: ?Array<'s' | 'w' | 'e' | 'n' | 'sw' | 'nw' | 'se' | 'ne'> = ['se']
      // resizeHandles = ['se'],
      // Custom component for resize handles
      //resizeHandle?: ReactElement<any> | ((resizeHandleAxis: ResizeHandleAxis) => ReactElement<any>)

      //
      // Callbacks
      //

      /**
       * Calls back with breakpoint and new number pf cols.
       */
      onBreakpointChange={(newBreakpoint: string) => {
        if (onBreakpointChange) onBreakpointChange(newBreakpoint);
      }}
      //
      // Callback so you can save the layout.
      // Calls back with (currentLayout) after every drag or resize stop.
      /*onLayoutChange={(currentLayout: Layout[]) => {
        if (onLayoutChange) onLayoutChange(currentLayout);
      }}*/
      onWidthChange={(containerWidth: number) => {
        getBreakpointFromWidth(containerWidth);
      }}
      //
      // All callbacks below have signature (layout, oldItem, newItem, placeholder, e, element).
      // 'start' and 'stop' callbacks pass `undefined` for 'placeholder'.
      //
      //type ItemCallback = (layout: Layout, oldItem: LayoutItem, newItem: LayoutItem,
      //                    placeholder: LayoutItem, e: MouseEvent, element: HTMLElement) => void;

      // // Calls when drag starts.
      // onDragStart: ItemCallback,
      // // Calls on each drag movement.
      // onDrag: ItemCallback,
      // // Calls when drag is complete.
      // onDragStop: ItemCallback,
      // // Calls when resize starts.
      // onResizeStart: ItemCallback,
      // // Calls when resize movement happens.
      // onResize: ItemCallback,
      // // Calls when resize is complete.
      // onResizeStop: ItemCallback,
      // // Calls when an element has been dropped into the grid from outside.
      // onDrop: (layout: Layout, item: ?LayoutItem, e: Event) => void

      // Ref for getting a reference for the grid's wrapping div.
      // You can use this instead of a regular ref and the deprecated `ReactDOM.findDOMNode()`` function.
      //innerRef: ?React.Ref<"div">
      //
      // const [items, setitems] = useState(3);
      //
      //
      // Number of columns in this layout.
      // cols: number = 12;
      compactType={"vertical"}
      cols={{
        xl: LARGE_WIDTH,
        lg: LARGE_WIDTH,
        md: LARGE_WIDTH,
        sm: LARGE_WIDTH,
        xs: SMALL_WIDTH,
        xxs: SMALL_WIDTH,
      }}
      breakpoints={breakpoints}
      children={children as any}
    />
  );
}
