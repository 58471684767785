export const KPIKeys = [
  /*
    The availability indicates the proportion of occupied
    time without technical shortcomings. [%] Base: Occupied time
     */
  "kpi_availability",
  /*
    The productivity indicates the proportion of utilisation time (occupied time without any shortcomings).
     */
  "kpi_productivity",
  /*
    The performance indicates the proportion of max.
    performance, e. g. the speed ratio. [%] Base: max speed
     */
  "kpi_performance_ratio",
  /*
    The quality indicates the proportion of flawless output.
    [%] Base: total output
     */
  "kpi_quality_ratio",
] as const;
type TKPIType = (typeof KPIKeys)[number];

export default TKPIType;
