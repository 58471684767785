import {
  IServiceOrderSettingsFromServer,
  IServiceOrderSettingsUpdate,
} from "../api/IServiceOrderSettings";
import IVariable from "../api/IVariable";

export function transformServiceOrdersForTable(
  serviceOrders: IServiceOrderSettingsFromServer[],
): IServiceOrderSettingsUpdate[] {
  const getValueLabel = (variable: IVariable): string =>
    `${variable.value} ${variable.unit}`;

  return serviceOrders.map((so) => {
    const {
      id,
      name,
      responsible,
      category,
      component,
      variable,
      description,
      interval,
      preWarning,
      isActive,
    } = so;
    const responsibleName = `${responsible.firstName} ${responsible.lastName}`;
    return {
      id,
      name,
      description,
      interval,
      preWarning,
      isActive,
      categoryName: category.name,
      componentName: component.name,
      variableName: variable.name,
      responsibleName,
      value: getValueLabel(variable),
      variableId: variable.id,
      responsibleId: responsible.id,
      categoryId: category.id,
      componentId: component.id,
    };
  });
}
