import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  pieChartWrapper: {
    "& .recharts-text": {
      fontFamily: "Titillium Web",
    },
  },
});

export default useStyles;
