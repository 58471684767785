import { useMemo } from "react";
import { EApiState } from "../../../../../../api";
import {
  IJobAbort,
  IJobFromServer,
} from "../../../../../../api/jobs/interfaces/IJobSettings";
import abortJob from "../../../../../../api/jobs/mutations/abortJob";
import { useMutation } from "@apollo/client";
import getJobList from "../../../../../../api/jobs/queries/getJobList";
import unabortJob from "../../../../../../api/jobs/mutations/unabortJob";
import { IJobMutationVariables } from "../../../../../../api/jobs/interfaces/IJobMutationVariables";
import { IJobQueryVariables } from "../queries";

interface IAbortJobResponse {
  abortedJob: IJobFromServer;
}

export function useAbortJobMutation(isAbortMutation: boolean): {
  abortJobMutation: (
    jobInput: IJobAbort,
    fetchVariables: IJobQueryVariables,
  ) => void;
  apiState: EApiState;
  abortError?: string;
} {
  const mutation = isAbortMutation ? abortJob : unabortJob;
  const [mutateFunction, { data, loading, error }] =
    useMutation<IAbortJobResponse>(mutation);

  const getApiState = useMemo(() => {
    const hasError = error != undefined;
    if (!hasError && !loading && data != null) {
      return EApiState.OK;
    } else if (hasError) {
      return EApiState.ERROR;
    } else if (loading) {
      return EApiState.LOADING;
    } else {
      return EApiState.EMPTY;
    }
  }, [loading, error, data]);

  return {
    abortJobMutation: (jobInput: IJobAbort, fetchVariables): void => {
      const variables: IJobMutationVariables = {
        jobId: jobInput.jobId,
        abortMessage: jobInput.abortMessage,
        vid: jobInput.vid,
      };
      mutateFunction({
        variables,
        refetchQueries: [
          {
            query: getJobList,
            variables: {
              filter: {
                aborted: fetchVariables.aborted,
                deleted: fetchVariables.deleted,
                done: fetchVariables.done,
                machineTypeId: fetchVariables.machineTypeId,
              },
            },
          },
        ],
      }).catch(
        // eslint-disable-next-line no-console
        console.error,
      );
    },
    apiState: getApiState,
    abortError: error?.message,
  };
}
