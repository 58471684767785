import { makeStyles } from "tss-react/mui";
import { colors } from "../../../../../../shared/theme";

const useStyles = makeStyles()({
  default: {
    height: 160,
    width: "100%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: `${colors.yellow_base} !important`,
      color: colors.blue_base,
    },
  },
  inactive: {
    // div: {
    backgroundColor: colors.blue_base,
    color: colors.white_base,
    // },
  },
  active: {
    // div: {
    backgroundColor: colors.white_base,
    color: colors.blue_base,
    // },
  },
});

export default useStyles;
