import React from "react";
import LinkCard from "./linkCard";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { TFunction } from "i18next";

interface IProps {
  classes: {
    link: string;
    disabledLink: string;
    linkContainer: string;
  };
  t: TFunction;
}

export default function LinkCardWrapper({
  classes,
  t,
}: IProps): React.ReactElement {
  return (
    <LinkCard
      keyLabel="3"
      label={t("service.serviceParts.linkText")}
      link="https://georg.cadshare.com"
      styles={{
        linkStyle: `${classes.link} ${classes.disabledLink}`,
        gridStyle: classes.linkContainer,
      }}
      icon={
        <ArrowForwardIcon color="disabled" sx={{ height: 16, width: 16 }} />
      }
    />
  );
}
