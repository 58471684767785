import { IHeader } from "../../../index";
import React from "react";
import useStyles from "./styles";

interface IProps {
  label: string;
  sublabel: string;
}

export default function MachineOption({
  label,
  sublabel,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  return (
    <div>
      <IHeader
        headline={label}
        headlineColor="primary"
        headlineVariant="h4"
        tagline={sublabel}
        taglineColor="primary"
        taglineVariant="body1"
        styles={{ headline: classes.headLine, tagline: classes.headLine }}
      />
    </div>
  );
}
