import React, { useEffect, useMemo, useState } from "react";
import useStyles from "./styles";
import createUtcTimestamp from "../../../services/createUtcTimestamp";
import JobTimePeriodSelection, {
  JobTimeSelectionValues,
  TJobTimeSelectionKey,
} from "./components/jobTimePeriodSelection";
import DateTimeSelection from "../dateTimeSelection";
import { useTranslation } from "react-i18next";
import { EErrorState, validateTime } from "./validation";
import { Box } from "@mui/material";
import moment from "moment-timezone";
import { getTimezone } from "../../../helper/time/timezone";

interface IProps {
  initPeriod: TJobTimeSelectionKey;
  initTime: Date;
  onTimeChanged: (
    selectedPeriod: TJobTimeSelectionKey | undefined,
    newDateStr: Date | undefined,
  ) => void;
  isPastAllowed?: boolean;
}
function isValidDate(d: unknown): boolean {
  return d instanceof Date && !Number.isNaN(d as unknown as number);
}

function isJobTimeSelectionKeyType(
  arg: TJobTimeSelectionKey | unknown,
): arg is TJobTimeSelectionKey {
  return JobTimeSelectionValues.some((element) => element === arg);
}

export default function JobDateTimeSelection({
  initPeriod,
  initTime,
  onTimeChanged,
  isPastAllowed = false,
}: IProps): React.ReactElement {
  const { t } = useTranslation();
  const timezone = getTimezone();
  const [isIndividualSelected, setIsIndividualSelected] = useState<boolean>(
    () => {
      return initPeriod === "individual";
    },
  );

  const [jobDateTime, setJobDateTime] = useState<Date | undefined>(() => {
    return initTime != null ? initTime : new Date();
  });
  const [selectedPeriod, setSelectedPeriod] = useState<
    TJobTimeSelectionKey | undefined
  >(initPeriod);
  const [hasIndividualTimeSelectionErr, setHasIndividualTimeSelectionErr] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorState, setErrorState] = useState<EErrorState>(
    EErrorState.NO_ERROR,
  );

  const errorTranslationMap = useMemo(() => {
    return {
      [EErrorState.EMPTY_DATE]: t("common.timeSelection.error.emptyDateTime"),
      [EErrorState.HAS_DATE_ERROR]: t(
        "common.timeSelection.error.dateTimeError",
      ),
      [EErrorState.TIME_IN_PAST]: t(
        "common.timeSelection.error.dateTimeInPast",
      ),
      [EErrorState.NO_ERROR]: "",
    };
  }, [t]);

  useEffect(() => {
    const error = jobDateTime
      ? validateTime(
          jobDateTime,
          timezone,
          hasIndividualTimeSelectionErr,
          isPastAllowed,
        )
      : EErrorState.EMPTY_DATE;
    if (errorState !== error) {
      setErrorState(error);
      setErrorMessage(errorTranslationMap[error]);
    }
  }, [jobDateTime, isPastAllowed, hasIndividualTimeSelectionErr]);

  useEffect(() => {
    onTimeChanged(selectedPeriod, jobDateTime);
  }, [errorState, selectedPeriod, jobDateTime]);

  const { classes } = useStyles();
  return (
    <div className={classes.box}>
      <div className={classes.dropDown}>
        <JobTimePeriodSelection
          initPeriod={initPeriod}
          name={t("taskManager.jobManagement.form.timeSelection.created-at")}
          onTimeChange={(timePeriod: TJobTimeSelectionKey) => {
            let newJobDateTime: Date | undefined = undefined;
            if (isJobTimeSelectionKeyType(timePeriod)) {
              if (timePeriod && timePeriod !== "individual") {
                newJobDateTime = moment
                  .tz(createUtcTimestamp(true, timePeriod, timezone), timezone)
                  .toDate();
              } else {
                newJobDateTime = moment.tz(timezone).toDate();
              }
            }
            setJobDateTime(newJobDateTime);
            setSelectedPeriod(timePeriod);
          }}
          label={t("taskManager.jobManagement.form.timeSelection.created-at")}
          onSetIndividualSelected={setIsIndividualSelected}
        />
      </div>
      {isIndividualSelected && (
        <div className={classes.dateTimeSelection}>
          <DateTimeSelection
            value={jobDateTime}
            onTimeSelect={(newDateTime: Date) => {
              if (!hasIndividualTimeSelectionErr && isValidDate(newDateTime)) {
                setJobDateTime(newDateTime);
                // onTimeChanged(selectedPeriod, newDateTime);
              }
            }}
            mode={"datetime"}
            onError={(hasError) => {
              setHasIndividualTimeSelectionErr(hasError);
            }}
          />
          {errorState !== EErrorState.NO_ERROR ? (
            <Box className={classes.errorBox}>{errorMessage}</Box>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
}
