import { Box, Typography } from "@mui/material";
import React from "react";

interface IProps {
  color?: string;
  label: string;
}

function TooltipRow({ color, label }: IProps): React.ReactElement {
  if (label === "") return <></>;
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexWrap: "nowrap",
      }}
    >
      {color !== undefined && color !== "" && color !== null && (
        <Box
          style={{
            marginRight: 8,
            backgroundColor: color,
            height: 12,
            width: 12,
          }}
        />
      )}
      <Typography variant="caption" color="primary">
        {label}
      </Typography>
    </div>
  );
}
export default TooltipRow;
