import React, { useCallback, useMemo, useRef, useState } from "react";
import GeorgModal from "../../../../../../shared/components/modal";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import SaveCancelBtnPair from "../../../../../../shared/components/saveCancelBtnPair";
import useStyles from "./styles";
import validation, { EValidationResult, MAX_REASON_LENGTH } from "./validation";
import { useKeyPressed } from "../../../../../../helper";

interface IProps {
  isModalVisible: boolean;
  title: string;
  question: string;
  toBeAborted: boolean;
  onCancel: () => void;
  onClose: () => void;
  onSubmit: (abortMessage: string) => void;
}

// eslint-disable-next-line complexity
export default function JobAbortForm({
  isModalVisible,
  title,
  question,
  toBeAborted,
  onCancel,
  onClose,
  onSubmit,
}: IProps): React.ReactElement {
  const modalRef = useRef<HTMLDivElement>(null);

  const { classes } = useStyles();
  const { t } = useTranslation();
  const [abortMessage, setAbortMessage] = useState<string>("");
  const validationResult = useMemo(() => {
    return validation(abortMessage);
  }, [abortMessage]);

  const onSubmitCallback = useCallback(() => {
    if (isContentValid) {
      onSubmit(abortMessage);
    }
  }, [abortMessage, validationResult]);

  const isContentValid = useMemo(() => {
    return validationResult === EValidationResult.OK || !toBeAborted;
  }, [validationResult]);

  useKeyPressed("Enter", onSubmitCallback, modalRef.current);

  const reasonLength = abortMessage.length;
  const reasonText = useMemo(() => {
    return t("taskManager.jobManagement.abortDialog.reason");
  }, [t]);

  return (
    <GeorgModal isModalVisible={isModalVisible} onClose={onClose} title={title}>
      <>
        <div className={classes.question}>{question}</div>
        <div
          className={`${classes.container} ${
            toBeAborted ? "" : classes.withPadding
          }`}
        >
          {toBeAborted && (
            <>
              <Grid size={12}>
                <TextField
                  name="reason"
                  label={
                    reasonLength === 0
                      ? reasonText
                      : `${reasonText} (${reasonLength}/${MAX_REASON_LENGTH})`
                  }
                  type={"string"}
                  value={abortMessage}
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLTextAreaElement | HTMLInputElement
                    >,
                  ) => {
                    setAbortMessage(event.currentTarget.value);
                  }}
                  error={
                    validationResult === EValidationResult.REASON_EMPTY ||
                    validationResult ===
                      EValidationResult.REASON_LENGTH_VIOLATED
                  }
                  fullWidth
                  margin="normal"
                  variant="standard"
                />
              </Grid>
              <Grid className={classes.placeHolderRow} size={{ md: 6, xs: 12 }}>
                {t(
                  `taskManager.jobManagement.abortDialog.validation.${validationResult}`,
                )}
              </Grid>
            </>
          )}
          <Grid size={12} className={classes.lastItem}>
            <SaveCancelBtnPair
              disabled={!isContentValid}
              onCancelClicked={onCancel}
              onSaveClicked={() => {
                onSubmitCallback();
              }}
            />
          </Grid>
        </div>
      </>
    </GeorgModal>
  );
}
