import React, { useMemo, useContext, useCallback } from "react";
import { IButton, IMenu } from "../../../../shared/components";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import useStyles from "./styles";
import PersonIcon from "@mui/icons-material/PersonOutlineOutlined";
import InfoIcon from "@mui/icons-material/Info";
import ErrorIcon from "@mui/icons-material/Error";
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
import { useNavigate, useLocation } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Badge, useMediaQuery, useTheme } from "@mui/material";
import useClaims from "../../../../auth/hooks/useClaims";
import LogoutIcon from "@mui/icons-material/Logout";
import { serviceWorkerUpdated } from "../../../../redux/reducers/serviceWorkerTokenReducer";
import { useSelector } from "react-redux";
import GeorgStorageContext from "../../../../context/GeorgStorageContext";
import WithToggleableTooltip from "../../../withToggableTooltip";

export default function LoggedInButton(): React.ReactElement {
  const isServiceWorkerUpdated = useSelector(serviceWorkerUpdated);
  const { timezone } = useContext(GeorgStorageContext);
  const { t } = useTranslation();
  const { instance } = useMsal();
  const navigate = useNavigate();
  const location = useLocation();
  const claims = useClaims();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { breakpoints } = useTheme();
  const isWidthAboveMD = useMediaQuery(breakpoints.up("md"), { noSsr: true });
  // Handling menu open and close.
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const isTimezoneDifferent = browserTimezone !== timezone;

  const { classes } = useStyles();
  const badgeStyle = { width: "15px", height: "15px" };

  const isCurrentPath = useCallback(
    (path: string) => location.pathname === path,
    [location.pathname],
  );

  const badgeTooltipText = useMemo(() => {
    const updateRequired = isServiceWorkerUpdated
      ? t("version.updateAvailable")
      : "";
    const timezoneMismatch = isTimezoneDifferent ? t("timezone.title") : "";
    const newLineText =
      isServiceWorkerUpdated && isTimezoneDifferent ? "\n" : "";
    return `${updateRequired}${newLineText}${timezoneMismatch}`;
  }, [isServiceWorkerUpdated, isTimezoneDifferent, t]);

  const mainWithBadgeIcon = useMemo(() => {
    const Icon = isWidthAboveMD ? AccountCircleIcon : MoreVertIcon;
    return (
      <WithToggleableTooltip
        tooltipInfo={{
          label: badgeTooltipText,
        }}
        smallTooltip={true}
        placement="bottom"
        hasBulletPoints={isServiceWorkerUpdated && isTimezoneDifferent}
        isFirstHasBulletPoint={true}
      >
        <Badge
          badgeContent={<ErrorIcon style={badgeStyle} color="warning" />}
          invisible={!isServiceWorkerUpdated && !isTimezoneDifferent}
        >
          <Icon color="primary" />
        </Badge>
      </WithToggleableTooltip>
    );
  }, [
    isServiceWorkerUpdated,
    isTimezoneDifferent,
    isWidthAboveMD,
    badgeTooltipText,
  ]);

  const versionWithBadgeIcon = useMemo(() => {
    return (
      <Badge
        badgeContent={<ErrorIcon style={badgeStyle} color="warning" />}
        invisible={!isServiceWorkerUpdated}
        showZero={false}
      >
        <InfoIcon />
      </Badge>
    );
  }, [isServiceWorkerUpdated]);

  const profileWithBadgeIcon = useMemo(() => {
    return (
      <Badge
        badgeContent={<ErrorIcon style={badgeStyle} color="warning" />}
        invisible={!isTimezoneDifferent}
        showZero={false}
      >
        <PersonIcon />
      </Badge>
    );
  }, [isTimezoneDifferent]);

  const buttonLabel = useMemo(() => {
    if (claims != null) {
      if (isWidthAboveMD) {
        return claims.name;
      } else {
        return "";
      }
    }
    return "anonymous";
  }, [claims?.name, isWidthAboveMD]);

  return (
    <>
      <IButton
        icon={mainWithBadgeIcon}
        label={buttonLabel}
        labelColor={isWidthAboveMD ? "secondary" : undefined}
        labelVariant={isWidthAboveMD ? "caption" : undefined}
        onClick={handleMenuClick}
        styles={{
          button: classes.button,
        }}
      />
      <IMenu
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        items={[
          {
            id: 0,
            Icon: profileWithBadgeIcon,
            label: t("navigations.profile"),
            onClick: () => navigate("/profile/dashboard"),
            isHighlighted: isCurrentPath("/profile/dashboard"),
          },
          {
            id: 1,
            Icon: versionWithBadgeIcon,
            label: t("navigations.version"),
            onClick: () => navigate("/version"),
          },
          {
            id: 2,
            Icon: <LogoutIcon />,
            label: t("navigations.logout"),
            onClick: () => instance.logout(),
          },
        ]}
        styles={{
          menuPanel: classes.menuPanel,
          menuItems: classes.menuItems,
          menuItemLabel: classes.menuItemLabel,
        }}
      />
    </>
  );
}
