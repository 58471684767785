import { useEffect, useRef } from "react";

type TCallback = () => void;

export default function useInterval(callback: TCallback, delay: number): void {
  const savedCallback = useRef<TCallback | null>(null);

  // Save the latest callback if it changes
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay == null) {
      return;
    }

    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }

    // Set up the interval and clear it on cleanup
    const id = setInterval(tick, delay);
    return () => {
      clearInterval(id);
    };
  }, [delay]);
}
