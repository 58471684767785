import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
// custom imports
import GeorgToast from "../../shared/components/georgToast";
import GeorgStorageContext from "../../context/GeorgStorageContext";
import useStyles from "./styles";

export default function TimezoneToast(): React.ReactElement {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { timezone, setTimezone, ignoreTimezone, setIgnoreTimezone } =
    useContext(GeorgStorageContext);
  const [openTimezoneToast, setOpenTimezoneToast] = useState(false);

  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    setOpenTimezoneToast(!ignoreTimezone && browserTimezone !== timezone);
  }, [timezone, browserTimezone, ignoreTimezone]);

  const synchronizeTimezone = useCallback(
    async (newTimezone: string) => {
      const timezone = newTimezone;
      setIgnoreTimezone(false);
      await setTimezone(timezone);
    },
    [setTimezone],
  );

  // Timezone Toast Handling
  const onHandleSynchronize = () => {
    onHandleTimezoneClose();
    synchronizeTimezone(browserTimezone);
  };

  const onHandleTimezoneClose = () => {
    setOpenTimezoneToast(false);
    setIgnoreTimezone(true);
  };

  const textComponent = (
    <React.Fragment>
      <div className={classes.cardContainer}>
        <Typography color="secondary" className={classes.titleText}>
          {t("timezone.title")}
        </Typography>
        <Typography
          className={classes.textContainer}
          color={"secondary"}
          variant="body1"
        >
          {t("timezone.description")}
        </Typography>
        <Box className={classes.smallTextContainerWithTopPadding}>
          <Typography
            color="secondary"
            variant="body2"
            className={classes.boldText}
          >
            {t("timezone.dashboard")}
          </Typography>
          <Typography
            color="secondary"
            variant="body2"
            className={classes.text}
          >
            {timezone}
          </Typography>
        </Box>

        <Box className={classes.smallTextContainer}>
          <Typography
            color="secondary"
            variant="body2"
            className={classes.boldText}
          >
            {t("timezone.browser")}
          </Typography>
          <Typography
            color="secondary"
            variant="body2"
            className={classes.text}
          >
            {browserTimezone}
          </Typography>
        </Box>
        <Box className={classes.smallTextContainerWithTopPadding}>
          <Typography
            color="secondary"
            variant="body2"
            className={classes.lightText}
          >
            {t("timezone.footerDescription")}
          </Typography>
        </Box>
      </div>
    </React.Fragment>
  );

  return (
    <GeorgToast
      isToastOpen={openTimezoneToast}
      onHandleClose={onHandleTimezoneClose}
      onHandleUpdate={onHandleSynchronize}
      textContent={textComponent}
      actionButtonLabels={{
        actionText: t("timezone.synchronize"),
        cancelText: t("timezone.close"),
      }}
    />
  );
}
