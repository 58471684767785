import { useEffect, useState } from "react";
import INewsInformation from "./INewsInformation";
import XML_MOCK from "./rssmock";

interface IQueryResponse {
  isLoading: boolean;
  hasError: boolean;
  payload: INewsInformation[] | undefined;
}

function removeTags(str: string) {
  if (str === null || str === "") return str;
  return str.replace(/(<([^>]+)>)/gi, "");
}

function replaceNbsps(str: string) {
  return str.replace(/&nbsp;/g, " ");
}

function toNewsItem(item) {
  let content = "";
  const header = item.getElementsByTagName("title")[0];
  if (item.getElementsByTagName("description")[0].innerHTML === "") {
    content = replaceNbsps(
      removeTags(item.getElementsByTagName("content:encoded")[0].innerHTML),
    ).substring(0, 100);
  } else {
    content = replaceNbsps(
      removeTags(item.getElementsByTagName("description")[0].innerHTML),
    );
  }
  const enclosureElements = item.getElementsByTagName("enclosure");

  const enclosure =
    enclosureElements != null && enclosureElements.length > 0
      ? enclosureElements[0]
      : "";
  const uri = item.getElementsByTagName("link")[0];
  const image = enclosure !== "" ? enclosure.attributes[0].nodeValue : "";
  return {
    header: header.innerHTML,
    content: content + "...",
    image,
    uri: uri.innerHTML,
  };
}

// tested on georg.com
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function loadxml() {
  return new Promise<string>((resolve, reject) => {
    fetch("https://www.georg.com/en/?type=9818", {
      mode: "cors",
    })
      .then((response) => response.text())
      .then((str) => {
        if (!str) {
          reject("Did not receive any data from request.");
          return;
        }
        resolve(str);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getItems(XML_Doc: string): [] {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(XML_Doc, "text/xml");
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return [...xmlDoc.getElementsByTagName("item")];
}

export default function useNewsQuery(): IQueryResponse {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [data, setData] = useState<INewsInformation[] | undefined>(undefined);

  useEffect(() => {
    setIsLoading(true);
    const timeout = setTimeout(() => {
      let items = [];
      loadxml()
        .then((result) => {
          items = getItems(result);
        })
        .catch(() => {
          items = getItems(XML_MOCK);
        })
        .finally(() => {
          if (items.length > 0) {
            const newsItems = items.map(toNewsItem);
            setIsLoading(false);
            setHasError(false);
            setData(newsItems);
          } else {
            setIsLoading(false);
            setHasError(true);
          }
        });
    }, 800);
    return () => clearTimeout(timeout);
  }, [XML_MOCK]);

  return { isLoading, hasError, payload: data };
}
