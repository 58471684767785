import React, { useMemo } from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";
import TabWithMachineSelection from "../../../shared/components/tabsWithMachineTimePeriodSelection";
import DocumentSearchView from "../views/documentSearchView";
import DocumentTypeView from "../views/documentTypeView";
// Refactor here
const basePath = "/service/documentation/machine";

export default function Service(): React.ReactElement {
  const { t } = useTranslation();
  const tabConfig = useMemo(
    () => [
      {
        label: t("service.documentsearch.documenttype_title"),
        path: "/doctype",
      },
      {
        label: t("service.documentsearch.documentsearch_title"),
        path: "/docsearch",
      },
    ],
    [],
  );

  return (
    <div>
      <TabWithMachineSelection basePath={basePath} tabConfig={tabConfig}>
        <DocumentTypeView keywordSearch={false} />
        <DocumentSearchView keywordSearch={true} />
      </TabWithMachineSelection>
    </div>
  );
}
