import gql from "graphql-tag";

export default gql`
  mutation addJob($jobData: JobCreateInputType!) {
    addJob(jobData: $jobData) {
      vid
      abortMessage
      abortedAt
      createdBy
      createdById
      deletedAt
      description
      doneAt
      id
      jobId
      machineType {
        id
      }
      priority
      scheduledAt
    }
  }
`;
