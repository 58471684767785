import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
  rowContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "row",
    alignItems: "flex-end",
    height: "calc(100% - 25px - 10px)",
  },
  lineContainer: {
    borderBottom: "2px solid #aaa",
    margin: "0 6px",
  },
  axisContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "row",
    paddingTop: "2px",
    height: "auto",
    justifyItems: "center",
  },
});

export default useStyles;
