import { Box } from "@mui/material";
import useStyles from "./styles";
import DateTimeSelection from "../dateTimeSelection";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { EErrorState, validateTimeRange } from "./validation";
import { timezoneUTC } from "../../../helper/time/timezone";
import { GetCurrentTime } from "../../../helper/time/formatting/dateToIsoUtils";

interface IProps {
  locale?: string;
  isFutureAllowed?: boolean;
  onError?: (state: EErrorState) => void;
  onChange: (startDate: Date, endDate: Date) => void;
  onlyDate?: boolean;
  initStart?: Date;
  initEnd?: Date;
  timezone?: string;
  isStartTimeChangePending?: boolean;
  isEndTimeChangePending?: boolean;
}

export default function DateTimeRangeSelection({
  isFutureAllowed = true,
  onChange,
  onError,
  onlyDate = false,
  initStart,
  initEnd,
  timezone = timezoneUTC,
  isStartTimeChangePending = false,
  isEndTimeChangePending = false,
}: IProps): React.ReactElement {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  const [startDate, setStartDate] = useState<Date>(
    initStart != null ? initStart : GetCurrentTime(timezone),
  );
  const [endDate, setEndDate] = useState<Date>(
    initEnd != null ? initEnd : GetCurrentTime(timezone),
  );
  const [hasStartDateError, setHasStartDateErr] = useState<boolean>(false);
  const [hasEndDateError, setHasEndDateErr] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errorState, setErrorState] = useState<EErrorState>(
    EErrorState.NO_ERROR,
  );

  const errorTranslationMap = useMemo(() => {
    return {
      [EErrorState.HAS_START_DATE_ERROR]: t(
        "common.timeRange.error.startDateError",
      ),
      [EErrorState.HAS_END_DATE_ERROR]: t(
        "common.timeRange.error.endDateError",
      ),
      [EErrorState.END_TIME_BEFORE_START_TIME]: t(
        "common.timeRange.error.endTimeBeforeStartTime",
      ),
      [EErrorState.START_TIME_IN_FUTURE]: t(
        "common.timeRange.error.startTimeInFuture",
      ),
      [EErrorState.IS_END_TIME_IN_FUTURE]: t(
        "common.timeRange.error.endTimeInFuture",
      ),
      [EErrorState.NO_ERROR]: "",
    };
  }, [t]);

  useEffect(() => {
    const error = validateTimeRange(
      startDate,
      endDate,
      timezone,
      hasStartDateError,
      hasEndDateError,
      isFutureAllowed,
    );
    if (errorState !== error) {
      setErrorState(error);
      setErrorMessage(errorTranslationMap[error]);
    }
  }, [startDate, endDate, isFutureAllowed, hasStartDateError, hasEndDateError]);

  const { classes } = useStyles();

  useEffect(() => {
    onChange(startDate, endDate);
  }, [errorState, startDate, endDate]);

  useEffect(() => {
    if (onError) {
      onError(errorState);
    }
  }, [errorState, startDate, endDate]);
  return (
    <div>
      <Box className={classes.timeRangeBox}>
        <DateTimeSelection
          mode={onlyDate ? "date" : "datetime"}
          onTimeSelect={(newStartDate: Date) => {
            setStartDate(newStartDate);
          }}
          label={t("common.timeRange.startLabel")}
          value={startDate}
          locale={locale}
          onError={(hasError) => {
            setHasStartDateErr(hasError);
          }}
          isChangePending={isStartTimeChangePending}
        />
        <DateTimeSelection
          mode={onlyDate ? "date" : "datetime"}
          onTimeSelect={(newEndDate: Date) => {
            setEndDate(newEndDate);
          }}
          label={t("common.timeRange.endLabel")}
          value={endDate}
          locale={locale}
          onError={(hasError) => {
            setHasEndDateErr(hasError);
          }}
          isChangePending={isEndTimeChangePending}
        />
      </Box>
      {errorState !== EErrorState.NO_ERROR ? (
        <Box className={classes.errorBox}>{errorMessage}</Box>
      ) : (
        <></>
      )}
    </div>
  );
}
