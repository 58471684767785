import BlockIcon from "@mui/icons-material/Block";
import InfoIcon from "@mui/icons-material/Info";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SvgIconTypeMap } from "@mui/material/SvgIcon/SvgIcon";

const STATE_ICON_MAP = {
  indicator_state_notavailable: BlockIcon,
  indicator_state_idle: InfoIcon,
  indicator_state_executing: ThumbUpAltIcon,
};

export default function getIconForStateName(
  stateName: string,
): OverridableComponent<SvgIconTypeMap> {
  const icon = STATE_ICON_MAP[stateName];
  return icon || BlockIcon;
}

/*
former state icon assignments:
  /*
  {
    Icon: ThumbUpAltIcon,
    statusCode: 30,
    statusMessage: "executing",
  },
  {
    Icon: InfoIcon,
    statusCode: 28,
    statusMessage: "orgDowntime",
  },
  {
    Icon: InfoIcon,
    statusCode: 33,
    statusMessage: "waiting",
  },
  {
    Icon: CancelIcon,
    statusCode: 29,
    statusMessage: "techDowntime",
  },
  {
    Icon: BlockIcon,
    statusCode: 37,
    statusMessage: "notAvailable",
  },
  */
