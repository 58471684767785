import { makeStyles } from "tss-react/mui";
import { colors } from "../../theme";

const useStyles = makeStyles()({
  timeRangeBox: {
    display: "flex",
    // marginTop: 20,
  },
  errorBox: {
    color: colors.red_base,
    fontFamily: "Titillium Web",
  },
});

export default useStyles;
