import { useCallback, useState } from "react";
import useLocalStorage from "../persistence/useLocalStorage";
// import { useUnleashContext } from "@unleash/proxy-client-react";

interface IPreviewSettingsApi {
  isPreviewEnabled: boolean;
  setPreviewEnabled: (enabled: boolean) => void;
}

const Preview_LS_KEY = "Preview";

/*
Please use this not directly but with GeorgStorageProvider
 */
export default function usePreviewSettings(): IPreviewSettingsApi {
  const { load, set } = useLocalStorage();
  const isPreviewEnabledFromLS = load(Preview_LS_KEY) === "true";
  const [isPreviewEnabled, setIsPreviewEnabledInternal] = useState<boolean>(
    isPreviewEnabledFromLS,
  );
  const setPreviewEnabled = useCallback((enabled: boolean) => {
    setIsPreviewEnabledInternal(enabled);
    set(Preview_LS_KEY, `${enabled}`);
    // //dynamicupdate of featureflag context
    // const values = {appName: "mygeorg.com2"};
    // const updateContext = useUnleashContext();
    // useEffect(() => {
    //   // Update context with userrole, tenantID, and isPreviewEnabled
    //   updateContext({ appName });
    // }, [values]);
    // //dynamicupdate of featureflag context
  }, []);

  return { isPreviewEnabled, setPreviewEnabled };
}
