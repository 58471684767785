import { ITimeSlot } from "../../../../redux/reducers/timeSelectReducer";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { IStateListEntry } from "./IStateListEntry";
import IStateListQuery from "./IStateListQuery";

interface IStateListResponse {
  stateListResponse: IStateListEntry[] | undefined;
  isLoading: boolean;
  hasError: boolean;
  isEmpty: boolean;
}

export default function useStateListQuery(
  allIndicatorIds: string[],
  timeSlot: ITimeSlot,
  timezone: string,
  machineTypeId: string,
): IStateListResponse {
  const [stateListResponse, setStateListResponse] = useState<
    IStateListEntry[] | undefined
  >([]);
  const [hasError, setHasError] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  const [doQuery, { data: dataFromServer, loading: isLoading, error }] =
    useLazyQuery(IStateListQuery, {
      variables: {
        startedAfter: timeSlot.startedAfter,
        endedBefore: timeSlot.endedBefore,
        indicatorIdentificationTypeIds: allIndicatorIds,
        machineTypeId,
        shift: timeSlot.shift,
        timezone,
      },
    });
  useEffect(() => {
    doQuery();
  }, []);
  useEffect(() => {
    setHasError(error != null);
    if (dataFromServer != null) {
      setStateListResponse(dataFromServer?.getStateList);
    }
  }, [dataFromServer, error]);
  useEffect(() => {
    const isDataArrEmpty = stateListResponse?.every(
      (entry: IStateListEntry) => {
        return entry.data.length === 0;
      },
    );
    const isEmpty =
      error == null &&
      (stateListResponse == null ||
        isDataArrEmpty ||
        stateListResponse?.length <= 0);
    setIsEmpty(isEmpty);
  }, [stateListResponse]);

  useEffect(() => {
    if (timeSlot) {
      doQuery();
    }
  }, [timeSlot, doQuery]);

  return {
    stateListResponse,
    isLoading,
    hasError,
    isEmpty,
  };
}
