import React, { useCallback, useContext, useMemo } from "react";
import { IInput } from "../../../../../../shared/components";
import { FormLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { map } from "lodash";
import moment from "moment-timezone";
import GeorgStorageContext from "../../../../../../context/GeorgStorageContext";
import { timezoneUTC } from "../../../../../../helper/time/timezone";

export default function TimezoneSelection(): React.ReactElement {
  const { t } = useTranslation();
  const { timezone, setTimezone, setIgnoreTimezone } =
    useContext(GeorgStorageContext);
  const { classes } = useStyles();

  const handleTimezone = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const timezone = event.target.value || timezoneUTC;
      setIgnoreTimezone(false);
      await setTimezone(timezone);
    },
    [setTimezone],
  );

  const allTimezones = useMemo(
    () =>
      map(moment.tz.names(), (name) => ({
        label: name,
        value: name,
      })),
    [],
  );
  return (
    <>
      <div style={{ height: 24 }}>
        <FormLabel id="demo-controlled-radio-buttons-group">
          {t("profile.dashboard.chooseTimezone")}
        </FormLabel>
      </div>
      <div className={classes.inputContainer}>
        <IInput
          disableUnderline
          selectDisplayEmpty
          name="select-timezone"
          onChange={handleTimezone}
          select={true}
          selectOptions={allTimezones}
          styles={{
            rootInputStyles: classes.rootInput,
            textInputStyles: classes.textInput,
            selectIcon: classes.selectIcon,
          }}
          value={timezone}
        />
      </div>
    </>
  );
}
