import { ShiftSelection, TimePeriodSelection } from "../../../components";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import useStyles from "./styles";
import DateTimeRangeSelection from "../dateTimeRangeSelection";
import createUtcTimestamp from "../../../services/createUtcTimestamp";
import {
  IsoDateToTimePickerDate,
  TimePickerDateToIsoString,
} from "../../../helper/time/formatting/dateToIsoUtils";
import { EErrorState } from "../dateTimeRangeSelection/validation";
import { TTimeSelectionKey } from "../../../redux/reducers/timeSelectReducer";
import { useSelector } from "react-redux";
import { selectMachine } from "../../../redux/reducers/machineSelectReducer";
import useShiftOptions from "../../../api/shifts/useShiftOptions";
import {
  getLayoutInfo,
  DATE_TIME_RANGE_LAYOUT_KEY,
  SHIFT_LAYOUT_KEY,
  TIME_PERIOD_LAYOUT_KEY,
  TResizeConfig,
  getLayoutInfoSmall,
} from "./layoutConfig";
import GridPage from "../gridPage";
import { timeRangeHeight } from "../timeSelectionCurrentReference/layoutConfig";
import { EFilterParameters } from "../timeSelectionCurrentReference";
interface IProps {
  initPeriod: TTimeSelectionKey;
  onTimeChanged: (
    selectedPeriod: TTimeSelectionKey,
    newStartDateStr: string,
    newEndDateStr: string,
  ) => void;
  onShiftChanged: (newShift: string) => void;
  period: "current" | "reference";
  periodLabel: string;
  shiftLabel?: string;
  initStartTime: string;
  initEndTime: string;
  initShift: string;
  timezone: string;
  isSmallLayout?: boolean;
  onMoreHeightNeeded: () => void;
  onErrorChange?: (period: string, hasError: boolean) => void;
  changedFilters?: string[];
}

function isValidDate(d: unknown): boolean {
  return (
    d instanceof Date &&
    !Number.isNaN(d as unknown as number) &&
    d.toString() !== "Invalid Date"
  );
}

export default function TimeSelection({
  initPeriod,
  initStartTime,
  initEndTime,
  onTimeChanged,
  onShiftChanged,
  period,
  periodLabel,
  shiftLabel = "",
  initShift,
  timezone,
  isSmallLayout = false,
  onMoreHeightNeeded,
  onErrorChange,
  changedFilters = [],
}: IProps): React.ReactElement {
  const [isIndividualSelected, setIndividualSelected] = useState<boolean>(
    initPeriod === "individual",
  );
  const selectedMachine = useSelector(selectMachine);
  const { shiftOptions } = useShiftOptions(
    parseInt(selectedMachine, 10),
    timezone,
  );
  const [startTime, setStartTime] = useState(initStartTime);
  const [endTime, setEndTime] = useState(initEndTime);
  const [selectedPeriod, setSelectedPeriod] =
    useState<TTimeSelectionKey>(initPeriod);
  const [hasIndividualTimeSelectionErr, setHasIndividualTimeSelectionErr] =
    useState(false);

  useLayoutEffect(() => {
    increaseHeight();
    onMoreHeightNeeded();
  }, []);

  useEffect(() => {
    increaseHeight();
    onMoreHeightNeeded();
  }, [isIndividualSelected]);

  useEffect(() => {
    onTimeChanged(selectedPeriod, startTime, endTime);
  }, [startTime, endTime, selectedPeriod]);
  const { classes } = useStyles();
  const [resizeConf, setResizeconf] = useState<TResizeConfig>(
    {} as TResizeConfig,
  );
  const layoutConf = useMemo(() => {
    return isSmallLayout
      ? getLayoutInfoSmall(resizeConf)
      : getLayoutInfo(resizeConf);
  }, [
    getLayoutInfoSmall,
    getLayoutInfo,
    isSmallLayout,
    resizeConf,
    isIndividualSelected,
  ]);
  function increaseHeight() {
    setResizeconf((prevConfig) => ({
      ...prevConfig,
      [DATE_TIME_RANGE_LAYOUT_KEY]: isIndividualSelected ? timeRangeHeight : 0,
    }));
  }

  const hasPendingChanges = useCallback(
    (filterParameter: string) => changedFilters.includes(filterParameter),
    [changedFilters],
  );

  return (
    <GridPage
      layouts={layoutConf}
      gridBackgroundColor="transparent"
      containerPadding={[0, 0]}
      itemMargin={[0, 0]}
    >
      <div key={TIME_PERIOD_LAYOUT_KEY}>
        <TimePeriodSelection
          initPeriod={initPeriod}
          name={`${period}-period`}
          onTimeChange={(timePeriod: TTimeSelectionKey) => {
            if (timePeriod !== "individual") {
              setStartTime(createUtcTimestamp(true, timePeriod, timezone));
              setEndTime(createUtcTimestamp(false, timePeriod, timezone));
              if (onErrorChange) {
                onErrorChange(period, false);
              }
            }
            setSelectedPeriod(timePeriod);
          }}
          label={periodLabel}
          onSetIndividualSelected={setIndividualSelected}
          isChangePending={hasPendingChanges(EFilterParameters.PERIOD)}
        />
      </div>
      <div key={DATE_TIME_RANGE_LAYOUT_KEY}>
        {isIndividualSelected ? (
          <div className={classes.dateTimeSelection}>
            <DateTimeRangeSelection
              initStart={IsoDateToTimePickerDate(startTime, timezone)}
              initEnd={IsoDateToTimePickerDate(endTime, timezone)}
              onChange={(newStart: Date, newEndTime: Date) => {
                if (
                  !hasIndividualTimeSelectionErr &&
                  isValidDate(newStart) &&
                  isValidDate(newEndTime)
                ) {
                  const startIso = TimePickerDateToIsoString(
                    newStart,
                    timezone,
                  );
                  const endIso = TimePickerDateToIsoString(
                    newEndTime,
                    timezone,
                  );
                  setStartTime(startIso);
                  setEndTime(endIso);
                }
              }}
              isFutureAllowed={false}
              onError={(errState: EErrorState) => {
                const hasError = errState !== EErrorState.NO_ERROR;
                if (onErrorChange) {
                  onErrorChange(period, hasError);
                }
                setHasIndividualTimeSelectionErr(hasError);
              }}
              timezone={timezone}
              isStartTimeChangePending={hasPendingChanges(
                EFilterParameters.STARTED_AFTER,
              )}
              isEndTimeChangePending={hasPendingChanges(
                EFilterParameters.ENDED_BEFORE,
              )}
            />
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <div key={SHIFT_LAYOUT_KEY}>
        <ShiftSelection
          initShift={initShift}
          shiftOptions={shiftOptions}
          name={`${period}-shift`}
          onShiftChange={(shift: string) => {
            onShiftChanged(shift);
          }}
          label={shiftLabel}
          isTextContainerNeeded={!isSmallLayout}
          isChangePending={hasPendingChanges(EFilterParameters.SHIFT)}
        />
      </div>
    </GridPage>
  );
}
