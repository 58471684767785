import { EApiState } from "../../../../../../api";
import { useCallback, useState } from "react";
import { IServiceOrderDone } from "../../api";

export function useDoneMutation(): {
  state: EApiState;
  doneMutation: (serviceOrderTarget: IServiceOrderDone) => void;
} {
  const [state, setState] = useState(EApiState.OK);
  const doneMutation = useCallback((serviceOrderTarget: IServiceOrderDone) => {
    setState(EApiState.LOADING);
    const t = setTimeout(() => {
      alert(
        "done: " +
          serviceOrderTarget.id +
          " " +
          serviceOrderTarget.version +
          " - at: " +
          serviceOrderTarget.serviceTimestamp +
          " - lastService: " +
          serviceOrderTarget.lastService,
      );
      setState(EApiState.OK);
    }, 600);

    return () => clearTimeout(t);
  }, []);
  return { state, doneMutation };
}
