import {
  ReactiveBase,
  // MultiList,
  ReactiveList,
  // SelectedFilters,
  DataSearch,
} from "@appbaseio/reactivesearch";
import { useSelector } from "react-redux";
import {
  selectFinalSearchTerm,
  selectCheckboxes,
} from "../../../redux/reducers/serviceReducer";
import useStyles from "../common/styles";
import GeorgDocumentSearchDataGrid from "../../../shared/components/georgDocumentSearchDataGrid";
import getGridColDef from "./ResultsGridDefinition";
import { Alert, Skeleton } from "@mui/material";
import { react } from "@appbaseio/reactivesearch/lib/types";
import { useTranslation } from "react-i18next";
import { token } from "../../../redux/reducers/tokenReducer";
import Loader from "../../loader";
import useClaims from "../../../auth/hooks/useClaims";
import { selectMachine } from "../../../redux/reducers/machineSelectReducer";
import { isNull } from "lodash";
import { domainUrlMapping } from "../../Apollo";

interface IProps {
  keywordSearch?: boolean;
  searchCriteria?: react;
  sortCriteria?: { and: [] };
}

/**
 * Clamp function
 * @param value value which could be clamped
 * @param max treshold for clamping
 * @returns either the normal value (value is not greater than max) or max (value is greater than max)
 */
function clamp(value, max) {
  if (value <= max) {
    return value;
  }
  return max;
}

export default function DocumentSearchResult(props: IProps) {
  const keywordSelector = useSelector(selectFinalSearchTerm);
  const selectedDoctypes = useSelector(selectCheckboxes);
  const { t } = useTranslation();

  // You can *only* get the translation of the header labels inside a React component and *after* you got the useTranslation() hook
  const headerLabels = [
    t("service.documentsearch.result_table_header.preview"),
    t("service.documentsearch.result_table_header.content"),
    t("service.documentsearch.result_table_header.match"),
    t("service.documentsearch.result_table_header.pageNumber"),
  ];
  const downloadLabel = t("service.documentsearch.downloadLabel");

  const classes = useStyles();
  const { ResultListWrapper } = ReactiveList;
  // get the authentication token from local storage if it exists
  const jwt = useSelector(token);

  const selectedMachine = useSelector(selectMachine);
  const claims = useClaims();
  let url = "";
  if (!isNull(claims)) {
    const domain =
      domainUrlMapping[claims.extension_tenant] || domainUrlMapping.default;
    url = `https://${domain}/${claims.extension_tenant}/es`;
  }
  const app = selectedMachine;
  // const hasIndex = useIndexExistsCheck("https://localhost:9200" + "/" + app);
  // const hasDocuments = useIndexIsPopulated(url + "/" + app + "/count");

  function AddIdToSearchResults(input) {
    const output: object[] = [];
    let currDocument = 0;
    input.forEach((item) => {
      item.id = currDocument;
      output.push(item);
      currDocument++;
    });
    return output;
  }

  if (!jwt) return <Loader />;

  const chosenColumnType = getGridColDef(headerLabels, downloadLabel);
  const loadingColumns = chosenColumnType.map((column) => ({
    ...column,
    sortable: false,
    renderCell: () => {
      // Ensure to include params if you might use them
      if (column.field === "image") {
        return (
          <Skeleton
            variant="rectangular"
            width={299}
            height={242}
            animation="pulse"
            sx={{ borderRadius: 8 }}
          />
        );
      } else if (column.field === "fileName") {
        // Default skeleton for other types of fields
        return (
          <div>
            <Skeleton
              variant="text"
              width="100%"
              height={28}
              animation="pulse"
              sx={{ margin: "10px 0" }}
            />
            <Skeleton variant="text" width={60} height={24} animation="pulse" />
          </div>
        );
      }
      return <Skeleton variant="text" width={50} height={30} />;
    },
  }));

  return (
    <div>
      <ReactiveBase
        url={url}
        app={app}
        headers={{
          Authorization: `Bearer ${jwt}`,
          Accept: "application/x-ndjson, json",
        }}
      >
        {
          <DataSearch
            className={classes.classes.invisible}
            componentId="searchBox"
            title="SearchBox"
            autosuggest={false}
            value={keywordSelector}
            queryFormat="or"
            nestedField="pages"
            customQuery={
              (value) =>
                value && keywordSelector.length > 0
                  ? {
                      query: {
                        nested: {
                          path: "pages",
                          query: {
                            bool: {
                              should: [
                                {
                                  multi_match: {
                                    query: keywordSelector,
                                    fields: [
                                      "fileName^20",
                                      "pages.pageContent^3",
                                    ],
                                    type: "best_fields",
                                    operator: "or",
                                    fuzziness: "AUTO",
                                  },
                                },
                                {
                                  multi_match: {
                                    query: keywordSelector,
                                    fields: [
                                      "fileName.keyword^20",
                                      "pages.pageContent^3",
                                    ],
                                    type: "phrase",
                                    operator: "or",
                                  },
                                },
                                {
                                  multi_match: {
                                    query: keywordSelector,
                                    fields: ["pages.pageContent^3"],
                                    type: "phrase_prefix",
                                    operator: "or",
                                  },
                                },
                              ],
                              minimum_should_match: "1",
                            },
                          },
                          inner_hits: {},
                        },
                      },
                    }
                  : null // { query: { match_none: {} } }
            }
            onQueryChange={function (prevQuery, nextQuery) {
              if ("match_all" in nextQuery["query"]) {
                nextQuery["query"] = { match_none: {} };
              }
            }}
            fieldWeights={[20, 3]}
            highlight={true}
            customHighlight={() => ({
              highlight: {
                order: "score",
                force_source: false,
                pre_tags: ["<mark class='magic'>"],
                post_tags: ["</mark>"],
                fields: {
                  fileName: {},
                  "pages.pageContent": {},
                },
                number_of_fragments: 1, //only one page per document
              },
            })}
            dataField={["fileName", "pages.pageContent"]}
            fuzziness="AUTO"
            // size={5}
          />
        }
        <ReactiveList
          componentId="SearchResult"
          showLoader={true}
          size={5}
          includeFields={["contentType", "fileName", "image", "path"]}
          pages={props.keywordSearch ? 10 : 1}
          // loader="Loading results..."
          showResultStats={true}
          //excludeFields={["pages"]} //No fulltext in results
          defaultQuery={() => {
            if (keywordSelector.length <= 0 || selectedDoctypes.length <= 0) {
              return {
                query: {
                  match_none: {},
                },
              };
            }
            return {
              query: {
                terms: {
                  contentType: selectedDoctypes,
                },
              },
            };
          }}
          renderNoResults={function () {
            if (keywordSelector.length > 0 && selectedDoctypes.length > 0) {
              {
                return (
                  <Alert severity="info">
                    {t("service.documentsearch.messages.no_results_found")}
                  </Alert>
                );
              }
            } else if (
              keywordSelector.length > 0 &&
              selectedDoctypes.length <= 0
            ) {
              return (
                <Alert severity="info">
                  {t("service.documentsearch.messages.select_doctype_hint")}
                </Alert>
              );
            } else if (
              keywordSelector.length <= 0 &&
              selectedDoctypes.length > 0
            ) {
              return (
                <Alert severity="info">
                  {t("service.documentsearch.messages.select_search_hint")}
                </Alert>
              );
            }
            return <></>;
          }}
          renderError={(error) => {
            if (error.responses[0].status != "404") {
              return (
                <Alert severity="error">
                  {t("service.documentsearch.messages.server_error")}
                </Alert>
              );
            }
          }}
          pagination={props.keywordSearch}
          dataField={"fileName"}
          react={{
            and: ["doctypeFilter", "searchBox"],
          }}
          render={({ error, data, loading }) => {
            if (data.length > 0 || loading) {
              AddIdToSearchResults(data);
              const dataLength = loading ? 4 : data.length;
              const columnVisibilityModel = {
                // Hide columns id, the other columns will remain visible
                id: false,
                _score: false,
              };
              return (
                <ResultListWrapper>
                  <div
                    style={{
                      minHeight: 56 + 304 * clamp(dataLength, 6),
                      maxHeight: 56 + 304 * clamp(dataLength, 6),
                    }}
                  >
                    <GeorgDocumentSearchDataGrid
                      autoHeightProp={true}
                      rows={data}
                      columns={chosenColumnType}
                      loadingColumns={loadingColumns}
                      rowSize={304}
                      resultsPerPage={6}
                      columnVisibilityModel={columnVisibilityModel}
                      isLoading={loading}
                    />
                  </div>
                </ResultListWrapper>
              );
            } else if (data.length <= 0 && !error) {
              return <div />;
            }
          }}
        />
      </ReactiveBase>
    </div>
  );
}
