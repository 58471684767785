import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  buttons: {
    display: "flex",
    marginTop: 60,
    marginBottom: 50,
    alignContent: "space-between",
    justifyContent: "space-evenly",
  },
  question: {
    marginTop: 50,
    maxWidth: 400,
  },
}));

export default useStyles;
