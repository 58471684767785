import gql from "graphql-tag";

export default gql`
  query qetAvgOfKpiEntriesWithWorkShifts(
    $startedAfter: DateTime!
    $endedBefore: DateTime!
    $machineTypeId: ID!
    $indicatorIdentificationTypeIds: [ID]!
    $scale: AggregateScale!
    $shift: String!
    $timezone: String!
  ) {
    getAvgOfKpiEntriesWithWorkShifts(
      filter: {
        startedAfter: $startedAfter
        endedBefore: $endedBefore
        indicatorIdentificationTypeIds: $indicatorIdentificationTypeIds
        machineTypeId: $machineTypeId
        scale: $scale
        shift: $shift
        timezone: $timezone
      }
    )
  }
`;
