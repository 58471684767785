import useStyles from "./styles";
import React from "react";
import { SvgIconComponent } from "@mui/icons-material";
interface IProps {
  onClick?: () => void;
  icon: SvgIconComponent;
  disabled?: boolean;
}
export default function IconCell({
  onClick,
  icon,
  disabled,
}: IProps): React.ReactElement {
  const { classes } = useStyles();
  return (
    <div
      onClick={() => {
        if (!disabled && onClick) {
          onClick();
        }
      }}
      className={`${classes.container} ${onClick ? classes.clickable : ""} ${
        disabled ? classes.disabled : ""
      }`}
    >
      {React.createElement(icon)}
    </div>
  );
}
