import { DocumentNode } from "graphql";
import { ITimeSlot } from "../../../../redux/reducers/timeSelectReducer";
import CondensedStateListQuery from "./CondensedStateListQuery";

interface ICondensedStateListQueryResponse {
  query: DocumentNode;
  parameters: {
    variables: {
      startedAfter: string;
      endedBefore: string;
      indicatorIdentificationTypeIds: string[];
      machineTypeId: string;
      shift: string;
      timezone: string;
    };
  };
}

export default function createCondensedStateListQuery(
  indicatorIdentificationTypeIds: string[],
  machineTypeId: string,
  timeSelection: ITimeSlot,
  timezone: string,
): ICondensedStateListQueryResponse {
  const { shift, startedAfter, endedBefore } = timeSelection;
  return {
    query: CondensedStateListQuery,
    parameters: {
      variables: {
        startedAfter,
        endedBefore,
        indicatorIdentificationTypeIds,
        machineTypeId,
        shift,
        timezone,
      },
    },
  };
}
