import { Layout } from "react-grid-layout";
import createLayout from "../../../../shared/components/gridPage/helper/createLayout";

export const LANGUAGE_SELECT_LAYOUT_KEY = "language_select";
export const TIMEZONE_SELECT_LAYOUT_KEY = "timezone_select";
export const TOOLTIP_SELECT_LAYOUT_KEY = "tooltip_select";
export const PREVIEW_SELECT_LAYOUT_KEY = "preview_select";
export const BROWSER_TIMEZONE_LAYOUT_KEY = "timezone_browser";

const layout_LG: Layout[] = [
  { i: LANGUAGE_SELECT_LAYOUT_KEY, x: 0, y: 0, w: 6, h: 1.76 },
  { i: TIMEZONE_SELECT_LAYOUT_KEY, x: 6, y: 0, w: 6, h: 1.76 },
  { i: TOOLTIP_SELECT_LAYOUT_KEY, x: 0, y: 1.76, w: 6, h: 1.76 },
  { i: BROWSER_TIMEZONE_LAYOUT_KEY, x: 6, y: 1.76, w: 6, h: 0, minH: 0 },
  { i: PREVIEW_SELECT_LAYOUT_KEY, x: 0, y: 2, w: 6, h: 1.76 },
];

const layout_XS: Layout[] = [
  { i: LANGUAGE_SELECT_LAYOUT_KEY, x: 0, y: 0, w: 12, h: 1.76 },
  { i: TIMEZONE_SELECT_LAYOUT_KEY, x: 0, y: 1.76, w: 12, h: 1.76 },
  { i: BROWSER_TIMEZONE_LAYOUT_KEY, x: 0, y: 3.52, w: 12, h: 0, minH: 0 },
  { i: TOOLTIP_SELECT_LAYOUT_KEY, x: 0, y: 6, w: 12, h: 1.76 },
  { i: PREVIEW_SELECT_LAYOUT_KEY, x: 0, y: 8, w: 12, h: 1.76 },
];

export type TResizeConfig = {
  [key in TGridLayoutProfileKEY]: number;
};
type TGridLayoutProfileKEY =
  | "language_select"
  | "timezone_select"
  | "tooltip_select"
  | "preview_select"
  | "timezone_browser";

// export default PROFILE_VIEW_LAYOUTS;
export default function getLayoutInfo(resizeConfig: TResizeConfig) {
  return createLayout<TResizeConfig>(
    resizeConfig,
    layout_LG,
    layout_LG,
    layout_XS,
    layout_XS,
    layout_XS,
    layout_XS,
  );
}
