import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import IconCell from "../../../../../../shared/components/georgDataGrid/components/iconCell";
import { Delete, VpnKey } from "@mui/icons-material";
import { TFunction } from "i18next";
import { TooltipCell } from "../../../../../../shared/components/georgDataGrid/components/tooltipCell";
const defaultProps: Omit<GridColDef, "field"> = {
  headerAlign: "center",
  align: "center",
  sortable: true,
  editable: false,
  minWidth: 220,
  disableColumnMenu: true,
};

function tooltipTextCellRender(cellValue: string) {
  return (
    <TooltipCell tooltipText={cellValue}>
      <span>{cellValue}</span>
    </TooltipCell>
  );
}

export default function getColumnConfig(
  t: TFunction,
  onOpenResetPasswordDialog: (id: string) => void,
  openRemoveUserDialog: (id: string) => void,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isResetPasswordButtonDeactivated: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isRemoveUserButtonDeactivated: string,
): GridColDef[] {
  return [
    {
      ...defaultProps,
      field: "firstName",
      headerName: t("settings.userManagement.table.firstName"),
      renderHeader: () => (
        <h3>{t("settings.userManagement.table.firstName")}</h3>
      ),
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return tooltipTextCellRender(cellValue);
      },
    },
    {
      ...defaultProps,
      field: "lastName",
      headerName: t("settings.userManagement.table.lastName"),
      renderHeader: () => (
        <h3>{t("settings.userManagement.table.lastName")}</h3>
      ),
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return tooltipTextCellRender(cellValue);
      },
    },
    {
      ...defaultProps,
      field: "eMail",
      headerName: t("settings.userManagement.table.eMail"),
      renderHeader: () => <h3>{t("settings.userManagement.table.eMail")}</h3>,
      renderCell: (params: GridCellParams) => {
        const cellValue = params.value as string;
        return tooltipTextCellRender(cellValue);
      },
    },
    {
      ...defaultProps,
      field: "resetPassword",
      headerName: t("settings.userManagement.table.resetPassword"),
      sortable: false,
      filterable: false,
      minWidth: 170,
      renderHeader: () => (
        <h3>{t("settings.userManagement.table.resetPassword")}</h3>
      ),
      renderCell: (params: GridCellParams) => {
        return params.formattedValue ? (
          <TooltipCell
            tooltipText={t("settings.userManagement.table.resetPassword")}
          >
            <IconCell
              onClick={() => {
                onOpenResetPasswordDialog(params.id as string);
              }}
              icon={VpnKey}
            />
          </TooltipCell>
        ) : (
          <></>
        );
      },
    },
    {
      ...defaultProps,
      field: "removeUser",
      headerName: t("settings.userManagement.table.delete"),
      filterable: false,
      sortable: false,
      minWidth: 80,
      renderHeader: () => <h3>{t("settings.userManagement.table.delete")}</h3>,
      renderCell: (params: GridCellParams) => {
        return params.formattedValue ? (
          <TooltipCell tooltipText={t("settings.userManagement.table.delete")}>
            <IconCell
              onClick={() => {
                openRemoveUserDialog(params.id as string);
              }}
              icon={Delete}
            />
          </TooltipCell>
        ) : (
          <></>
        );
      },
    },
  ];
}
